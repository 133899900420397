import { removeFalsyProperties } from '@credit-web/common/object-utils'

import BlackBird from '~/domains/platform/lib/blackbird'

import { type UseCreditoffersReturn } from '../../shared'
import { type NegotiationsProposal, type Proposal, LoanConcessionSteps } from '../entities'

type LoanConcessionFlowGuardProps = {
  children: React.ReactNode
  proposalProcessing?: NegotiationsProposal | Proposal
  creditOffer: UseCreditoffersReturn
}

type ProposalNavigateProps = {
  step: LoanConcessionSteps
  query?: any
}

export function loanConcessionNavigate({ step, query }: ProposalNavigateProps) {
  const { proposalId, offerId } = BlackBird.getQuery()
  const params = removeFalsyProperties({ offerId, proposalId, ...query })

  return BlackBird.travelTo({
    pathname: '/credito/emprestimo/proposta/${step}',
    urlParams: { step },
    query: params
  })
}

export function LoanConcessionFlowGuard({ children, proposalProcessing, creditOffer }: LoanConcessionFlowGuardProps) {
  if (!creditOffer?.offer) BlackBird.goHome()

  if (proposalProcessing !== undefined && ![LoanConcessionSteps.QR_CODE].includes(BlackBird.getQuery().step)) {
    loanConcessionNavigate({ step: LoanConcessionSteps.QR_CODE })
  }
  return <>{children}</>
}
