import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Card, CardBody, Heading } from '@dlpco/ginga-stone'

import { SharedCreditGrid } from '~/domains/credit/shared/components/credit-grid'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import Wong from '~/domains/platform/lib/wong'

import { SharedStepList } from '../../../shared/components/shared-step-list'
import { type CreditOffer, type CreditOfferRolesRenegotiation } from '../../../shared/entities'
import { SpotOfferDialog } from '../../../spot-offer/components/spot-offer-dialog'
import { type SpotOffer } from '../../../spot-offer/entities'
import { RenegotiationDiscountSettlementBreadcrumb } from '../../components/discount-settlement/discount-settlement-breadcrumb'
import { DiscountSettlementProvider, useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { type RenegotiationData, DiscountSettlementSteps } from '../../entities'
import { basePath, isAdminProposal } from '../../helpers/renegotiation'
import { voidRenegotiationProposal } from '../../services/renegotiation'

import { RenegotiationDiscountRateAdminProposal } from './discount-settlement-rate-admin-proposal'
import { RenegotiationDiscountSettlementRatePaymentMethod } from './discount-settlement-rate-payment-method'
import { RenegotiationDiscountSettlementRateReview } from './discount-settlement-rate-review'
import { RenegotiationDiscountSettlementRateSimulation } from './discount-settlement-rate-simulation'
import { RenegotiationChallenge } from './renegotiation-challenge'

interface DiscountSettlementFlowProps {
  renegotiationData: RenegotiationData
  userEmail: string
}

const stepTexts: Partial<Record<Partial<DiscountSettlementSteps>, string>> = {
  simulacao: 'Simular renegociação',
  'forma-de-pagamento': 'Escolher forma de pagamento',
  revisao: 'Revisar pagamento',
  pin: 'Garantir seu desconto'
}

const proposalStepTexts: Partial<Record<Partial<DiscountSettlementSteps>, string>> = {
  revisao: 'Aceitar proposta',
  pin: 'Garantir seu desconto'
}

function getBestOfferCreditLimitForCustomer(offer: CreditOffer): number | null {
  const customerRole = offer.roles.filter(obj => {
    return obj.userType == 'Customer'
  })[0]

  if (customerRole) {
    const conditions = customerRole.conditions as CreditOfferRolesRenegotiation[]

    if (conditions.length > 0) {
      return conditions.reduce((prev: any, current: any) => {
        return prev.max > current.max ? prev : current
      }).max
    } else {
      return null
    }
  } else {
    return null
  }
}

export function renegotiationGoToLoan() {
  const { loan: loanId } = BlackBird.getQuery()
  const loanBasePath = '/credito/emprestimo/${loan}'
  BlackBird.travelTo({
    pathname: loanBasePath,
    urlParams: { loan: loanId }
  })
}

export function RenegotiationDiscountSettlementRateFlowBase({
  renegotiationData,
  userEmail
}: DiscountSettlementFlowProps) {
  const { step: currentStep, loan: loanId } = BlackBird.getQuery()
  const { saveOffer, saveProposal, proposal } = useDiscountSettlementContext()
  const [spotOffer, setSpotOffer] = useState<SpotOffer | undefined>(undefined)

  const isChallengeStep = currentStep === DiscountSettlementSteps.PIN
  useEffectOnce(() => {
    if (renegotiationData?.offer) saveOffer(renegotiationData?.offer)
    if (renegotiationData?.proposal) saveProposal(renegotiationData?.proposal)
  })

  if (
    renegotiationData?.proposal &&
    ![DiscountSettlementSteps.REVIEW, DiscountSettlementSteps.PIN, DiscountSettlementSteps.PROPOSAL].includes(
      currentStep
    )
  ) {
    const stepToNavigate = isAdminProposal(renegotiationData?.proposal)
      ? DiscountSettlementSteps.PROPOSAL
      : DiscountSettlementSteps.REVIEW
    BlackBird.travelTo({
      pathname: basePath,
      urlParams: { loan: loanId, step: stepToNavigate }
    })
  }

  if (!renegotiationData?.proposal && !currentStep) {
    BlackBird.travelTo({
      pathname: basePath,
      urlParams: { loan: loanId, step: DiscountSettlementSteps.SIMULATE }
    })
  }

  const steps: { key: DiscountSettlementSteps; render: () => JSX.Element }[] = [
    {
      key: DiscountSettlementSteps.PROPOSAL,
      render: () => <RenegotiationDiscountRateAdminProposal />
    },
    {
      key: DiscountSettlementSteps.SIMULATE,
      render: () => (
        <RenegotiationDiscountSettlementRateSimulation
          offerId={renegotiationData?.offer?.id}
          maxDiscountRate={
            (renegotiationData?.offer && getBestOfferCreditLimitForCustomer(renegotiationData?.offer)) || 0
          }
          installmentNumbersToShow={
            renegotiationData?.offer?.discountSettlement?.securities[0]?.installmentNumbers || []
          }
        />
      )
    },
    {
      key: DiscountSettlementSteps.PAYMENT_METHOD,
      render: () => (
        <RenegotiationDiscountSettlementRatePaymentMethod
          maxDiscountRate={
            (renegotiationData?.offer && getBestOfferCreditLimitForCustomer(renegotiationData?.offer)) || 0
          }
          offerId={renegotiationData?.offer?.id as string}
          userEmail={userEmail}
        />
      )
    },
    {
      key: DiscountSettlementSteps.REVIEW,
      render: () => <RenegotiationDiscountSettlementRateReview />
    },
    {
      key: DiscountSettlementSteps.PIN,
      render: () => <RenegotiationChallenge saveSpotOffer={setSpotOffer} />
    }
  ]
  const backFunction = () => {
    if (proposal && !isAdminProposal(proposal)) {
      voidRenegotiationProposal(proposal.id)
    }
    renegotiationGoToLoan()
  }
  return (
    <Stack space="1.5rem">
      <Stack space="1rem">
        <RenegotiationDiscountSettlementBreadcrumb
          backFunction={backFunction}
          currentStep={currentStep}
          fullFlow={Boolean(proposal) && !isAdminProposal(proposal || undefined)}
        />
        <Heading size="large" weight="semi">
          Renegociar empréstimo
        </Heading>
        <Choose>
          <Choose.When condition={isChallengeStep}>
            <Wong steps={steps} basePath={basePath} />
          </Choose.When>
          <Choose.Otherwise>
            <Card>
              <CardBody>
                <SharedCreditGrid
                  left={
                    <Stack space="2rem">
                      <Heading size="large" weight="semi">
                        Quitar com desconto
                      </Heading>
                      <SharedStepList
                        steps={proposal && isAdminProposal(proposal) ? proposalStepTexts : stepTexts}
                        currentStep={currentStep}
                      />
                    </Stack>
                  }
                  right={<Wong steps={steps} basePath={basePath} />}
                />
              </CardBody>
            </Card>
          </Choose.Otherwise>
        </Choose>
        <If
          condition={!!spotOffer}
          render={() => {
            if (spotOffer) {
              return (
                <SpotOfferDialog
                  isOpen={!!spotOffer}
                  spotOffer={spotOffer}
                  onClose={() => {
                    setSpotOffer(undefined)
                    renegotiationGoToLoan()
                  }}
                />
              )
            }
          }}
        />
      </Stack>
    </Stack>
  )
}

export function RenegotiationDiscountSettlementRateFlow(props: DiscountSettlementFlowProps) {
  return (
    <DiscountSettlementProvider>
      <RenegotiationDiscountSettlementRateFlowBase {...props} />
    </DiscountSettlementProvider>
  )
}
