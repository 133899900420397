import { useState } from 'react'
import { type AxiosResponse } from 'axios'

import { type CreditPaging } from '~/domains/credit/shared/entities'
import analitica from '~/domains/credit/shared/helpers/spot-offer-analitica'
import { type FetchSpotOfferReturn, fetchSpotOffer } from '~/domains/credit/shared/services/spot-offers'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { httpClientCreditNegotiations } from '../../loan-concession/infra/http-client-credit-negotiations'
import {
  type LegacySpotOffer,
  type LegacySpotOfferBoletoStatus,
  type LegacySpotOfferPixStatus,
  type SpotOffer,
  type SpotOfferStatus
} from '../entities'

export interface fetchSpotOffersProps {
  OriginExternalReference?: string
  productExternalId: string
  status?: SpotOfferStatus
  pixStatus?: LegacySpotOfferPixStatus
  boletoStatus?: LegacySpotOfferBoletoStatus
  document: string
  pageNumber?: number
  pageSize?: number
}

export const fetchSpotOffers = (params?: fetchSpotOffersProps) => {
  return httpClientCreditNegotiations<CreditPaging<SpotOffer[]>>({
    url: `/experience-bff-service/api/web/v1/spot-offers}`,
    params: { ...{ pageNumber: 1, pageSize: 20 }, ...params }
  })
}

interface useSpotOfferProps {
  spotOfferId: string
  showSpotOffer: (a: SpotOffer) => void
  showEmail: () => void
  proposalId: string
  productName: string
  queryOptions?: UseQueryRequestOptions<FetchSpotOfferReturn>
}

export function useSpotOffer({
  spotOfferId,
  showSpotOffer,
  showEmail,
  proposalId,
  productName,
  queryOptions
}: useSpotOfferProps) {
  const [shouldRefetch, setShouldRefetch] = useState(true)
  const [retryInterval, setRetryInterval] = useState(3000)
  const [count, setCount] = useState(0)
  const { data: response, ...restQuery } = useQueryRequest<FetchSpotOfferReturn>(
    ['useSpotOffer'],
    () => fetchSpotOffer(spotOfferId),
    {
      ...queryOptions,
      onSettled: (response: AxiosResponse<FetchSpotOfferReturn> | undefined) => {
        const spotOffer = response?.data.content
        const retryContent = response?.data.retry
        if (spotOffer?.generalStatus === 'processed') {
          setShouldRefetch(false)
          showSpotOffer(spotOffer)
        } else if (retryContent && count > retryContent?.attempts) {
          analitica.events.flow.errorSpotOffer({
            paymentMethodStatus: spotOffer?.generalStatus || '',
            paymentType: spotOffer?.paymentMethod || 'Pix',
            spotOfferStatus: spotOffer?.generalStatus || '',
            proposalId: proposalId,
            product: productName
          })
          setShouldRefetch(false)
          showEmail()
        }

        if (retryContent) setRetryInterval(retryContent.intervalInSeconds * 1000)
        setCount(current => current + 1)
      },
      refetchInterval: shouldRefetch ? retryInterval : false,
      refetchIntervalInBackground: true,
      retry: 7
    }
  )
  return {
    ...restQuery,
    data: response?.data
  }
}

export function useSpotOfferList(
  params: fetchSpotOffersProps,
  queryOptions?: UseQueryRequestOptions<CreditPaging<LegacySpotOffer[]>>
) {
  const { data: response, ...restQuery } = useQueryRequest<CreditPaging<LegacySpotOffer[]>>(
    ['useSpotOfferList'],
    () => fetchSpotOffers(params),
    {
      ...queryOptions
    }
  )
  return {
    ...restQuery,
    data: response?.data
  }
}
