import { type InputHTMLAttributes, type ReactNode, forwardRef } from 'react'
import { Grid } from '@dlpco/fluid-layout'
import { Radio, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'

import { CardWrapper } from './styles'

export interface CardCheckProps extends Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onBlur'> {
  title: string | ReactNode
  value?: string
  name?: string
  subtitle?: string | ReactNode
  stoneCode?: string
  icon?: ReactNode
  disabled?: boolean
  withRadio?: boolean
  defaultValue?: boolean
  sx?: React.CSSProperties
}

export const CardRadio = forwardRef<HTMLInputElement, CardCheckProps>((props, ref) => {
  const { title, subtitle, name, value, stoneCode, icon, disabled, withRadio = true, defaultValue, sx, ...rest } = props

  return (
    <CardWrapper as="label" style={sx as Record<string, unknown>} p="large" data-testid="card">
      <Flex alignItems="center" justifyContent="space-between">
        {withRadio && (
          <Radio
            id={name}
            role="radio"
            name={name}
            ref={ref}
            value={value}
            disabled={disabled}
            defaultChecked={defaultValue}
            {...rest}
          />
        )}
        <Box mr="auto" ml="medium">
          <Text weight="bold">{title}</Text>
          <Text size="small">{subtitle}</Text>
        </Box>
        <Grid min="max-content" gutter="0.5rem">
          <Text color="neutral" weight="bold" size="small">
            {stoneCode}
          </Text>
          {icon}
        </Grid>
      </Flex>
    </CardWrapper>
  )
})
