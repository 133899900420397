import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, Heading, theme } from '@dlpco/ginga-stone'

import { SharedCreditListBox } from '~/domains/credit/shared/components/credit-list-box'
import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import { RenegotiationFlowLoading } from '../../renegotiation/components/renegotiation/renegotiation-flow-loading'
import { type SummaryFields } from '../../renegotiation/entities'
import { formatRenegotiationConditions } from '../../renegotiation/helpers/format-renegotiation-conditions'
import { useLoanSummary } from '../../renegotiation/hooks/use-loan-summary'
import { isRenegotiationSummary } from '../../shared/helpers/loan'

import { RenegotiationFollowUpBreadcrumb } from './renegotiation-follow-up-breadcrumb'

export function RenegotiationFollowUpConditions() {
  const { loan: loanId } = BlackBird.getQuery()
  const { data: summaryResponse, isLoading, isError, isFetched } = useLoanSummary(loanId)
  const { previousSummary, currentSummary } = summaryResponse ?? {
    previousSummary: {},
    currentSummary: {},
    renegotiationSummary: {}
  }
  const hasRenegotiation = isRenegotiationSummary(summaryResponse)
  const { loan } = BlackBird.getQuery()
  const goToinstallments = () =>
    BlackBird.travelTo({
      pathname: '/credito/emprestimo/${loan}/renegociacao-parcelas',
      urlParams: { loan }
    })

  if (isLoading) return <RenegotiationFlowLoading />

  if (isError || (isFetched && !summaryResponse)) return <SharedGenericCardError />

  const changedValues = formatRenegotiationConditions({ summary: currentSummary as SummaryFields, highlightText: true })
  const initialValues = formatRenegotiationConditions({
    summary: previousSummary as SummaryFields,
    useAlternativeBalanceLabel: true,
    totalRowsToAdd: Math.abs(Number(currentSummary?.plans?.length) - Number(previousSummary?.plans?.length))
  })

  return (
    <Box>
      <Stack space="1rem">
        <RenegotiationFollowUpBreadcrumb currentStep="renegociacao-condicoes" />
        <Heading size="xLarge" weight="semi">
          Condições da renegociação
        </Heading>

        <Stack space={theme.light.space.large}>
          <Card spacing="large">
            <CardBody>
              <Flex justifyContent="space-between" mt={theme.light.space.xLarge}>
                <Box width="48%">
                  <SharedCreditListBox title="Como ficou" items={changedValues} />
                </Box>

                <Box width="48%">
                  <SharedCreditListBox
                    title="Como era"
                    button={
                      <If condition={hasRenegotiation}>
                        <Button color="neutral" size="small" icon="extract-outline" onClick={goToinstallments}>
                          Conferir parcelas
                        </Button>
                      </If>
                    }
                    items={initialValues}
                  />
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </Stack>
      </Stack>
    </Box>
  )
}
