import { type CreditPaging } from '~/domains/credit/shared/entities'
import { queryUrlParser } from '~/domains/credit/shared/helpers/query-url-parser'
import { BFF_LEGACY_V1, BFF_V2, CREDIT_NEGOTIATIONS_V1 } from '~/domains/credit/shared/infra/apis-paths'
import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'

import { type NegotiationsProposal } from '../../loan-concession/entities'
import { httpClientCreditNegotiations } from '../../loan-concession/infra/http-client-credit-negotiations'
import { type CreditOffer } from '../entities'

export const fetchCreditOffersByDocument = (document: string) => {
  const status = ['Available', 'Suspended']
  const queryParam = new URLSearchParams()
  for (const item of status) {
    queryParam.append('NegotiationStatus', item)
  }
  return httpClientCredit<CreditPaging<CreditOffer[]>>({
    url: `${BFF_LEGACY_V1}/offers?${queryParam}`,
    params: {
      document
    }
  })
}

export const fetchCreditOfferById = (offerId: string) =>
  httpClientCredit<CreditOffer>({
    url: `${BFF_LEGACY_V1}/offers/${offerId}`
  })

type ProposalsByDocumentProps = {
  document: string
  offerId?: string
  status?: 'Available' | 'Processing'
  type?: 'LoanConcession' | 'RevolvingCredit' | 'Renegotiation' | 'DiscountSettlement'
}

export const fetchProposalsByDocument = ({ document, status, type, offerId }: ProposalsByDocumentProps) => {
  return httpClientCredit<CreditPaging<NegotiationsProposal[]>>({
    method: 'GET',
    url: `${BFF_LEGACY_V1}/proposals`,
    params: {
      status,
      document,
      offerId,
      type
    }
  })
}

export async function getProposalByIdV2(proposalId: string) {
  return await httpClientCreditNegotiations<NegotiationsProposalProps>({
    url: `${BFF_V2}/proposals/${proposalId}`
  })
}

export async function getProposalById(proposalId: string) {
  return await httpClientCreditNegotiations<NegotiationsProposal>({
    url: `${BFF_LEGACY_V1}/proposals/${proposalId}`
  })
}

export async function acceptProposal(proposalId?: string) {
  return httpClientCreditNegotiations({
    url: `${BFF_LEGACY_V1}/proposals/${proposalId}/accept`,
    method: 'POST'
  })
}

/**
 * @deprecated use `acceptProposal`
 */
export async function acceptNegotiationProposal(proposalId?: string) {
  return httpClientCreditNegotiations({
    url: `${CREDIT_NEGOTIATIONS_V1}/proposals/${proposalId}/accept`,
    method: 'POST'
  })
}

type Contract = {
  id: string
  contractId: string
}

export async function fetchContracts(proposalId: string) {
  const { data } = await httpClientCreditNegotiations<CreditPaging<Contract[]>>({
    url: '/contracts-service/api/v1/contracts',
    method: 'GET',
    params: {
      originId: proposalId
    }
  })

  return data
}

/**
 * @deprecated use `fetchCreditOffersByDocument`
 */
export const fetchCreditOffers = (params: any = {}) => {
  const status = ['Available', 'Suspended']
  const queryParam = new URLSearchParams()
  for (const item of status) {
    queryParam.append('NegotiationStatus', item)
  }
  return httpClientCredit<CreditPaging<CreditOffer[]>>({
    url: `/credit-negotiations-service/api/v1/offers?${queryParam}`,
    params
  })
}

/**
 * @deprecated use `fetchCreditOfferById`
 */
export const fetchCreditOffer = (offerId: string) =>
  httpClientCredit<CreditOffer>({
    url: `/credit-negotiations-service/api/v1/offers/${offerId}`
  })

/**
 * @deprecated use `ProposalsByDocumentProps`
 */
interface NegotiationsProposalProps {
  offerId?: string
  status?: 'Available' | 'Processing'
}

/**
 * @deprecated use `fetchProposalsByDocument`
 */
export const fetchNegotiationsProposal = ({ offerId, status }: NegotiationsProposalProps) => {
  return httpClientCredit<CreditPaging<NegotiationsProposal[]>>({
    method: 'GET',
    url: `/credit-negotiations-service/api/v1/proposals${queryUrlParser({ offerId, status })}`
  })
}

/**
 * @deprecated use `getProposalById`
 */
export async function getNegotiationsProposalById(proposalId: string) {
  return await httpClientCreditNegotiations<NegotiationsProposal>({
    url: `/credit-negotiations-service/api/v1/proposals/${proposalId}`
  })
}
