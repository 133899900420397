import { useEffectOnce } from 'react-use'
import { Button, Card, CardBody, Heading, Text } from '@dlpco/ginga-stone'
import { PaymentSuccessIllustration } from 'design-elements/shared/illustrations'
import styled from 'styled-components'

import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { Box } from '~/domains/platform/design-system'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'
import { theme } from '~/ui/theme'

const CardBanner = styled(Card)`
  height: 235px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface BannerProposalProps {
  handleClick: () => void
}

export function RenegotiationBannerProposal({ handleClick }: BannerProposalProps) {
  useEffectOnce(() => renegotiationAnalitica.events.banner.proposalView('proposta'))
  const { isViewSmall } = useViewSize()

  return (
    <CardBanner>
      <CardBody style={{ padding: theme.space.xLarge }}>
        <Box width="417px">
          <Heading weight="semi" size="xLarge" style={{ marginBottom: theme.space.medium }}>
            Renegocie seu empréstimo
          </Heading>
          <Text size="large" style={{ marginBottom: theme.space.xLarge }}>
            Encontre as melhores condições para quitar o valor em aberto do seu empréstimo.
          </Text>
          <Button
            color="primary"
            style={{ width: '100%' }}
            onClick={handleClick}
            data-test-id="renegotiation-proposal-banner-button"
          >
            Conferir condições
          </Button>
        </Box>
      </CardBody>
      {!isViewSmall && <PaymentSuccessIllustration />}
    </CardBanner>
  )
}
