import { type LoanSection } from '../entities'

import { LoanHomeActiveLoanCard } from './loan-home-active-loan-card'
import { LoanHomeAnalysisLoanCard } from './loan-home-analysis-loan-card'
import { LoanHomeRevolvingCredit } from './loan-home-revolving-credit-card'

export function LoanHomeLoanCards({ loans = [] }: { loans: LoanSection[] }) {
  return (
    <>
      {loans?.map((loanInfo: LoanSection, index) => {
        const key = `loan-home-cards-${index}-${loanInfo.type}-${loanInfo.id}`
        if (loanInfo.type === 'revolving_credit') return <LoanHomeRevolvingCredit loanInfo={loanInfo} key={key} />
        if (loanInfo.type === 'current_loan') return <LoanHomeActiveLoanCard loanInfo={loanInfo} key={key} />
        if (['processing_loan', 'canceled_loan'].includes(loanInfo.type))
          return (
            <LoanHomeAnalysisLoanCard
              loanAmount={loanInfo?.creditAmount}
              creationDate={loanInfo?.creationDate}
              id={loanInfo?.id}
              status={loanInfo?.status}
              daysToReceive={loanInfo?.daysToReceive}
              key={key}
              type={loanInfo?.type}
            />
          )
        return null
      })}
    </>
  )
}
