import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import {
  Badge,
  Button,
  Card,
  CardAlert,
  CardBody,
  Description,
  Heading,
  Icon,
  Money,
  Overline,
  Text
} from '@dlpco/ginga-stone'
import styled, { css } from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { If } from '~/domains/platform/lib/utilities-components'
import { SideModal } from '~/ui/components/modals/side-modal'
import { theme } from '~/ui/theme'

import { type InstallmentStatus, type RenegotiationSummaryStatus } from '../../shared/entities'

const ProgressBarWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mediumGray};
  height: 18px;
  width: 100%;
  margin: 0.5rem 0 1rem;
  border-radius: 4px;
`

const ProgressBar = styled.div<{ progress: string }>`
  border-radius: 4px;
  height: 18px;
  margin: 0.5rem 0;
  position: relative;
  overflow: hidden;

  ${({ theme, progress }) =>
    css`
      background-color: ${theme.colors.stoneGreen};
      width: ${progress}%;

      &::after {
        ${+progress <= 99 && "content: '';"}
        background-color: ${theme.colors.white};
        height: 18px;
        width: 2px;
        position: absolute;
        margin-left: calc(100% - 2px);
      }
    `}
`

const AlertWrapper = styled.div`
  margin-top: 1rem;
  cursor: pointer;
  color: ${theme.colors.warning};
  border-top: 1px solid ${theme.colors.mediumGray4};
  padding-top: 1rem;
`

function LoanDetailsAboutLoanLoader() {
  return (
    <Card>
      <CardBody>
        <Box mb="1rem">
          <Skeleton width="90%" height="1rem" />
        </Box>

        <Skeleton width="90%" height="1rem" />

        <Skeleton width="90%" height="1rem" />

        <Skeleton width="90%" height="1rem" />

        <Box mt="1rem">
          <Stack space="0.5rem">
            <Skeleton width="90%" height="2rem" />

            <Skeleton width="90%" height="2rem" />
          </Stack>
        </Box>
      </CardBody>
    </Card>
  )
}

type LoanDetailsAboutLoanProps = {
  paidAmount: number
  futureBalance: number
  interestEconomyAmount: number
  currentInstallmentStatus?: InstallmentStatus
  isLoading: boolean
  actionComponent?: React.ReactNode
  renegotiationStatus?: RenegotiationSummaryStatus
}

export function LoanDetailsAboutLoan({
  paidAmount,
  futureBalance,
  interestEconomyAmount,
  currentInstallmentStatus,
  isLoading,
  actionComponent,
  renegotiationStatus
}: LoanDetailsAboutLoanProps) {
  const [isOpen, toggleOpen] = useToggle(false)
  const [isDrawerOpen, toggleDrawerOpen] = useToggle(false)
  const paidAmountValue = Math.abs(paidAmount)
  const interestEconomyAmountValue = Math.abs(interestEconomyAmount)

  const normalizedFutureBalance = futureBalance <= 1 ? 0 : futureBalance

  const totalToPayAmount = Math.abs(paidAmountValue + normalizedFutureBalance)
  const percentage = ((100 * paidAmountValue) / totalToPayAmount).toFixed(2)

  const hasPenaltyWarning = currentInstallmentStatus === 'Overdue'

  if (isLoading) return <LoanDetailsAboutLoanLoader />

  const hasRenegotiation = !!renegotiationStatus
  const hasActiveRenegotiation = Boolean(hasRenegotiation && ['Active', 'Settled'].includes(renegotiationStatus))
  const isRenegotiationInAnalysis =
    hasRenegotiation &&
    ['DisbursementRequested', 'Disbursed', 'Created', 'TreasuryOrderRequested', 'TreasuryOrderApproved'].includes(
      renegotiationStatus
    )

  return (
    <>
      <SideModal isOpen={isOpen} toggle={toggleOpen} title="Cobranças por atraso">
        <Text color="neutral">
          As cobranças por atraso são: multa, juros remuneratórios e juros de mora. Entenda as condições de cada uma
          delas nas perguntas frequentes.
        </Text>
      </SideModal>
      <SideModal isOpen={isDrawerOpen} toggle={toggleDrawerOpen} title="A economia de juros reduz o valor da parcela">
        <Flex gap="2rem" flexDirection="column">
          <Text color="neutral">
            Qualquer pagamento feito antes do vencimento da parcela gera economia de juros e o{' '}
            <b>valor economizado é descontado do total da parcela.</b>
          </Text>

          <Flex>
            <Button
              color="neutral"
              size="small"
              onClick={() => window.open('https://ajuda.stone.com.br/', '_blank')}
              icon="direction-arrow-right-outline"
              iconPosition="end"
            >
              Ir pras perguntas frequentes
            </Button>
          </Flex>
        </Flex>
      </SideModal>
      <Card>
        <CardBody>
          <Box mb="1rem">
            <Flex alignItems="start" flexDirection="column" gap=".5rem">
              <Overline color="neutral">Sobre seu empréstimo</Overline>
              <If condition={hasActiveRenegotiation}>
                <Badge>Renegociado</Badge>
              </If>
            </Flex>
          </Box>

          <Box>
            <Description color="neutral">Total quitado</Description>
            <Heading weight="bold">
              <Money amount={paidAmountValue} />
            </Heading>
          </Box>

          <ProgressBarWrapper>
            <ProgressBar progress={percentage} />
          </ProgressBarWrapper>

          <If condition={interestEconomyAmountValue > 0}>
            <Box mt="1rem" borderTop={`1px solid ${theme.colors.mediumGray4}`} paddingTop="1rem">
              <Flex justifyContent="space-between">
                <Flex alignItems="center">
                  <Box mr="2px">
                    <Description color="neutral">Economia de juros</Description>
                  </Box>
                  <Icon
                    use="round-info-outline"
                    color="neutral"
                    onClick={toggleDrawerOpen}
                    style={{ cursor: 'pointer' }}
                  />
                </Flex>
                <Description>
                  <strong>
                    <Money amount={interestEconomyAmountValue || 0} />
                  </strong>
                </Description>
              </Flex>
            </Box>
          </If>
          <Box mt="1rem" borderTop={`1px solid ${theme.colors.mediumGray4}`} paddingTop="1rem">
            <Stack space="0.5rem">
              <Flex justifyContent="space-between">
                <Description color="neutral">Valor restante</Description>
                <Description>
                  <strong>
                    <Money amount={normalizedFutureBalance || 0} />
                  </strong>
                </Description>
              </Flex>
              <If condition={currentInstallmentStatus !== 'Settled'}>
                <Flex justifyContent="space-between">
                  <Description color="neutral">Total a pagar</Description>
                  <Description>
                    <strong>
                      <Money amount={totalToPayAmount} />
                    </strong>
                  </Description>
                </Flex>
              </If>
            </Stack>
          </Box>
          <If condition={hasPenaltyWarning}>
            <AlertWrapper>
              <CardAlert color="warning" onClick={toggleOpen}>
                <Stack space="0.5rem">
                  <Text size="small"> Os valores estão aumentando por causa das cobranças por atraso.</Text>
                  <Box color="alertDarkOrange">
                    <Text size="small" weight="bold" inheritColor>
                      Entender cobranças por atraso
                    </Text>
                  </Box>
                </Stack>
              </CardAlert>
            </AlertWrapper>
          </If>
          <If condition={!isRenegotiationInAnalysis}>
            <Box mt="1rem">{actionComponent}</Box>
          </If>
        </CardBody>
      </Card>
    </>
  )
}
