import { type ReactNode } from 'react'

import { Box, Flex } from '~/domains/platform/design-system'

interface GridProps {
  left: ReactNode
  right: ReactNode
}

export function SharedCreditGrid({ left, right }: GridProps) {
  return (
    <Flex justifyContent="space-between" gap="2.5rem">
      <Box width="40%">{left}</Box>
      <Box width="60%">{right}</Box>
    </Flex>
  )
}
