import { Stack } from '@dlpco/fluid-layout'
import { Card, Overline } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'

import { BorderHeader, CardBodyGrid, FooterGrid, StackTextEnd } from './loan-installment-details-summary.styles'

export const LoanInstallmentDetailsSummaryLoading = () => {
  return (
    <Card data-testid="installment-summary-loading">
      <BorderHeader verticalAlignment="center">
        <Flex height="1.5rem" alignItems="center">
          <Overline style={{ width: '20%' }}>
            <Skeleton width="100%" height="4ch" />
          </Overline>
        </Flex>
      </BorderHeader>

      <CardBodyGrid>
        <Flex justifyContent="space-between" alignItems="center">
          <Skeleton width="50%" height="6ch" />
        </Flex>
        <StackTextEnd>
          <Skeleton width="100%" height="6ch" />
        </StackTextEnd>
        <StackTextEnd>
          <Skeleton width="100%" height="6ch" />
        </StackTextEnd>
      </CardBodyGrid>

      <FooterGrid>
        <Stack>
          <Skeleton width="20%" height="3ch" />
        </Stack>

        <StackTextEnd>
          <Skeleton width="100%" height="3ch" />
        </StackTextEnd>

        <StackTextEnd>
          <Skeleton width="100%" height="3ch" />
        </StackTextEnd>
      </FooterGrid>
    </Card>
  )
}
