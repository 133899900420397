import { type AssessmentCheck } from '~/domains/stone-account/ports/entities/KYC'

import { KycFlowForm } from '../components/kyc-flow-form'

export type KycSteps = 'revisao' | 'marital_status' | 'nationality' | 'address'

export type KycStepperData = {
  render: () => JSX.Element
  key: KycSteps
  isForm?: boolean
}

export function buildKycFlowSteps(assessmentChecks: AssessmentCheck[]): KycStepperData[] {
  const order = ['revisao', 'nationality', 'marital_status', 'address']
  const checks = assessmentChecks.map((check: AssessmentCheck) => buildKycFlowFormStep(check))
  checks.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key))
  return checks
}

export function buildKycFlowFormStep(check: AssessmentCheck): KycStepperData {
  return {
    key: check.fieldType as KycSteps,
    render: () => <KycFlowForm assessmentCheck={check} />,
    isForm: true
  }
}

interface StepTitle {
  revisao: string
  marital_status: string
  nationality: string
  address: string
}
export function getStepTitle(step: string): string {
  const stepTitles: StepTitle = {
    revisao: 'Atualização de dados',
    nationality: 'Em qual país você nasceu?',
    marital_status: 'Qual seu estado civil atual?',
    address: 'Qual o endereço da sua casa?'
  }
  return stepTitles[step as keyof StepTitle]
}
