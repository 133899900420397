import { type BFFDictionary, type BFFRoot, type BFFRootList, type CustomerV2 } from '~/domains/credit/shared/entities'

import { type RenegotiationProposal } from '../loan-concession/entities'
import { type CreditOffer } from '../shared/entities'

export enum RenegotiationSteps {
  PROPOSAL = 'proposta',
  REVIEW = 'revisao',
  KYC = 'dados-pessoais',
  ROOT = '/',
  PIN = 'pin',
  CONDITIONS = 'condicoes'
}

export enum DiscountSettlementSteps {
  SIMULATE = 'simulacao',
  PROPOSAL = 'proposta',
  PIN = 'pin',
  PAYMENT_METHOD = 'forma-de-pagamento',
  REVIEW = 'revisao'
}

export interface SummaryFields {
  initialInstallmentAmount: number
  initialAmount: number
  futureBalance: number
  currentBalance: number
  taxAmount: number
  numberOfInstallments: number
  retentionRate: number
  monthlyInterestRate?: number
  yearlyInterestRate?: number
  yearlyTotalEffectiveCostRate: number
  dueDate: string
  plans?: {
    amountByInstallment: {
      value: number
      description: string
      type: string
    }
    installments: {
      value: number
      description: string
      type: string
    }
    total: {
      value: number
      description: string
      type: string
    }
    type: string
  }[]
  gracePeriodInDays?: number
}

export interface RenegotiationData {
  offer?: CreditOffer
  proposal?: RenegotiationProposal
}

export type RenegotiationPaymentMethod = 'Boleto' | 'Pix'

export interface RenegotiationSimulation {
  type: string
  securities: [
    {
      type: string
      externalReference: string
      isOverdue: boolean
      installmentNumbers: number[]
      summary: {
        totalPaid: number
        currentDebtAmount: number
        numberOfOpenInstallments: number
        daysOverdue: number
      }
      lastUpdateDate: string
    }
  ]
  installmentNumbers: number[]
  paymentMethod: RenegotiationPaymentMethod
  negotiationDebtAmount: number
  discountAmount: number
  discountOfferRate: number
  discountEffectiveRate: number
  amount: number
  dueDate: string
}

// V2

type BFFDictionaryStringValue = BFFDictionary<string>
type BFFDictionaryNumberValue = BFFDictionary<number>

type Type = BFFDictionary<
  'loan_concession' | 'renegotiation' | 'discount_settlement' | 'revolving_credit',
  'enum',
  string
>
type InstallmentType = BFFDictionary<'regular' | 'grace_with_interest', 'enum', string>
type Status = BFFDictionary<
  'created' | 'available' | 'accepted' | 'processing' | 'processed' | 'voided' | 'expired' | 'suspended',
  'enum',
  string
>

type DueDate = BFFDictionaryStringValue
type HasGracePeriodInterest = BFFDictionary<boolean>
type PaymentReferenceDate = BFFDictionaryStringValue
type Date = BFFDictionaryStringValue
type TotalSum = BFFDictionaryNumberValue
type GracePeriod = BFFDictionaryNumberValue
type Installments = BFFDictionaryNumberValue
type InterestRate = BFFDictionaryNumberValue
type RetentionRate = BFFDictionaryNumberValue
type AnnualTotalEffectiveCostRate = BFFDictionaryNumberValue
type AnnualInterestRate = BFFDictionaryNumberValue
type TaxAmount = BFFDictionaryNumberValue

interface Formalization {
  type: string
}

interface Origin {
  createdBy: string
  userType: string
}

export interface CurrentConditions {
  taxAmount: TaxAmount
  annualTotalEffectiveCostRate: AnnualTotalEffectiveCostRate
  annualInterestRate: AnnualInterestRate
  retentionRate: RetentionRate
  interestRate: InterestRate
  iofRate: BFFDictionary<number>
  gracePeriod: GracePeriod
  dueDate: DueDate
  installments: Installments
  hasGracePeriodInterest: HasGracePeriodInterest
  paymentReferenceDate: PaymentReferenceDate
  totalSum: TotalSum
  lowestInstallment: BFFDictionary<number, 'money'>
}

interface ProductSummary {
  totalPaid: BFFDictionary<number>
  currentDebtAmount: BFFDictionary<number>
  numberOfOpenInstallments: BFFDictionary<number>
  dueDate: DueDate
  daysOverdue: BFFDictionary<number>
}

interface ProposalConditions {
  taxAmount: TaxAmount
  annualTotalEffectiveCostRate: AnnualTotalEffectiveCostRate
  annualInterestRate: AnnualInterestRate
  retentionRate: RetentionRate
  interestRate: InterestRate
  gracePeriod: GracePeriod
  dueDate: DueDate
  installments: Installments
  hasGracePeriodInterest: HasGracePeriodInterest
  paymentReferenceDate: PaymentReferenceDate
  totalSum: TotalSum
}

export interface ProposalPlan {
  amountByInstallment: BFFDictionary<number>
  installments: Installments
  total: BFFDictionary<number>
  type: InstallmentType
}

export interface ProposalInstallment {
  installmentNumber: BFFDictionary<number>
  amount: BFFDictionary<number>
  date: Date
  type: InstallmentType
}

interface RenegotiationProposalIdContent {
  id: string
  offerId: string
  loanId: string
  type: Type
  status: Status
  origin: Origin
  formalization: Formalization
  expirationDate: string
  creationDate: string
  lastUpdateDate: string
  templateContractUrl: string
  customer: CustomerV2
  current: {
    conditions: CurrentConditions
    productSummary: ProductSummary
    lastUpdateDate: string
  }
  proposal: {
    conditions: ProposalConditions
    plans: ProposalPlan[]
    installments: ProposalInstallment[]
    lastUpdateDate: string
  }
}

export type RenegotiationProposalId = RenegotiationProposalIdContent
export type RenegotiationProposals = RenegotiationProposalIdContent[]

export type RenegotiationProposalIdRoot = BFFRoot<RenegotiationProposalIdContent>
export type RenegotiationProposalsRoot = BFFRootList<RenegotiationProposalIdContent>
