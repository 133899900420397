import { useEffect, useState } from 'react'
import useToggle from 'react-use/lib/useToggle'
import { Box } from '@dlpco/fluid-layout'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { getSpotOfferById } from '~/domains/credit/shared/services/spot-offers'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import Wong from '~/domains/platform/lib/wong'

import { SharedLoanGenericFeedback } from '../../shared/components/shared-loan-generic-feedback'
import { SpotOfferProvider } from '../context/spot-offer-context'
import { type SpotOffer } from '../entities'
import { convertLegacySpotOffer, getSpotOfferTitle } from '../helpers/spot-offer'
import { useSpotOfferFlow } from '../hooks/use-spot-offer-flow'

import {
  SpotOfferFlowIndex,
  SpotOfferFlowNextInstallments,
  SpotOfferFlowReview,
  SpotOfferFlowSpecificAmount,
  SpotOfferNavigationGuard,
  SpotOfferPayment,
  SpotOfferPaymentMethod
} from './spot-offer-flow-routes'
import { SpotOfferLoading } from './spot-offer-loading'

export enum SpotOfferSteps {
  SPOT_OFFER_INDEX = 'inicio',
  SPOT_OFFER_CURRENT_INSTALLMENT = 'antecipar-parcela-atual',
  SPOT_OFFER_SPECIFIC_AMOUNT_INPUT = 'escolher-valor-especifico',
  SPOT_OFFER_SPECIFIC_AMOUNT = 'valor-especifico',
  SPOT_OFFER_ALL_INSTALLMENTS = 'quitar-emprestimo',
  SPOT_OFFER_NEXT_INSTALLMENTS_INPUT = 'escolher-proximas-parcelas',
  SPOT_OFFER_NEXT_INSTALLMENTS = 'proximas-parcelas',
  SPOT_OFFER_PAYMENT_METHOD = 'forma-de-pagamento',
  SPOT_OFFER_PAYMENT = 'pagamento'
}

const basePath = '/credito/emprestimo/${loan}/antecipar-pagamento/inicio'

const SpotOfferContainer = styled(Box)`
  min-height: 100%;
  background-color: ${jadeTheme.theme.color.background.surface};
  justify-content: center;
  position: relative;
`

export function SpotOfferFlow({
  entity,
  subject
}: WithPageConfigProps<'stone_account' | 'banking' | 'stone_account_kyc'>) {
  const { document } = entity
  const { loan: loanId, spotOfferId } = BlackBird.getQuery()
  const [isFetchingSpotOffer, setFetchingSpotOffer] = useToggle(false)

  const {
    data: { summary, installments, spotOffers },
    isError: isSpotOfferFlowError,
    isLoading: isSpotOfferFlowLoading,
    isSpotOffersLoading
  } = useSpotOfferFlow(document, loanId)

  const [spotOffer, setSpotOffer] = useState<SpotOffer>()

  const checkForSpotOffer = async () => {
    setFetchingSpotOffer(true)
    try {
      const _spotOffer = await getSpotOfferById(spotOfferId)
      setSpotOffer(_spotOffer.content)
      setFetchingSpotOffer(false)
    } catch (e) {
      console.error('Erro ao tentar recuperar a oferta')
      setFetchingSpotOffer(false)
    }
  }

  useEffect(() => {
    if (spotOffer === undefined && !!spotOfferId) {
      checkForSpotOffer()
    }
  }, [spotOfferId])

  useEffect(() => {
    if (spotOffers && spotOffers.length > 0) {
      setSpotOffer(convertLegacySpotOffer(spotOffers[0]))
    }
  }, [spotOffers])

  if (isSpotOfferFlowLoading || isFetchingSpotOffer || isSpotOffersLoading) return <SpotOfferLoading />

  if (isSpotOfferFlowError) return <SharedLoanGenericFeedback navigationBarTitle="Fazer um pagamento" hasSagaKey />

  const steps: { key: SpotOfferSteps; render: () => JSX.Element }[] = [
    {
      key: SpotOfferSteps.SPOT_OFFER_INDEX,
      render: () => <SpotOfferFlowIndex summary={summary} spotOffer={spotOffer || undefined} />
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS_INPUT,
      render: () => (
        <SpotOfferFlowNextInstallments
          installments={installments}
          loanId={loanId}
          title={getSpotOfferTitle(SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS_INPUT, summary?.currentInstallment)}
        />
      )
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS,
      render: () => <SpotOfferFlowReview summary={summary} installments={installments} />
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_CURRENT_INSTALLMENT,
      render: () => <SpotOfferFlowReview summary={summary} installments={installments} />
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_ALL_INSTALLMENTS,
      render: () => <SpotOfferFlowReview summary={summary} installments={installments} />
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT,
      render: () => <SpotOfferFlowReview summary={summary} installments={installments} />
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT_INPUT,
      render: () => <SpotOfferFlowSpecificAmount futureBalance={summary.futureBalance} />
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_PAYMENT_METHOD,
      render: () => (
        <SpotOfferPaymentMethod
          summary={summary}
          installments={installments}
          setSpotOffer={setSpotOffer}
          email={subject?.email || ''}
        />
      )
    },
    {
      key: SpotOfferSteps.SPOT_OFFER_PAYMENT,
      render: () => <SpotOfferPayment spotOffer={spotOffer || undefined} />
    }
  ]

  return (
    <SpotOfferProvider>
      <SpotOfferNavigationGuard>
        <SpotOfferContainer>
          <Wong steps={steps} basePath={basePath} />
        </SpotOfferContainer>
      </SpotOfferNavigationGuard>
    </SpotOfferProvider>
  )
}
