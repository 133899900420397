import { type KeyboardEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Heading } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Box, Flex } from '~/domains/platform/design-system'
import { Input } from '~/domains/platform/design-system/input/input'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { Select } from '~/domains/platform/design-system/select/select'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose, For } from '~/domains/platform/lib/utilities-components'
import { BrazilianStatesSelectOptions, masks } from '~/lib/helpers'
import { unmask } from '~/lib/helpers/utils/unmask'
import { useAddress } from '~/ui/hooks/utils/service/use-address'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { useKycContext } from '../context/kyc-context'
import { type AssessmentAddress } from '../entities'

import { ResponsiveFlexContainer } from '../../loan-concession/helpers/loan-concession.styles'

const REQUIRED = 'Campo obrigatório'
export const BorderBox = styled(Flex)`
  border: 1px solid ${theme('colors.mediumGray4')};
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
`

export function KycFlowFormAddressForm() {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<AssessmentAddress>()
  const { isViewSmall } = useViewSize()
  const { addToast } = useToast()
  const [showForm, toggleForm] = useToggle(false)
  const [currentCep, setCurrentCep] = useState('')

  const { kycAnswerData, saveKycAnswerData } = useKycContext()

  const { isFetching: isCepLoading } = useAddress(currentCep, {
    onError: () => {
      addToast({ type: 'error', message: 'CEP não encontrado' })
      toggleForm(false)
    },

    onSuccess: ({ state, city, neighborhood, street }) => {
      setValue(`city`, city)
      setValue(`state`, state)
      setValue(`neighborhood`, neighborhood)
      setValue(`street`, street)

      toggleForm(true)
    }
  })

  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 9) setCurrentCep(e.target.value)
    else setCurrentCep('')
  }

  const postalCodeFormRegistration = register('postalCode', { required: REQUIRED })

  const formSubmit = (values: any) => {
    if (Object.keys(errors).length === 0) {
      saveKycAnswerData({ ...kycAnswerData, address: values })
    }
  }

  return (
    <Stack space="1rem">
      <Box>
        <Heading weight="bold" size="large">
          Qual o endereço da sua casa?
        </Heading>
      </Box>
      <BorderBox flexDirection="column" p="1.5rem">
        <Box width="100%">
          <form onChange={handleSubmit(formSubmit)}>
            <Stack space="1.5rem">
              <MaskedInput
                guide={false}
                mask={masks.zipcode}
                render={(ref, maskedProps) => (
                  <Input
                    label="CEP residencial"
                    placeholder="Somente números"
                    data-test-id="kyc-address-cep"
                    error={errors?.postalCode?.message}
                    {...maskedProps}
                    {...postalCodeFormRegistration}
                    ref={input => {
                      if (input) {
                        ref(input)
                        postalCodeFormRegistration.ref(input)
                      }
                    }}
                    onChange={event => {
                      maskedProps.onChange(event)
                      postalCodeFormRegistration.onChange(event)
                      handleCepChange(event)
                    }}
                    onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                      if (e.currentTarget.value.length < 9) toggleForm(false)
                      if (e.keyCode === 8) {
                        // keyCode === 8 === backspace
                        e.preventDefault()
                        e.currentTarget.value = unmask(e.currentTarget.value || '')
                        e.currentTarget.value = e.currentTarget.value.substring(0, e.currentTarget.value.length - 1)
                      }
                      postalCodeFormRegistration.onChange(e)
                    }}
                  />
                )}
              />

              <Choose>
                <Choose.When condition={isCepLoading}>
                  <Loader />
                </Choose.When>
                <Choose.When condition={showForm}>
                  <Input
                    width="100%"
                    label="Endereço"
                    data-test-id="kyc-address-street"
                    {...register('street', { required: REQUIRED })}
                    error={errors?.street?.message}
                  />
                  <Input
                    label="Número"
                    data-test-id="kyc-address-street-number"
                    {...register('streetNumber', { required: REQUIRED })}
                    error={errors?.streetNumber?.message}
                  />
                  <Input
                    label="Complemento"
                    data-test-id="kyc-address-extra"
                    {...register('extra')}
                    placeholder="Informe o complemento, se tiver"
                    defaultValue=""
                  />
                  <Input
                    label="Bairro"
                    data-test-id="kyc-address-neighborhood"
                    {...register('neighborhood', {
                      required: REQUIRED
                    })}
                    disabled={isCepLoading}
                    error={errors?.neighborhood?.message}
                    placeholder="Digite o Bairro"
                  />
                  <ResponsiveFlexContainer>
                    <Box width={isViewSmall ? '100%' : '50%'}>
                      <Input
                        label="Cidade"
                        data-test-id="kyc-address-city"
                        {...register('city', {
                          required: REQUIRED
                        })}
                        disabled={isCepLoading}
                        error={errors?.city?.message}
                        placeholder="Digite a cidade"
                      />
                    </Box>
                    <Box width={isViewSmall ? '100%' : 'calc(50% - 1rem)'}>
                      <Select
                        label="Estado"
                        data-test-id="kyc-address-state"
                        {...register('state', {
                          required: REQUIRED
                        })}
                        disabled={isCepLoading}
                        error={errors?.state?.message}
                        defaultValue="-1"
                      >
                        <option key="" value="-1" disabled hidden>
                          Selecione o estado
                        </option>
                        <For
                          of={BrazilianStatesSelectOptions}
                          render={option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          )}
                        />
                      </Select>
                    </Box>
                  </ResponsiveFlexContainer>
                </Choose.When>
              </Choose>
            </Stack>
          </form>
        </Box>
      </BorderBox>
    </Stack>
  )
}
