import axios, { type AxiosTransformer } from 'axios'
import { CREDIT_API_ENDPOINT } from 'shared/envs'

import { bffHeadersInterceptor } from '~/domains/credit/shared/interceptors/bff-headers-interceptor'
import { httpClientFactory } from '~/domains/platform/infra/http/http-client-factory'
import { keysToSnakecase, transformResponse } from '~/domains/platform/infra/http/http-helpers'
import { responseUnauthorizedAccessInterceptor } from '~/domains/platform/infra/http/interceptors/unauthorized-access-interceptor'

/**
 * @disclaimer This client is used with the credit-negotiations api that, different of httpClientCredit, needs the request kays to be snake_case.
 */

export const httpClientCreditNegotiations = httpClientFactory({
  baseURL: CREDIT_API_ENDPOINT(),
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  transformRequest: [keysToSnakecase, ...(axios.defaults.transformRequest as AxiosTransformer[])],
  requestInterceptor: bffHeadersInterceptor,
  responseErrorInterceptors: [responseUnauthorizedAccessInterceptor]
})
