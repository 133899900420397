import { CreditBucket, getKeysFromStorage } from '@credit-web/common/browser-utils'
import { analiticaInstance } from 'shared/analitica'

import { LoanConcessionSteps } from '../entities'

export default analiticaInstance.eventFactory('Proposal credit', {
  simulation: {
    /**
     * @analitica The user saw the loan simulation screen
     */
    view: () => ({ label: 'credit - simulation view' }),

    /**
     * @analitica Click on the simulation screen.
     */
    click: (action: 'ir para atualização de dados' | 'alterar' | 'fechar') => ({
      label: 'credit - simulation clicked',
      metadata: {
        'simulation clicked': action
      }
    })
  },
  loanRequested: {
    /**
     * @analitica State change in the loan requested.
     */
    stateChange: (
      step: 'criando conta stone' | 'demora na criação da conta stone' | 'sucesso' | 'erro ao criar conta stone'
    ) => ({
      label: 'credit - loan requested',
      metadata: { step, 'initial path': CreditBucket.get('credit::shared::initial-path'), ...getKeysFromStorage() }
    })
  },
  restartDialog: {
    /**
     * @analitica The user saw the dialog restart simulation
     */
    view: () => ({ label: 'credit - restart simulation view' }),

    /**
     * @analitica Click on the dialog restart simulation
     */
    click: (action: 'começar de novo' | 'ficar aqui' | 'fechar') => ({
      label: 'credit - restart simulation clicked',
      metadata: { action }
    })
  },
  breadcrumb: {
    /**
     * @analitica The user click on the simulation breadcrumb
     */
    changeStep: (step: string) => {
      const stepList: any = {
        [LoanConcessionSteps.UPDATE_INFORMATION]: {
          label: 'credit - pre kyc clicked',
          metadata: { 'pre kyc clicked': 'voltar' }
        },
        [LoanConcessionSteps.REVIEW]: {
          label: 'credit - review clicked',
          metadata: { 'review clicked': 'voltar' }
        }
      }

      const defaultEvent = {
        label: 'credit - simulation clicked',
        metadata: { 'simulation clicked': 'fechar' }
      }
      return stepList[step] ?? defaultEvent
    }
  },
  review: {
    /**
     * @analitica The user saw the loan review screen
     */
    view: () => ({ label: 'credit - review view' }),
    /**
     * @analitica Click on the simulation review page
     */
    click: (
      reviewClicked:
        | 'voltar'
        | 'chat'
        | 'termos e condições da cédula de crédito bancário'
        | 'confirmar pedido de empréstimo'
        | 'simular outro valor'
        | 'checkbox confirmação'
        | 'saber mais sobre garantias'
        | 'mais detalhes'
    ) => ({
      label: 'credit - review clicked',
      metadata: { 'review clicked': reviewClicked }
    })
  },
  kyc: {
    /**
     * @analitica update marital status kyc on loan simulation
     */
    maritalStatus: () => ({ label: 'credit - kyc update', metadata: { step: 'marital status' } }),
    /**
     * @analitica update nationality kyc  on loan simulation
     */
    nationality: () => ({ label: 'credit - kyc update', metadata: { step: 'country' } }),
    /**
     * @analitica update address kyc  on loan simulation
     */
    address: () => ({ label: 'credit - kyc update', metadata: { step: 'address' } }),
    /**
     * @analitica confirm kyc data on loan simulation
     */
    finish: () => ({ label: 'credit - kyc update', metadata: { step: 'confirm data' } }),
    /**
     * @analitica view pre kyc screen on loan simulation
     */
    preKyc: () => ({ label: 'credit - pre kyc view' }),
    /**
     * @analitica start kyc on loan simulation
     */
    preKycStart: () => ({ label: 'credit - pre kyc clicked', metadata: { 'pre kyc clicked': 'atualizar dados' } }),
    /**
     * @analitica pre kyc click on loan simulation
     */
    preKycClick: (
      preKycClicked:
        | 'chat'
        | 'voltar'
        | 'saber mais sobre garantias'
        | 'atualizar dados'
        | 'botão editar'
        | 'confirmar'
        | 'checkbox confirmação'
    ) => ({ label: 'credit - pre kyc clicked', metadata: { 'pre kyc clicked': preKycClicked } }),
    /**
     * @analitica user come back after aswering kyc
     */
    kycAnswered: () => ({
      label: 'credit - kyc answered'
    })
  },
  insurance: {
    /**
     * @analitica The user saw the insurance screen
     */
    view: (proposalId: string) => ({
      label: 'credit - insurance proposal view',
      metadata: { 'proposal id': proposalId }
    }),
    click: (action: string) => ({
      label: 'credit - insurance proposal clicked',
      metadata: { action }
    }),
    proposalAccepted: (proposalId: string) => ({
      label: 'credit - insurance proposal accepted',
      metadata: { 'proposal id': proposalId }
    }),
    proposalRejected: (proposalId: string) => ({
      label: 'credit - insurance proposal rejected',
      metadata: { 'proposal id': proposalId }
    })
  }
})
