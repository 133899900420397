import { useState } from 'react'

import { type CreditPaging } from '~/domains/credit/shared/entities'
import { useQueryRequest } from '~/ui/hooks/utils'
import { queryClient } from '~/ui/hooks/utils/service/use-query-request'

import { type LoanConcessionConfirmState } from '../components/loan-concession-confirm-loading-state'
import { type Contract } from '../entities'
import { httpClientCreditNegotiations } from '../infra/http-client-credit-negotiations'

const MAX_REQUEST_COUNT = 6
const REQUEST_INTERVAL_MILISECONDS = 5000

type FetchContractsProps = {
  document: string
  proposalId: string
}

async function fetchContracts({ proposalId, document }: FetchContractsProps) {
  const url = '/experience-bff-service/api/legacy/web/v1/contracts'

  const { data } = await httpClientCreditNegotiations<CreditPaging<Contract[]>>({
    url,
    method: 'GET',
    params: {
      originId: proposalId,
      ...{ document }
    }
  })

  return data?.items
}

type useProposalSignProps = {
  proposalId: string
  isProposalVoided: boolean
  document: string
}

interface DataResponse {
  state: LoanConcessionConfirmState
  contractId?: string
}
interface useProposalSignResponse {
  data: DataResponse
}

export function useProposalSign({ proposalId, isProposalVoided, document }: useProposalSignProps) {
  const [disablePolling, setDisablePolling] = useState(false)
  const [count, setCount] = useState(0)
  const [isContractsPollingError, setContractsPollingError] = useState(false)

  function updateQueryData(data?: { state: LoanConcessionConfirmState; contractId?: string }) {
    queryClient.setQueryData(['useContractsPolling', proposalId], { data })
  }

  const { data, ...restQuery } = useQueryRequest<Contract>(
    ['useContractsPolling', proposalId],
    () => fetchContracts({ proposalId, document }),
    {
      onSettled: (response: any, error) => {
        if (error || count >= MAX_REQUEST_COUNT) {
          setDisablePolling(true)
          return setContractsPollingError(true)
        }

        if (response && response.length > 0) {
          const { id: contractId } = response[0]
          setDisablePolling(true)
          if (count === 2) updateQueryData({ state: 'CLOSE_TO_FINISH' })
          if (contractId) return updateQueryData({ state: 'ACCOUNT_CREATION', contractId })
        }
        return setCount(current => current + 1)
      },
      initialData: { data: { state: 'ACCOUNT_CREATION' } },
      refetchInterval: disablePolling || isContractsPollingError ? false : REQUEST_INTERVAL_MILISECONDS,
      enabled: Boolean(!isProposalVoided)
    }
  )

  if (isProposalVoided || isContractsPollingError) {
    return {
      data: { data: { state: 'ERROR' } } as useProposalSignResponse,
      ...restQuery
    }
  }

  return {
    data: (data === undefined ? { data: { state: 'ACCOUNT_CREATION' } } : data) as useProposalSignResponse,
    ...restQuery
  }
}
