import { CardBody, Heading, IconButton } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Modal } from '~/ui/components'

import { type SpotOffer } from '../entities'
import { translatePaymentMethod } from '../helpers/spot-offer'

import { SpotOfferDetails } from './spot-offer-details'

interface SpotOfferDialogProps {
  isOpen: boolean
  onClose: () => void
  spotOffer: SpotOffer
}

export function SpotOfferDialog(props: SpotOfferDialogProps) {
  const { isOpen, onClose, spotOffer } = props
  const paymentMethod = spotOffer.paymentMethod

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header>
        <Flex justifyContent="space-between" alignItems="center" p="2rem 2rem 1rem">
          <Heading size="large" weight="semi">
            Pagar com {translatePaymentMethod(paymentMethod)}
          </Heading>
          <IconButton
            icon="close-outline"
            aria-label="Fechar"
            variant="contentOnly"
            color="neutral"
            onClick={onClose}
          />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Box p="0 2rem 2rem">
          <CardBody>
            <Box width="42rem">
              <SpotOfferDetails spotOffer={spotOffer} />
            </Box>
          </CardBody>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
