import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type LoanSummaryHistory } from '../../shared/entities'

export async function fetchLoanSummary(loanId?: string) {
  return httpClientCredit<LoanSummaryHistory>({
    url: `experience-bff-service/api/legacy/web/v1/loans/${loanId}/summary`
  })
}

export function useLoanSummary(loanId?: string, queryOptions?: UseQueryRequestOptions<LoanSummaryHistory>) {
  const { data: response, ...restQuery } = useQueryRequest<LoanSummaryHistory>(
    ['useLoanSummaryV5', loanId],
    () => fetchLoanSummary(loanId),
    { ...queryOptions }
  )
  return { data: response?.data, ...restQuery }
}
