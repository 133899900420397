import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Card } from '~/domains/platform/design-system'
import { toRem } from '~/lib/helpers'

interface CardWrapperProps {
  disabled?: boolean
  selected?: boolean
  onClick?(): void
}

export const CardWrapper = styled(Card)<CardWrapperProps>`
  border: 1px solid ${theme('colors.mediumGray3')};
  cursor: pointer;
  box-shadow: 0 4px 4px rgba(13, 17, 27, 0.08);
  display: block;
  padding: ${toRem(20)};

  &:hover {
    border: 1px solid ${theme('colors.stoneGreen')};
  }

  ${ifProp(
    'selected',
    css`
      border: 1px solid ${theme('colors.stoneGreen')};
    `
  )}

  ${ifProp(
    'disabled',
    css`
      box-shadow: unset;
      background-color: ${theme('colors.lightGray5')};
      border: 1px solid ${theme('colors.mediumGray4')};
      svg {
        fill: ${theme('colors.mediumGray2')};
      }
      pointer-events: none;
    `
  )}
`
