import { Icon, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'

export function RenegotiationFollowUpBreadcrumb({
  showArrow = true,
  currentStep = 'renegociacao-condicoes'
}: {
  showArrow?: boolean
  currentStep?: string
}) {
  const { loan } = BlackBird.getQuery()
  const goToPath = (pathname: Routes) => BlackBird.travelTo({ pathname, urlParams: { loan } })

  const defaultBreadcrumbs = [
    {
      steps: ['emprestimo'],
      title: 'Empréstimo',
      pathName: '/credito/emprestimo/${loan}' as const
    },
    {
      steps: ['renegociacao-condicoes'],
      title: 'Condições de renegociação',
      pathName: '/credito/emprestimo/${loan}/renegociacao-condicoes' as const
    },
    {
      steps: ['renegociacao-parcelas'],
      title: 'Parcelas do contrato original',
      pathName: '/credito/emprestimo/${loan}/renegociacao-parcelas' as const
    }
  ]

  const breadcrumbs = defaultBreadcrumbs.slice(0, defaultBreadcrumbs.findIndex(e => e.steps.includes(currentStep)) + 1)

  return (
    <Flex alignContent="center" gap=".5rem">
      <If condition={showArrow}>
        <Flex
          onClick={() => goToPath('/credito/emprestimo/${loan}')}
          alignItems="center"
          style={{ cursor: 'pointer' }}
          aria-label="Voltar"
        >
          <Icon use="direction-arrow-left-outline" />
        </Flex>
      </If>
      <For
        of={breadcrumbs}
        render={(breadcrumb, index) => {
          const { title, steps, pathName } = breadcrumb
          const isCurrentStep = steps.includes(currentStep)
          const color = isCurrentStep ? 'neutralHigh' : 'neutral'
          return (
            <Flex key={breadcrumb.title} alignItems="center" gap=".5rem">
              <If condition={index > 0}>
                <Text color="neutral">/</Text>
              </If>
              <If condition={!isCurrentStep}>
                <Text onClick={() => goToPath(pathName)} color={color} style={{ cursor: 'pointer' }}>
                  {title}
                </Text>
              </If>
              <If condition={isCurrentStep}>
                <Text color={color}>{title}</Text>
              </If>
            </Flex>
          )
        }}
      />
    </Flex>
  )
}
