import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'

export async function openDDC(document: string, url?: string) {
  return httpClientCredit({
    url,
    method: 'GET',
    params: {
      document
    },
    responseType: 'blob'
  })
}
