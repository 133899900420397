import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Alert } from '@dlpco/ginga-stone'

import { useLoans } from '~/domains/credit/ports/hooks/use-loans'
import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { Box, Flex } from '~/domains/platform/design-system'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { RenegotiationBannerContainer } from '../../renegotiation/components/renegotiation-banner/renegotiation-banner-container'
import { useLoanSummary } from '../../renegotiation/hooks/use-loan-summary'
import { SharedSpotOfferButton } from '../../shared/components/shared-spot-offer-button'
import { isRenegotiationSummary, isZeroTaxRenegotiation } from '../../shared/helpers/loan'
import { useContracts } from '../../shared/hooks/use-contracts'
import { useInstallmentPlans } from '../../shared/hooks/use-installments-plans'
import analitica from '../analitica'

import { LoanDetailsAboutLoan } from './loan-details-about-loan'
import { LoanDetailsAboutRetention } from './loan-details-about-retention'
import { type LoanInformations, LoanDetailsContractInfo } from './loan-details-contract-info'
import { LoanDetailsCurrentInstallmentStatus } from './loan-details-current-installment-status'
import { LoanDetailsHeader } from './loan-details-header'
import { LoanDetailsRetentionInfo } from './loan-details-retention-info'
import { LoanDetailsSettledLoanCard } from './loan-details-settled-loan-card'
import { LoanDetailsInstallmentList } from './loan-detals-installment-list'

export function LoanDetailsHome(props: WithPageConfigProps) {
  const { entity } = props
  const { loan: loanId }: { loan?: string } = useRouter().query
  const { isViewSmall } = useViewSize()

  const {
    data: summaryResponse,
    isLoading: isSummaryLoading,
    isError: isSummaryError,
    isFetched: summaryIsFetched
  } = useLoanSummary(loanId)
  const summary = summaryResponse?.currentSummary
  const hasRenegotiation = isRenegotiationSummary(summaryResponse)
  const retentionIsPaused = summary?.isRetentionEnabled === false || summary?.isRetentionEnabled === undefined

  const {
    data: installments,
    isLoading: isInstallmentsLoading,
    isError: isInstallmentsError
  } = useInstallmentPlans({ installmentPlanId: summary?.installmentPlanId })
  useEffect(() => {
    if (loanId && summaryResponse)
      analitica.events.loanDetails.view(
        loanId ?? '',
        summaryResponse?.currentSummary?.creditAmount ?? 0,
        summaryResponse?.renegotiationSummary?.status
      )
  }, [loanId, summaryResponse])

  const contractId = summaryResponse?.renegotiationSummary?.loanRenegotiationContractId
  const { data: contracts } = useContracts({ id: contractId }, !!contractId)

  /**
   * @disclaimer the /loans/{{loanId}} endpoint is the most indicated for this page, but it isn't working currently. It will be added in the future or be replaced by the /summary one.
   */
  const {
    data: loans,
    isLoading: isLoansLoading,
    isError: isLoansError
  } = useLoans({ id: loanId, document: entity.document })

  const loan = loans?.items[0]
  const renegotiationData: LoanInformations['renegotiationData'] = useCallback(() => {
    if (!summaryResponse?.renegotiationSummary) return undefined
    const originalContractDate = loan?.creationDate || ''
    const originalContractUri = summaryResponse.previousSummary?.contractUri || ''
    const contract = contracts?.items?.length ? contracts?.items[0] : null
    const renegotiationContractDate = contract?.creationDate || ''
    const renegotiationContractUri = contract?.url || ''
    return {
      originalContractDate,
      originalContractUri,
      renegotiationContractDate,
      renegotiationContractUri,
      hasRenegotiation
    }
  }, [summaryResponse, loan?.creationDate, contracts?.items, hasRenegotiation])()

  const loanInformations: LoanInformations = {
    creditAmount: summary?.creditAmount,
    ddcUrl: summary?.ddcUrl,
    numberOfInstallments: summary?.numberOfInstallments,
    installmentAmount: summary?.initialInstallmentAmount,
    totalAmount: summary?.initialAmount,
    iofAmount: summary?.taxAmount,
    monthlyInterestRate: summary?.monthlyInterestRate,
    yearlyInterestRate: summary?.yearlyInterestRate,
    yearlyTotalEffectiveCostRate: summary?.yearlyTotalEffectiveCostRate,
    dueDate: summary?.dueDate,
    contractUri: summary?.contractUri,
    guarantors: loan?.contract?.warranty,
    renegotiationData,
    plans: summary?.plans,
    gracePeriodInDays: summary?.gracePeriodInDays,
    renegotiationSummary: summaryResponse?.renegotiationSummary
  }

  if (isSummaryError || isInstallmentsError) return <SharedGenericCardError />

  if (loan && loan?.status === 'Cancelled') {
    BlackBird.travelTo('/')
    return null
  }

  return (
    <Box>
      <If condition={!isSummaryLoading}>
        <Box mb="2rem">
          <LoanDetailsHeader title={summary?.label} />
        </Box>
      </If>
      <Box mb="2rem">
        <RenegotiationBannerContainer
          document={entity.document}
          renegotiationStatus={summaryResponse?.renegotiationSummary?.status}
          renegotiationCreationDate={summaryResponse?.renegotiationSummary?.contractSignatureDate || ''}
        />
      </Box>
      <If condition={summary?.currentInstallment.status === 'Overdue'}>
        <Box mb="1.5rem">
          <Alert heading="Evite negativação e outras cobranças" color="negative" dismissible={false}>
            Quite o valor em atraso para evitar a aplicação das garantias.
          </Alert>
        </Box>
      </If>

      <Flex gap="1.5rem" flexDirection={isViewSmall ? 'column' : 'row'}>
        <Box width={isViewSmall ? '100%' : '65%'}>
          <Stack space="1.5rem">
            <Choose>
              <Choose.When condition={loan?.status === 'Settled'}>
                <LoanDetailsSettledLoanCard
                  loanId={loan?.id}
                  numberOfInstallments={summary?.numberOfInstallments}
                  paidAmount={summary?.paidAmount}
                  lastInstallmentNumber={summary?.currentInstallment.installmentNumber}
                  interestEconomyAmount={summary?.interestEconomyAmount}
                  startDate={loan?.disbursementDate}
                  endDate={loan?.settlementDate}
                />
              </Choose.When>

              <Choose.Otherwise>
                <LoanDetailsCurrentInstallmentStatus
                  loanId={loan?.id || ''}
                  currentInstallment={summary?.currentInstallment}
                  overdueInstallments={summary?.overdueInstallmentsSummary}
                  numberOfInstallments={summary?.numberOfInstallments}
                  numberOfOverdueInstallments={summary?.numberOfOverdueInstallments}
                  isLoading={isSummaryLoading}
                  isZeroTaxRenegotiation={hasRenegotiation && summary && isZeroTaxRenegotiation(summary)}
                />
              </Choose.Otherwise>
            </Choose>

            <LoanDetailsInstallmentList
              installments={installments?.items}
              installmentPlanId={summary?.installmentPlanId}
              isLoading={isInstallmentsLoading}
            />
          </Stack>
        </Box>
        <Box width={isViewSmall ? '100%' : '35%'}>
          <Stack space="1.5rem">
            <If condition={retentionIsPaused && summaryIsFetched}>
              <LoanDetailsRetentionInfo />
            </If>

            <LoanDetailsAboutLoan
              paidAmount={summary?.paidAmount || 0}
              futureBalance={summary?.futureBalance || 0}
              interestEconomyAmount={summary?.interestEconomyAmount || 0}
              currentInstallmentStatus={summary?.currentInstallment.status}
              isLoading={isSummaryLoading}
              renegotiationStatus={summaryResponse?.renegotiationSummary?.status}
              actionComponent={<SharedSpotOfferButton loan={loan} currentInstallment={summary?.currentInstallment} />}
            />

            <LoanDetailsAboutRetention
              isRetentionEnabled={summary?.isRetentionEnabled || false}
              retentionRate={summary?.retentionRate || 0}
              isLoading={isSummaryLoading}
            />

            <If condition={!isLoansError && Boolean(loan)}>
              <LoanDetailsContractInfo
                loanInformation={loanInformations}
                isLoading={isLoansLoading}
                loanId={loan?.id || ''}
                concessionProposalId={loan?.concessionProposalId || ''}
                document={entity.document}
              />
            </If>
          </Stack>
        </Box>
      </Flex>
    </Box>
  )
}
