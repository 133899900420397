import { CreditBucket, getKeysFromStorage } from '@credit-web/common/browser-utils'
import { analiticaInstance } from 'shared/analitica'

type ReviewClickActions =
  | 'chevron forma de pagamento'
  | 'chevron informações sobre juros'
  | 'chevron tipos de garantia'
  | 'atrasos e quebra de acordo'
  | 'conferir detalhes'
  | 'ler os termos e condições'
  | 'checkbox termos e condições'
  | 'voltar'
  | 'ícone de dúvida'

export default analiticaInstance.eventFactory('Renegotiation', {
  review: {
    /**
     * @analitica The user saw renegotiation contract view
     */
    view: () => ({ label: 'credit - renegotiation contract view' }),
    /**
     * @analitica Click on the renegotiation contract
     */
    click: (action: ReviewClickActions) => ({
      label: 'credit - renegotiation contract clicked',
      metadata: { action }
    }),
    /**
     * @analitica renegotiation contract requested
     */
    contractRequested: () => ({ label: 'credit - renegotiation requested' }),
    /**
     * @analitica The user saw renegotiation success view
     */
    success: () => ({
      label: 'credit - renegotiation success view',
      metadata: { 'initial path': CreditBucket.get('credit::shared::initial-path') }
    }),
    /**
     * @analitica The user clicks the renegotiation success view
     */
    successClick: (action: 'acompanhar' | 'fechar') => ({
      label: 'credit - renegotiation success clicked',
      metadata: { action }
    }),
    /**
     * @analitica The user saw renegotiation error view
     */
    error: () => ({ label: 'credit - renegotiation error view', metadata: { ...getKeysFromStorage() } })
  },
  banner: {
    /**
     * @analitica The user saw renegotiation card
     */
    proposalView: (type: string) => ({ label: 'credit - renegotiation card view', metadata: { type } }),
    /**
     * @analitica click on the renegotiation card
     */
    proposalClick: () => ({ label: 'credit - renegotiation card clicked' })
  },
  proposal: {
    /**
     * @analitica The user saw renegotiation conditions view
     */
    view: () => ({
      label: 'credit - renegotiation conditions view'
      // metadata: { 'loan id': loanId, 'renegotiation status': renegotiationStatus }
    }),
    /**
     * @analitica Click on the renegotiation conditions view
     */
    click: (action: string) => ({
      label: 'credit - renegotiation conditions clicked',
      metadata: { action }
    }),
    /**
     * @analitica The user saw kyc renegotiation view
     */
    preKycView: () => ({ label: 'credit - renegotiation pre kyc view' }),

    /**
     * @analitica The user saw some action on kyc renegotiation view
     */
    preKycClick: (action: 'revisar contrato' | 'atualizar dados' | 'voltar') => ({
      label: 'credit - renegotiation pre kyc clicked',
      metadata: { action }
    })
  }
})
