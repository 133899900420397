import { Icon, Text, theme } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { List } from '~/ui/components/utils/list/list'

export function RenegotiationReviewList({ items }: { items: string[] }) {
  return (
    <List
      items={items}
      render={item => {
        return (
          <Flex
            flexDirection="row"
            alignContent="flex-start"
            alignItems="center"
            gap="1rem"
            mb={theme.light.space.xSmall}
            key={item}
          >
            <Icon use="check-round-solid" color="primary" size="large" style={{ flexShrink: 0 }} />
            <Text size="small" weight="regular" color="neutralHigh">
              {item}
            </Text>
          </Flex>
        )
      }}
    />
  )
}
