import { type CreditPaging, type MaritalStatus, type NegotiationsCustomer } from '~/domains/credit/shared/entities'
import { type AssessmentCheckOption } from '~/domains/stone-account/ports/entities/KYC'

import { type SpotOfferPaymentMethod } from '../spot-offer/entities'

import { type LoanConcessionConfirmState } from './components/loan-concession-confirm-loading-state'

export enum LoanConcessionSteps {
  SIMULATION = 'simulacao',
  UPDATE_INFORMATION = 'atualizar-dados',
  CONFIRM_INFORMATION = 'confirmar-dados',
  REVIEW = 'revisao',
  CONFIRM_PROPOSAL = 'confirmar-pedido',
  INSURANCE = 'seguro',
  PIN = 'pin',
  SUCCESS = 'sucesso',
  QR_CODE = 'qr-code'
}

export enum LoanConcessionKycSteps {
  UPDATE_INFORMATION = 'Atualização de dados',
  NATIONALITY = 'Em qual país você nasceu?',
  MARITAL_STATUS = 'Qual seu estado civil atual?',
  HOME_ADDRESS = 'Qual o endereço da sua casa?',
  CONFIRM_INFORMATION = 'Confirmar dados'
}

export interface FinancialInstitution {
  id: number
  name: string
}

export enum DisbursementAccountType {
  PAYMENT = 'Payment',
  SAVINGS = 'Savings',
  CHECKING = 'Checking'
}

export interface InstallmentPlan {
  amount: number
  count: number
  totalSum: number
  paymentReferenceDate: string
  gracePeriod?: number
  daysToDownPayment?: number
}

export interface ProposalBankAccount {
  bankAccount: {
    id: string
    accountDigit: string
    accountNumber: string
    accountType: DisbursementAccountType
    bankName: string
    bankNumber: string
    branchDigit: string
    branchNumber: string
    legalEntity: {
      name: string
    }
  }
}

export interface Proposal {
  id: string
  creditLineId: string
  financialInstitution: FinancialInstitution
  externalCode: string
  contractUri: string
  contractDueDate: string
  expirationDate: string
  maximumDisbursementDate: string
  borrowerId: string
  disbursementBankAccount: ProposalBankAccount
  warrantyBankAccount: ProposalBankAccount
  creditAmount: number
  interestRate: number
  creationDate: string
  lastUpdateDate: string
  status: string
  retentionRate: number
  totalEffectiveCostRate: number
  monthlyTotalEffectiveCostRate: number
  annualTotalEffectiveCostRate: number
  operationFeeAmount: number
  taxAmount: number
  initialGracePeriodInDays: number
  fundingSource: string
  paymentTermInMonths: number
  totalEstimatedAmountToBePaid: number
  repayment: ProposalRepayment
}

export interface ProposalRepayment {
  isMaximumMonthlyRetentionAmountEnabled: boolean
  maximumMonthlyRetentionAmount: number
}

export interface AssistedProposal extends CreditPaging<Proposal[]> {}

export interface LoanConcessionKycChecksAnswers {
  nationality: AssessmentCheckOption
  maritalStatus: AssessmentCheckOption
  address: ProposalAddress
  [key: string]: any
}

export interface ProposalAddress {
  street: string
  streetNumber: string
  extra: string
  referencePoint: string
  neighborhood: string
  city: string
  state: string
  postalCode: string
}

export interface UniversalProposalData {
  maxValue: number
  minValue: number
  offerId?: string
  inputValue?: number
  proposalId: string
  creditAmount: number
  totalEstimatedAmountToBePaid: number
  retentionRate: number
  interestRate: number
  taxAmount: number
  annualTotalEffectiveCostRate: number
  contractDueDate: string
  numberOfInstallments?: number
  installmentValue?: number
  lastUpdateDate: string
  annualInterestRate: number
  requestedAmount: number
  customer: NegotiationsCustomer
}

export interface SecurityRenegotiation {
  externalReference: string
  installmentPlan: {
    amount: number
    count: number
  }
  rate: {
    taxAmount: number
    annualInterestRate: number
    annualTotalEffectiveCostRate: number
    retentionRate: number
    interestRate: number
  }
  summary: {
    currentDebtAmount: number
    dueDate: string
  }
}

export interface NegotiationsProposal {
  id: string
  offerId: string
  type: string
  status: string
  expirationDate: string
  creationDate: string
  customer: NegotiationsCustomer
  loanConcession: {
    requestedAmount: number
    dueDate: string
    rate: {
      taxAmount: number
      annualInterestRate: number
      annualTotalEffectiveCostRate: number
      retentionRate: number
      interestRate: number
      iofRate: number
    }
    installmentPlan: { amount: number; count: number; totalSum: number; paymentReferenceDate: string }
  }
  origin?: {
    createdBy: string
    userType: 'Customer' | 'Operator' | 'Leadership'
  }
}

export interface RenegotiationProposal {
  id: string
  offerId: string
  type: string
  status: string
  expirationDate: string
  creationDate: string
  lastUpdateDate?: string
  origin?: {
    createdBy: string
    userType: 'Customer' | 'Operator' | 'Leadership'
  }
  formalization: {
    type: string
  }
  discountSettlement?: ProposalDiscountSettlement
  customer: NegotiationsCustomer
  renegotiation: {
    securities: SecurityRenegotiation[]
    dueDate: string
    amount: number
    rate: {
      taxAmount: number
      annualInterestRate: number
      annualTotalEffectiveCostRate: number
      retentionRate: number
      interestRate: number
      iofRate: number
    }
    installmentPlan: InstallmentPlan
  }
}

export interface ProposalDiscountSettlement {
  paymentMethod: {
    type: SpotOfferPaymentMethod
    amount: number
    dueDate: string
    emailNotification: string
  }
  securities: [
    {
      type: 'Credit'
      externalReference: string
      isOverdue: boolean
      installmentNumbers: number[]
      lastUpdateDate: string
    }
  ]
  installmentNumbers: number[]
  negotiationDebtAmount: number
  discountOfferRate: number
  discountEffectiveRate: number
  discountAmount: number
}

export interface MainPartnerReviewGuarantors {
  name: string
  document: string
  maritalStatus: MaritalStatus
  nationality: string
  address: Address
}

interface Address {
  city: string
  neighborhood: string
  postalCode: string
  state: string
  street: string
  streetNumber: string
  complement: string
}

export interface NegotiationsSimulationResult {
  id: string
  type: string
  requestedAmount: number
  installmentAmount: number
  numberOfInstallments: number
  installmentsSum: number
  interestRate: number
  retentionRate: number
  dueDate: string
  taxAmount: number
  annualTotalEffectiveCostRate: number
  annualInterestRate: number
  lastUpdateDate: string
}

export type Contract = {
  id: string
  contractId: string
  state: LoanConcessionConfirmState
  url: string
  number: string
  originId: string
  contractType: string
  dueDate: string
  signatureDeadline: string
  customer: NegotiationsCustomer
  document: Document
  status: string
  creationDate: string
  lastUpdateDate: string
}

export type InsuranceContractStatus =
  | 'OPEN'
  | 'ACTIVE'
  | 'WAITING_SIGNATURE'
  | 'SIGNED'
  | 'SENT_INSURER'
  | 'PENDING_INSURER'
  | 'DECLINED'
  | 'CANCELED'
  | 'NO_DEAL'

export type InsuranceContract = {
  id: string
  insurerId: string
  product: {
    id: string
    name: string
    code: string
  }
  code: string
  status: InsuranceContractStatus
  startsAt: string
  expiresAt: string
  category: string
  type: string
  createdAt: string
  updatedAt: string
  signedAt: string
  signatureMode: string
  proposalNumber: string
  tenderer: {
    id: string
    string: string
    name: string
    document: string
    phone: string
  }
  broker: {
    id: string
    name: string
  }
  metadata: {
    externalId: string
    source: string
  }
  payments: {
    value: number
    dueDay: number
    payerDocument: string
    payerName: string
    schedule: string
    type: string
  }
  creditOption?: boolean
}

export interface InsuranceContractResponse {
  base64: string
  fileType: string
}
