import { Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Box } from '~/domains/platform/design-system'
import { toRem } from '~/lib/helpers'

export const StepListItemWrapper = styled.div<{ isClickable?: boolean }>`
  position: relative;
  align-items: flex-start;
  word-break: break-word;

  cursor: ${ifProp('isClickable', 'pointer', 'initial')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translate(0.75rem, 1.7rem);
    z-index: 0;
    width: 2px;
    background-color: ${theme('colors.mediumGray4')};
  }

  &:last-child {
    &::before {
      display: none;
    }
  }
`

export const CustomGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  column-gap: 1.5rem;
  row-gap: ${toRem(4)};
  align-items: center;
`

export const Description = styled(Text)`
  font-size: ${toRem(16)};
  line-height: ${toRem(20)};
  color: ${theme('colors.mediumGray3')};
  grid-column-start: 2;
`

export const NavigationText = styled.button`
  font-size: ${toRem(16)};
  line-height: ${toRem(20)};
  background: transparent;
  border: none;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  color: ${theme('colors.green')};
  grid-column-start: 2;

  :hover {
    color: ${theme('colors.primary')};
  }
`

export const Circle = styled(Box)`
  border-color: ${theme('colors.mediumGray3')};
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  height: ${theme('space.large')};
  width: ${theme('space.large')};
  z-index: 1;
  flex-shrink: 0;
`

export const GreenCircle = styled(Box)`
  border-color: ${theme('colors.primary')};
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  height: ${theme('space.large')};
  width: ${theme('space.large')};
  z-index: 1;
  flex-shrink: 0;
`
