import styled from 'styled-components'
import { theme } from 'styled-tools'

export const Day = styled.span`
  font-size: ${theme('fontSizes.normal')};
  font-weight: ${theme('fontWeights.bold')};
  color: ${theme('colors.darkGray')};
  line-height: 1;
`

export const Month = styled.span`
  font-size: ${theme('fontSizes.tiny')};
  font-weight: ${theme('fontWeights.body')};
  color: ${theme('colors.mediumGray2')};
  line-height: 1;
`
