import { analiticaInstance } from 'shared/analitica'

import { type RenegotiationPaymentMethod } from '../entities'

export default analiticaInstance.eventFactory('Discount Settlement', {
  simulation: {
    /**
     * @analitica The user saw discount settlement screen
     */
    view: (type: 'DiscountRate' | 'DiscountAmount') => ({
      label: 'credit - discount settlement simulate view',
      metadata: { type }
    }),

    /**
     * @analitica Click on the discount settlement
     */
    clicked: (
      action:
        | 'fechar'
        | 'faq (conversar com a Stone)' // TODO: Onde fica esse
        | 'selecionar todas'
        | 'calcular desconto'
        | 'escolher forma de pagamento' // TODO: Esse ficou redundante
    ) => ({
      label: 'credit - discount settlement simulate clicked',
      metadata: {
        action
      }
    })
  },
  calculation: {
    /**
     * @analitica The user saw discount settlement calculation screen
     */
    view: () => ({ label: 'credit - discount settlement calculation view' }),

    /**
     * @analitica Click on the discount settlement calculation
     */
    clicked: (type: 'continuar' | 'voltar para a simulação') => ({
      label: 'credit - discount settlement calculation clicked',
      metadata: { type }
    })
  },
  payment: {
    /**
     * @analitica The user saw discount settlement payment screen
     */
    view: () => ({ label: 'credit - discount settlement payment view' }),
    /**
     * @analitica Click on the discount settlement payment
     */
    clicked: (paymentType: RenegotiationPaymentMethod) => ({
      label: 'credit - discount settlement payment clicked',
      metadata: { 'payment type': paymentType.toLowerCase() }
    })
  },
  review: {
    /**
     * @analitica The user saw discount settlement review screen
     */
    view: () => ({ label: 'credit - discount settlement review view' }),
    /**
     * @analitica Click on the discount settlement review
     */
    clicked: (
      action: 'voltar' | 'alterar' | 'voltar para simulação' | 'ir para o pagamento' | 'conversar com a stone'
    ) => ({ label: 'credit - discount settlement review clicked', metadata: { action } })
  },
  proposal: {
    /**
     * @analitica The user saw discount settlement proposal
     */
    view: () => ({ label: 'credit - discount settlement proposal view' }),
    /**
     * @analitica Click on the discount settlement proposal
     */
    clicked: (
      action:
        | 'fechar'
        | 'conversar com a stone' // TODO: Onde fica esse
        | 'continuar'
    ) => ({ label: 'credit - discount settlement proposal clicked', metadata: { action } })
  }
})
