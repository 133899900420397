import { Card, CardBody, Text } from '@dlpco/ginga-stone'

export function LoanDetailsRetentionInfo() {
  return (
    <Card spacing="small">
      <CardBody>
        <Text>Pausamos a retenção automática dos seus recebimentos. Converse com a Stone pra saber mais.</Text>
      </CardBody>
    </Card>
  )
}
