import { BFF_LEGACY_V1 } from '~/domains/credit/shared/infra/apis-paths'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { httpClientCreditNegotiations } from '../infra/http-client-credit-negotiations'

interface useCreateNegotiationsProposalAssessmentsProps {
  negotiationsProposalId: string
  enabled: boolean
  onError: () => void
}

export async function fetchNegotiationsProposalAksforAssessments(negotiationsProposalId?: string) {
  return await httpClientCreditNegotiations<any>({
    url: `${BFF_LEGACY_V1}/proposals/${negotiationsProposalId}/assessments`,
    method: 'POST'
  })
}

export function useCreateNegotiationsProposalAssessments(
  { negotiationsProposalId, enabled, onError }: useCreateNegotiationsProposalAssessmentsProps,
  queryOptions?: UseQueryRequestOptions
) {
  const { data: response, ...restQuery } = useQueryRequest(
    ['negotiationsProposalAssessments'],
    () => fetchNegotiationsProposalAksforAssessments(negotiationsProposalId),
    {
      onError,
      ...queryOptions,
      refetchInterval: 3000,
      refetchIntervalInBackground: true,
      retry: 10,
      enabled
    }
  )
  return { data: response?.data, ...restQuery }
}
