import { useEffectOnce, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Description, Heading, Money, Overline, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird from '~/domains/platform/lib/blackbird'

import { CustomBox, CustomDivider } from '../../../shared/components/shared-custom-styles'
import { SharedPaymentOptionSelector } from '../../../shared/components/shared-payment-option-selector'
import { useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { DiscountSettlementSteps } from '../../entities'
import discountSettlementAnalitica from '../../helpers/discount-settlement-analitica'
import { basePath } from '../../helpers/renegotiation'
import { createRenegotiationsProposal } from '../../services/renegotiation'

export function RenegotiationDiscountSettlementRatePaymentMethod({
  offerId,
  maxDiscountRate,
  userEmail
}: {
  offerId: string
  maxDiscountRate: number
  userEmail: string
}) {
  const loanId = BlackBird.getQuery().loan

  const [isLoading, toggleLoading] = useToggle(false)
  const { saveProposal, installments, simulation, resetProposal, resetSimulation, paymentMethod, savePaymentMethod } =
    useDiscountSettlementContext()

  useEffectOnce(() => {
    discountSettlementAnalitica.events.payment.view()
  })

  const navigateTo = (step: DiscountSettlementSteps) => {
    BlackBird.travelTo({
      pathname: basePath,
      urlParams: { loan: loanId, step }
    })
    return null
  }

  if (!simulation) {
    return navigateTo(DiscountSettlementSteps.SIMULATE)
  }
  const isButtonDisabled = !paymentMethod

  const debtAmount = simulation?.negotiationDebtAmount ?? 0
  const amount = simulation?.amount ?? 0

  async function handlePaymentMethodSelectionClick() {
    if (simulation && paymentMethod) {
      toggleLoading(true)
      createRenegotiationsProposal({
        offerId,
        installmentNumbers: simulation.installmentNumbers,
        paymentMethod,
        rate: maxDiscountRate,
        email: userEmail || ''
      })
        .then(response => {
          const { data } = response
          saveProposal(data)
          BlackBird.travelTo({
            pathname: basePath,
            urlParams: { loan: loanId, step: DiscountSettlementSteps.REVIEW }
          })
        })
        .finally(() => toggleLoading(false))
    }
  }

  return (
    <>
      <Stack space="1.75rem">
        <CustomBox>
          <Stack space="1rem">
            <Stack space="0.125rem">
              <Text color="neutral">Total com desconto</Text>
              <Description color="neutral" style={{ textDecoration: 'line-through' }}>
                <Money amount={debtAmount} />
              </Description>
              <Heading weight="semi">
                <Money amount={amount} />
              </Heading>
            </Stack>

            <CustomDivider />

            <Flex justifyContent="space-between">
              <Stack>
                <Description color="neutral">Você está quitando</Description>
                <Text weight="semi">{installments?.length} parcelas</Text>
              </Stack>
              <Button
                variant="contentOnly"
                color="primary"
                onClick={() => {
                  resetProposal()
                  resetSimulation()
                }}
              >
                Alterar
              </Button>
            </Flex>
          </Stack>
        </CustomBox>
        <Stack space="1rem">
          <Overline color="neutral">Como você quer pagar?</Overline>

          <SharedPaymentOptionSelector
            paymentOption={paymentMethod || undefined}
            setPaymentOption={paymentMethod => {
              discountSettlementAnalitica.events.payment.clicked(paymentMethod)
              savePaymentMethod(paymentMethod)
            }}
          />
        </Stack>
        <Button
          disabled={isButtonDisabled}
          shape="pill"
          iconPosition="end"
          {...(!isButtonDisabled && { icon: 'direction-arrow-right-outline' })}
          onClick={handlePaymentMethodSelectionClick}
        >
          {isButtonDisabled ? 'Escolha uma forma de pagamento' : 'Continuar'}
        </Button>
      </Stack>

      <Dimmer isVisible={isLoading}>
        <Loader />
      </Dimmer>
    </>
  )
}
