import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { multiplyByOneHundred } from '@credit-web/common/number-utils'
import { Box, Stack } from '@dlpco/fluid-layout'
import { Button, Description, Heading, Icon, IconButton, Money, Overline, Text, Tooltip } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { Modal } from '~/ui/components/modals/modal'

import { CustomBox, CustomDivider } from '../../../shared/components/shared-custom-styles'
import { SharedInstallmentSelectionList } from '../../../shared/components/shared-installment-selection-list'
import { useLoanInstallments } from '../../../shared/hooks/use-loans-installments'
import { useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { type RenegotiationSimulation, DiscountSettlementSteps } from '../../entities'
import discountSettlementAnalitica from '../../helpers/discount-settlement-analitica'
import { basePath } from '../../helpers/renegotiation'
import { simulateRenegotiationProposal } from '../../services/renegotiation'

interface SimulationModalProps {
  selectedInstallmentCount?: number
  selectedInstallmentTotalAmount?: number
  discountAmount?: number
  totalToPay?: number
  isLoading: boolean
  isOpen: boolean
  closeModal: () => void
  clearSimulation: () => void
  onContinue: () => void
}

function SimulationResultModal({
  selectedInstallmentCount,
  selectedInstallmentTotalAmount,
  discountAmount,
  totalToPay,
  isLoading,
  isOpen,
  closeModal,
  clearSimulation,
  onContinue
}: SimulationModalProps) {
  useEffect(() => {
    discountSettlementAnalitica.events.calculation.view()
  }, [])
  return (
    <Modal isOpen={isOpen} onClose={closeModal} width="550px" height="448px">
      <Choose>
        <Choose.When condition={isLoading}>
          <Dimmer isVisible>
            <Loader />
          </Dimmer>
        </Choose.When>
        <Choose.Otherwise>
          <Modal.Header>
            <Box padding="2.5rem 2.5rem 0 2.5rem">
              <Flex justifyContent="space-between" alignItems="center">
                <Heading weight="bold">Confira o cálculo do desconto</Heading>
                <IconButton icon="close-outline" onClick={closeModal} variant="contentOnly" color="neutral" />
              </Flex>
            </Box>
          </Modal.Header>
          <Modal.Content>
            <Box padding="1.5rem 2.5rem 2.5rem">
              <CustomBox>
                <Flex height="100%" width="100%" alignItems="center">
                  <Stack space="1rem" style={{ width: '100%' }}>
                    <Flex justifyContent="space-between">
                      <Text color="neutral">Você está quitando</Text>
                      <Text weight="bold">{selectedInstallmentCount} parcelas</Text>
                    </Flex>

                    <CustomDivider />

                    <Flex justifyContent="space-between">
                      <Text color="neutral">Valor das parcelas selecionadas</Text>
                      <Text>
                        <Money amount={selectedInstallmentTotalAmount || 0} />
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Flex gap="0.5rem">
                        <Text color="neutral">Desconto</Text>
                        <Tooltip content="O desconto inclui o valor de juros economizados, se houver.">
                          <Icon use="round-info-outline" color="neutral" />
                        </Tooltip>
                      </Flex>
                      <Text color="neutral">
                        - <Money amount={discountAmount || 0} />
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text weight="bold">Total a pagar</Text>
                      <Text size="large" color="primary" weight="semi">
                        <Money amount={totalToPay || 0} />
                      </Text>
                    </Flex>

                    <Button
                      shape="pill"
                      color="primary"
                      onClick={() => {
                        discountSettlementAnalitica.events.calculation.clicked('continuar')
                        onContinue()
                      }}
                    >
                      Continuar
                    </Button>
                    <Button
                      shape="pill"
                      color="neutral"
                      onClick={() => {
                        discountSettlementAnalitica.events.calculation.clicked('voltar para a simulação')
                        clearSimulation()
                        closeModal()
                      }}
                    >
                      Voltar para a simulação
                    </Button>
                  </Stack>
                </Flex>
              </CustomBox>
            </Box>
          </Modal.Content>
        </Choose.Otherwise>
      </Choose>
    </Modal>
  )
}

interface RenegotiationDiscountSettlementSimulationProps {
  offerId?: string //TODO: use offer from the context
  maxDiscountRate: number
  installmentNumbersToShow?: number[]
}
export function RenegotiationDiscountSettlementRateSimulation({
  offerId,
  maxDiscountRate,
  installmentNumbersToShow
}: RenegotiationDiscountSettlementSimulationProps) {
  const [isModalOpen, toggleModalOpen] = useToggle(false)
  const [isModalLoading, toggleModalLoading] = useToggle(false)
  const [simulationResult, setSimulationResult] = useState<RenegotiationSimulation>()
  const { addToast } = useToast()

  const { installments, saveInstallments, saveSimulation } = useDiscountSettlementContext()

  const loanId = BlackBird.getQuery().loan

  useEffect(() => {
    discountSettlementAnalitica.events.simulation.view('DiscountRate')
  }, [])

  const { data: fetchedInstallments, isLoading: isContentLoading } = useLoanInstallments(loanId)
  const installmentsToShow =
    fetchedInstallments &&
    fetchedInstallments.items.filter(installment => installmentNumbersToShow?.includes(installment.installmentNumber))

  const buttonText = installments?.length ? 'Calcular desconto' : 'Selecione pelo menos uma parcela'
  const selectedInstallmentsValues: number[] =
    fetchedInstallments?.items?.filter(value => installments?.includes(value)).map(value => value.presentBalance) || []

  const selectedInstallmentsTotalValue = selectedInstallmentsValues?.length
    ? selectedInstallmentsValues.reduce((previous, presentBalance) => presentBalance + previous)
    : 0

  const selectedInstallmentNumbers = installments?.map(item => item.installmentNumber) || []
  async function handleSimulate() {
    toggleModalLoading(true)
    toggleModalOpen(true)
    discountSettlementAnalitica.events.simulation.clicked('calcular desconto')
    try {
      const { data: simulationData } = await simulateRenegotiationProposal({
        offerId: offerId || '',
        installments: selectedInstallmentNumbers,
        rate: maxDiscountRate
      })

      setSimulationResult(simulationData)
      toggleModalLoading(!simulationData)
    } catch (error) {
      addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
      toggleModalLoading(false)
      toggleModalOpen(false)
    }
  }

  function clearSimulation() {
    saveInstallments([])
  }

  function handleContinue() {
    if (simulationResult) {
      saveSimulation(simulationResult)

      // TODO: melhorar a maneira que construímos as urls do fluxo
      BlackBird.travelTo({
        pathname: basePath,
        urlParams: { loan: loanId, step: DiscountSettlementSteps.PAYMENT_METHOD }
      })
    }
  }

  return (
    <>
      <Stack space="1.75rem">
        <CustomBox>
          <Stack space="1rem">
            <Stack space="1rem">
              <Flex alignItems="center" justifyContent="space-between">
                <Text color="neutral">Economize até </Text>
                <Heading size="xSmall" color="primary" weight="semi">
                  {multiplyByOneHundred(maxDiscountRate)}%
                </Heading>
              </Flex>

              <CustomDivider />

              <Flex alignItems="center" gap="0.5rem">
                <Icon use="round-info-outline" color="neutral" />
                <Description color="neutral">
                  O desconto vai ser aplicado no valor total das parcelas selecionadas.
                </Description>
              </Flex>
            </Stack>
          </Stack>
        </CustomBox>
        <Stack space="1rem">
          <Stack>
            <Overline color="neutral">Quais parcelas você quer quitar?</Overline>
            <Description color="neutral">Escolha até {installmentNumbersToShow?.length} parcelas.</Description>
          </Stack>

          <CustomBox>
            <If condition={installmentsToShow != undefined && installmentsToShow.length > 0}>
              <SharedInstallmentSelectionList
                installments={installmentsToShow || []}
                selectedInstallments={installments || []}
                setSelectedInstallments={saveInstallments}
                onSelectAllEvent={() => discountSettlementAnalitica.events.simulation.clicked('selecionar todas')}
              />
            </If>
          </CustomBox>
        </Stack>
        <If condition={selectedInstallmentsTotalValue > 0}>
          <Flex justifyContent="space-between">
            <Text color="neutral">Valor das parcelas selecionadas</Text>
            <Text size="large" color="primary" weight="semi">
              <Money amount={selectedInstallmentsTotalValue} />
            </Text>
          </Flex>
        </If>
        <Button
          shape="pill"
          disabled={!installments?.length}
          style={{
            width: '100%'
          }}
          onClick={handleSimulate}
        >
          {buttonText}
        </Button>
      </Stack>

      <SimulationResultModal
        isLoading={isModalLoading}
        selectedInstallmentTotalAmount={simulationResult?.negotiationDebtAmount}
        discountAmount={simulationResult?.discountAmount}
        totalToPay={simulationResult?.amount}
        selectedInstallmentCount={installments?.length}
        isOpen={isModalOpen}
        closeModal={() => {
          discountSettlementAnalitica.events.simulation.clicked('fechar')
          toggleModalOpen(false)
        }}
        clearSimulation={clearSimulation}
        onContinue={handleContinue}
      />

      <Dimmer isVisible={isContentLoading}>
        <Loader />
      </Dimmer>
    </>
  )
}
