import { type LoanInstallment } from '../../shared/entities'
import { SpotOfferSteps } from '../components/spot-offer-flow'
import {
  type LegacySpotOffer,
  type SpotOffer,
  type SpotOfferCreationParams,
  type SpotOfferPaymentMethod,
  type SpotOfferType
} from '../entities'

export const getOverduedInstallments = (installments: LoanInstallment[]): LoanInstallment[] => {
  return installments.filter((installment: LoanInstallment) => installment.status === 'Overdue')
}

export const getSpotOfferTitle = (step: SpotOfferSteps, currentInstallment: LoanInstallment): string => {
  if (step === SpotOfferSteps.SPOT_OFFER_CURRENT_INSTALLMENT) {
    return currentInstallment?.status === 'Overdue' ? 'Pagar o valor em atraso' : 'Quitar a parcela aberta'
  } else if (step === SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS_INPUT) {
    return currentInstallment?.status === 'Overdue' ? 'Quitar mais parcelas' : 'Antecipar parcelas futuras'
  } else if (step === SpotOfferSteps.SPOT_OFFER_ALL_INSTALLMENTS) {
    return 'Quitar o empréstimo'
  }
  return 'Revise seu pagamento'
}

export const getSpotOfferReviewStepTitle = (step: SpotOfferSteps, currentInstallment: LoanInstallment): string => {
  return getSpotOfferTitle(step, currentInstallment)
}

interface SpotOfferReviewAmountProps {
  step: SpotOfferSteps
  installments?: LoanInstallment[]
  currentInstallment?: LoanInstallment
  installmentsSelected?: LoanInstallment[]
  amount?: number
}

export const getSpotOfferReviewAmount = (data: SpotOfferReviewAmountProps): number => {
  const { step, installments, currentInstallment, installmentsSelected, amount } = data
  if (
    [
      SpotOfferSteps.SPOT_OFFER_CURRENT_INSTALLMENT,
      SpotOfferSteps.SPOT_OFFER_ALL_INSTALLMENTS,
      SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS
    ].includes(step)
  ) {
    return getInstallmentList({ step, installments, currentInstallment, installmentsSelected }).reduce(
      (acc, curr) => acc + (curr.status === 'Overdue' ? curr.futureBalance : curr.presentBalance),
      0
    )
  } else if ([SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT].includes(step)) {
    return amount ?? 0
  }
  return 0
}

interface GetInstallmentListProps {
  step: SpotOfferSteps
  installments?: LoanInstallment[]
  currentInstallment?: LoanInstallment
  installmentsSelected?: LoanInstallment[]
}

export const getInstallmentList = ({
  step,
  installments = [],
  currentInstallment,
  installmentsSelected
}: GetInstallmentListProps): LoanInstallment[] => {
  const isStepCurrentInstallment = currentInstallment && step === SpotOfferSteps.SPOT_OFFER_CURRENT_INSTALLMENT
  const isStepNextInstallments = step === SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS
  const isStepAllInstallments = step === SpotOfferSteps.SPOT_OFFER_ALL_INSTALLMENTS

  if (isStepCurrentInstallment) {
    if (!!installments && currentInstallment.status === 'Overdue') return getOverduedInstallments(installments)
    else if (currentInstallment.status === 'Settled') {
      const availableInstallment = installments.find(({ installmentNumber, status, presentBalance }) => {
        const isAfterCurrentInstallment = installmentNumber > currentInstallment.installmentNumber
        const isNotSettled = status !== 'Settled'
        const hasPositiveBalance = presentBalance > 0

        return isAfterCurrentInstallment && isNotSettled && hasPositiveBalance
      })
      return availableInstallment ? [availableInstallment] : []
    }
    return [currentInstallment]
  }

  if (isStepNextInstallments)
    return installments.filter(({ installmentNumber }) =>
      installmentsSelected?.map(item => item.installmentNumber)?.includes(installmentNumber)
    )

  if (isStepAllInstallments) return installments.filter(installment => installment.futureBalance > 0)
  return []
}

export const getSpotOfferTypeByStep = (step: SpotOfferSteps): SpotOfferType => {
  if ([SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT].includes(step)) return 'Regular'
  return 'Settlement'
}

export const getSpotOfferProductInstallmentsByStep = (
  step: SpotOfferSteps,
  installments?: LoanInstallment[],
  installmentsSelected?: LoanInstallment[]
): number[] | undefined => {
  if (installments && step === SpotOfferSteps.SPOT_OFFER_CURRENT_INSTALLMENT) {
    return installments.map(installment => installment.installmentNumber)
  } else if (
    [SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS, SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS_INPUT].includes(step)
  ) {
    return installmentsSelected?.map(item => item.installmentNumber)
  }
  return undefined
}

export const getSpotOfferAmountByStep = (step: SpotOfferSteps, amount?: number): number | undefined => {
  if (step === SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT_INPUT) return amount
  return undefined
}

interface PaymentMethodTranslationMap {
  title: string
  action: string
}

export const translatePaymentMethod = (
  paymentMethod: SpotOfferPaymentMethod,
  attribute: 'title' | 'action' = 'title'
) => {
  const translationMap: Record<SpotOfferPaymentMethod, PaymentMethodTranslationMap> = {
    Pix: {
      title: 'Pix',
      action: 'QR Code Pix'
    },
    Boleto: {
      title: 'boleto',
      action: 'boleto'
    }
  }
  return translationMap[paymentMethod][attribute]
}

interface CreateSpotOfferProps {
  loanId: string
  paymentMethod: SpotOfferPaymentMethod
  step: SpotOfferSteps
  currentInstallment: LoanInstallment
  customerEmail: string
  installments?: LoanInstallment[]
  inputAmount?: number
  installmentsSelected?: LoanInstallment[]
  type: SpotOfferType
}
export const createSpotOffer = ({
  loanId,
  paymentMethod,
  step,
  currentInstallment,
  installments,
  customerEmail,
  inputAmount,
  installmentsSelected,
  type
}: CreateSpotOfferProps): SpotOfferCreationParams => {
  const installmentList = getInstallmentList({ step, installments, currentInstallment })
  const installmentNumbers = getSpotOfferProductInstallmentsByStep(step, installmentList, installmentsSelected)
  const basePayload = {
    product: {
      type: 'Loan',
      externalId: loanId
    },
    customer: {
      email: customerEmail
    },
    type,
    paymentMethod
  }

  if (type === 'Regular') {
    return {
      ...basePayload,
      amount: inputAmount
    }
  } else {
    return {
      ...basePayload,
      product: {
        ...basePayload.product,
        installmentNumbers
      }
    }
  }
}

export function convertLegacySpotOffer(legacySpotOffer: LegacySpotOffer): SpotOffer {
  const payment = {
    content: legacySpotOffer.pix ? legacySpotOffer.pix.qrCode.content : legacySpotOffer.boleto?.barCode || '',
    image: legacySpotOffer.pix ? legacySpotOffer.pix.qrCode.image : undefined,
    publicUri: legacySpotOffer.pix ? undefined : legacySpotOffer.boleto?.publicUri
  }

  return {
    id: legacySpotOffer.id || '',
    dueDate: legacySpotOffer.dueDate || '',
    amount: legacySpotOffer.amount || 0,
    createdBy: legacySpotOffer.customer?.email || '',
    paymentMethod: legacySpotOffer.paymentMethod,
    generalStatus: 'Processed',
    payment
  }
}
