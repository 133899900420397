import { type ReactNode } from 'react'

import { Box } from '~/domains/platform/design-system'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { ResponsiveFlexContainer } from '../../loan-concession/helpers/loan-concession.styles'

interface GridProps {
  left: ReactNode
  right: ReactNode
}

export function KycFlowGrid({ left, right }: GridProps) {
  const { isViewSmall } = useViewSize()

  return (
    <ResponsiveFlexContainer gap="2.5rem">
      {!isViewSmall && <Box width="40%">{left}</Box>}
      <Box width={isViewSmall ? 'auto' : '60%'}>{right}</Box>
    </ResponsiveFlexContainer>
  )
}
