import { useRouter } from 'next/router'
import { Heading, IconButton } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'

interface InstallmentHeaderProps {
  installmentNumber?: string
}

export const LoanInstallmentDetailsHeader = (props: InstallmentHeaderProps) => {
  const { installmentNumber } = props
  const { query } = useRouter()
  const { loan: loanId }: { loan?: string } = query

  return (
    <Flex alignItems="center" justifyContent="space-between" mb="2rem">
      <Flex alignItems="center" gap="1rem">
        <IconButton
          icon="direction-arrow-left-outline"
          data-testid="back-button"
          color="neutral"
          variant="contentOnly"
          size="large"
          onClick={() => BlackBird.travelTo({ pathname: '/credito/emprestimo/${loan}', urlParams: { loan: loanId } })}
        />
        <Heading size="xLarge" weight="semi">
          Parcela {installmentNumber}
        </Heading>
      </Flex>
    </Flex>
  )
}
