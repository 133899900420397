import { type RenegotiationProposal } from '../../loan-concession/entities'
import { type CreditOffer } from '../../shared'
import analitica from '../analitica'

type BannerEventProps = {
  offer?: CreditOffer
  proposal?: RenegotiationProposal
}

//TODO: move this to shared
export const proposalBannerEvent = (eventType: 'clicked' | 'view', { proposal, offer }: BannerEventProps) => {
  const typeMapper = {
    DiscountSettlement: 'quitação com desconto',
    Renegotiation: 'renegociação'
  }

  if (proposal?.id && offer) {
    analitica.events.banner.proposal(eventType, {
      type: typeMapper[offer?.type as 'DiscountSettlement' | 'Renegotiation'] as
        | 'renegociação'
        | 'quitação com desconto',
      status: proposal?.status,
      proposalId: proposal.id,
      expirationDate: proposal.expirationDate,
      origin: proposal.origin?.userType || ''
    })
  }
}
