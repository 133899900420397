import { Stack } from '@dlpco/fluid-layout'
import { Heading } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { type AnswerChallenge, httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { ChallengeForm } from '~/ui/business-components/shared'

import { httpClientCreditNegotiations } from '../../../loan-concession/infra/http-client-credit-negotiations'
import { useRenegotiationContext } from '../../context/renegotiation-context'
import { RenegotiationSteps } from '../../entities'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'

export function RenegotiationChallenge() {
  const { contractId } = useRenegotiationContext()

  const handleRequest = async (payload?: AnswerChallenge) => {
    return httpWithChallenge({
      config: {
        url: `/contracts-service/api/v1/contracts/${contractId}/sign`,
        method: 'POST'
      },
      client: httpClientCreditNegotiations
    })(payload)
  }

  const redirect = (status: 'success' | 'error') => {
    renegotiationNavigate({
      step: RenegotiationSteps.REVIEW,
      query: { challenge: status }
    })
  }

  return (
    <Stack space="2rem">
      <Heading size="large" weight="bold">
        Confirmar pedido
      </Heading>
      <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
        <ChallengeForm
          onRequest={handleRequest}
          onError={() => redirect('error')}
          onSuccess={() => redirect('success')}
        />
      </Flex>
    </Stack>
  )
}
