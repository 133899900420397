import styled, { css } from 'styled-components'

import { theme } from '~/ui/theme'

import { type CurrentInstallment } from '../entities'
import { getProgressBarValues, progressBarRevolvingCredit } from '../helpers/progress-bar'

const getBackgroundColor = (color: 'green' | 'blue' | 'gray') => {
  const colors = {
    green: theme.colors.stoneGreen,
    blue: theme.colors.blue,
    gray: theme.colors.neutralOverlay
  }

  return colors[color]
}

const ProgressBar = styled.div<{ progress: string; color: 'green' | 'blue' | 'gray'; isFirst?: boolean }>`
  height: 18px;
  margin: 0;
  position: relative;
  overflow: hidden;

  ${({ theme, progress, color, isFirst }) =>
    css`
      border-radius: ${isFirst ? '4px 0 0 4px' : '4px'};
      background-color: ${getBackgroundColor(color)};
      width: ${progress}%;

      ${isFirst &&
      `
        &::after {
          ${+progress <= 99 && "content: '';"}
          background-color: ${theme.colors.white};
          height: 18px;
          width: 2px;
          position: absolute;
          margin-left: calc(100% - 2px);
        }
      `}
    `}
`
const ProgressBarWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutralOverlay};
  height: 18px;
  width: 100%;
  margin: 0.5rem 0 1rem;
  border-radius: 4px;
`

export function LoanHomeProgressBar({ currentInstallment }: { currentInstallment: CurrentInstallment }) {
  const progressBarValues = getProgressBarValues(currentInstallment)

  return (
    <ProgressBarWrapper>
      <ProgressBar
        progress={progressBarValues?.paid?.toString() || ''}
        color="green"
        isFirst
        data-testid="progress-paid-green"
      />
      <ProgressBar
        progress={progressBarValues?.economy?.toString() || ''}
        color="blue"
        data-testid="progress-economy-blue"
      />
    </ProgressBarWrapper>
  )
}

type RevolvingCreditProgressBarProps = {
  amountLimit: number
  contractedAmount: number
  withdrawalAmount: number
}

export function LoanHomeRevolvingCreditProgressBar({
  amountLimit,
  contractedAmount,
  withdrawalAmount
}: RevolvingCreditProgressBarProps) {
  const { withdrawn, available } = progressBarRevolvingCredit({ amountLimit, contractedAmount, withdrawalAmount })
  return (
    <ProgressBarWrapper>
      <ProgressBar progress={withdrawn?.toString() || ''} color="gray" isFirst data-testid="progress-withdrawn-gray" />
      <ProgressBar progress={available?.toString() || ''} color="blue" data-testid="progress-available-blue" />
    </ProgressBarWrapper>
  )
}
