import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box } from '~/domains/platform/design-system'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'

import { LoanDetailsHome } from './loan-details-home'
import { LoanDetailsJade } from './loan-details-jade'

export function LoanDetailsPageSelector(props: WithPageConfigProps) {
  const hasJadeFlag = Heimdall.pass(['credit_loan_management_web'])

  if (hasJadeFlag) return <LoanDetailsJade />
  return (
    <Box maxWidth="1024px" padding="40px">
      <LoanDetailsHome {...props} />
    </Box>
  )
}
