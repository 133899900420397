import { useEffectOnce } from 'react-use'
import { formatDate } from '@credit-web/common/date-utils'
import { Button, Card, CardBody, Heading, Icon, Money, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { theme } from '~/ui/theme'

import { type AnalysisStatuses } from '../entities'
import analitica, { type LoanCardViewProps } from '../helpers/analitica'

import { BorderHeader } from './loan-home-active-loan-card'
import { LoanHomeBadgeProductType } from './loan-home-badge-product-type'

const Line = styled.hr<{ lineColor: 'primary' | 'gray' }>`
  flex-grow: 100;
  height: 1px;
  ${props =>
    props.lineColor === 'primary'
      ? `border: 2px solid ${theme.colors.primary};`
      : `border: 2px solid ${theme.colors.border.disabled};`}
`

const EmptyCircle = styled.div`
  border-radius: 99px;
  border: 2px solid ${theme.colors.mediumGray2};
  width: 1.2rem;
  height: 1.2rem;
`
interface BaseSteps {
  received: NumberStepProps
  underReview: NumberStepProps
  disbursement_requested: NumberStepProps
  inTheAccount: NumberStepProps
}

const baseSteps: BaseSteps = {
  received: {
    number: 1,
    text: 'Pedido recebido',
    neutralText: false,
    icon: 'check-round-solid',
    iconColor: 'primary',
    lineColor: 'primary'
  },
  underReview: {
    number: 2,
    text: 'Empréstimo em análise',
    neutralText: true,
    iconColor: 'neutralLow',
    lineColor: 'gray'
  },
  disbursement_requested: {
    number: 3,
    text: 'Empréstimo aprovado',
    neutralText: true,
    iconColor: 'neutralLow',
    lineColor: 'gray'
  },
  inTheAccount: {
    number: 4,
    text: 'Recebimento na conta',
    neutralText: true,
    iconColor: 'neutralLow',
    lineColor: 'gray',
    showLine: false
  }
}

const getLabelText = (status: AnalysisStatuses) => {
  const messages = {
    accepted: baseSteps?.underReview?.text,
    disbursement_requested: baseSteps?.underReview?.text,
    disbursement_confirmed: baseSteps?.disbursement_requested?.text,
    cancelled: 'Cancelado'
  }

  return messages[status] || 'Em análise'
}

const steps: Record<AnalysisStatuses, NumberStepProps[]> = {
  accepted: [
    baseSteps.received,
    {
      ...baseSteps.underReview,
      neutralText: false,
      icon: 'round-clock-outline',
      iconColor: 'primary',
      lineColor: 'gray'
    },
    baseSteps.disbursement_requested,
    baseSteps.inTheAccount
  ],
  disbursement_requested: [
    baseSteps.received,
    {
      ...baseSteps.underReview,
      neutralText: false,
      icon: 'check-round-solid',
      iconColor: 'primary',
      lineColor: 'primary'
    },
    {
      ...baseSteps.disbursement_requested,
      neutralText: false,
      icon: 'check-round-solid',
      iconColor: 'primary',
      lineColor: 'primary'
    },
    {
      ...baseSteps.inTheAccount,
      neutralText: false,
      icon: 'round-clock-outline',
      iconColor: 'primary',
      lineColor: 'gray'
    }
  ],
  cancelled: [
    {
      ...baseSteps.received,
      neutralText: true,
      iconColor: 'neutralLow',
      lineColor: 'gray'
    },
    {
      ...baseSteps.underReview,
      icon: 'check-round-solid'
    },
    {
      ...baseSteps.disbursement_requested,
      text: 'Empréstimo não aprovado',
      icon: 'error-round-solid',
      iconColor: 'negative'
    },
    baseSteps.inTheAccount
  ],
  disbursement_confirmed: [{ ...baseSteps.inTheAccount }]
}

const bottomText = ({ status, daysToReceive }: { status: AnalysisStatuses; daysToReceive: number }) => {
  const textStatus = {
    accepted: (
      <Flex flexDirection="column" gap="1.5rem">
        <Text>
          Se aprovado, seu dinheiro cai na conta em até <b>{daysToReceive} dias úteis.</b>
        </Text>
      </Flex>
    ),
    disbursement_requested: (
      <Text>
        Seu dinheiro vai cair na conta em até <b>{daysToReceive} dias úteis</b> a partir da data do pedido.
      </Text>
    ),
    cancelled: <Text>O contrato desse empréstimo foi cancelado. A oferta pode não estar mais disponível.</Text>,
    disbursement_confirmed: (
      <Text>
        Seu dinheiro vai cair na conta em até <b>{daysToReceive} dias úteis</b> a partir da data do pedido.
      </Text>
    )
  }

  return textStatus[status]
}

type LoanHomeAnalysisLoanCardProps = {
  id: string
  status: AnalysisStatuses
  loanAmount: number
  creationDate: string
  daysToReceive: number
  type: string
}

export function LoanHomeAnalysisLoanCard({
  id,
  status,
  loanAmount,
  creationDate,
  daysToReceive,
  type
}: LoanHomeAnalysisLoanCardProps) {
  const { currency } = stringFormat
  useEffectOnce(() => {
    analitica.events.home.loanCardView({
      id,
      status
    } as LoanCardViewProps)
  })
  return (
    <Card
      key={id}
      tabIndex={0}
      role="region"
      aria-label={`Status do pedido de Capital de Giro: ${getLabelText(status)}`}
    >
      <BorderHeader
        slotEnd={
          <>
            <If condition={status === 'cancelled'}>
              <Button color="neutral" onClick={() => chat.open()}>
                Conversar com a Stone
              </Button>
            </If>
            <If condition={status === 'accepted'}>
              <Button
                color="neutral"
                onClick={() => {
                  analitica.events.home.loanCardClick({ 'loan id': id, action: 'cancelar pedido' })
                  chat.open()
                }}
                aria-label="Cancelar pedido de capital de giro"
              >
                Cancelar pedido
              </Button>
            </If>
          </>
        }
      >
        <LoanHomeBadgeProductType type={type} />
        <Heading
          size="medium"
          weight="semi"
          aria-label={`Valor solicitado: ${currency(loanAmount, false)}`}
          style={{ marginTop: theme.space.default }}
        >
          <Money amount={loanAmount / 100} />
        </Heading>
        <Text size="small" color="neutral" aria-label={`Data da solicitação: ${formatDate(creationDate)}`}>
          {formatDate(creationDate)}
        </Text>
      </BorderHeader>
      <CardBody aria-hidden>
        <Flex flexDirection="column" gap="2rem">
          <Flex justifyContent={'space-between'} gap="1rem">
            <For
              of={steps[status]}
              render={loanStepInfo => <NumberStep key={loanStepInfo.number} {...loanStepInfo} />}
            />
          </Flex>
          {bottomText({ status, daysToReceive })}
        </Flex>
      </CardBody>
    </Card>
  )
}

interface NumberStepProps {
  number: number
  text: string
  neutralText?: boolean
  icon?: 'check-round-solid' | 'round-clock-outline' | 'error-round-solid'
  iconColor: 'negative' | 'primary' | 'neutralLow'
  showLine?: boolean
  lineColor?: 'primary' | 'gray'
}

const NumberStep = ({
  number,
  icon,
  iconColor,
  text,
  neutralText = false,
  showLine = true,
  lineColor = 'primary'
}: NumberStepProps) => {
  return (
    <Flex flexDirection="column" gap="1rem" width="calc(25% - 1rem)">
      <Flex gap="0.5rem">
        <Heading size="xLarge" weight="bold" color={neutralText ? 'neutralLow' : 'neutralHigh'}>
          {number}
        </Heading>
        <Text size="small" color={neutralText ? 'neutralLow' : 'neutralHigh'}>
          {text}
        </Text>
      </Flex>
      <Flex gap="0.75rem" alignItems="center">
        {icon ? <Icon use={icon} color={iconColor} /> : <EmptyCircle />} {showLine && <Line lineColor={lineColor} />}
      </Flex>
    </Flex>
  )
}
