import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

const fifteenMinutes = 15 * 60 * 1000

type CheckConfigurationsParams = {
  document: string
}

export type Content = {
  productsUnderMaintenance: any[]
  allProductsIsUnderMaintenance: boolean
}

export type ConfigurationReturn = {
  content: Content
  traceKey: string
  isValid: boolean
}

async function fetchConfigurations({ document }: CheckConfigurationsParams) {
  return await httpClientCredit<ConfigurationReturn>({
    url: `experience-bff-service/api/web/v1/configurations?document=${document}`,
    method: 'GET',
    params: { document }
  })
}

export function useCheckConfigurations(
  { document }: CheckConfigurationsParams,
  queryOptions?: UseQueryRequestOptions<ConfigurationReturn>
) {
  const { data: response, ...restQuery } = useQueryRequest<ConfigurationReturn>(
    ['useCheckConfigurations', document],
    () => fetchConfigurations({ document }),
    {
      ...queryOptions,
      staleTime: fifteenMinutes
    }
  )

  const data = response?.data

  return {
    data,
    ...restQuery
  }
}
