import { useMemo, useState } from 'react'

import { type CreditPaging } from '~/domains/credit/shared/entities'
import { useQueryRequest } from '~/ui/hooks/utils'

import { type Contract } from '../../loan-concession/entities'
import { fetchContracts } from '../../shared/services/loans'

const MAX_REQUEST_COUNT = 10
const REQUEST_INTERVAL_MILISECONDS = 5000

export type ConfirmStates = 'START' | 'ERROR' | 'SUCCESS'

interface RenegotiationSignResponse {
  state: ConfirmStates
  contractId?: string
}

interface RequestResponse {
  id?: string
}

interface useRenegotiationSignProps {
  proposalId: string
  challenge?: string
  enabled: boolean
}

export function useRenegotiationSign({ proposalId, challenge, enabled }: useRenegotiationSignProps) {
  const [count, setCount] = useState(0)
  const resetCount = () => setCount(0)

  const queryInfo = useQueryRequest<RequestResponse>(
    ['useRenegotiationContractsPolling', proposalId],
    () => fetchContracts(proposalId),
    {
      initialData: undefined,
      refetchInterval: data => {
        // @ts-ignore
        if (data?.id || count >= MAX_REQUEST_COUNT || challenge) return false
        return REQUEST_INTERVAL_MILISECONDS
      },
      onSettled: () => setCount(current => current + 1),
      retry: MAX_REQUEST_COUNT,
      enabled
    }
  )

  return {
    ...queryInfo,
    data: {
      ...(useMemo(() => {
        const contracts = queryInfo?.data as unknown as CreditPaging<Contract[]>
        const firstContract = contracts?.items[0]
        const hasContracts = Boolean(firstContract)

        if (challenge) {
          if (challenge === 'error') return { state: 'ERROR' }
          if (challenge === 'success') return { state: 'SUCCESS' }
        } else {
          if (!hasContracts && count < MAX_REQUEST_COUNT) {
            return { state: 'START' }
          }

          if (firstContract) {
            const { id: contractId } = firstContract
            if (contractId) {
              return { state: 'START', contractId }
            }
          }
          if (queryInfo.isFetched) return { state: 'ERROR' }
        }
      }, [queryInfo.data, count, queryInfo.isFetched, challenge]) as RenegotiationSignResponse),
      resetCount
    }
  }
}
