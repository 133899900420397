import { Badge } from '@dlpco/ginga-stone'

// TODO verificar o type correto, hoje no /api/v3/loans não existe o campo type, enquanto isso será usado o campo loanConditionsStrategy
export function LoanHomeBadgeProductType({ type }: { type: string }) {
  if (type === 'revolving_credit') {
    return (
      <Badge color="info" variant="solid">
        Giro Fácil
      </Badge>
    )
  }

  return (
    <Badge color="positive" variant="solid">
      Capital de Giro
    </Badge>
  )
}
