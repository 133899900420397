import { createContext, useContext, useState } from 'react'

export function useRenegotiationContext() {
  const context = useContext(RenegotiationContext)
  return context
}

export function RenegotiationProvider({ children }: { children: React.ReactNode }) {
  const [contractId, setContractId] = useState<string>('')

  function saveContractId(data: string) {
    setContractId(data)
  }

  const contextValue = {
    contractId,
    saveContractId
  }
  return <RenegotiationContext.Provider value={contextValue}>{children}</RenegotiationContext.Provider>
}
interface ContextProps {
  contractId: string
  saveContractId: (data: string) => void
}

export const RenegotiationContext = createContext<ContextProps>({
  contractId: '',
  saveContractId: (data: string) => {
    return data
  }
})
