import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { getProposalByIdV2 } from '../../shared/services/loans'
import { type RenegotiationProposalId, type RenegotiationProposalIdRoot } from '../entities'

export function useProposalByIdV2(
  proposalId: string,
  queryOptions?: UseQueryRequestOptions<RenegotiationProposalIdRoot>
) {
  const { data: response, ...restQuery } = useQueryRequest<RenegotiationProposalIdRoot>(
    ['useGetProposalById', proposalId],
    () => getProposalByIdV2(proposalId),
    { ...queryOptions, enabled: Boolean(proposalId) }
  )
  const data: RenegotiationProposalId | undefined = response?.data?.content
  if (data && data.type.value === 'renegotiation') return { data, ...restQuery }
  return { data: undefined, ...restQuery }
}
