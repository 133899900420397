import { type CreditPaging, type Loan } from '~/domains/credit/shared/entities'
import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

export async function fetchLoanDetails(document: string, loanId: string) {
  return await httpClientCredit<CreditPaging<Loan[]>>({
    url: `/experience-bff-service/api/legacy/web/v1/loans`,
    params: { document, id: loanId }
  })
}

export function useLoanDetails(document: string, loanId?: string, queryOptions?: UseQueryRequestOptions<Loan>) {
  const { data: response, ...restQuery } = useQueryRequest<Loan>(
    ['useLoanDetails', document, loanId],
    () => fetchLoanDetails(document, loanId || ''),
    {
      enabled: Boolean(loanId),
      ...queryOptions
    }
  )

  return {
    ...restQuery,
    data: response?.data
  }
}
