import { SharedCreditBanner } from '~/domains/credit/shared/components/credit-banner'
import BlackBird from '~/domains/platform/lib/blackbird'

import { type NotificationMaintenance } from '../entities'

const LoanHomeNotificationsBanners = ({ notifications }: { notifications: NotificationMaintenance[] }) => {
  return notifications.map(notification => {
    const { button, subtitle, title } = notification.metadata
    return (
      <SharedCreditBanner
        key={notification.id}
        showButton={true}
        buttonText={button.text}
        buttonAction={() => BlackBird.travelTo(button.action as any)}
        title={title}
        description={subtitle}
        iconName="round-info-outline"
        buttonColor="neutral"
        iconColor={'neutral'}
      />
    )
  })
}
export { LoanHomeNotificationsBanners }
