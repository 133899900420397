import { Stack } from '@dlpco/fluid-layout'
import { PageContainer } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Box } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import BlackBird from '~/domains/platform/lib/blackbird'

import { SpotOfferSteps } from './spot-offer-flow'
export function SpotOfferLoading() {
  const { loan: loanId } = BlackBird.getQuery()
  return (
    <Box style={{ height: '100%', backgroundColor: jadeTheme.theme.color.background.surface }}>
      <SharedWithCustomNavbar
        navigationBarTitle={''}
        navigationBarNavigateButtonTarget={`/credito/emprestimo/${loanId}/antecipar-pagamento/${SpotOfferSteps.SPOT_OFFER_INDEX}`}
        navigationBarNavigateButtonType="back"
        backgroundColor="surface"
      >
        <PageContainer size="narrow" style={{ minHeight: '100' }}>
          <Box data-testid="spot-offer-loading">
            <Skeleton width="50%" height="2.5rem" />
          </Box>
          <Box mt={2}>
            <Skeleton width="40%" height="1.5rem" />
          </Box>
          <Box p={2} mt={4}>
            <Stack space="1rem">
              <Skeleton width="100%" height="4ch" />
              <Skeleton width="100%" height="4ch" />
            </Stack>
          </Box>
        </PageContainer>
      </SharedWithCustomNavbar>
    </Box>
  )
}
