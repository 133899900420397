import { useRouter } from 'next/router'

import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { goToLoanDetails } from '~/domains/credit/shared/helpers/go-to-loan-details'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import { Choose } from '~/domains/platform/lib/utilities-components'

import { findOfferConditionsType } from '../helpers/discount-settlement'
import { useRenegotiationsData } from '../hooks/use-renegotiation-data'

import { RenegotiationDiscountAmountFlow } from './discount-settlement-amount/discount-settlement-amount-flow'
import { RenegotiationDiscountSettlementRateFlow } from './discount-settlement-rate/discount-settlement-rate-flow'
import { RenegotiationFlow } from './renegotiation/renegotiation-flow'
import { RenegotiationFlowLoading } from './renegotiation/renegotiation-flow-loading'

export function RenegotiationHome(props: WithPageConfigProps<'stone_account' | 'banking' | 'stone_account_kyc'>) {
  const { loan: loanId, offerId }: { loan?: string; proposalId?: string; offerId?: string } = useRouter().query
  const { entity, subject } = props
  const {
    data: renegotiationData,
    isLoading,
    isError
  } = useRenegotiationsData({ loanId: loanId ?? '', offerId, document: entity.document })
  const { offer, proposal } = renegotiationData ?? {}
  const conditionType = findOfferConditionsType(offer)

  if (isError) return <SharedGenericCardError />

  if (isLoading) return <RenegotiationFlowLoading />

  if (!isLoading && !offer) goToLoanDetails(loanId ?? '')

  return (
    <Choose>
      <Choose.When condition={offer?.type === 'DiscountSettlement'}>
        <Choose>
          <Choose.When condition={conditionType === 'DiscountRate'}>
            <RenegotiationDiscountSettlementRateFlow
              userEmail={subject?.email}
              renegotiationData={renegotiationData || {}}
            />
          </Choose.When>

          <Choose.When condition={conditionType === 'DiscountAmount'}>
            <RenegotiationDiscountAmountFlow userEmail={subject?.email} renegotiationData={renegotiationData || {}} />
          </Choose.When>

          <Choose.Otherwise>
            <SharedGenericCardError />
          </Choose.Otherwise>
        </Choose>
      </Choose.When>

      <Choose.When condition={offer?.type === 'Renegotiation'}>
        <RenegotiationFlow entity={entity} subject={subject} proposalId={proposal?.id} />
      </Choose.When>

      <Choose.Otherwise>
        <SharedGenericCardError />
      </Choose.Otherwise>
    </Choose>
  )
}
