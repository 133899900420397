import { rateUtils } from '@credit-web/common/number-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Card, CardBody, CardHeader, Description, Icon, Overline } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'

import { LoanDetailsCustomTooltip } from './loan-details-custom-tooltip'

function LoanDetailsAboutRetentionLoader() {
  return (
    <Card>
      <Box width="100%" p="1rem 1.5rem">
        <Skeleton width="90%" height="2rem" />
      </Box>
      <CardBody removeSpacing="top">
        <Stack space="1rem">
          <Skeleton width="90%" height="1rem" />

          <Skeleton width="90%" height="1rem" />
        </Stack>
      </CardBody>
    </Card>
  )
}

type LoanDetailsAboutRetentionProps = {
  isRetentionEnabled: boolean
  retentionRate: number
  isLoading: boolean
}

export function LoanDetailsAboutRetention({
  isRetentionEnabled,
  retentionRate,
  isLoading
}: LoanDetailsAboutRetentionProps) {
  if (isLoading) return <LoanDetailsAboutRetentionLoader />

  return (
    <Card>
      <CardHeader
        slotEnd={
          <LoanDetailsCustomTooltip
            text={
              'Com a retenção diária, você economiza o valor dos juros do dia, mesmo se não antecipar pagamentos. O valor desses juros economizados são usados no pagamento da sua parcela atual e ajudam a acelerar sua quitação.'
            }
          />
        }
      >
        <Overline color="neutral">Sobre a retenção</Overline>
      </CardHeader>
      <CardBody removeSpacing="top">
        <Stack space="1rem">
          <Flex alignItems="center">
            <Icon use="device-card-machine-outline" color={isRetentionEnabled ? 'primary' : 'neutral'} />
            <Box ml="0.5rem">
              <Description>
                Retenção automática <strong>{isRetentionEnabled ? 'ativada' : 'pausada'}</strong>
              </Description>
            </Box>
          </Flex>

          <Flex alignItems="center">
            <Icon use="percent-outline" color={isRetentionEnabled ? 'primary' : 'neutral'} />
            <Box ml="0.5rem">
              <Description>
                <strong>{rateUtils.formatToLimitedDecimalPlaces(retentionRate)}%</strong> dos seus recebimentos diários
              </Description>
            </Box>
          </Flex>
        </Stack>
      </CardBody>
    </Card>
  )
}
