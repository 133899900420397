import { useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { IconShape, ListItem, Modal, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { revolvingCreditConcessionBasePath } from '~/domains/credit/ports/credit.routes'
import { Box } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { stringFormat } from '~/lib/helpers'
import { theme } from '~/ui/theme'

import analitica from '../helpers/analitica'

type ConcessionOnboardingModalProps = {
  open: boolean
  toggle: (value?: boolean) => void
  offerId: string
  amount: number
}

type StyledElement =
  | (Element & {
      style: {
        [key: string]: string
      }
    })
  | null

const handleHeaderOverlay = (isOpen: boolean) => {
  if (typeof window === 'undefined') return

  const documentInstance = window.document
  if (!documentInstance) return

  const header: StyledElement = documentInstance.querySelector('[data-cy="navbar"]')
  const menu: StyledElement = documentInstance.querySelector('[data-cy="menu"]')

  if (header) {
    header.style.zIndex = isOpen ? '300' : theme.zIndices.topMenu.toString()
  }

  if (menu) {
    menu.style.borderTop = isOpen ? `1px solid ${theme.colors.border.disabled}` : ''
    menu.style.boxShadow = isOpen ? 'inset 0px 4px 4px rgba(13, 17, 27, 0.08)' : ''
  }
}

export function ConcessionOnboardingModal({ open, toggle, offerId, amount }: ConcessionOnboardingModalProps) {
  useEffect(() => {
    if (open) analitica.events.onboarding.view({ offerId, amount })
  }, [open, offerId, amount])

  useEffectOnce(() => {
    if (open) handleHeaderOverlay(true)
  })

  return (
    <Modal
      open={open}
      toggle={() => {
        analitica.events.onboarding.click({ offerId, amount, action: 'voltar' })
        toggle()
        handleHeaderOverlay(open)
      }}
      title="Giro Fácil"
      actions={[
        {
          text: 'Saber mais',
          variant: 'primary-solid',
          name: 'onboarding-modal-activate-revolving-credit',
          onClick: () => {
            analitica.events.onboarding.click({ offerId, amount, action: 'saber mais' })
            return BlackBird.travelTo({
              pathname: revolvingCreditConcessionBasePath,
              query: { offerId }
            })
          }
        }
      ]}
    >
      <Stack space={jadeTheme.global.space[250]}>
        <Box>
          <Text variant="display-small" as="span">
            Que tal{' '}
            <Text color="brand" variant="display-small" as="span">
              {stringFormat.currency(amount ?? 0)}{' '}
            </Text>
            disponíveis para quando você precisar?
          </Text>
          <Box marginTop={jadeTheme.global.space[100]}>
            <Text variant="text-medium" color="medium">
              Ative grátis e deixe pronto para usar.
            </Text>
          </Box>
        </Box>
        <Stack space={jadeTheme.global.space[100]}>
          <ListItem
            content={
              <ListItem.Content label="Retiradas ilimitadas" description="Faça quantas retiradas quiser do limite." />
            }
            leading={<IconShape variant="brand" use="money-notes" size="medium" color="brand" />}
          />

          <ListItem
            content={
              <ListItem.Content
                label="Pague com recebimentos diários"
                description="A retenção automática paga o serviço."
              />
            }
            leading={<IconShape variant="brand" use="device-pos" size="medium" color="brand" />}
          />

          <ListItem
            content={
              <ListItem.Content
                label="Libere o limite e use de novo"
                description="O valor devolvido volta a ficar disponível."
              />
            }
            leading={<IconShape variant="brand" use="arrow-curve-cycle" size="medium" color="brand" />}
          />
        </Stack>
      </Stack>
    </Modal>
  )
}
