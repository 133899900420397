import { useState } from 'react'

import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type Assessment } from '~/domains/stone-account/ports/entities/KYC'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type KYCReason } from '../../kyc-flow/context/kyc-context'

interface FetchUserAssessmentResponse {
  data: Assessment[]
}

async function fetchUserAssessment(params: any) {
  const { data } = await httpClientBanking<FetchUserAssessmentResponse>({
    url: 'me/assessments/pending/users',
    method: 'GET',
    params
  })

  return data?.data ? data.data[0] : {}
}

export function useCreditUserAssessmentPolling(
  reason: KYCReason | null,
  assessmentSuccess: (data: Assessment) => void,
  hasEnabledAssessment: boolean
) {
  const [disablePooling, setDisablePooling] = useState(false)

  return useQueryRequest<Assessment, unknown, true>(
    ['useCreditUserAssessmentPooling'],
    () => fetchUserAssessment(reason),
    {
      onSettled: (response: any) => {
        if (response) {
          const responseData = response as Assessment
          if (responseData.checks.length > 0) {
            setDisablePooling(true)
            return assessmentSuccess(responseData)
          }
        }
      },
      refetchInterval: disablePooling ? false : 3000,
      refetchIntervalInBackground: true,
      enabled: hasEnabledAssessment
    }
  )
}
