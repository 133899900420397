import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'
import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Card, CardBody, Heading } from '@dlpco/ginga-stone'

import { SharedCreditGrid } from '~/domains/credit/shared/components/credit-grid'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import Wong from '~/domains/platform/lib/wong'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { SharedStepList } from '../../../shared/components/shared-step-list'
import { type LoanInstallmentList } from '../../../shared/entities'
import { useLoanInstallments } from '../../../shared/hooks/use-loans-installments'
import { SpotOfferDialog } from '../../../spot-offer/components/spot-offer-dialog'
import { type SpotOffer } from '../../../spot-offer/entities'
import { RenegotiationDiscountSettlementBreadcrumb } from '../../components/discount-settlement/discount-settlement-breadcrumb'
import { RenegotiationDiscountAmountSimulation } from '../../components/discount-settlement-amount/discount-settlement-amount-simulation'
import { renegotiationGoToLoan } from '../../components/discount-settlement-rate/discount-settlement-rate-flow'
import { RenegotiationChallenge } from '../../components/discount-settlement-rate/renegotiation-challenge'
import { useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { DiscountSettlementSteps } from '../../entities'
import { getInstallmentsFromOffer } from '../../helpers/discount-settlement'
import { basePath, isAdminProposal } from '../../helpers/renegotiation'
import { voidRenegotiationProposal } from '../../services/renegotiation'

import { type DiscountSettlementFlowProps } from './discount-settlement-amount-flow'

const proposalStepTexts: Partial<Record<Partial<DiscountSettlementSteps>, string>> = {
  proposta: 'Aceitar proposta',
  pin: 'Garantir seu desconto'
}

export function RenegotiationDiscountAmountFlowBase({ renegotiationData, userEmail }: DiscountSettlementFlowProps) {
  const { offer } = renegotiationData
  const { step: currentStep, loan: loanId }: any = useRouter().query
  const { saveOffer, saveProposal, saveInstallments, proposal } = useDiscountSettlementContext()
  const [spotOffer, setSpotOffer] = useState<SpotOffer | undefined>(undefined)

  const isChallengeStep = currentStep === DiscountSettlementSteps.PIN
  const loanInstallmentQueryOptions: UseQueryRequestOptions<LoanInstallmentList> = {
    onSuccess: (response: any) => {
      const installments = getInstallmentsFromOffer(offer, response?.data?.items)
      if (!installments) {
        BlackBird.travelTo({
          pathname: '/credito/emprestimo/${loan}',
          urlParams: { loan: loanId }
        })
      }
      saveInstallments(installments)
    }
  }
  useLoanInstallments(loanId, undefined, loanInstallmentQueryOptions)

  useEffectOnce(() => {
    if (offer) saveOffer(offer)
    if (renegotiationData?.proposal && !isAdminProposal(renegotiationData.proposal))
      saveProposal(renegotiationData.proposal)
  })

  const expirationDate = proposal?.expirationDate as string
  const mode =
    renegotiationData?.proposal && isAdminProposal(renegotiationData.proposal)
      ? 'paymentLoanConcessionReview'
      : 'paymentMethodSelection'

  const steps: { key: DiscountSettlementSteps; render: () => JSX.Element }[] = [
    {
      key: DiscountSettlementSteps.PROPOSAL,
      render: () => <RenegotiationDiscountAmountSimulation userEmail={userEmail} mode={mode} />
    },
    {
      key: DiscountSettlementSteps.PIN,
      render: () => <RenegotiationChallenge saveSpotOffer={setSpotOffer} />
    }
  ]
  const backFunction = () => {
    if (proposal && !isAdminProposal(proposal)) {
      voidRenegotiationProposal(proposal.id)
    }
    renegotiationGoToLoan()
  }
  return (
    <Stack space="1.5rem">
      <Stack space="1rem">
        <RenegotiationDiscountSettlementBreadcrumb backFunction={backFunction} currentStep={currentStep} />
        <Heading size="large" weight="semi">
          Renegociar empréstimo
        </Heading>
        <Choose>
          <Choose.When condition={isChallengeStep}>
            <Wong steps={steps} basePath={basePath} />
          </Choose.When>
          <Choose.Otherwise>
            <Card>
              <CardBody>
                <SharedCreditGrid
                  left={
                    <Stack space="2rem">
                      <Flex gap="1rem" flexDirection="column" alignItems="start">
                        <Heading size="large" weight="semi">
                          Você recebeu uma proposta especial de renegociação
                        </Heading>
                        <If condition={Boolean(expirationDate)}>
                          <Badge icon="round-clock-outline" color="warning">
                            Expira em {formatDate(expirationDate)}
                          </Badge>
                        </If>
                      </Flex>
                      <SharedStepList steps={proposalStepTexts} currentStep={currentStep} />
                    </Stack>
                  }
                  right={<Wong steps={steps} basePath={basePath} />}
                />
              </CardBody>
            </Card>
          </Choose.Otherwise>
        </Choose>
        <If
          condition={!!spotOffer}
          render={() => {
            if (spotOffer) {
              return (
                <SpotOfferDialog
                  isOpen={!!spotOffer}
                  spotOffer={spotOffer}
                  onClose={() => {
                    setSpotOffer(undefined)
                    renegotiationGoToLoan()
                  }}
                />
              )
            }
          }}
        />
      </Stack>
    </Stack>
  )
}
