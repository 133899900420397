import { Stack } from '@dlpco/fluid-layout'
import { Heading, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { Choose, For } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

const StepNumber = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-color: ${theme.colors.lightGray};
  margin-right: 8px;
`

type StepListProps = {
  currentStep: any
  steps: Record<any, string>
}

export function SharedStepList({ currentStep, steps }: StepListProps) {
  const stepTexts = Object.values(steps)
  const currentStepIndex = stepTexts.indexOf(steps[currentStep])

  return (
    <Stack space="2rem">
      <For
        of={stepTexts}
        render={(step, index) => {
          const displayIndex = index + 1
          return (
            <Choose key={index}>
              <Choose.When condition={step === steps[currentStep]}>
                <Box>
                  <Flex alignItems="center">
                    <StepNumber>
                      <Text weight="bold" color="primary">
                        {displayIndex}
                      </Text>
                    </StepNumber>
                    <Heading color="primary" size="xSmall" weight="bold">
                      {step}
                    </Heading>
                  </Flex>
                </Box>
              </Choose.When>
              <Choose.When condition={index < currentStepIndex}>
                <Flex alignItems="center">
                  <StepNumber>
                    <Text weight="bold">{displayIndex}</Text>
                  </StepNumber>
                  <Heading size="xSmall" weight="bold" aria-label="Etapa atual">
                    {step}
                  </Heading>
                </Flex>
              </Choose.When>
              <Choose.Otherwise>
                <Box>
                  <Flex alignItems="center">
                    <StepNumber>
                      <Text weight="bold" color="neutral">
                        {displayIndex}
                      </Text>
                    </StepNumber>
                    <Heading color="neutral" size="xSmall" weight="bold">
                      {step}
                    </Heading>
                  </Flex>
                </Box>
              </Choose.Otherwise>
            </Choose>
          )
        }}
      />
    </Stack>
  )
}
