import { Icon, Text } from '@dlpco/ginga-stone'

import { DiscountSettlementSteps } from '../../entities'
import { Flex } from '~/domains/platform/design-system'
import { For, If } from '~/domains/platform/lib/utilities-components'

interface RenegotiationDiscountSettlementBreadcrumbProps {
  fullFlow?: boolean
  currentStep: DiscountSettlementSteps
  backFunction: () => void
}

export const RenegotiationDiscountSettlementBreadcrumb = ({
  fullFlow = false,
  currentStep,
  backFunction
}: RenegotiationDiscountSettlementBreadcrumbProps) => {
  const defaultBreadcrumbs = [
    {
      step: '',
      title: 'Empréstimo'
    },
    {
      step: DiscountSettlementSteps.PROPOSAL,
      title: 'Proposta',
      fullStep: false
    },
    {
      step: DiscountSettlementSteps.SIMULATE,
      title: 'Simular renegociação',
      fullStep: true
    },
    {
      step: DiscountSettlementSteps.PAYMENT_METHOD,
      title: 'Forma de pagamento',
      fullStep: true
    },
    {
      step: DiscountSettlementSteps.REVIEW,
      title: 'Revisar pagamento',
      fullStep: true
    },
    {
      step: DiscountSettlementSteps.PIN,
      title: 'Garantir seu desconto'
    }
  ]
  const filteredBreadcrumb = defaultBreadcrumbs.filter(s => (s.fullStep !== undefined ? s.fullStep === fullFlow : true))
  const breadcrumbs = filteredBreadcrumb.slice(0, filteredBreadcrumb.findIndex(e => e.step === currentStep) + 1)

  return (
    <Flex alignContent="center" gap=".5rem">
      <Flex onClick={backFunction} alignItems="center" style={{ cursor: 'pointer' }} aria-label="Voltar">
        <Icon use="direction-arrow-left-outline" />
      </Flex>
      <For
        of={breadcrumbs}
        render={(breadcrumb, index) => {
          const { title, step } = breadcrumb
          const color = step === currentStep ? 'neutralHigh' : 'neutral'
          return (
            <Flex key={breadcrumb.title} alignItems="center" gap=".5rem">
              <If condition={index > 0}>
                <Text color="neutral">/</Text>
              </If>
              <Text color={color}>{title}</Text>
            </Flex>
          )
        }}
      />
    </Flex>
  )
}
