import axios, { type AxiosTransformer } from 'axios'
import { CREDIT_INSURANCE_API_ENDPOINT } from 'shared/envs'

import { httpClientFactory } from '~/domains/platform/infra/http/http-client-factory'
import { transformResponse } from '~/domains/platform/infra/http/http-helpers'
import { responseUnauthorizedAccessInterceptor } from '~/domains/platform/infra/http/interceptors/unauthorized-access-interceptor'

export const httpClientCreditInsurance = httpClientFactory({
  baseURL: CREDIT_INSURANCE_API_ENDPOINT(),
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  responseErrorInterceptors: [responseUnauthorizedAccessInterceptor]
})
