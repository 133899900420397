import { useState } from 'react'

import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { useLoanConcessionContext } from '../context/loan-concession-context'
import { type InsuranceContract } from '../entities'
import { httpClientCreditInsurance } from '../infra/http-client-credit-insurance'

interface useCreateNegotiationsProposalAssessmentsProps {
  negotiationsProposalId: string
}

export async function fetchNegotiationsLoanConcessionInsurance(negotiationsProposalId?: string) {
  return await httpClientCreditInsurance<any>({
    url: `/v1/proposal/${negotiationsProposalId}`,
    method: 'GET'
  })
}

export function useLoanConcessionInsurance(
  proposalId: string,
  queryOptions?: UseQueryRequestOptions<InsuranceContract>
) {
  const { data: response, ...restQuery } = useQueryRequest<InsuranceContract>(
    ['useLoanConcessionInsurance', proposalId],
    () => fetchNegotiationsLoanConcessionInsurance(proposalId),
    { ...queryOptions, enabled: Boolean(proposalId) }
  )

  return { data: response?.data, ...restQuery }
}

export function useCheckLoanConcessionInsurance(
  { negotiationsProposalId }: useCreateNegotiationsProposalAssessmentsProps,
  queryOptions?: UseQueryRequestOptions
) {
  const [stopFetching, setStopFeching] = useState(false)
  const [count, setcount] = useState(0)
  const { saveInsuranceContract } = useLoanConcessionContext()

  const { data: response, ...restQuery } = useQueryRequest(
    ['negotiationsLoanConcessionInsurance'],
    () => fetchNegotiationsLoanConcessionInsurance(negotiationsProposalId),
    {
      onSettled: (response: any, error) => {
        if (error || count > 4) {
          setStopFeching(true)
        }
        const { data } = response ?? {}
        if (data) {
          saveInsuranceContract(data)
          setStopFeching(true)
        }

        setcount(current => current + 1)
      },
      ...queryOptions,
      refetchInterval: stopFetching ? false : 3000,
      refetchIntervalInBackground: true,
      retry: 3
    }
  )
  return { data: response?.data, ...restQuery }
}
