import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { formatDate } from '@credit-web/common/date-utils'
import {
  Badge,
  Heading,
  Money,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For } from '~/domains/platform/lib/utilities-components'

import { type InstallmentStatus, type LoanInstallment } from '../../shared/entities'
import { renegotiationStatusColorMap, statusColorMap } from '../../shared/helpers/status-colors-map'
import analitica from '../analitica'

interface StatusColorsItem {
  amountColor: string
  periodColor: string
}

const statusColors: Partial<Record<InstallmentStatus, StatusColorsItem>> = {
  Open: {
    amountColor: 'darkGray',
    periodColor: 'darkGray'
  },
  Settled: {
    amountColor: 'stoneGreen',
    periodColor: 'mediumGray2'
  },
  Overdue: {
    amountColor: 'danger',
    periodColor: 'danger'
  },
  Future: {
    amountColor: 'mediumGray2',
    periodColor: 'mediumGray2'
  }
}

function LoanDetailsInstallmentListLoader() {
  return (
    <Box padding="1rem" backgroundColor="lightGray6" borderRadius="16px">
      <Box pb="1rem">
        <Skeleton width="90%" height="2rem" />
      </Box>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell> </TableHeaderCell>
            <TableHeaderCell>Valor da parcela</TableHeaderCell>
            <TableHeaderCell>Situação</TableHeaderCell>
            <TableHeaderCell>Período</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <LoaderRow />
          <LoaderRow />
          <LoaderRow />
          <LoaderRow />
        </TableBody>
      </Table>
    </Box>
  )
}

function LoaderRow() {
  return (
    <TableRow uniqueId="id">
      <TableCell width="4rem" alignment="center">
        <Skeleton width="90%" height="1rem" />
      </TableCell>

      <TableCell>
        <Skeleton width="90%" height="1rem" />
      </TableCell>

      <TableCell>
        <Skeleton width="90%" height="1rem" />
      </TableCell>

      <TableCell>
        <Skeleton width="90%" height="1rem" />
      </TableCell>
    </TableRow>
  )
}
export function LoanDetailsInstallmentList({
  installments,
  isLoading,
  installmentPlanId,
  renegotiationOriginalContract = false
}: {
  installments?: LoanInstallment[]
  isLoading?: boolean
  installmentPlanId?: string
  renegotiationOriginalContract?: boolean
}) {
  const { loan: loanId }: { loan?: string } = useRouter().query

  useEffect(() => {
    if (loanId && installmentPlanId) {
      if (!renegotiationOriginalContract) analitica.events.installmentList.view(loanId, installmentPlanId)
      else analitica.events.originalContract.installmentListView(loanId, installmentPlanId)
    }
  }, [installmentPlanId, loanId, renegotiationOriginalContract])

  if (isLoading || !installments) {
    return <LoanDetailsInstallmentListLoader />
  }
  const handleRowClick = (installmentNumber: number) => {
    if (renegotiationOriginalContract) {
      BlackBird.travelTo({
        pathname: '/credito/emprestimo/${loan}/emprestimo-original/parcela/${installment}',
        urlParams: { loan: loanId, installment: installmentNumber }
      })
    } else {
      BlackBird.travelTo({
        pathname: '/credito/emprestimo/${loan}/parcela/${installment}',
        urlParams: { loan: loanId, installment: installmentNumber }
      })
    }
  }
  const statusColorBadgeInfo = renegotiationOriginalContract ? renegotiationStatusColorMap : statusColorMap
  const handleHideAction = (loanInstallment: LoanInstallment) => {
    return loanInstallment.status === 'Future' && loanInstallment.paidAmount === 0
  }
  return (
    <Box padding="1rem" backgroundColor="lightGray6" borderRadius="16px">
      <Box pb="1rem">
        <Heading size="xSmall" weight="bold">
          Todas as parcelas
        </Heading>
      </Box>
      <Table clickableRows>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell> </TableHeaderCell>
            <TableHeaderCell>Valor da parcela</TableHeaderCell>
            <TableHeaderCell>Situação</TableHeaderCell>
            <TableHeaderCell>Período</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <For
            of={installments}
            render={(item: LoanInstallment) => {
              const { installmentNumber, status, startDate, dueDate, futureBalance, initialAmount, paidAmount } = item

              return (
                <TableRow
                  key={installmentNumber}
                  uniqueId={installmentNumber.toString()}
                  onRowClick={() => {
                    if (!renegotiationOriginalContract)
                      analitica.events.installmentList.click(item.installmentNumber, item.status)
                    else analitica.events.originalContract.installmentListClick(item.installmentNumber, item.status)

                    handleRowClick(item.installmentNumber)
                  }}
                  hideAction={handleHideAction(item)}
                >
                  <TableCell width="4rem" alignment="center">
                    <Badge color={statusColorBadgeInfo[status].color} variant={statusColorBadgeInfo[status].variant}>
                      {installmentNumber}
                    </Badge>
                  </TableCell>

                  <TableCell>
                    <Box color={statusColors[status]?.amountColor}>
                      {renegotiationOriginalContract ? (
                        <Money amount={initialAmount} />
                      ) : (
                        <Money amount={status === 'Settled' ? Math.abs(paidAmount) : futureBalance} />
                      )}
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Badge color={statusColorBadgeInfo[status].color} variant={statusColorBadgeInfo[status].variant}>
                      {statusColorBadgeInfo[status].text}
                    </Badge>
                  </TableCell>

                  <TableCell>
                    <Box color={statusColors[status]?.periodColor}>
                      {formatDate(startDate)} a {formatDate(dueDate)}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            }}
          />
        </TableBody>
      </Table>
    </Box>
  )
}
