import { type NegotiationsCustomer } from '~/domains/credit/shared/entities'

import { type UseCreditoffersReturn } from '../../shared/entities'
import { type NegotiationsProposal, type NegotiationsSimulationResult, type UniversalProposalData } from '../entities'

type SimulationData = {
  creditOffer?: UseCreditoffersReturn
  proposal?: NegotiationsProposal
  simulationProposal?: NegotiationsSimulationResult
}

export const formatSimulationData = ({
  creditOffer,
  proposal,
  simulationProposal
}: SimulationData): UniversalProposalData => {
  if (!proposal && !simulationProposal) return {} as UniversalProposalData

  const { loanConcession, id: proposalId, customer, offerId } = proposal || {}

  return {
    maxValue: creditOffer?.maxCreditValue || 0,
    minValue: creditOffer?.minCreditValue || 0,
    offerId: creditOffer?.offer?.id || offerId,
    proposalId: proposalId || '',
    inputValue: loanConcession?.requestedAmount || simulationProposal?.requestedAmount, // TODO retirar, pode ser apenas o requestedAmount
    creditAmount: loanConcession?.requestedAmount || simulationProposal?.requestedAmount || 0,
    totalEstimatedAmountToBePaid: loanConcession?.installmentPlan?.totalSum || simulationProposal?.installmentsSum || 0,
    retentionRate: loanConcession?.rate?.retentionRate || simulationProposal?.retentionRate || 0,
    interestRate: loanConcession?.rate?.interestRate || simulationProposal?.interestRate || 0,
    taxAmount: loanConcession?.rate?.taxAmount || simulationProposal?.taxAmount || 0,
    requestedAmount: loanConcession?.requestedAmount || simulationProposal?.requestedAmount || 0,
    annualTotalEffectiveCostRate:
      loanConcession?.rate?.annualTotalEffectiveCostRate || simulationProposal?.annualTotalEffectiveCostRate || 0,
    contractDueDate: loanConcession?.dueDate || simulationProposal?.dueDate || '',
    numberOfInstallments: loanConcession?.installmentPlan?.count || simulationProposal?.numberOfInstallments,
    installmentValue: loanConcession?.installmentPlan?.amount || simulationProposal?.installmentAmount,
    lastUpdateDate: customer?.lastUpdateDate || '',
    annualInterestRate: loanConcession?.rate?.annualInterestRate || simulationProposal?.annualInterestRate || 0,
    customer: customer || ({} as NegotiationsCustomer)
  }
}

const requiredKeys = [
  'totalEstimatedAmountToBePaid',
  'retentionRate',
  'interestRate',
  'taxAmount',
  'annualTotalEffectiveCostRate',
  'contractDueDate'
]

export const hasProposalContent = (obj: UniversalProposalData) => {
  return requiredKeys.every(
    key =>
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key as keyof UniversalProposalData] !== undefined &&
      obj[key as keyof UniversalProposalData] !== null
  )
}

export const hasProposalData = (data: any) => !!Object.keys(data).length
