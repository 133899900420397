export const CREDIT_V2 = `/api/v2`
export const CREDIT_V3 = `/api/v3`
export const CREDIT_V4 = `/api/v4`

export const BFF_API_PATH = `/experience-bff-service/api`
export const BFF_V1 = `${BFF_API_PATH}/web/v1`
export const BFF_V2 = `${BFF_API_PATH}/web/v2`
export const BFF_LEGACY_V1 = `${BFF_API_PATH}/legacy/web/v1`

export const CREDIT_NEGOTIATIONS_V1 = `/credit-negotiations-service/api/v1`
export const CONTRACTS_SERVICE_V1 = `/contracts-service/api/v1`
