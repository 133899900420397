import { Icon, Tooltip } from '@dlpco/ginga-stone'
import styled from 'styled-components'

const CustomTooltip = styled(Tooltip)`
  max-width: 30ch;
`

export function LoanDetailsCustomTooltip({ text }: { text: string }) {
  return (
    <CustomTooltip content={text}>
      <Icon use="round-info-outline" color="neutralLow" />
    </CustomTooltip>
  )
}
