import { removeFalsyProperties } from '@credit-web/common/object-utils'

import { renegotiationBasePath } from '~/domains/credit/ports/credit.routes'
import { type CreditPaging } from '~/domains/credit/shared/entities'
import BlackBird from '~/domains/platform/lib/blackbird'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type NegotiationsProposal, type RenegotiationProposal } from '../../loan-concession/entities'
import { type CreditOffer } from '../../shared/entities'
import { fetchCreditOffersByDocument, fetchProposalsByDocument } from '../../shared/services/loans'

type RedirectOfferProps = {
  offer?: CreditOffer
  proposal?: NegotiationsProposal
}

export const goToOffer = ({ offer, proposal }: RedirectOfferProps) => {
  const query = removeFalsyProperties({ offerId: offer?.id, proposalId: proposal?.id })
  if (offer?.type === 'Renegotiation') {
    BlackBird.travelTo({
      pathname: renegotiationBasePath,
      urlParams: {
        loan: (proposal as unknown as RenegotiationProposal)?.renegotiation?.securities[0]?.externalReference || ''
      },
      query
    })
    return
  }
}

type UseGoToOffersReturnType = {
  isLoading: boolean
  selectedOfferId?: string
  selectedProposalId?: string
  showLoanConcessionModal: boolean
}

type useGoToOffersProps = {
  offerId?: string
  proposalId?: string
  document: string
}

export const useGoToOffers = (
  { offerId, proposalId, document }: useGoToOffersProps,
  queryOptions?: UseQueryRequestOptions<CreditPaging<CreditOffer[]>>
): UseGoToOffersReturnType => {
  const {
    data: offerResponse,
    isLoading: loadingOffer,
    isFetched
  } = useQueryRequest<CreditPaging<CreditOffer[]>>(['useGoToOffers'], () => fetchCreditOffersByDocument(document), {
    ...queryOptions
  })
  const selectedOffer = (offerResponse?.data?.items || []).find(({ id }) => offerId === id)

  const {
    data: proposalResponse,
    isLoading: loadingProposal,
    isFetched: proposalFetched
  } = useQueryRequest<CreditPaging<NegotiationsProposal[]>>(
    ['useGoToProposal'],
    () => fetchProposalsByDocument({ document, offerId: selectedOffer?.id || '' }),
    { enabled: Boolean(selectedOffer) }
  )
  if (isFetched && !selectedOffer) return { isLoading: false, showLoanConcessionModal: false }

  const selectedProposal = proposalId
    ? (proposalResponse?.data?.items || []).find(({ id }) => proposalId === id)
    : proposalResponse?.data?.items[0]

  goToOffer({ offer: selectedOffer, proposal: selectedProposal })

  const response = {
    isLoading: loadingOffer || loadingProposal,
    selectedOfferId: selectedOffer?.id,
    showLoanConcessionModal: selectedOffer?.type === 'LoanConcession'
  }

  if (selectedProposal && proposalFetched) return { ...response, selectedProposalId: selectedProposal?.id }

  return response
}
