import { Cluster } from '@dlpco/fluid-layout'
import styled, { css } from 'styled-components'
import { ifProp, theme as themeFn } from 'styled-tools'

import { Box } from '~/domains/platform/design-system'
import { theme } from '~/ui/theme'

export const ContentWrapper = styled.div<{ maxHeight: string | undefined; rounder: boolean }>`
  background-color: ${themeFn('colors.white')};
  overflow: hidden;
  max-height: ${props => props.maxHeight || '0'};
  border-radius: ${ifProp('rounder', `0 0 ${theme.radii.medium} ${theme.radii.medium}`, 0)};

  & > * {
    opacity: ${ifProp('maxHeight', '1', '0')};
  }
`

export const TriggerWrapper = styled(Cluster)<{ triggered: boolean; rounder: boolean }>`
  background-color: ${themeFn('colors.white')};

  ${props =>
    props.rounder &&
    css`
      border-top-left-radius: ${themeFn('radii.medium')};
      border-top-right-radius: ${themeFn('radii.medium')};
      border-bottom-right-radius: ${ifProp('triggered', 0, themeFn('radii.medium'))};
      border-bottom-left-radius: ${ifProp('triggered', 0, themeFn('radii.medium'))};
    `}
`

export const TriggerContentWrapper = styled(Box)<{ variant?: string }>`
  flex-grow: ${props => (props?.variant === 'secondary' ? '1' : 'initial')};
`

export const TriggerButtonWrapper = styled(Box)<{ variant?: string }>`
  padding-right: ${props => (props?.variant === 'secondary' ? '1rem' : 'initial')};
`

export const OpennessIndicator = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background: ${ifProp('isOpen', themeFn('colors.stoneGreen'), 'transparent')};
  margin: auto;
  top: 0;
  bottom: 0;
  width: 4px;
`

export const Wrapper = styled(Box)<{
  withBorder?: boolean
  variant?: string
  rounder?: boolean
  withoutDivider?: boolean
  disableTrigger?: boolean
}>`
  border: ${props => (props?.withBorder ? '1px solid #DCE0E9' : '')};
  padding: ${props => (props?.variant === 'secondary' ? '0' : '1rem')};
  cursor: ${ifProp('disableTrigger', 'default', 'pointer')};
  box-sizing: border-box;
  border-radius: ${props => (props?.rounder ? themeFn('radii.large') : themeFn('radii.medium'))};
  overflow: hidden;
`
