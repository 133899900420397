import { analiticaInstance } from 'shared/analitica'

import { type InstallmentStatus } from '../../shared/entities'
import { type AnalysisStatuses, type RevolvingCreditSection } from '../entities'

export interface BannerViewProps {
  id: string
  expirationDate: string
  type: string
  amount: number
  cardLayout: 'com destaque' | 'sem destaque'
}

export interface BannerClickProps {
  id: string
  expirationDate: string
  type: string
  amount: number
}
type LoanHomeRevolvingCreditCardEventCommonProps = Omit<RevolvingCreditSection, 'badge' | 'withdrawalAmount'>

const translateStatus: Record<
  InstallmentStatus | AnalysisStatuses,
  | 'em análise'
  | 'aprovado'
  | 'não aprovado'
  | 'parcela aberta'
  | 'parcela quitada'
  | 'em atraso'
  | 'retenção pausada'
  | 'vence em breve'
  | 'futura'
> = {
  Open: 'parcela aberta',
  Settled: 'parcela quitada',
  Overdue: 'em atraso',
  DueSoon: 'vence em breve',
  Future: 'futura',
  Suspended: 'retenção pausada',
  accepted: 'em análise',
  cancelled: 'não aprovado',
  disbursement_confirmed: 'aprovado',
  disbursement_requested: 'aprovado'
}

export interface LoanCardViewProps {
  id: string
  status: InstallmentStatus | AnalysisStatuses
  daysUntilOverdue: number
}

interface LoansHistoryListClickProps {
  'loan id': string
  'loan type': string
  'loan amount': number
}

export type LoanCardClickActions = 'ver detalhes do empréstimo' | 'fazer um pagamento' | 'cancelar pedido'
export interface LoanCardClickProps {
  'loan id': string
  action: LoanCardClickActions
}

export default analiticaInstance.eventFactory('Loans home', {
  home: {
    /**
     * @analitica The user saw the credit area
     */
    view: () => ({
      label: 'credit - credit area page view'
    }),
    /**
     * @analitica The user saw the offer banner
     */

    offerBannerView: (props: BannerViewProps) => {
      const { id, type, expirationDate, amount, cardLayout } = props
      return {
        label: 'credit - credit area offer card view',
        metadata: {
          'offer id': id,
          'offer type': type,
          'expiration date': expirationDate,
          amount,
          'card layout': cardLayout
        }
      }
    },
    /**
     * @analitica The user clicked on the offer banner button
     */
    offerBannerClick: (props: BannerClickProps) => {
      const { id, type, expirationDate, amount } = props
      return {
        label: 'credit - credit area offer card clicked',
        metadata: { 'offer id': id, 'offer type': type, 'expiration date': expirationDate, amount }
      }
    },
    /**
     * @analitica The user saw the proposal banner
     */

    proposalBannerView: (props: BannerViewProps) => {
      const { id, type, expirationDate, amount, cardLayout } = props
      return {
        label: 'credit - credit area proposal card view',
        metadata: {
          'proposal id': id,
          'proposal type': type,
          'expiration date': expirationDate,
          amount,
          'card layout': cardLayout
        }
      }
    },
    /**
     * @analitica The user clicked on the proposal banner button
     */
    proposalBannerClick: (props: BannerClickProps) => {
      const { id, type, expirationDate, amount } = props
      return {
        label: 'credit - credit area proposal card clicked',
        metadata: { 'proposal id': id, 'proposal type': type, 'expiration date': expirationDate, amount }
      }
    },

    /**
     * @analitica The user saw a loan card
     */
    loanCardView: (props: LoanCardViewProps) => {
      return {
        label: 'credit - credit area loan card view',
        metadata: {
          'loan id': props.id,
          'card type': translateStatus[props.status],
          'dias para vencimento': props.daysUntilOverdue
        }
      }
    },
    /**
     * @analitica The user clicked on a loan card
     */
    loanCardClick: (props: LoanCardClickProps) => ({
      label: 'credit - credit area loan card clicked',
      metadata: { ...props }
    }),

    /**
     * @analitica The user saw the loans history list
     */
    loansHistoryListView: (totalItems: number) => ({
      label: 'credit - credit area loans history list view',
      metadata: { items: totalItems }
    }),

    /**
     * @analitica The user clicked on a item at the loans history list
     */
    loansHistoryListClick: (props: LoansHistoryListClickProps) => ({
      label: 'credit - credit area loans history list clicked',
      metadata: { ...props }
    }),
    /**
     * @analitica The user saw revolving credit card
     */
    loanHomeRevolvingCreditCardView: ({
      amountLimit,
      contractedAmount,
      id
    }: LoanHomeRevolvingCreditCardEventCommonProps) => {
      return {
        label: 'credit - credit area revolving credit card view',
        metadata: {
          'limite total': contractedAmount,
          'limite disponível': amountLimit,
          'id do produto': id
        }
      }
    },

    /**
     * @analitica The user clicked in revolving credit card
     */
    loanHomeRevolvingCreditCardClick: ({
      amountLimit,
      contractedAmount,
      id
    }: LoanHomeRevolvingCreditCardEventCommonProps) => {
      return {
        label: 'credit - credit area revolving credit card clicked',
        metadata: {
          'limite total': contractedAmount,
          'limite disponível': amountLimit,
          'id do produto': id
        }
      }
    },
    /**
     * @analitica The user saw revolving credit card proposal banner
     */ homeRevolvingCreditCardProposalBannerView: ({ amount, id }: BannerViewProps) => {
      return {
        label: 'credit - credit area offer card view',
        metadata: {
          amount: amount,
          'offer id': id,
          'offer type': 'RevolvingCredit'
        }
      }
    },

    /**
     * @analitica The user clicked in revolving credit card proposal banner
     */
    homeRevolvingCreditCardProposalBannerClick: ({ amount, id }: BannerClickProps) => {
      return {
        label: 'credit - credit area offer card clicked',
        metadata: {
          amount: amount,
          'offer id': id,
          'offer type': 'RevolvingCredit'
        }
      }
    }
  }
})
