import { removeFalsyProperties } from '@credit-web/common/object-utils'

import { creditHomeBasePath } from '~/domains/credit/ports/credit.routes'
import BlackBird from '~/domains/platform/lib/blackbird'

export type CreditOfferProps = {
  offerId?: string
  proposalId?: string
}

export const goToCreditOffer = ({ offerId, proposalId }: CreditOfferProps): (() => void) => {
  return BlackBird.travelTo({
    pathname: creditHomeBasePath,
    query: removeFalsyProperties({ offerId, proposalId })
  })
}
