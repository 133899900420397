import { createContext, useContext, useState } from 'react'

import { type LoanInstallment, type SpotOfferPaymentMethod } from '../../shared/entities'
import { SpotOfferSteps } from '../components/spot-offer-flow'
import { type SpotOfferType } from '../entities'

export function useSpotOfferContext() {
  const context = useContext(SpotOfferContext)
  return context
}

export function SpotOfferProvider({ children }: { children: React.ReactNode }) {
  const [amount, setAmount] = useState<number>(0)
  const [installmentsSelected, setInstallmentsSelected] = useState<LoanInstallment[]>([])
  const [paymentMethod, setPaymentMethod] = useState<SpotOfferPaymentMethod>('Pix')
  const [type, setType] = useState<SpotOfferType>('Settlement')
  const [initialStep, setInitialStep] = useState<SpotOfferSteps>(SpotOfferSteps?.SPOT_OFFER_CURRENT_INSTALLMENT)

  const saveAmount = (amount: number) => setAmount(amount)
  const saveInstallments = (data: LoanInstallment[]) => setInstallmentsSelected(data)
  const savePaymentMethod = (method: SpotOfferPaymentMethod) => setPaymentMethod(method)
  const saveType = (type: SpotOfferType) => setType(type)
  const saveInitialStep = (step: SpotOfferSteps) => setInitialStep(step)
  const paymentMethodCapitalized = (paymentMethod[0].toUpperCase() +
    paymentMethod.substring(1)) as SpotOfferPaymentMethod
  const contextValue = {
    amount,
    saveAmount,
    installmentsSelected,
    saveInstallments,
    paymentMethod: paymentMethodCapitalized,
    savePaymentMethod,
    type,
    saveType,
    saveInitialStep,
    initialStep
  }

  return <SpotOfferContext.Provider value={contextValue}>{children}</SpotOfferContext.Provider>
}

interface ContextProps {
  amount: number
  saveAmount: (amount: number) => void
  installmentsSelected: LoanInstallment[]
  saveInstallments: (installments: LoanInstallment[]) => void
  paymentMethod: SpotOfferPaymentMethod
  savePaymentMethod: (paymentMethod: SpotOfferPaymentMethod) => void
  type: SpotOfferType
  saveType: (type: SpotOfferType) => void
  initialStep: SpotOfferSteps
  saveInitialStep: (step: SpotOfferSteps) => void
}

export const SpotOfferContext = createContext<ContextProps>({
  amount: 0,
  saveAmount: () => {},
  installmentsSelected: [],
  saveInstallments: () => {},
  paymentMethod: 'Pix',
  savePaymentMethod: () => null,
  type: 'Settlement',
  saveType: () => null,
  saveInitialStep: () => null,
  initialStep: SpotOfferSteps?.SPOT_OFFER_CURRENT_INSTALLMENT
})
