import { useEffectOnce } from 'react-use'
import { type ButtonColor } from '@dlpco/ginga-components/cjs/components/button/props/types'

import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { type CreditBannerProps, SharedCreditBanner } from '~/domains/credit/shared/components/credit-banner'

interface RenegotiationBannerProps extends CreditBannerProps {
  eventViewParam?: string
}

interface BannerOfferProps {
  handleClick: () => void
}

export function RenegotiationBannerOffer({ handleClick }: BannerOfferProps) {
  const bannerProps: RenegotiationBannerProps = {
    buttonColor: 'primary' as ButtonColor,
    showButton: true,
    iconColor: 'warning',
    iconName: 'money-notes-outline',
    title: 'Renegociação disponível',
    description: 'Confira as novas condições que preparamos pra você.',
    buttonText: 'Conferir condições',
    buttonAction: () => handleClick(),
    eventViewParam: 'proposta'
  }

  useEffectOnce(() => renegotiationAnalitica.events.banner.proposalView(bannerProps?.eventViewParam || ''))

  return <SharedCreditBanner {...bannerProps} />
}
