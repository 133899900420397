import { Card, CardBody } from '@dlpco/ginga-stone'
import { CoinFailedIllustration } from 'design-elements/shared/illustrations'

import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'

import { SharedSagaKeyErrorDisplay } from './shared-saga-key-error-display'

export function SharedGenericCardError() {
  return (
    <Card>
      <CardBody>
        <EmptyState
          illustration={<CoinFailedIllustration />}
          title="Algo não deu certo por aqui"
          body="Por enquanto, não vai ser possível continuar. Se precisar de ajuda, converse com a gente."
          action={() => chat.open()}
          callToAction="Conversar com a Stone"
        >
          <SharedSagaKeyErrorDisplay />
        </EmptyState>
      </CardBody>
    </Card>
  )
}
