import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type LoanInstallmentList } from '../entities'

const fetchInstallmentPlans = (installmentPlanId?: string, params?: any) => {
  return httpClientCredit({
    url: `experience-bff-service/api/legacy/web/v1/installment-plans/${installmentPlanId}/installments`,
    params
  })
}

export type UseInstallmentPlansArgs = {
  installmentPlanId?: string
  params?: {
    installmentNumber?: string
  }
}

export function useInstallmentPlans(
  args: UseInstallmentPlansArgs,
  queryOptions?: UseQueryRequestOptions<LoanInstallmentList>
) {
  const { installmentPlanId, params } = args
  const { data: response, ...restQuery } = useQueryRequest<LoanInstallmentList>(
    ['useInstallmentPlans', installmentPlanId, params],
    () => fetchInstallmentPlans(installmentPlanId, params),
    { enabled: Boolean(installmentPlanId), ...queryOptions }
  )
  return {
    ...restQuery,
    data: response?.data
  }
}
