import { type ReactElement } from 'react'
import { useEffectOnce } from 'react-use'
import { CreditBucket } from '@credit-web/common/browser-utils'
import { type BadgeColor } from '@dlpco/ginga-components/cjs/components/badge/props/types'
import { type ButtonColor } from '@dlpco/ginga-components/cjs/components/button/props/types'

import { type BannerType, type CamelCaseSffResult } from '~/domains/credit/ports/sff/entities'
import { SharedCreditBanner } from '~/domains/credit/shared/components/credit-banner'
import { goToCreditOffer } from '~/domains/credit/shared/helpers/go-to-credit-offer'
import { SESSION_KEY } from '~/domains/credit/shared/helpers/initial-path'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import analitica from '../helpers/analitica'

type BannerContentMap = {
  title: string
  description: string | ReactElement
  iconColor: BadgeColor
  showButton: boolean
  buttonText?: string
  buttonColor?: ButtonColor
  buttonAction?: () => void
  viewEvent?: () => void
}

type FollowUpBannerProps = {
  params: CamelCaseSffResult
}

const getFollowUpBannerContentMap = (bannerParams: CamelCaseSffResult): Record<BannerType, BannerContentMap> => {
  const { offerCondition, offerId } = bannerParams || {}

  const { currency } = stringFormat

  const getCurrencyValue = (value?: number) => currency(value || 0, false)

  return {
    available: {
      title: `Simule um empréstimo de até ${getCurrencyValue(offerCondition?.max || 0)}`,
      description: `Pague em até ${offerCondition?.terms}x com o dinheiro das suas vendas.`,
      iconColor: 'positive',
      showButton: true,
      buttonText: 'Simular empréstimo',
      buttonColor: 'primary',
      buttonAction: () => {
        analitica.events.oportunityBanners.clickOffer()
        CreditBucket.set(SESSION_KEY, 'home')
        goToCreditOffer({ offerId })
      },
      viewEvent: () => analitica.events.oportunityBanners.viewOffer()
    },
    none: { title: '', description: '', iconColor: 'neutral', showButton: false }
  }
}

export function FollowUpBannerIndex({ params }: FollowUpBannerProps) {
  const hasNoType = !params.type || params.type === 'none'

  useEffectOnce(() => {
    if (!hasNoType && bannerContent.viewEvent) bannerContent.viewEvent()
  })

  if (hasNoType) return null

  const bannerContent = getFollowUpBannerContentMap(params)[params.type]

  return <SharedCreditBanner {...bannerContent} />
}
