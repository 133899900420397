import { Icon, Text } from '@dlpco/ginga-stone'

import { type ItemContent, CustomTooltip, ListItem } from '~/domains/credit/shared/components/credit-list-box'
import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { SideModal } from '~/ui/components'
import { List } from '~/ui/components/utils/list/list'

import { type CurrentConditions, type ProposalInstallment } from '../../entities'
import { RenegotiationAccordion } from '../renegotiation-accordion'

export type InstallmentPlanPartialPaymentInfo = Partial<
  Pick<CurrentConditions, 'hasGracePeriodInterest' | 'gracePeriod'>
>

export function RenegotiationInstallmentPlanDrawer({
  isOpen,
  toggle,
  installments,
  paymentInfo
}: {
  isOpen: boolean
  toggle: (toggleValue: boolean) => void
  installments?: ProposalInstallment[]
  paymentInfo?: InstallmentPlanPartialPaymentInfo
}) {
  if (!installments) {
    return <></>
  }

  let paymentInfoItems: ItemContent[] | undefined
  if (paymentInfo) {
    paymentInfoItems = [
      {
        title: 'Tempo de carência:',
        value: `${paymentInfo!.gracePeriod!.description} dias`,
        description: 'O tempo de carência começa a ser contado após 30 dias a partir da data de criação da proposta.'
      },
      {
        title: 'Juros durante a carência?',
        value: paymentInfo!.hasGracePeriodInterest!.description
      }
    ]
  }
  return (
    <SideModal title="Condições de pagamento" isOpen={isOpen} toggle={toggle}>
      <RenegotiationAccordion
        withIndicator={false}
        initialOpen={true}
        disableTrigger={true}
        variant="secondary"
        title="Parcelas"
        content={
          <>
            <List
              items={installments}
              render={({ installmentNumber, amount, date }, index) => {
                return (
                  <ListItem key={index} showBorder={false} data-testid="list-item">
                    <Flex alignItems="center" gap="5px">
                      <Text as="span" color="neutral" weight="semi" style={{ marginRight: '12px' }}>
                        {installmentNumber.value}
                      </Text>
                      <Text as="span" color="neutral" style={{ flexGrow: 1 }}>
                        {date.description}
                      </Text>
                    </Flex>
                    <Text as="span" color="neutralHigh" weight="semi">
                      {amount.description}
                    </Text>
                  </ListItem>
                )
              }}
            />
          </>
        }
      />

      <If condition={Boolean(paymentInfoItems)}>
        <RenegotiationAccordion
          withIndicator={false}
          initialOpen={true}
          disableTrigger={true}
          variant="secondary"
          title="Informações de pagamento"
          content={
            <>
              <List
                items={paymentInfoItems!}
                render={({ title, value, description }) => {
                  return (
                    <ListItem key={title} data-testid="list-item-grace">
                      <Flex alignItems="center" gap="5px">
                        <Text size="medium" color="neutral">
                          {title}
                        </Text>
                        <If condition={Boolean(description)}>
                          <CustomTooltip content={description} placement="top">
                            <Icon use="round-info-outline" color="neutralLow" />
                          </CustomTooltip>
                        </If>
                      </Flex>
                      <Text size="medium" color={'neutralHigh'} weight="semi">
                        {value}
                      </Text>
                    </ListItem>
                  )
                }}
              />
            </>
          }
        />
      </If>
    </SideModal>
  )
}
