import { type LoanDetails } from '../entities'

export const loanDetailsMock: LoanDetails = {
  notifications: [
    {
      notificationType: 'warning',
      title: 'teste',
      subtitle: 'teste notification',
      notificationButton: {
        text: 'clique aqui',
        action: 'link',
        link: 'https://ajuda.stone.com.br'
      }
    }
  ],
  retention: {
    status: {
      description: 'Ativado',
      value: 'enabled',
      type: 'enum'
    },
    rate: {
      description: '6,00%',
      value: 0.06,
      type: 'percentage'
    }
  },
  generalInformation: {
    status: 'open',
    totalPaid: {
      description: 'R$ 0,12',
      value: 12,
      type: 'money'
    },
    remainingAmount: {
      description: 'R$ 7.247,48',
      value: 724748,
      type: 'money'
    },
    interestEconomyAmount: {
      description: 'R$ 0,00',
      value: 0,
      type: 'money'
    },
    taxAndInterestAmount: {
      description: 'R$ 68,09',
      value: 68009,
      type: 'money'
    },
    creditAmount: {
      description: 'R$ 6.000,00',
      value: 600000,
      type: 'money'
    }
  },
  installments: [
    {
      number: 1,
      status: {
        description: 'Em atraso',
        value: 'overdue',
        type: 'enum'
      },
      paidAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 3.821,01',
        value: 382101,
        type: 'money'
      },
      initialAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 394,54',
        value: 39454,
        type: 'money'
      },
      daysOverdue: 92,
      isCurrentInstallment: false,
      installmentTitle: '3 parcelas não pagas'
    },
    {
      number: 2,
      status: {
        description: 'Em atraso',
        value: 'overdue',
        type: 'enum'
      },
      paidAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 3.821,01',
        value: 382101,
        type: 'money'
      },
      initialAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      taxAndInterestAmount: {
        description: 'R$ 394,54',
        value: 39454,
        type: 'money'
      },
      daysOverdue: 60,
      isCurrentInstallment: false,
      installmentTitle: '3 parcelas não pagas'
    },
    {
      number: 3,
      status: {
        description: 'Em atraso',
        value: 'overdue',
        type: 'enum'
      },
      paidAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 3.821,01',
        value: 382101,
        type: 'money'
      },
      initialAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      daysOverdue: 31,
      isCurrentInstallment: false,
      installmentTitle: '3 parcelas não pagas'
    },
    {
      number: 4,
      status: {
        description: 'Vence em breve',
        value: 'dueSoon',
        type: 'enum'
      },
      paidAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      initialAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      daysOverdue: 0,
      isCurrentInstallment: false,
      installmentTitle: 'Parcela 4 de 6'
    },
    {
      number: 5,
      status: {
        description: 'Futura',
        value: 'future',
        type: 'enum'
      },
      paidAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      initialAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      daysOverdue: 0,
      isCurrentInstallment: false,
      installmentTitle: 'Parcela 5 de 6'
    },
    {
      number: 6,
      status: {
        description: 'Em atraso',
        value: 'multipleOverdue',
        type: 'enum'
      },
      paidAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      remainingAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      initialAmount: {
        description: 'R$ 1.142,16',
        value: 114216,
        type: 'money'
      },
      interestEconomyAmount: {
        description: 'R$ 0,00',
        value: 0,
        type: 'money'
      },
      daysOverdue: 0,
      isCurrentInstallment: true,
      installmentTitle: 'Parcela 6 de 6'
    }
  ],
  aboutLoan: {
    informations: [
      [
        {
          title: 'Valor do pedido',
          value: {
            description: 'R$ 6.000,00',
            value: 600000,
            type: 'money'
          },
          hasTooltip: false
        }
      ],
      [
        {
          title: 'Valor total das parcelas',
          value: {
            description: 'R$ 7.247,48',
            value: 724748,
            type: 'money'
          },
          hasTooltip: false
        }
      ],
      [
        {
          title: '6 parcelas de',
          value: {
            description: 'R$ 1.142,16',
            value: 114216,
            type: 'money'
          },
          hasTooltip: false
        }
      ],
      [
        {
          title: 'Juros ao mês',
          value: {
            description: '3,59%',
            value: 3.59,
            type: 'percentage'
          },
          hasTooltip: false
        }
      ],
      [
        {
          title: 'Juros ao ano',
          value: {
            description: '52,69%',
            value: 52.69,
            type: 'percentage'
          },
          hasTooltip: false
        }
      ],
      [
        {
          title: 'Contrato vence em',
          value: {
            description: '17 mar 2025',
            value: '2025-03-17',
            type: 'date'
          },
          hasTooltip: false
        }
      ],
      [
        {
          title: 'IOF financiado',
          value: {
            description: 'R$ 68,09',
            value: 6809,
            type: 'money'
          },
          hasTooltip: true,
          tooltipType: 'iof'
        }
      ],
      [
        {
          title: 'Custo Efetivo Total',
          value: {
            description: '58,91% ao ano',
            value: 58.91,
            type: 'percentage'
          },
          hasTooltip: true,
          tooltipType: 'cet'
        }
      ]
    ],
    documents: [
      {
        name: 'Contrato',
        description: 'Ativo desde 9 out 2024',
        url: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr7km4xf3jkmgnx5fc1z373ae'
      },
      {
        name: 'Descritivo de Crédito',
        description:
          'O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de crédito.',
        url: 'https://credit-sandbox.stone.com.br/experience-bff-service/api/web/v1/loans/jr7km5kmmq2bf6st48kjya4j6/ddc-pdf'
      }
    ]
  },
  title: 'Capital de Giro de R$ 6.000,00',
  id: 'jr7km5kmmq2bf6st48kjya4j6',
  warranties: [
    {
      name: 'Tarcísio Mendes Pereira',
      document: '36670151861',
      address: 'Rua Tomás Coelho, 123 CEP 20540-110, Vila Isabel, Rio de Janeiro - RJ',
      maritalStatus: 'Casado'
    }
  ]
}
