import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type BffHistory } from '../entities'

async function fetchLoanHistory({ document }: { document: string }) {
  const url = `experience-bff-service/api/web/v1/historic?Document=${document}`
  return await httpClientCredit<BffHistory>({
    url,
    method: 'GET'
  })
}

export function useLoanHistoryCard(
  { document }: { document: string },
  queryOptions?: UseQueryRequestOptions<BffHistory>
) {
  const { data: response, ...restQuery } = useQueryRequest<BffHistory>(
    ['useLoanHistoryCard'],
    () => fetchLoanHistory({ document }),
    {
      ...queryOptions
    }
  )

  return {
    data: response?.data?.content,
    ...restQuery
  }
}
