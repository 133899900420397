import { type ReactElement } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Button, Card, CardBody, Heading, Text } from '@dlpco/ginga-stone'
import { WomanOnRollerSkaterIllustration } from 'design-elements/shared/illustrations'

import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

interface FullBannerBaseProps {
  title: string
  description: string | ReactElement
  buttonText: string
  buttonTextArialLabel?: string
  buttonAction: () => void
  badgeText?: string
  illustration?: ReactElement
}

export function LoanHomeFullBannerBase({
  title,
  description,
  buttonText,
  buttonTextArialLabel,
  buttonAction,
  badgeText,
  illustration
}: FullBannerBaseProps) {
  return (
    <Card>
      <CardBody removeSpacing="all">
        <Flex gap="2rem" alignItems="center" justifyContent="space-between">
          <Stack space="2rem" style={{ marginLeft: '2rem' }}>
            <Stack space="1rem">
              <Heading size="xLarge" weight="semi">
                {title}
              </Heading>
              <Text>{description}</Text>
              <If condition={!!(badgeText && badgeText.length > 0)}>
                <Badge icon="clock-round-solid">{badgeText}</Badge>
              </If>
            </Stack>
            <Button {...(buttonTextArialLabel && { 'aria-label': buttonTextArialLabel })} onClick={buttonAction}>
              {buttonText}
            </Button>
          </Stack>
          {illustration ?? <WomanOnRollerSkaterIllustration />}
        </Flex>
      </CardBody>
    </Card>
  )
}
