import { type LoanSummaryHistory } from '../../shared/entities'

export const mockLoanWithInstallment = {
  label: 'Empréstimo',
  initialAmount: 619.59,
  initialInstallmentAmount: 206.53,
  futureBalance: 413.98,
  creditAmount: 568.72,
  taxAmount: 4.53,
  interestEconomyAmount: -5.12,
  paidAmount: -200.48,
  numberOfInstallments: 3,
  numberOfSettledInstallments: 1,
  numberOfOverdueInstallments: 2,
  isRetentionEnabled: true,
  retentionRate: 0.1,
  monthlyInterestRate: 0.04,
  yearlyInterestRate: 0.6,
  yearlyTotalEffectiveCostRate: 0.66,
  dueDate: '2023-03-09',
  contractUri:
    'https://credit-sandbox.stone.com.br/api/v3/borrowers/jqqky9fux5xxcz11q4uu9rpza/proposals/jqqu3dq1j6218tn5kxk92p4jd/contract',
  currentInstallment: {
    id: 'jqqu3e377j5k9yberm6ua86j8',
    installmentNumber: 2,
    startDate: '2022-12-30',
    dueDate: '2023-01-30',
    daysUntilDueDate: 16,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: true,
    isOverdue: true,
    status: 'Overdue',
    paidAmount: 0,
    initialAmount: 206.53,
    futureBalance: 206.53,
    presentBalance: 202.21,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 206.53
  },
  overdueInstallmentsSummary: {
    overdueInstallments: [
      {
        installmentNumber: 1,
        dueDate: '2022-12-28',
        presentBalance: 27.81
      },
      {
        installmentNumber: 2,
        dueDate: '2023-01-30',
        presentBalance: 27.81
      },
      {
        installmentNumber: 3,
        dueDate: '2023-02-28',
        presentBalance: 27.39
      }
    ],
    totalPresentBalance: 83,
    daysOverdue: 91,
    penaltyFeeAmount: 12.24,
    penaltyInterestAmount: 123.223,
    latePaymentInterestAmount: 123.112
  },
  nextInstallment: {
    installmentNumber: 3,
    startDate: '2023-01-30',
    dueDate: '2023-02-28',
    status: 'Overdue',
    futureBalance: 206.53,
    presentBalance: 194.76
  }
}

export const emptyOverdueInstallmentSummaryMock = {
  overdueInstallments: [],
  totalPresentBalance: 0,
  daysOverdue: 0,
  penaltyFeeAmount: 0,
  penaltyInterestAmount: 0,
  latePaymentInterestAmount: 0
}

export const singleOverdueInstallmentSummaryMock = {
  overdueInstallments: [
    {
      installmentNumber: 3,
      dueDate: '2023-02-28',
      presentBalance: 206.53
    }
  ],
  totalPresentBalance: 206.53,
  daysOverdue: 29,
  penaltyFeeAmount: 876.54,
  penaltyInterestAmount: 555.42,
  latePaymentInterestAmount: 123.12
}

export const loanInstallmentsMock = {
  items: [
    {
      id: 'jqqu3e377j4u9q7f77ufbr7yw',
      installmentNumber: 1,
      startDate: '2022-11-29',
      dueDate: '2022-12-29',
      daysUntilDueDate: 0,
      isDueSoon: true,
      daysOverdue: 27,
      settlementDate: '2022-12-09',
      isCollectionEnabled: false,
      isOverdue: false,
      status: 'Settled',
      paidAmount: -200.48,
      initialAmount: 204.53,
      futureBalance: 0.92,
      presentBalance: 0.92,
      interestEconomyAmount: -5.12,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 2056.2
    },
    {
      id: 'jqqu3e377j5k9yberm6ua86j8',
      installmentNumber: 2,
      startDate: '2022-12-30',
      dueDate: '2023-01-30',
      daysUntilDueDate: 4,
      isDueSoon: true,
      daysOverdue: 0,
      isCollectionEnabled: true,
      isOverdue: true,
      status: 'Overdue',
      paidAmount: 0,
      initialAmount: 205.53,
      futureBalance: 206.53,
      presentBalance: 205.25,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 206.53
    },
    {
      id: 'jqqu3e377j61gkjfkn6xats68',
      installmentNumber: 3,
      startDate: '2023-01-30',
      dueDate: '2023-02-28',
      daysUntilDueDate: 33,
      isDueSoon: false,
      daysOverdue: 0,
      isCollectionEnabled: false,
      isOverdue: true,
      status: 'Open',
      paidAmount: 0,
      initialAmount: 207.53,
      futureBalance: 208.53,
      presentBalance: 197.76,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 206.53
    },
    {
      id: 'jqqu3e377j61gkjfkn6xats69',
      installmentNumber: 4,
      startDate: '2023-03-01',
      dueDate: '2023-04-01',
      daysUntilDueDate: 33,
      isDueSoon: false,
      daysOverdue: 0,
      isCollectionEnabled: false,
      isOverdue: true,
      status: 'Future',
      paidAmount: 0,
      initialAmount: 207.53,
      futureBalance: 208.53,
      presentBalance: 197.76,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 206.53
    }
  ]
}

export const loanSummaryCurrent = {
  currentSummary: {
    label: 'Empréstimo',
    initialInstallmentAmount: 413.8764316497,
    futureBalance: 1041.6292949491,
    taxAmount: 6.5610148428,
    numberOfInstallments: 4,
    retentionRate: 0.1,
    monthlyInterestRate: 0.0399,
    yearlyInterestRate: 0.5992,
    yearlyTotalEffectiveCostRate: 0.6617,
    dueDate: '2024-02-28T00:00:00Z'
  }
} as LoanSummaryHistory
