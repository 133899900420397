import { removeFalsyProperties } from '@credit-web/common/object-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Button, CardBody, Heading, IconButton, Text } from '@dlpco/ginga-stone'
import { CoinsStackIllustration } from 'design-elements/shared/illustrations'

import { loanConcessionBasePath } from '~/domains/credit/ports/credit.routes'
import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Modal } from '~/ui/components/modals/modal/modal'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

interface LoanHomeLoanConcessionMobileDialogProps {
  isOpen: boolean
  offerId?: string
  toggleModal: () => void
  proposalId?: string
  bannerProps?: {
    offerId?: string
    proposalId?: string
  }
}

export function LoanHomeLoanConcessionMobileDialog(props: LoanHomeLoanConcessionMobileDialogProps) {
  const { isOpen, toggleModal, offerId, proposalId, bannerProps } = props
  const { isViewSmall } = useViewSize()

  const hasBannerProps = Object.prototype.hasOwnProperty.call(bannerProps || {}, 'offerId')

  const goToSimulation = () => {
    const query = hasBannerProps
      ? removeFalsyProperties({ ...bannerProps })
      : removeFalsyProperties({ offerId, proposalId })
    BlackBird.travelTo({ pathname: loanConcessionBasePath, query })
  }

  return (
    <Modal isOpen={isOpen} onClose={toggleModal} width={isViewSmall ? '90%' : 'auto'}>
      <Modal.Content>
        <Box maxWidth="30rem" textAlign="center" p="2.5rem">
          <Flex justifyContent="flex-end" p="0.5rem 1rem">
            <IconButton
              icon="close-outline"
              aria-label="Fechar"
              variant="contentOnly"
              color="neutral"
              onClick={() => {
                toggleModal()
              }}
            />
          </Flex>
          <CardBody>
            <Flex justifyContent="center" mb="2.5rem">
              <CoinsStackIllustration width={72} height={72} />
            </Flex>
            <Stack space="1rem" data-testid="automatic-disable">
              <Heading size="large" weight="bold">
                Simule seu empréstimo aqui ou direto pelo aplicativo
              </Heading>
              <Text color="neutral" size="medium">
                Por segurança, a finalização do seu pedido é feita pelo aplicativo da Stone.
              </Text>
            </Stack>
          </CardBody>

          <Flex flexDirection="column" justifyContent="center" mt="2.5rem">
            <Button
              style={{
                width: '100%'
              }}
              size="large"
              color="primary"
              onClick={() => goToSimulation()}
            >
              Continuar
            </Button>
          </Flex>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
