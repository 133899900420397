import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type OfferType } from '../../loan-concession/hooks/use-negotiations-proposal'
import { type CreditOffer, type CreditOfferRoles, type UseCreditoffersReturn } from '../entities'
import { fetchCreditOfferById, fetchCreditOffersByDocument } from '../services/loans'

type MinMaxCreditValueProps = {
  roles: CreditOfferRoles[]
  type?: OfferType
}

export function minMaxCreditValue({ roles, type = 'LoanConcession' }: MinMaxCreditValueProps) {
  const isRevolvingCredit = type === 'RevolvingCredit'
  const conditionsList = roles.flatMap(role => role.conditions as any)

  const maxValuesList = conditionsList
    ?.map(condition => (isRevolvingCredit && condition?.type === 'Amount' ? condition.max : condition.maxCreditLimit))
    .filter(Boolean)
  const minValuesList = conditionsList
    ?.map(condition => (isRevolvingCredit && condition?.type === 'Amount' ? condition.min : condition.minCreditLimit))
    .filter(Boolean)

  const maxValue = maxValuesList.length > 0 && Math.max(...maxValuesList)
  const minValue = minValuesList.length > 0 && Math.min(...minValuesList)

  return {
    maxCreditValue: maxValue || undefined,
    minCreditValue: minValue || undefined
  }
}

type UseOfferProps = {
  offerId?: string
  type?: OfferType
  document: string
}
export function useOffer(
  { offerId, type = 'LoanConcession', document }: UseOfferProps,
  queryOptions?: UseQueryRequestOptions<CreditOffer>
) {
  const hasOfferId = Boolean(offerId)

  const fetchOffers = () => {
    if (hasOfferId) return fetchCreditOfferById(offerId ?? '')
    return fetchCreditOffersByDocument(document)
  }

  const { data: response, ...restQuery } = useQueryRequest<any>(['useCreditOffers'], () => fetchOffers(), queryOptions)

  let selectedOffer: CreditOffer
  if (hasOfferId) {
    selectedOffer = response?.data?.type === type ? response?.data : undefined
  } else {
    selectedOffer = response?.data?.items?.find((offer: CreditOffer) => offer.type === type)
  }

  const { maxCreditValue, minCreditValue } = minMaxCreditValue({ roles: selectedOffer?.roles || [], type })

  const offer: UseCreditoffersReturn = {
    offer: selectedOffer,
    maxCreditValue,
    minCreditValue
  }

  return { ...restQuery, data: offer }
}
