import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Checkbox, Heading, Text, Tooltip } from '@dlpco/ginga-stone'

import { SharedCreditGrid } from '~/domains/credit/shared/components/credit-grid'
import { SharedCreditListBox } from '~/domains/credit/shared/components/credit-list-box'
import { type MainPartnerV2, type MaritalStatus } from '~/domains/credit/shared/entities'
import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type KYCReason, useKycContext } from '../../kyc-flow/context/kyc-context'
import { useCreateNegotiationsProposalAssessments } from '../../loan-concession/hooks/use-check-proposal-assessment'
import { useCheckProposalUpdateTime } from '../../loan-concession/hooks/use-check-proposal-update-time'
import { answerProposalKYC, updateProposalCustomer } from '../../loan-concession/services/proposal'
import { type RenegotiationProposalId, RenegotiationSteps } from '../entities'
import renegotiationAnalitica from '../helpers/renegotiation-analitica'
import { renegotiationNavigate } from '../helpers/renegotiation-navigate'

interface RenegotiationKycProps {
  userId: string
  proposalData: RenegotiationProposalId
  proposalRefetch: () => void
}

export function RenegotiationKyc({ proposalData, userId, proposalRefetch }: RenegotiationKycProps) {
  const { kycAnswerData, nextStep, assessment, saveReason, steps } = useKycContext()
  const { addToast } = useToast()
  const [confirmation, toggleConfirmation] = useToggle(false)
  const [isLoading, toggleLoading] = useToggle(true)
  const [proposalPollingIsActive, setProposalPollingIsActive] = useState(false)
  const { document } = stringFormat
  const mainPartner = proposalData?.customer?.mainPartner as MainPartnerV2
  const isInvalidMainPartner: boolean =
    !mainPartner ||
    !mainPartner.maritalStatus ||
    !mainPartner.nationality ||
    !mainPartner.address ||
    !mainPartner.name ||
    !mainPartner.document

  const isKycComplete = Object.keys(kycAnswerData).length === assessment?.checks?.length
  const maritalStatusFromKyc = kycAnswerData?.marital_status?.referenceName ?? ''
  const maritalStatusFromMainPartner = isInvalidMainPartner ? '' : mainPartner?.maritalStatus?.description ?? ''
  const maritalStatus: MaritalStatus | string = maritalStatusFromKyc || maritalStatusFromMainPartner

  const nationalityFromKyc = kycAnswerData?.nationality?.referenceName ?? ''
  const nationalityFromMainPartner = isInvalidMainPartner ? '' : mainPartner?.nationality
  const nationality: string = nationalityFromKyc || nationalityFromMainPartner

  const addressFromKyc = kycAnswerData?.address ?? undefined
  const addressFromMainPartner = isInvalidMainPartner ? undefined : mainPartner?.address
  const address = addressFromKyc || addressFromMainPartner

  const travelToReview = () => renegotiationNavigate({ step: RenegotiationSteps.REVIEW })
  const travelToProposal = () => renegotiationNavigate({ step: RenegotiationSteps.PROPOSAL })

  useEffectOnce(() => renegotiationAnalitica.events.proposal.preKycView())

  useCheckProposalUpdateTime(
    {
      proposalId: proposalData?.id as string,
      lastUpdateDate: proposalData.customer.lastUpdateDate,
      onSuccess: () => {
        toggleLoading(false)
        travelToReview()
      },
      onError: () => {
        toggleLoading(false)
        addToast({ message: 'Ocorreu um erro durante o processo de confirmação dos dados', type: 'error' })
      }
    },
    {
      enabled: proposalPollingIsActive
    }
  )

  const handleGoToReview = async () => {
    renegotiationAnalitica.events.proposal.preKycClick('revisar contrato')
    toggleLoading(true)
    if (!!assessment?.id && isKycComplete) {
      await answerProposalKYC({ userId, assessment, kycAnswerData })
      await updateProposalCustomer(proposalData?.id)
      setProposalPollingIsActive(true)
    } else {
      travelToReview()
    }
  }

  const [assessmentCreated, toggleAssessmentCreated] = useToggle(!assessment?.id)
  useCreateNegotiationsProposalAssessments(
    {
      negotiationsProposalId: proposalData?.id,
      enabled: assessmentCreated,
      onError: () => {
        travelToProposal()
      }
    },
    {
      onSettled: (response: any) => {
        const kycReason = response?.data as KYCReason
        if (kycReason?.reason) {
          saveReason(kycReason)
          toggleAssessmentCreated(false)
        }
      }
    }
  )

  if (isLoading && !assessmentCreated && !!assessment) toggleLoading(false)

  const nextStepButton = (
    <Button
      onClick={handleGoToReview}
      color="primary"
      shape="pill"
      disabled={!confirmation || address === undefined}
      icon="direction-arrow-right-outline"
      iconPosition="end"
      style={{ width: '100%' }}
      data-test-id="renegotiation-flow-pre-kyc-step-review-button"
    >
      Revisar contrato
    </Button>
  )

  const personalData = [
    {
      title: 'Nome',
      value: mainPartner?.name ?? ''
    },
    {
      title: 'CPF',
      value: mainPartner?.document ? document(mainPartner?.document) : ''
    },
    {
      title: 'Nacionalidade',
      value: nationality
    },
    {
      title: 'Estado civil',
      value: maritalStatus
    }
  ]

  const addressData = [
    {
      title: 'CEP',
      value: address?.postalCode ?? ''
    },
    {
      title: 'Endereço',
      value: address ? `${address?.street}${address?.streetNumber ? ', ' + address?.streetNumber : ''}` : ''
    },
    {
      title: 'Bairro',
      value: address?.neighborhood ?? ''
    },
    {
      title: 'Estado',
      value: address?.state ?? ''
    },
    {
      title: 'Cidade',
      value: address?.city ?? ''
    }
  ]

  const handleGoToKyc = useCallback(() => {
    renegotiationAnalitica.events.proposal.preKycClick('atualizar dados')
    nextStep()
  }, [nextStep])

  const redirectToKyc = isKycComplete ? false : !!isInvalidMainPartner && steps.length > 1

  useEffect(() => {
    if (redirectToKyc) handleGoToKyc()
  }, [redirectToKyc, handleGoToKyc])

  useEffect(() => {
    if (!!isInvalidMainPartner && isKycComplete) proposalRefetch()
  }, [isInvalidMainPartner, proposalRefetch, isKycComplete])

  return (
    <>
      <SharedCreditGrid
        left={
          <Heading size="large" weight="semi">
            Revisão de dados
          </Heading>
        }
        right={
          <Stack space="1rem">
            <Stack space="0.5rem">
              <SharedCreditListBox title="Dados pessoais" items={personalData} />
            </Stack>
            <Stack space="0.5rem">
              <SharedCreditListBox title="Endereço" items={addressData} />
            </Stack>
            <Flex gap="1rem">
              <Checkbox id="confirm-term" onChange={toggleConfirmation} />
              <Text>
                Eu <strong>confirmo</strong> os dados informados e <strong>autorizo</strong> a vinculação do meu CPF a
                esta renegociação.
              </Text>
            </Flex>
            <Flex gap="1rem" justifyContent="space-evenly">
              <Button onClick={handleGoToKyc} color="neutral" shape="pill" style={{ width: '100%' }}>
                Atualizar dados
              </Button>
              <Choose>
                <Choose.When condition={!confirmation}>
                  <Tooltip content="Confirme os dados ou atualize-os para continuar para a revisão do contrato">
                    <Box width="100%">{nextStepButton}</Box>
                  </Tooltip>
                </Choose.When>
                <Choose.When condition={confirmation}>{nextStepButton}</Choose.When>
              </Choose>
            </Flex>
          </Stack>
        }
      />
      {isLoading && (
        <Dimmer isVisible>
          <Loader />
        </Dimmer>
      )}
    </>
  )
}
