import { useEffectOnce } from 'react-use'
import { Alert, Text } from '@dlpco/ginga-stone'

import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { timeMachine } from '~/domains/platform/lib/time-machine'
import { appEnv } from '~/lib/helpers'

import analitica from '../../../loan-details/analitica'
import { type RenegotiationSummaryStatus } from '../../../shared/entities'

const analysisRenegStatusList = [
  'DisbursementRequested',
  'Disbursed',
  'Created',
  'TreasuryOrderRequested',
  'TreasuryOrderApproved'
]

const approvedRenegStatusList = ['Active', 'Settled']

const rejectedRenegStatusList = ['TreasuryOrderFailed', 'Cancelled']

const DAYS_TO_EXPIRE = 7

export function RenegotiationBannerContainerFollowUp({
  renegStatus,
  renegotiationCreationDate
}: {
  renegotiationCreationDate?: string
  renegStatus: RenegotiationSummaryStatus
}) {
  useEffectOnce(() => analitica.events.banner.followUp('view', { status: renegStatus, type: 'renegociação' }))
  const endDate = timeMachine(renegotiationCreationDate).addDay(DAYS_TO_EXPIRE)

  const isBannerExpired = timeMachine().isAfter(endDate)

  if (analysisRenegStatusList.includes(renegStatus))
    return (
      <Alert heading="Renegociação em processamento" color="info">
        <Text size="small">Vamos te dar o retorno em até 1 dia útil.</Text>
      </Alert>
    )

  if (approvedRenegStatusList.includes(renegStatus) && !isBannerExpired)
    return (
      <Alert heading="A renegociação já está valendo" color="positive">
        <Text size="small">
          Você pode consultar as condições originais em <b>Informações do contrato.</b>
        </Text>
      </Alert>
    )

  if (rejectedRenegStatusList.includes(renegStatus) && !isBannerExpired)
    return (
      <Alert
        heading="A renegociação não aconteceu"
        color="negative"
        action={{
          label: 'Entrar em contato',
          detail: {
            hasDetail: true
          }
        }}
        onAction={() => {
          analitica.events.banner.followUp('clicked', { status: renegStatus, type: 'renegociação' })
          appEnv.isProduction() && chat.open()
        }}
      >
        <Text size="small">Converse com a Stone para saber mais.</Text>
      </Alert>
    )

  return null
}
