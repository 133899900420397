import { formatDate } from '@credit-web/common/date-utils'
import { rateUtils } from '@credit-web/common/number-utils'

import { type ItemContent } from '~/domains/credit/shared/components/credit-list-box'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type SummaryFields } from '../entities'

export function formatRenegotiationConditions({
  summary = {} as SummaryFields,
  highlightText = false,
  useAlternativeBalanceLabel = false,
  totalRowsToAdd = 0
}: {
  summary?: SummaryFields
  highlightText?: boolean
  useAlternativeBalanceLabel?: boolean
  totalRowsToAdd?: number
}) {
  if (Object.keys(summary).length > 0) {
    const balance = highlightText ? summary?.currentBalance : summary?.futureBalance
    const plans = summary?.plans ?? []

    const graceInstallments =
      plans.length > 0 ? plans.filter(plan => plan?.type === 'GraceWithInterest' || plan?.type === 'InterestOnly') : []

    const regularInstallments =
      plans.length > 0 ? plans.filter(plan => plan?.type === 'Standard' || plan?.type === 'Regular') : []

    const handleRowSpace = () => {
      if (useAlternativeBalanceLabel) {
        const rowsToAdd = Array.from({ length: totalRowsToAdd }).map((_, index) => ({
          title: ' ',
          value: ' ',
          condition: Boolean(regularInstallments),
          showBorder: index === totalRowsToAdd - 1
        }))

        return rowsToAdd
      }
      return []
    }

    return [
      {
        title: useAlternativeBalanceLabel ? 'Valor renegociado' : 'Total a pagar',
        value: useAlternativeBalanceLabel
          ? stringFormat.currency(balance * 100)
          : stringFormat.currency(summary?.initialAmount * 100),
        description: useAlternativeBalanceLabel
          ? 'Valor total do contrato, incluindo o saldo em aberto e cobranças por atraso.'
          : undefined,
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'Parcelado em',
        value: `${summary?.numberOfInstallments}x`,
        color: highlightText ? 'primary' : undefined,
        showBorder: graceInstallments.length === 0 && regularInstallments.length === 0
      },
      ...graceInstallments.map(installment => ({
        title: `${installment.installments.value} parcelas de`,
        value: installment.amountByInstallment.description,
        description: 'Parcelas referentes aos juros acumulados sobre o tempo de carência.',
        color: highlightText ? 'primary' : undefined,
        showBorder: graceInstallments.length === 0
      })),
      ...regularInstallments.map((installment, i) => ({
        title: `${installment.installments.value} parcelas de`,
        value: installment.amountByInstallment.description,
        color: highlightText ? 'primary' : undefined,
        showBorder: handleRowSpace().length > 0 ? false : i === regularInstallments.length - 1
      })),
      ...handleRowSpace(),
      {
        title: 'Juros ao mês',
        value: summary?.monthlyInterestRate
          ? `${rateUtils.formatToLimitedDecimalPlaces(summary?.monthlyInterestRate)}%`
          : 'Sem juros',
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'Juros ao ano',
        value: summary?.yearlyInterestRate
          ? `${rateUtils.formatToLimitedDecimalPlaces(summary?.yearlyInterestRate)}%`
          : 'Sem juros',
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'Forma de pagamento',
        value: `Retenção diária`,
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'Percentual de retenção',
        value: `${rateUtils.formatToLimitedDecimalPlaces(summary?.retentionRate)}% dos rendimentos`,
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'Contrato vence em',
        value: formatDate(summary?.dueDate),
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'IOF financiado',
        value: summary?.taxAmount > 0 ? stringFormat.currency(summary?.taxAmount * 100) : 'Sem IOF',
        description: 'O IOF é um imposto federal aplicado sobre operações financeiras.',
        color: highlightText ? 'primary' : undefined
      },
      {
        title: 'CET',
        value:
          summary?.yearlyTotalEffectiveCostRate > 0
            ? `${rateUtils.formatToLimitedDecimalPlaces(summary?.yearlyTotalEffectiveCostRate)}% ao ano`
            : 'Sem taxas',
        description: 'O CET é a soma de todos os custos da operação de empréstimo, ou seja, juros e IOF.',
        color: highlightText ? 'primary' : undefined
      }
    ] as ItemContent[]
  } else {
    return [] as ItemContent[]
  }
}
