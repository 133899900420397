import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type LoanInstallmentList } from '../entities'

const fetchLoanInstallments = (loanId?: string, installmentNumber?: string) => {
  return httpClientCredit({
    url: `/api/v4/loans/${loanId}/installments`,
    params: { ...(installmentNumber && { installmentNumber: installmentNumber }) }
  })
}

export function useLoanInstallments(
  loanId?: string,
  installmentNumber?: string,
  queryOptions?: UseQueryRequestOptions<LoanInstallmentList>
) {
  const { data: response, ...restQuery } = useQueryRequest<LoanInstallmentList>(
    ['useLoanInstallment', loanId, installmentNumber],
    () => fetchLoanInstallments(loanId, installmentNumber),
    { enabled: Boolean(loanId), ...queryOptions }
  )

  return {
    ...restQuery,
    data: response?.data
  }
}
