import {
  type LoanHomeProposalBannerSizes,
  type LoanHomeProposalBannerTypes,
  type OpportunitySectionElement
} from '../entities'

const loanObj = {
  proposal: 'Proposal',
  offer: 'Offer'
}

export const translateOpportunityType = (opportunity: OpportunitySectionElement): LoanHomeProposalBannerTypes => {
  if (opportunity.type === 'revolving_credit') return 'RevolvingCredit'
  if (opportunity.type === 'loan_concession') {
    return loanObj[opportunity.negotiationType] as LoanHomeProposalBannerTypes
  }
  if (opportunity.type === 'renegotiation') return 'Renegotiation'

  return 'NotMapped'
}

export const translateOpportunitySize = (opportunity: OpportunitySectionElement): LoanHomeProposalBannerSizes => {
  if (opportunity.type === 'revolving_credit') return 'small'
  return opportunity.highlight ? 'large' : 'small'
}
