import { getSagaKey } from '@credit-web/common/browser-utils'
import { Text, theme } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box } from '~/domains/platform/design-system'

export const BorderBox = styled(Box)`
  border: 1px solid ${theme.light.color.borderLow};
  border-radius: 0.5rem;
  text-align: left;
  padding: ${theme.light.space.medium};
`

export function SharedSagaKeyErrorDisplay() {
  const sagaKey = getSagaKey()
  return (
    <>
      {sagaKey ? (
        <BorderBox>
          <Text color="neutralHigh" size="medium" weight="semi">
            Código do erro: {sagaKey}
          </Text>
          <Text color="neutral" size="medium">
            Informe o código para o time de atendimento ao conversar com a Stone.
          </Text>
        </BorderBox>
      ) : null}
    </>
  )
}
