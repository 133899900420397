import { type ReactElement } from 'react'
import { CreditBucket } from '@credit-web/common/browser-utils'
import { formatDate } from '@credit-web/common/date-utils'
import { rateUtils } from '@credit-web/common/number-utils'
import { Box } from '@dlpco/fluid-layout'
import { type BadgeColor } from '@dlpco/ginga-components/cjs/components/badge/props/types'
import { type ButtonColor } from '@dlpco/ginga-components/cjs/components/button/props/types'
import { type IconId, Badge, Heading, Text } from '@dlpco/ginga-stone'
import { PaymentSuccessIllustration } from 'design-elements/shared/illustrations'

import { renegotiationBasePath } from '~/domains/credit/ports/credit.routes'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers'

import { type RenegotiationProposal } from '../../loan-concession/entities'
import { getNegotiationsProposalById, getProposalById } from '../../shared/services/loans'
import {
  type LoanHomeProposalBannerSizes,
  type LoanHomeProposalBannerTypes,
  type OpportunitySectionElement
} from '../entities'
import { translateOpportunitySize, translateOpportunityType } from '../helper/loan-home'
import analitica, { type BannerClickProps, type BannerViewProps } from '../helpers/analitica'

import { useLoanHomeLoanConcessionDialog } from './use-loan-home-loan-concession-dialog'

type BannerEventActionsProps<T extends 'click' | 'view'> = {
  eventType: T
  eventProps: T extends 'click' ? BannerClickProps : BannerViewProps
  productType: Exclude<LoanHomeProposalBannerTypes, 'NotMapped'>
}

interface LoanHomeBannerInfo {
  title: string
  description: string | ReactElement
  buttonText: string
  buttonTextArialLabel?: string
  dueDate?: string
  illustration?: ReactElement
}

interface LoanHomeBannerInfoSmall extends LoanHomeBannerInfo {
  iconColor: BadgeColor
  iconName?: IconId
  buttonColor?: ButtonColor
  buttonAction?: () => void
  onColorTheme?: boolean
}

type LoanHomeProposalBannerVariations = {
  large: LoanHomeBannerInfo
  small: LoanHomeBannerInfoSmall
}

type LoanHomeProposalBannerType = {
  [key in Exclude<LoanHomeProposalBannerTypes, 'NotMapped'>]: LoanHomeProposalBannerVariations
}

type GoToSimulationProps = {
  type: LoanHomeProposalBannerTypes
  id: string
  openRevolvingCredit: () => void
  offerId: string
}

export function useLoanHomeProposalBanner(opportunity: OpportunitySectionElement) {
  const { currency } = stringFormat
  const { amount, interestRate, installments, installmentAmount, daysToReceive, expirationDate } = opportunity
  const { toggleLoanConcessionModal, setLoanConcessionModalProps } = useLoanHomeLoanConcessionDialog()

  const bannerEventActions = <T extends 'click' | 'view'>({
    eventProps,
    eventType,
    productType
  }: BannerEventActionsProps<T>) => {
    const productsEventsActions = {
      Offer: {
        view: () => analitica.events.home.offerBannerView(eventProps as BannerViewProps),
        click: () => analitica.events.home.offerBannerClick(eventProps as BannerClickProps)
      },
      Proposal: {
        view: () => analitica.events.home.proposalBannerView(eventProps as BannerViewProps),
        click: () => analitica.events.home.proposalBannerClick(eventProps as BannerClickProps)
      },
      Renegotiation: {
        view: () => analitica.events.home.proposalBannerView(eventProps as BannerViewProps),
        click: () => analitica.events.home.proposalBannerClick(eventProps as BannerClickProps)
      },
      RevolvingCredit: {
        view: () => analitica.events.home.homeRevolvingCreditCardProposalBannerView(eventProps as BannerViewProps),
        click: () => analitica.events.home.homeRevolvingCreditCardProposalBannerClick(eventProps as BannerClickProps)
      }
    }

    return productsEventsActions[productType][eventType]?.()
  }

  const banner: LoanHomeProposalBannerType = {
    Offer: {
      small: {
        title: 'Simule um empréstimo de até ' + currency(amount),
        description: `Pague em até ${installments}x com o dinheiro de suas vendas.`,
        buttonText: 'Simular empréstimo',
        iconColor: 'positive',
        buttonColor: 'primary'
      },
      large: {
        title: 'É hora de investir no seu negócio',
        description: (
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            <Text size="large" style={{ display: 'inline' }}>
              Peça agora até
            </Text>
            <Heading color="primary" weight="bold" style={{ display: 'inline' }}>
              {' '}
              {currency(amount)}{' '}
            </Heading>
            <Text size="large" style={{ display: 'inline' }}>
              e receba o dinheiro em até {daysToReceive} dias úteis.
            </Text>
          </Box>
        ),
        buttonText: 'Simular empréstimo'
      }
    },
    Proposal: {
      small: {
        title: 'Você tem uma nova proposta de empréstimo',
        description: `${currency(amount)} com juros de
           ${rateUtils.formatToLimitedDecimalPlaces(interestRate)}% ao mês em ${installments}x de
           ${currency(installmentAmount)}.`,
        buttonText: 'Conferir',
        iconColor: 'positive',
        buttonColor: 'onColor',
        onColorTheme: true
      },
      large: {
        title: 'Especial pra você',
        description: (
          <Flex alignItems="start" flexDirection="column" gap={'1rem'}>
            <Box style={{ whiteSpace: 'pre-wrap' }}>
              <Heading color="primary" weight="bold" style={{ display: 'inline' }}>
                {currency(amount)}{' '}
              </Heading>
              <Text size="large" style={{ display: 'inline' }}>
                com juros de <b>{rateUtils.formatToLimitedDecimalPlaces(interestRate)}% ao mês</b> em{' '}
                <Text as={'span'}>{installments}x</Text> de <b>{currency(installmentAmount)}</b>.
              </Text>
            </Box>
            <If condition={!!expirationDate}>
              <Badge icon={'clock-round-solid'} color={'positive'}>
                Expira em {formatDate(expirationDate || '')}
              </Badge>
            </If>
          </Flex>
        ),
        buttonText: 'Conferir condições'
      }
    },
    Renegotiation: {
      small: {
        title: 'Renegocie seu empréstimo',
        description: 'Confira as novas condições que preparamos pra você.',
        buttonText: 'Conferir condições',
        iconColor: 'warning',
        iconName: 'money-notes-outline',
        buttonTextArialLabel: 'Conferir condições da renegociação',
        buttonColor: 'primary'
      },
      large: {
        title: 'Renegocie seu empréstimo',
        description: (
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            <Text size="large">Encontre as melhores condições para quitar o valor em aberto do seu empréstimo.</Text>
          </Box>
        ),
        buttonText: 'Conferir condições',
        buttonTextArialLabel: 'Conferir condições da renegociação',
        illustration: <PaymentSuccessIllustration />
      }
    },
    RevolvingCredit: {
      small: {
        title: 'Giro Fácil: conheça essa nova opção de crédito rápido',
        description: `São ${currency(amount)} disponíveis para quando você precisar. Ative grátis agora.`,
        buttonText: 'Saber mais',
        buttonTextArialLabel: 'Saber mais sobre o Giro fácil',
        iconColor: 'info',
        iconName: 'money-notes-outline',
        buttonColor: 'primary'
      },
      large: {
        title: 'Giro Fácil: conheça essa nova opção de crédito rápido',
        description: <Text>São {currency(amount)} disponíveis para quando você precisar. Ative grátis agora.</Text>,
        buttonText: 'Saber mais',
        buttonTextArialLabel: 'Saber mais sobre o Giro fácil'
      }
    }
  }

  const type: LoanHomeProposalBannerTypes = translateOpportunityType(opportunity)
  const size: LoanHomeProposalBannerSizes = translateOpportunitySize(opportunity)

  const loanHomeNavigation = async ({ type, id, openRevolvingCredit, offerId }: GoToSimulationProps) => {
    CreditBucket.set('credit::shared::initial-path', 'loan-home')

    if (type === 'RevolvingCredit') openRevolvingCredit()

    if (type === 'Renegotiation') {
      const hasMigrateBff = Heimdall.pass(['migrate_bff_proposals_renegotiaions'])

      const { data: proposal } = await (hasMigrateBff ? getProposalById(id) : getNegotiationsProposalById(id))
      const renegProposal = proposal as unknown as RenegotiationProposal
      const { offerId } = renegProposal
      const loanId = renegProposal?.renegotiation?.securities[0]?.externalReference || ''

      return BlackBird.travelTo({
        pathname: renegotiationBasePath,
        urlParams: { loan: loanId },
        query: { offerId, proposalId: id }
      })
    } else if (['Offer', 'Proposal'].includes(type)) {
      const params: any = { offerId: id }

      if (type === 'Proposal') {
        params.proposalId = id
        params.offerId = offerId
      }

      setLoanConcessionModalProps({ ...params })
      toggleLoanConcessionModal()
    }
  }

  return {
    bannerEventActions,
    banner,
    type,
    size,
    loanHomeNavigation
  }
}
