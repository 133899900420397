import { useEffectOnce } from 'react-use'
import { formatDate } from '@credit-web/common/date-utils'
import { divideByOneHundred } from '@credit-web/common/number-utils'
import { Stack } from '@dlpco/fluid-layout'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Description,
  Heading,
  Icon,
  Money,
  Overline,
  Text
} from '@dlpco/ginga-stone'
import { ListItem, Text as JadeText } from '@stone-payments/jade'
import styled from 'styled-components'

import { goToLoanDetails } from '~/domains/credit/shared/helpers/go-to-loan-details'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { timeMachine } from '~/domains/platform/lib/time-machine'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { theme } from '~/ui/theme'

import { type InstallmentStatus } from '../../shared/entities'
import { type StatusBadgePropsItem, loanStatusColorMap } from '../../shared/helpers/status-colors-map'
import { type CurrentLoan } from '../entities'
import analitica, { type LoanCardClickActions, type LoanCardViewProps } from '../helpers/analitica'

import { LoanHomeBadgeProductType } from './loan-home-badge-product-type'
import { LoanHomeProgressBar } from './loan-home-progress-bar'

export const BorderHeader = styled(CardHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGray4};
  padding: ${({ theme }) => theme.space.medium} ${({ theme }) => theme.space.xLarge} !important;
`

const Line = styled.hr<{ lineColor: 'primary' | 'gray'; height: string }>`
  flex-grow: 100;
  width: 1px;
  height: ${props => props.height};
  ${props =>
    props.lineColor === 'primary'
      ? `border: 1.5px solid ${theme.colors.primary};`
      : `border: 1.5px solid ${theme.colors.border.disabled};`}
`

const ListItemWithoutPadding = styled(ListItem)`
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 0.2rem !important;
  align-items: flex-start !important;
`

const pascalCase = (string: string): string => {
  const s = string.replace(/_(\w)/g, $1 => $1.toUpperCase())
  return `${s.charAt(0).toUpperCase()}${s.substring(1)}`
}

export const loanHomeGetLoanStatus = (currentLoan: CurrentLoan): InstallmentStatus => {
  const { status, currentInstallment } = currentLoan
  const loanStatus = pascalCase(status)
  const installmentStatus = pascalCase(currentInstallment.status) as InstallmentStatus
  if (loanStatus === 'Active') {
    if (currentLoan?.isRetentionEnabled === false) {
      return 'Suspended'
    } else if (['Open', 'Settled'].includes(installmentStatus)) {
      return installmentStatus
    } else if (currentInstallment.isDueSoon) {
      return 'DueSoon'
    } else if (currentInstallment.isOverdue) {
      return 'Overdue'
    }
  } else if (loanStatus === 'Suspended') {
    return 'Suspended'
  }
  return installmentStatus
}

export function LoanHomeActiveLoanCard({ loanInfo }: { loanInfo: CurrentLoan }) {
  const { currency } = stringFormat
  const {
    currentInstallment,
    overdueInstallment,
    id,
    creditAmount,
    initialInstallmentAmount,
    totalOfInstallment,
    type,
    plans,
    renegotiationSummary
  } = loanInfo || {}

  const orderedPlans =
    plans && plans.length > 1
      ? plans.sort((a, b) => {
          if (a.type === 'GraceWithInterest' || a.type === 'InterestOnly') return -1
          if (b.type === 'GraceWithInterest' || b.type === 'InterestOnly') return 1
          return 0
        })
      : []

  const installmentStatus: InstallmentStatus = loanHomeGetLoanStatus(loanInfo)
  const statusBadgeConfig: StatusBadgePropsItem =
    installmentStatus in loanStatusColorMap
      ? loanStatusColorMap[installmentStatus]
      : { color: 'neutral', text: 'status', variant: 'solid' }

  const showProgressBar = !(installmentStatus == 'Future' || installmentStatus == 'Overdue')
  const isOverdue = installmentStatus === 'Overdue'
  const hasMultipleOverdueInstallments = !!(isOverdue && overdueInstallment?.overdueInstallments?.length > 1)

  useEffectOnce(() => {
    analitica.events.home.loanCardView({
      id,
      status: installmentStatus,
      daysUntilOverdue:
        installmentStatus === 'Overdue' ? currentInstallment.daysOverdue * -1 : currentInstallment?.daysUntilDueDate
    } as LoanCardViewProps)
  })

  const sendClickEvent = (id: string, action: LoanCardClickActions) => {
    analitica.events.home.loanCardClick({ 'loan id': id, action })
  }
  const goToSpotOffers = () =>
    BlackBird.travelTo({
      pathname: '/credito/emprestimo/${loan}/antecipar-pagamento/inicio',
      urlParams: { loan: id }
    })
  return (
    <Card
      key={loanInfo?.id}
      onClick={() => {
        sendClickEvent(id, 'ver detalhes do empréstimo')
        if (!isOverdue) {
          goToLoanDetails(id)
        }
      }}
      style={{ cursor: isOverdue ? 'auto' : 'pointer' }}
      tabIndex={0}
      role="button"
      aria-label={`Capital de Giro contratado no valor: ${currency(creditAmount, false)}`}
    >
      <BorderHeader
        onClick={() => (isOverdue ? goToLoanDetails(id) : null)}
        verticalAlignment="center"
        slotEnd={<Icon use="chevron-right-outline" size="large" />}
        style={{ cursor: 'pointer' }}
      >
        <LoanHomeBadgeProductType type={type} />

        <Heading aria-hidden size="medium" weight="semi" style={{ marginTop: theme.space.default }}>
          <Money amount={divideByOneHundred(creditAmount)} />
        </Heading>
        <Flex flexDirection="column" gap="0.2rem" marginTop="0.2rem">
          {plans && plans.length > 1 ? (
            orderedPlans.map((plan, index) => (
              <Text size="small" color="neutral" key={`${loanInfo?.id}-plan-${index}`}>
                {plan.installments?.description} de {plan.amountByInstallment?.description}
              </Text>
            ))
          ) : plans && plans.length === 1 ? (
            <Text size="small" color="neutral" key={`${loanInfo?.id}-plan-regular`}>
              Em {plans[0].installments?.description} de {plans[0].amountByInstallment?.description}
            </Text>
          ) : (
            <Text size="small" color="neutral">
              Em {totalOfInstallment}x de <Money amount={divideByOneHundred(initialInstallmentAmount)} />
            </Text>
          )}
        </Flex>
      </BorderHeader>

      {renegotiationSummary !== undefined &&
      renegotiationSummary.status !== undefined &&
      renegotiationSummary.status !== 'Active' ? (
        <CardBody style={{ padding: '16px 24px 0px 24px' }}>
          <Stack>
            <ListItemWithoutPadding
              content={
                <>
                  <JadeText variant="heading-xsmall">Pedido de renegociação recebido</JadeText>
                  <JadeText variant="text-small" color="medium" weight="regular">
                    {timeMachine(renegotiationSummary.creationDate).format('fancyHour')}.
                  </JadeText>
                </>
              }
              leading={
                <Flex flexDirection="column" gap="0.2rem" alignItems="center">
                  <Icon use="check-round-solid" color="primary" />
                  <Line lineColor="gray" height="37px" />
                </Flex>
              }
            />
            <ListItemWithoutPadding
              content={
                <>
                  <JadeText variant="heading-xsmall">Renegociação em processamento</JadeText>
                  <JadeText variant="text-small" color="medium" weight="regular">
                    Vamos te dar o retorno em até 1 dia útil.
                  </JadeText>
                </>
              }
              leading={
                <Flex flexDirection="column" gap="0.2rem" alignItems="center">
                  {renegotiationSummary.status === 'Settled' ||
                  renegotiationSummary.status === 'TreasuryOrderFailed' ||
                  renegotiationSummary.status === 'Cancelled' ? (
                    <Icon use="check-round-solid" color="primary" />
                  ) : (
                    <Icon use="round-clock-outline" color="primary" />
                  )}
                  <Line lineColor="gray" height="30px" />
                </Flex>
              }
            />
            <ListItemWithoutPadding
              content={
                renegotiationSummary.status === 'Settled' ||
                renegotiationSummary.status === 'TreasuryOrderFailed' ||
                renegotiationSummary.status === 'Cancelled' ? (
                  <JadeText variant="heading-xsmall">Renegociação finalizada</JadeText>
                ) : (
                  <JadeText variant="heading-xsmall" color="medium">
                    Renegociação finalizada
                  </JadeText>
                )
              }
              leading={
                renegotiationSummary.status === 'Settled' ||
                renegotiationSummary.status === 'TreasuryOrderFailed' ||
                renegotiationSummary.status === 'Cancelled' ? (
                  <Icon use="check-round-solid" color="primary" />
                ) : (
                  <Flex justifyContent="center" alignItems="flex-start" width="20px">
                    <span
                      style={{
                        width: '0.9rem',
                        height: '0.9rem',
                        borderRadius: '64px',
                        marginTop: '0.2rem',
                        outline: `0.125rem solid #A6AEBA`
                      }}
                    />
                  </Flex>
                )
              }
            />
          </Stack>
        </CardBody>
      ) : (
        <CardBody>
          <Stack space="1.25rem">
            <Flex justifyContent="space-between" alignItems="center">
              <Choose>
                <Choose.When condition={hasMultipleOverdueInstallments}>
                  <Overline color="neutral">
                    {overdueInstallment?.overdueInstallments?.length} Parcelas não pagas
                  </Overline>
                </Choose.When>
                <Choose.Otherwise>
                  <Overline color="neutral">
                    Parcela {currentInstallment.installmentNumber} de {totalOfInstallment}
                  </Overline>
                </Choose.Otherwise>
              </Choose>
              <Badge color={statusBadgeConfig.color} variant={statusBadgeConfig.variant}>
                {statusBadgeConfig.text}
              </Badge>
            </Flex>

            <If condition={showProgressBar}>
              <LoanHomeProgressBar currentInstallment={currentInstallment} />
            </If>

            <ActiveCardBottomLines
              loanInfo={loanInfo}
              hasMultipleOverdueInstallments={hasMultipleOverdueInstallments}
            />

            <If condition={isOverdue}>
              <Button
                color="primary"
                onClick={() => {
                  sendClickEvent(id, 'fazer um pagamento')
                  goToSpotOffers()
                }}
              >
                Fazer um pagamento
              </Button>
            </If>
          </Stack>
        </CardBody>
      )}
    </Card>
  )
}

const ActiveCardBottomLines = ({
  loanInfo,
  hasMultipleOverdueInstallments
}: {
  loanInfo: CurrentLoan
  hasMultipleOverdueInstallments: boolean
}) => {
  const { currentInstallment, nextInstallment } = loanInfo || {}
  const installmentStatus: InstallmentStatus = loanHomeGetLoanStatus(loanInfo)
  if (installmentStatus === 'Overdue')
    return (
      <Stack space="0.5rem">
        <Flex alignItems="center" gap="0.25rem">
          <Icon use="money-coin-outline" />{' '}
          <Description>
            O valor total em atraso é de{' '}
            <strong>
              <Money amount={divideByOneHundred(loanInfo?.overdueInstallment?.totalPresentBalance)} />
            </strong>
          </Description>
        </Flex>
        <Flex alignItems="center" gap="0.25rem">
          <Icon use="calendar-money-outline" />
          <Choose>
            <Choose.When condition={hasMultipleOverdueInstallments}>
              <Description>
                Em atraso há <strong>{loanInfo?.overdueInstallment?.daysOverdue} dias</strong>
              </Description>
            </Choose.When>
            <Choose.Otherwise>
              <Description>
                Parcela em atraso há <strong>{loanInfo?.overdueInstallment?.daysOverdue} dias</strong>
              </Description>
            </Choose.Otherwise>
          </Choose>
        </Flex>
      </Stack>
    )

  if (installmentStatus === 'Settled')
    return (
      <Stack space="0.5rem">
        <Flex alignItems="center" gap="0.25rem">
          <Icon use="calendar-outline" />{' '}
          <Description>
            Próxima parcela abre em <strong> {formatDate(currentInstallment.startDate)}</strong>
          </Description>
        </Flex>
        <InterestEconomyInfo interestEconomyAmount={currentInstallment.interestEconomyAmount} />
      </Stack>
    )

  if (installmentStatus === 'Future')
    return (
      <Stack space="0.5rem">
        <Flex alignItems="center" gap="0.25rem">
          <Icon use="calendar-outline" />{' '}
          <Description>
            Abre em <strong> {formatDate(nextInstallment?.startDate)}</strong>
          </Description>
        </Flex>
      </Stack>
    )

  return (
    <Stack space="0.5rem">
      <Flex alignItems="center" gap="0.25rem">
        <Icon use="money-coin-outline" />
        <Description>
          Restam{' '}
          <strong>
            <Money amount={divideByOneHundred(currentInstallment.futureBalance)} />
          </strong>{' '}
          para quitar
        </Description>
      </Flex>
      <InterestEconomyInfo interestEconomyAmount={currentInstallment.interestEconomyAmount} />
    </Stack>
  )
}

function InterestEconomyInfo({ interestEconomyAmount }: { interestEconomyAmount: number }) {
  const interestEconomyValue = divideByOneHundred(Math.abs(interestEconomyAmount))
  const hasInterestEconomy = interestEconomyValue > 0

  if (!hasInterestEconomy) return null

  return (
    <Flex alignItems="center" gap="0.25rem">
      <Icon use="money-piggy-bank-outline" />
      <Description>
        Juros economizados{' '}
        <strong style={{ color: theme.colors.blue }}>
          <Money amount={interestEconomyValue} />
        </strong>
      </Description>
    </Flex>
  )
}
