import { formatDate } from '@credit-web/common/date-utils'
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Description,
  Heading,
  Icon,
  Money,
  Overline,
  Text
} from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { type LoanInstallment, type LoanSummaryOverdueInstallments } from '../../shared/entities'
import { statusColorMap } from '../../shared/helpers/status-colors-map'
import analitica from '../analitica'

import { LoanDetailsCustomTooltip } from './loan-details-custom-tooltip'

const BorderHeader = styled(CardHeader)`
  border-bottom: 1px solid ${theme.colors.mediumGray4};
  width: 100%;
`

const FooterGrid = styled(Box)`
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: 1.25fr 1.25fr 1fr 1fr;
  width: 100%;
  padding: 1.25rem 1.5rem;
  border-top: 1px solid ${theme.colors.mediumGray4};
`
export const CustomCard = styled(Card)`
  height: 17.5rem;
`

function LoanDetailsCurrentInstallmentStatusLoader() {
  return (
    <CustomCard>
      <Box width="100%" p="1rem 1.5rem">
        <Skeleton width="90%" height="2rem" />
      </Box>

      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Box width="100%">
            <Skeleton width="90%" height="1rem" />

            <Skeleton width="90%" height="3rem" />
          </Box>
          <Icon use="chevron-right-outline" size="large" />
        </Flex>
      </CardBody>

      <FooterGrid>
        <Skeleton width="25%%" height="2rem" />

        <Skeleton width="25%%" height="2rem" />

        <Skeleton width="25%%" height="2rem" />

        <Skeleton width="25%%" height="2rem" />
      </FooterGrid>
    </CustomCard>
  )
}

interface LoanDetailsCurrentInstallmentStatusProps {
  loanId: string
  currentInstallment?: LoanInstallment
  numberOfInstallments?: number
  overdueInstallments?: LoanSummaryOverdueInstallments
  numberOfOverdueInstallments?: number
  isLoading: boolean
  isZeroTaxRenegotiation?: boolean
}

export function LoanDetailsCurrentInstallmentStatus({
  loanId,
  currentInstallment,
  numberOfInstallments,
  numberOfOverdueInstallments,
  overdueInstallments,
  isLoading,
  isZeroTaxRenegotiation = false
}: LoanDetailsCurrentInstallmentStatusProps) {
  const isOverdue = currentInstallment?.status === 'Overdue'
  const hasMultipleOverdueInstallments = Boolean(
    overdueInstallments?.overdueInstallments && overdueInstallments.overdueInstallments.length > 1
  )

  if (isLoading || !currentInstallment) return <LoanDetailsCurrentInstallmentStatusLoader />

  const dateText = `${formatDate(currentInstallment.startDate)} a ${formatDate(currentInstallment.dueDate)}`

  const immutableStatus = ['Overdue', 'Settled', 'Future']
  const status =
    currentInstallment?.isDueSoon && !immutableStatus.includes(currentInstallment.status)
      ? 'DueSoon'
      : currentInstallment.status

  const isCardClickable = !hasMultipleOverdueInstallments && status !== 'Future'

  const overdueTotalAmount =
    Math.abs(overdueInstallments?.penaltyFeeAmount || 0) +
    Math.abs(overdueInstallments?.penaltyInterestAmount || 0) +
    Math.abs(overdueInstallments?.latePaymentInterestAmount || 0)

  return (
    <CustomCard
      style={isCardClickable ? { cursor: 'pointer' } : {}}
      onClick={() => {
        if (isCardClickable) {
          analitica.events.loanDetails.currentInstallmentCardClick()
          BlackBird.travelTo({
            pathname: '/credito/emprestimo/${loan}/parcela/${installment}',
            urlParams: { loan: loanId, installment: currentInstallment.installmentNumber }
          })
        }
      }}
    >
      <BorderHeader
        verticalAlignment="center"
        slotEnd={
          <Badge color={statusColorMap[status].color} variant={statusColorMap[status].variant}>
            {statusColorMap[status].text}
          </Badge>
        }
      >
        <Flex height="1.5rem" alignItems="center">
          <Choose>
            <Choose.When condition={Boolean(overdueInstallments?.daysOverdue) && isOverdue}>
              <Overline color="neutral">Em atraso há {overdueInstallments?.daysOverdue} dias</Overline>
            </Choose.When>
            <Choose.When condition={Boolean(dateText)}>
              <Overline color="neutral">{dateText}</Overline>
            </Choose.When>
          </Choose>
        </Flex>
      </BorderHeader>

      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text size="small">
              <Choose>
                <Choose.When condition={hasMultipleOverdueInstallments}>
                  {numberOfOverdueInstallments} parcelas em atraso
                </Choose.When>
                <Choose.When
                  condition={currentInstallment.installmentNumber !== undefined && numberOfInstallments !== undefined}
                >
                  Parcela {currentInstallment.installmentNumber} de {numberOfInstallments}
                </Choose.When>
              </Choose>
            </Text>
            <Box>
              <Choose>
                <Choose.When condition={currentInstallment.status === 'Settled'}>
                  <Heading weight="bold">Parabéns!</Heading>
                  <Heading weight="bold">A parcela foi quitada.</Heading>
                </Choose.When>

                <Choose.When condition={hasMultipleOverdueInstallments}>
                  <Box color="danger">
                    <Heading size="xLarge" weight="bold" inheritColor>
                      <Money amount={overdueInstallments?.totalPresentBalance || 0} />
                    </Heading>
                    <Description color="neutralLow">Valor total em atraso</Description>
                  </Box>
                </Choose.When>

                <Choose.When condition={isOverdue}>
                  <Box color="danger">
                    <Heading size="xLarge" weight="bold" inheritColor>
                      <Money amount={currentInstallment.futureBalance || 0} />
                    </Heading>
                    <Description color="neutralLow">Valor total em atraso</Description>
                  </Box>
                </Choose.When>

                <Choose.Otherwise>
                  <Heading size="xLarge" weight="bold">
                    <Money amount={currentInstallment.futureBalance || 0} />
                  </Heading>
                  <Description color="neutralLow">Restante para quitar essa parcela</Description>
                </Choose.Otherwise>
              </Choose>
            </Box>
          </Box>
          <If condition={isCardClickable}>
            <Icon use="chevron-right-outline" data-testid="chevron-click-indicator" size="large" />
          </If>
        </Flex>
      </CardBody>

      <FooterGrid>
        <If condition={!hasMultipleOverdueInstallments}>
          <Flex flexDirection="column" justifyContent="space-between">
            <If condition={!isZeroTaxRenegotiation}>
              <Flex alignItems="center">
                <Box mr="2px">
                  <Description color="neutralLow">Juros economizados</Description>
                </Box>
                <LoanDetailsCustomTooltip
                  text={
                    'Com a retenção diária, você economiza o valor dos juros do dia, mesmo se não antecipar pagamentos. O valor desses juros economizados são usados no pagamento da sua parcela atual e ajudam a acelerar sua quitação.'
                  }
                />
              </Flex>

              <Text weight="bold" inheritColor>
                <Money amount={Math.abs(currentInstallment.interestEconomyAmount || 0)} />
              </Text>
            </If>
          </Flex>
        </If>

        <Box height="100%">
          <If condition={Boolean(overdueInstallments?.latePaymentInterestAmount)}>
            <Flex flexDirection="column" justifyContent="space-between" height="100%">
              <Description color="neutralLow">Cobranças por atraso</Description>

              <Box color="danger">
                <Text weight="bold" inheritColor>
                  <Money amount={overdueTotalAmount} />
                </Text>
              </Box>
            </Flex>
          </If>
        </Box>

        <If condition={!hasMultipleOverdueInstallments}>
          <Flex flexDirection="column" justifyContent="space-between">
            <Description color="neutralLow">Total quitado</Description>

            <Text weight="bold" inheritColor>
              <Money amount={Math.abs(currentInstallment.paidAmount || 0)} />
            </Text>
          </Flex>
        </If>

        <If condition={!hasMultipleOverdueInstallments}>
          <Flex flexDirection="column" justifyContent="space-between">
            <Description color="neutralLow">Valor da parcela</Description>

            <Text weight="bold" inheritColor>
              <Money amount={Math.abs(currentInstallment.initialAmount || 0)} />
            </Text>
          </Flex>
        </If>
      </FooterGrid>
    </CustomCard>
  )
}
