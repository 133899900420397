import { create } from 'zustand'

type LoanConcessionModalProps = {
  offerId?: string
  proposalId?: string
}

interface Props {
  loanConcessionModalIsOpen: boolean
  loanConcessionModalProps?: LoanConcessionModalProps
  toggleLoanConcessionModal: (isOpen?: boolean) => void
  setLoanConcessionModalProps: ({ loanConcessionModalProps }: any) => void
}

export const useLoanHomeLoanConcessionDialog = create<Props>((set, get) => {
  return {
    loanConcessionModalIsOpen: false,
    toggleLoanConcessionModal: isOpen =>
      set(() => ({ loanConcessionModalIsOpen: isOpen !== undefined ? isOpen : !get().loanConcessionModalIsOpen })),
    loanConcessionModalProps: undefined,
    setLoanConcessionModalProps: loanConcessionModalProps => set(() => ({ loanConcessionModalProps }))
  }
})
