import { useState } from 'react'

import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { getProposalById, getProposalByIdV2 } from '../../shared/services/loans'
import { useLoanConcessionContext } from '../context/loan-concession-context'
import { type NegotiationsProposal } from '../entities'

type useCheckProposalUpdateTimeProps = {
  proposalId: string
  lastUpdateDate?: string
  onSuccess: () => void
  onError: () => void
  offerType?: 'LoanConcession' | null
}

export function useCheckProposalUpdateTime(
  { proposalId, lastUpdateDate, onSuccess, onError, offerType = null }: useCheckProposalUpdateTimeProps,
  queryOptions?: UseQueryRequestOptions<NegotiationsProposal>
) {
  const [stopFetching, setStopFeching] = useState(false)
  const [count, setcount] = useState(0)
  const { saveNegotiationsProposalData } = useLoanConcessionContext()

  return useQueryRequest<NegotiationsProposal>(
    ['proposalPolling'],
    () => {
      if (offerType === 'LoanConcession') {
        return getProposalById(proposalId)
      }
      return getProposalByIdV2(proposalId)
    },
    {
      onSettled: (response: any, error) => {
        if (error || count > 4) {
          setStopFeching(true)
          return onError()
        }
        const { data } = response
        const apiLastUpdateDate = data?.customer?.lastUpdateDate

        if (apiLastUpdateDate !== lastUpdateDate) {
          setStopFeching(true)
          saveNegotiationsProposalData(data)
          return onSuccess()
        }

        setcount(current => current + 1)
      },
      ...queryOptions,
      refetchInterval: stopFetching ? false : 3000,
      refetchIntervalInBackground: true,
      retry: 3
    }
  )
}
