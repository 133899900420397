import { type SpotOfferPaymentMethod } from '@credit-web/loans'
import { analiticaInstance } from 'shared/analitica'

export enum ProductEnum {
  LOAN = 'LoanConcession',
  REVOLVING = 'RevolvingCredit'
}

export enum ActionEnum {
  COPY_CODE = 'copiar código',
  SAVE_PAYMENT_SLIP = 'baixar boleto',
  COPY_QR_CODE = 'copiar qr code',
  SHARE_QR_CODE = 'compartilhar qr code',
  COPY = 'copiar'
}
interface ErrorSpotOfferProps {
  product: string
  spotOfferStatus: string
  paymentMethodStatus: string
  paymentType: SpotOfferPaymentMethod
  proposalId: string
}

type EventCommonProps = {
  paymentType?: SpotOfferPaymentMethod
  productType?: ProductEnum | string //TODO: Remove string and use ProductEnum in all assertions.
}

const defaultProduct = ProductEnum.LOAN

export default analiticaInstance.eventFactory('Spot Offer', {
  flow: {
    index: ({ productType = defaultProduct }: EventCommonProps) => ({
      label: 'credit - spot offers view',
      metadata: { product: productType }
    }),
    /**
     * @analitica The user saw spot offer success view
     */
    successView: ({ paymentType, productType = defaultProduct }: EventCommonProps) => ({
      label: 'credit - spot offer success view',
      metadata: { paymentMethod: paymentType?.toLowerCase(), product: productType, view: true }
    }),
    /**
     * @analitica click on the spot offer type
     */
    selectType: ({ type, productType = defaultProduct }: { type: string; productType?: ProductEnum }) => ({
      label: 'credit - spot offers type',
      metadata: { type, product: productType }
    }),
    /**
     * @analitica spot offer send payment to email
     */
    email: ({ paymentType, productType = defaultProduct }: EventCommonProps) => ({
      label: 'credit - spot offer email',
      metadata: { paymentMethod: paymentType?.toLowerCase(), product: productType }
    }),
    /**
     * @analitica spot offer set payment method
     */
    setPaymentType: ({ paymentType, productType }: EventCommonProps) => ({
      label: 'credit - payment type selected',
      metadata: { 'payment type': paymentType?.toLowerCase(), product: productType }
    }),
    /**
     * @analitica spot offer generate with success
     */
    success: ({ paymentType, productType = defaultProduct }: EventCommonProps) => ({
      label: 'credit - spot offers success',
      metadata: { paymentMethod: paymentType?.toLowerCase(), product: productType }
    }),
    /**
     * @analitica loading spot offer
     */
    loading: ({ paymentType, productType = defaultProduct }: EventCommonProps) => ({
      label: 'credit - spot offers loading',
      metadata: { paymentMethod: paymentType?.toLowerCase(), product: productType }
    }),
    /**
     * @analitica spot offer service error
     */
    errorSpotOffer: (props: ErrorSpotOfferProps) => ({
      label: 'credit - service error',
      metadata: { ...props },
      error: { ...props }
    }),
    /**
     * @analitica spot offer error view
     */
    errorView: (origin: 'quitação com desconto' | 'pagamento pontual') => ({
      label: 'credit - service error',
      metadata: { origin }
    })
  },
  button: {
    /**
     * @analitica success spot offer clicked actions
     */
    successClicked: ({
      action,
      paymentType,
      productType = defaultProduct
    }: {
      action: ActionEnum | string //TODO: Remove string and use ActiontEnum in all assertions.
      paymentType: SpotOfferPaymentMethod
      productType?: ProductEnum
    }) => ({
      label: 'credit - spot offer success',
      metadata: {
        action,
        paymentMethod: paymentType?.toLowerCase(),
        product: productType
      }
    }),
    /**
     * @analitica spot offer requested
     */
    requested: (status: string, productType: ProductEnum = defaultProduct) => ({
      label: 'credit - spot offers requested',
      metadata: { status, product: productType }
    }),
    /**
     * @analitica users clicked in continue button
     */
    continue: ({ productType = defaultProduct }: { productType: ProductEnum }) => ({
      label: 'credit - spot offers accepted',
      metadata: { product: productType }
    })
  }
})
