import { analiticaInstance } from 'shared/analitica'

type loanStatus =
  | 'em análise'
  | 'aprovado'
  | 'não aprovado'
  | 'parcela aberta'
  | 'parcela quitada'
  | 'em atraso'
  | 'retenção pausada'
  | 'vence em breve'
  | 'futura'

export default analiticaInstance.eventFactory('Credit follow-up banners', {
  oportunityBanners: {
    /**
     * @analitica The user saw the concession offer banner
     */
    viewOffer: () => ({
      label: 'credit - banner offer view'
    }),
    /**
     * @analitica The user clicks on the concession offer banner
     */
    clickOffer: () => ({
      label: 'credit - banner offer clicked'
    })
  },
  loanBanners: {
    /**
     * @analitica The user saw the active loan banner
     */
    view: (loanId: string, loanAmount: number, status: loanStatus) => {
      return {
        label: 'credit - home banner loan view',
        metadata: {
          'loan id': loanId,
          'loan amount': loanAmount,
          status
        }
      }
    },
    /**
     * @analitica The user clicks on the active loan banner
     */
    click: (loanId: string) => ({
      label: 'credit - home banner loan clicked',
      metadata: {
        'loan id': loanId
      }
    })
  }
})
