import { type CreditPaging } from '~/domains/credit/shared/entities'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type CreditOffer } from '../../shared/entities'
import { useOffer } from '../../shared/hooks/use-credit-offers'
import { fetchProposalsByDocument, getProposalById } from '../../shared/services/loans'
import { type NegotiationsProposal } from '../entities'

export function useProposalById(proposalId: string, queryOptions?: UseQueryRequestOptions<NegotiationsProposal>) {
  const { data: response, ...restQuery } = useQueryRequest<NegotiationsProposal>(
    ['useGetProposalById', proposalId],
    () => getProposalById(proposalId),
    { ...queryOptions, enabled: Boolean(proposalId) }
  )

  const isProposalValid = ['Processing', 'Available'].includes(response?.data?.status || '')

  if (isProposalValid) return { data: response?.data, ...restQuery }
  return { data: undefined, ...restQuery }
}

export function useNegotiationsProposal(
  { offerId, document }: { offerId: string; document: string },
  queryOptions?: UseQueryRequestOptions<CreditPaging<NegotiationsProposal[]>>
) {
  const { data: response, ...restQuery } = useQueryRequest<CreditPaging<NegotiationsProposal[]>>(
    ['useNegotiationsProposal', offerId],
    () => fetchProposalsByDocument({ document, offerId }),
    {
      enabled: Boolean(offerId),
      ...queryOptions
    }
  )
  if (!response?.data?.items) {
    return {
      ...restQuery,
      data: {
        proposalAvailable: undefined,
        proposalProcessing: undefined
      }
    }
  }

  const sortedProposals = response?.data?.items
    ? [...response.data.items].sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())
    : []
  const proposalProcessing = sortedProposals?.find(({ status }) => status === 'Processing')
  const proposalAvailable = sortedProposals?.find(({ status }) => status === 'Available')
  return {
    ...restQuery,
    data: {
      proposalAvailable,
      proposalProcessing
    }
  }
}

export type OfferType = 'LoanConcession' | 'RevolvingCredit'

type UseLoanConcessionFlowProps = {
  proposalId?: string
  offerId?: string
  offerType?: OfferType
  document?: string
}

export function useLoanConcessionFlow(
  { proposalId, offerId, offerType = 'LoanConcession', document = '' }: UseLoanConcessionFlowProps,
  queryOptions?: UseQueryRequestOptions<CreditOffer>
) {
  const {
    data: creditOffer,
    isError: offerError,
    isLoading: creditOfferLoading,
    isFetching: fetchingOffer
  } = useOffer({ offerId: offerId ?? '', type: offerType, document }, queryOptions)

  const {
    data: dataProposal,
    isError: proposalError,
    isLoading: proposalLoading,
    isFetching: isfetchingProposal,
    refetch: refetchProposal
  } = useNegotiationsProposal(
    {
      document,
      offerId: creditOffer?.offer?.id || ''
    },
    { enabled: Boolean(!proposalId) && Boolean(creditOffer?.offer?.id) }
  )

  const { data: proposal, isLoading: isLoadingProposal } = useProposalById(proposalId || '', {
    enabled: Boolean(proposalId)
  })

  const isProposalProcessing = proposal?.status === 'Processing'

  const refetchAll = async () => {
    await refetchProposal()
  }

  const { proposalAvailable, proposalProcessing } = dataProposal

  return {
    data: {
      creditOffer: creditOffer,
      proposalProcessing: isProposalProcessing ? proposal : proposalProcessing,
      proposalAvailable: !isProposalProcessing ? proposal || proposalAvailable : proposalAvailable
    },
    isError: offerError || proposalError,
    isLoading: [creditOfferLoading, proposalLoading, fetchingOffer, isfetchingProposal, isLoadingProposal].some(val =>
      Boolean(val)
    ),
    refetch: refetchAll
  }
}
