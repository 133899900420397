import { type RenegotiationProposal } from '../../loan-concession/entities'
import { type CreditOffer } from '../../shared/entities'
import { type RenegotiationSimulation } from '../entities'

export const loanId = 'jqtzbddb6ydn2q78dd6pk3y86'
export const document = '44873685000156'

export const renegotiationOffer = {
  id: 'jqux76ksa59q7hr26s7psxsch',
  type: 'Renegotiation',
  client: { document, documentType: 'CNPJ' },
  roles: [
    {
      userType: 'Customer',
      maxRiskLevel: 3,
      conditions: [
        {
          minCreditLimit: 100,
          maxCreditLimit: 10546.875,
          allowedTerms: [6, 7, 8]
        },
        {
          minCreditLimit: 10546.875,
          maxCreditLimit: 11546.875,
          allowedTerms: [7, 8]
        },
        {
          minCreditLimit: 11546.875,
          maxCreditLimit: 12546.875,
          allowedTerms: [8]
        }
      ]
    }
  ],
  creationDate: '2022-11-07T15:32:57',
  lastUpdateDate: '2022-11-07T15:32:59'
}

export const renegotiationProposalMock = {
  id: 'jqux776m32vnc66vrcs11bhf3',
  offerId: 'jqux76ksa59q7hr26s7psxsch',
  type: 'Renegotiation',
  status: 'Available',
  origin: {
    createdBy: 'gleidison.nascimento@stone.com.br',
    userType: 'Customer'
  },
  formalization: {
    type: 'AmendmentToCreditBankingAgreement'
  },
  expirationDate: '2023-05-26',
  customer: {
    mainPartner: {
      externalId: 'c1d20a40-78cb-43fe-a45a-4d8919fda18f',
      name: 'Nome do CPF 50202106098',
      document: '50202106098',
      documentType: 'CPF',
      maritalStatus: 'StableUnion',
      email: 'mvrmoreira+1@stone.com.br',
      nationality: 'Brasil',
      address: {
        city: 'Rio de Janeiro',
        neighborhood: 'Centro',
        postalCode: '20021290',
        state: 'RJ',
        street: 'Rua do Passeio',
        streetNumber: '48',
        complement: 'teatro'
      },
      bankAccount: {
        externalId: '7096b69c-e9e0-451f-b3b3-104496f31379',
        accountNumber: '1897537',
        branchNumber: '1'
      },
      lastUpdateDate: '2023-03-16T19:52:00Z'
    },
    externalId: '379bb077-a0fa-424a-985d-ee4d187caa82',
    name: 'FERNANDO DA SILVA GANGA 15720868747',
    document,
    documentType: 'CNPJ',
    email: 'mvrmoreira+1@stone.com.br',
    address: {
      city: 'Rio de Janeiro',
      neighborhood: 'Centro',
      postalCode: '20021290',
      state: 'RJ',
      street: 'Rua do Passeio',
      streetNumber: '48',
      complement: 'teatro'
    },
    bankAccount: {
      externalId: '875b320d-fae9-4702-8645-d140b719567c',
      accountNumber: '223354',
      branchNumber: '1'
    },
    lastUpdateDate: '2023-03-16T19:52:00Z'
  },
  renegotiation: {
    securities: [
      {
        externalReference: loanId,
        installmentPlan: {
          amount: 54.7131273237,
          count: 3
        },
        rate: {
          taxAmount: 152.21,
          annualInterestRate: 0.5992,
          annualTotalEffectiveCostRate: 0.6617,
          retentionRate: 0.1,
          interestRate: 0.0399
        },
        summary: {
          currentDebtAmount: 180.152579626,
          dueDate: '2023-07-17'
        }
      }
    ],
    amount: 180.152579626,
    dueDate: '2024-04-26',
    rate: {
      taxAmount: 0,
      annualInterestRate: 0.5991858503,
      annualTotalEffectiveCostRate: 0.5991858503,
      retentionRate: 0.01,
      interestRate: 0.0399,
      iofRate: 0
    },
    installmentPlan: {
      amount: 20.5531545218,
      count: 11,
      totalSum: 226.0846997398,
      paymentReferenceDate: '2023-05-26'
    }
  },
  creationDate: '2023-05-25T15:05:58Z',
  lastUpdateDate: '2023-05-25T15:05:58Z'
} as RenegotiationProposal

export const emptyRenegotiationContextMock = {
  contractId: '',
  saveContractId: () => {}
}

export const renegotiationConditionsMock = {
  renegotiationSummary: {
    status: 'analysis',
    loanRenegotiationContractId: 'string'
  },
  currentSummary: {
    currentInstallment: {
      id: 'jqx3hx8vvwrt1rsydvbef29qm',
      installmentNumber: 1,
      startDate: '2023-08-21',
      dueDate: '2023-09-22',
      daysUntilDueDate: 3,
      isDueSoon: true,
      daysOverdue: 0,
      isCollectionEnabled: true,
      isOverdue: false,
      status: 'Overdue',
      paidAmount: 0,
      initialAmount: 1688.03,
      futureBalance: 1688.03,
      presentBalance: 1683.88,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalInterestAndFeeSumAmount: 0,
      totalDebtAmount: 32.94
    },
    overdueInstallmentsSummary: {
      overdueInstallments: [],
      totalPresentBalance: 83,
      daysOverdue: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalInterestAndFeeSumAmount: 0
    },
    initialInstallmentAmount: 413.8764316497,
    futureBalance: 1041.6292949491,
    currentBalance: 1041.6292949491,
    taxAmount: 6.5610148428,
    numberOfInstallments: 4,
    retentionRate: 0.1,
    monthlyInterestRate: 0.0399,
    yearlyInterestRate: 0.5992,
    yearlyTotalEffectiveCostRate: 0.6617,
    dueDate: '2024-02-28T00:00:00Z'
  },
  previousSummary: {
    initialInstallmentAmount: 313.8764316497,
    futureBalance: 941.6292949491,
    taxAmount: 6.5610148428,
    numberOfInstallments: 4,
    retentionRate: 0.1,
    monthlyInterestRate: 0.0399,
    yearlyInterestRate: 0.5992,
    yearlyTotalEffectiveCostRate: 0.6617,
    dueDate: '2023-02-28T00:00:00Z'
  }
}

export const DiscountSettlementSimulation = {
  type: 'DiscountSettlement',
  securities: [
    {
      type: 'Credit',
      externalReference: 'jqu6mxjd9xjfcrzejk9rhgjr4',
      isOverdue: true,
      installmentNumbers: [2, 3],
      summary: {
        totalPaid: 4726.0156,
        currentDebtAmount: 9934.1537339866,
        numberOfOpenInstallments: 2,
        daysOverdue: 53
      },
      lastUpdateDate: '2023-07-14T14:26:37Z'
    }
  ],
  installmentNumbers: [2, 3],
  paymentMethod: 'Boleto',
  negotiationDebtAmount: 9934.15,
  discountAmount: 993.42,
  discountOfferRate: 0.1,
  discountEffectiveRate: 0.1,
  amount: 8940.73,
  dueDate: '2023-07-18'
} as RenegotiationSimulation

export const DiscountSettlementProposal: RenegotiationProposal = {
  id: 'jqux776m32vnc66vrcs11bhf3',
  offerId: 'jqux76ksa59q7hr26s7psxsch',
  type: 'DiscountSettlement',
  status: 'Available',
  origin: {
    createdBy: 'gleidison.nascimento@stone.com.br',
    userType: 'Customer'
  },
  formalization: { type: 'AmendmentToCreditBankingAgreement' },
  expirationDate: '2023-05-26',
  customer: {
    externalId: '6ac04a97-bc65-498a-a593-7c97b5559b57',
    name: 'WLADIMIR DIAS RODRIGUES',
    document,
    documentType: 'CNPJ',
    email: 'mvrmoreira+1@stone.com.br',
    bankAccount: {
      externalId: 'c02321ae-71e9-4006-85e5-d59a6fe392d5',
      accountNumber: '124883',
      branchNumber: '1'
    },
    address: {
      city: 'Rio de Janeiro',
      neighborhood: 'Centro',
      postalCode: '20021290',
      state: 'RJ',
      street: 'Rua do Passeio',
      streetNumber: '48',
      complement: 'teatro'
    },
    mainPartner: {
      externalId: 'c1d20a40-78cb-43fe-a45a-4d8919fda18f',
      name: 'Nome do CPF 50202106098',
      document: '50202106098',
      documentType: 'CPF',
      maritalStatus: 'StableUnion',
      email: 'mvrmoreira+1@stone.com.br',
      nationality: 'Brasil',
      address: {
        city: 'Rio de Janeiro',
        neighborhood: 'Centro',
        postalCode: '20021290',
        state: 'RJ',
        street: 'Rua do Passeio',
        streetNumber: '48',
        complement: 'teatro'
      },
      bankAccount: {
        externalId: '7096b69c-e9e0-451f-b3b3-104496f31379',
        accountNumber: '1897537',
        branchNumber: '1'
      },
      lastUpdateDate: '2023-03-16T19:52:00Z'
    },
    lastUpdateDate: '2023-05-25T15:05:58Z'
  },
  renegotiation: {
    securities: [
      {
        externalReference: 'jqtzbddb6ydn2q78dd6pk3y86',
        installmentPlan: { amount: 54.7131273237, count: 3 },
        rate: {
          taxAmount: 152.21,
          annualInterestRate: 0.5992,
          annualTotalEffectiveCostRate: 0.6617,
          retentionRate: 0.1,
          interestRate: 0.0399
        },
        summary: {
          currentDebtAmount: 180.152579626,
          dueDate: '2023-07-17'
        }
      }
    ],
    amount: 180.152579626,
    dueDate: '2024-04-26',
    rate: {
      taxAmount: 0,
      annualInterestRate: 0.5991858503,
      annualTotalEffectiveCostRate: 0.5991858503,
      retentionRate: 0.01,
      interestRate: 0.0399,
      iofRate: 0
    },
    installmentPlan: {
      amount: 20.5531545218,
      count: 11,
      totalSum: 226.0846997398,
      paymentReferenceDate: '2023-05-26'
    }
  },
  discountSettlement: {
    securities: [
      {
        type: 'Credit',
        externalReference: 'jqt1ss9yjj6pdq7wn7t39ctfw',
        isOverdue: false,
        installmentNumbers: [1, 2, 3, 4],
        lastUpdateDate: '2023-05-31T17:37:51Z'
      }
    ],
    paymentMethod: {
      type: 'Boleto',
      amount: 1462.38,
      dueDate: '2023-06-02T00:00:00Z',
      emailNotification: 'teste@stone.com.br'
    },
    installmentNumbers: [1, 2, 3, 4],
    negotiationDebtAmount: 2437.3,
    discountOfferRate: 0.4,
    discountEffectiveRate: 0.4,
    discountAmount: 974.92
  },
  creationDate: '2023-05-25T15:05:58Z',
  lastUpdateDate: '2023-05-25T15:05:58Z'
}

export const DiscountSettlementOffer = {
  id: 'jqusgch3uy7486mtqga7jd99q',
  type: 'DiscountSettlement',
  provider: {
    providerId: 'CreditAnalysis',
    referenceId: '6f54f6f546',
    referenceDate: '2023-05-19T00:00:00Z'
  },
  customer: { document, documentType: 'CNPJ' },
  approval: {
    status: 'Approved',
    isAutoApprove: true,
    approvedAt: '2023-05-19T15:08:17Z'
  },
  negotiation: { status: 'Available' },
  expirationDate: '2023-06-18',
  discountSettlement: {
    securities: [
      {
        type: 'Credit',
        externalReference: 'jqt2j2dmde6zfhnbkke1pd114',
        isOverdue: true,
        installmentNumbers: [1, 2, 3, 4, 5, 6],
        lastUpdateDate: '2023-05-19T15:08:17Z'
      }
    ],
    condition: {
      type: 'DiscountAmount',
      calculationDirection: 'Up',
      range: { min: 6, max: 12 },
      riskLevels: [
        { level: 1, proportionalPercentage: 30 },
        { level: 2, proportionalPercentage: 79 },
        { level: 3, proportionalPercentage: 100 }
      ]
    }
  },
  roles: [
    {
      userType: 'Customer',
      maxRiskLevel: 1,
      conditions: [{ type: 'DiscountAmount', min: 6, max: 7.8 }]
    },
    {
      userType: 'Operator',
      maxRiskLevel: 2,
      conditions: [{ type: 'DiscountAmount', min: 6, max: 10.74 }]
    },
    {
      userType: 'Leadership',
      maxRiskLevel: 3,
      conditions: [{ type: 'DiscountAmount', min: 6, max: 12 }]
    }
  ],
  creationDate: '2023-05-19T15:08:17Z',
  createdBy: 'service-account-runscope@placeholder.org',
  lastUpdateDate: '2023-05-19T15:08:17Z'
} as CreditOffer

export const DiscountSettlementOfferDiscountRate = {
  ...DiscountSettlementOffer,
  discountSettlement: {
    ...DiscountSettlementOffer.discountSettlement,
    condition: {
      ...DiscountSettlementOffer?.discountSettlement?.condition,
      type: 'DiscountRate'
    }
  },
  roles: [
    {
      userType: 'Customer',
      maxRiskLevel: 1,
      conditions: [{ type: 'DiscountRate', min: 6, max: 7.8 }]
    },
    {
      userType: 'Operator',
      maxRiskLevel: 2,
      conditions: [{ type: 'DiscountRate', min: 6, max: 10.74 }]
    },
    {
      userType: 'Leadership',
      maxRiskLevel: 3,
      conditions: [{ type: 'DiscountRate', min: 6, max: 12 }]
    }
  ]
} as CreditOffer

export const emptyDiscountSettlementContextMock = {
  proposal: null,
  saveProposal: () => {},
  resetProposal: () => {},
  offer: null,
  saveOffer: () => {},
  simulation: null,
  saveSimulation: () => {},
  resetSimulation: () => {},
  installments: null,
  saveInstallments: () => {},
  resetInstallments: () => {},
  paymentMethod: null,
  savePaymentMethod: () => {},
  resetPaymentMethod: () => {},
  contractId: '',
  saveContractId: () => {}
}

export const discountSettlementRateAdminProposalMock = {
  id: 'jqv2zr1u7q5p4p6n8phfmkykk',
  offerId: 'jqv2zp82mc6rd8pgcfq61ne3s',
  type: 'DiscountSettlement',
  status: 'Available',
  origin: {
    createdBy: 'teste@stone.com.br',
    userType: 'Operator'
  },
  formalization: {
    type: 'EletronicAcceptance'
  },
  expirationDate: '2023-06-01',
  customer: {},
  renegotiation: {
    securities: [
      {
        externalReference: 'jqt1ss9yjj6pdq7wn7t39ctfw',
        installmentPlan: {
          amount: 382,
          count: 12
        },
        rate: {
          taxAmount: 0.15,
          annualInterestRate: 2.5,
          annualTotalEffectiveCostRate: 2.8,
          retentionRate: 0.89,
          interestRate: 0.2
        },
        summary: {
          currentDebtAmount: 321,
          dueDate: '2023-06-01'
        }
      }
    ],
    dueDate: '2023-06-01',
    rate: {
      taxAmount: 0.15,
      annualInterestRate: 2.5,
      annualTotalEffectiveCostRate: 2.8,
      retentionRate: 0.89,
      interestRate: 0.2
    },
    installmentPlan: {
      amount: 382,
      count: 12,
      totalSum: 4584
    }
  },
  discountSettlement: {
    securities: [
      {
        type: 'Credit',
        externalReference: 'jqt1ss9yjj6pdq7wn7t39ctfw',
        isOverdue: false,
        installmentNumbers: [1, 2, 3, 4],
        lastUpdateDate: '2023-05-31T17:37:51Z'
      }
    ],
    paymentMethod: {
      type: 'Boleto',
      amount: 1462.38,
      dueDate: '2023-06-02T00:00:00Z',
      emailNotification: 'teste@stone.com.br'
    },
    installmentNumbers: [1, 2, 3, 4],
    negotiationDebtAmount: 2437.3,
    discountOfferRate: 0.4,
    discountEffectiveRate: 0.4,
    discountAmount: 974.92
  },
  creationDate: '2023-05-31T17:37:51Z',
  lastUpdateDate: '2023-05-31T17:37:51Z'
}

export const discountSettlementInstallmentsMock = {
  items: [
    {
      id: 'jqtuknzmye4fd8z4q7e8n4te3',
      installmentNumber: 1,
      startDate: '2023-04-12',
      dueDate: '2023-05-19',
      daysUntilDueDate: 0,
      isDueSoon: true,
      daysOverdue: 32,
      settlementDate: '2023-05-25',
      isCollectionEnabled: false,
      isOverdue: true,
      status: 'Settled',
      paidAmount: -181.22,
      initialAmount: 180.51,
      futureBalance: 0,
      presentBalance: 0,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0.71,
      totalDebtAmount: 181.22
    },
    {
      id: 'jqtuknzn6e1c76g4ztvhdvsdf',
      installmentNumber: 2,
      startDate: '2023-05-20',
      dueDate: '2023-06-19',
      daysUntilDueDate: 0,
      isDueSoon: true,
      daysOverdue: 1,
      settlementDate: '2023-05-25',
      isCollectionEnabled: false,
      isOverdue: false,
      status: 'Settled',
      paidAmount: -177.95,
      initialAmount: 180.51,
      futureBalance: 0,
      presentBalance: 0,
      interestEconomyAmount: -2.56,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 180.51
    },
    {
      id: 'jqtuknzndmjpa68gyj9jdawum',
      installmentNumber: 3,
      startDate: '2023-06-20',
      dueDate: '2023-07-19',
      daysUntilDueDate: 28,
      isDueSoon: false,
      daysOverdue: 0,
      settlementDate: '2023-05-25',
      isCollectionEnabled: false,
      isOverdue: false,
      status: 'Settled',
      paidAmount: -174.67,
      initialAmount: 180.51,
      futureBalance: 0,
      presentBalance: 0,
      interestEconomyAmount: -5.83,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 180.51
    },
    {
      id: 'jqtuknznsd1nd8euc8g7x4vyd',
      installmentNumber: 4,
      startDate: '2023-07-20',
      dueDate: '2023-08-21',
      daysUntilDueDate: 61,
      isDueSoon: false,
      daysOverdue: 0,
      isCollectionEnabled: false,
      isOverdue: false,
      status: 'Future',
      paidAmount: -121.21,
      initialAmount: 180.51,
      futureBalance: 52.76,
      presentBalance: 50.84,
      interestEconomyAmount: -6.54,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 180.51
    },
    {
      id: 'jqtuknzp12gh1wh6y28tmq7um',
      installmentNumber: 5,
      startDate: '2023-08-20',
      dueDate: '2023-09-19',
      daysUntilDueDate: 90,
      isDueSoon: false,
      daysOverdue: 0,
      isCollectionEnabled: false,
      isOverdue: false,
      status: 'Future',
      paidAmount: 0,
      initialAmount: 180.51,
      futureBalance: 180.51,
      presentBalance: 170.73,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 180.51
    },
    {
      id: 'jqtuknzp7vuv54ncdf1jjv3b7',
      installmentNumber: 6,
      startDate: '2023-09-20',
      dueDate: '2023-10-19',
      daysUntilDueDate: 120,
      isDueSoon: false,
      daysOverdue: 0,
      isCollectionEnabled: false,
      isOverdue: false,
      status: 'Future',
      paidAmount: 0,
      initialAmount: 180.51,
      futureBalance: 180.51,
      presentBalance: 167.48,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 180.51
    }
  ]
}

export const renegotiationSimulation: RenegotiationSimulation = {
  type: 'DiscountSettlement',
  securities: [
    {
      type: 'Security',
      externalReference: 'ABC123',
      isOverdue: true,
      installmentNumbers: [1, 2, 3],
      summary: {
        totalPaid: 1000,
        currentDebtAmount: 5000,
        numberOfOpenInstallments: 3,
        daysOverdue: 30
      },
      lastUpdateDate: '2023-08-16'
    }
  ],
  installmentNumbers: [4, 5, 6],
  paymentMethod: 'Boleto',
  negotiationDebtAmount: 4000,
  discountAmount: 1000,
  discountOfferRate: 0.2,
  discountEffectiveRate: 0.18,
  amount: 3000,
  dueDate: '2023-09-01'
}
