import { Icon, Text } from '@dlpco/ginga-stone'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { Flex } from '~/domains/platform/design-system'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { LoanConcessionSteps } from '../entities'

export interface LoanConcessionBreadcrumbProps {
  arrowLeftIconClickFn: () => void
  step: LoanConcessionSteps
}

export function LoanConcessionBreadcrumb({ arrowLeftIconClickFn, step }: LoanConcessionBreadcrumbProps) {
  const { isViewSmall } = useViewSize()
  const defaultBreadcrumbs = [
    {
      steps: [],
      title: 'Empréstimo'
    },
    {
      steps: [LoanConcessionSteps.SIMULATION, LoanConcessionSteps.QR_CODE],
      title: 'Simular empréstimo'
    },
    {
      steps: [LoanConcessionSteps.UPDATE_INFORMATION],
      title: 'Contratar empréstimo'
    },
    {
      steps: [LoanConcessionSteps.REVIEW],
      title: 'Pedir empréstimo'
    },
    {
      steps: [LoanConcessionSteps.CONFIRM_PROPOSAL, LoanConcessionSteps.PIN, LoanConcessionSteps.SUCCESS],
      title: 'Confirmar pedido'
    }
  ]

  const insuranceBreadcrumbs = [
    {
      steps: [],
      title: 'Empréstimo'
    },
    {
      steps: [LoanConcessionSteps.SIMULATION],
      title: 'Simular empréstimo'
    },
    {
      steps: [LoanConcessionSteps.UPDATE_INFORMATION],
      title: 'Contratar empréstimo'
    },
    {
      steps: [LoanConcessionSteps.INSURANCE],
      title: 'Proteger empréstimo'
    },
    {
      steps: [LoanConcessionSteps.REVIEW],
      title: 'Pedir empréstimo'
    },
    {
      steps: [LoanConcessionSteps.CONFIRM_PROPOSAL, LoanConcessionSteps.PIN, LoanConcessionSteps.SUCCESS],
      title: 'Confirmar pedido'
    }
  ]
  const targetBreadcrumbs = Heimdall.pass(['credit_insurance_web']) ? insuranceBreadcrumbs : defaultBreadcrumbs
  const breadcrumbs = targetBreadcrumbs.slice(0, targetBreadcrumbs.findIndex(e => e.steps.includes(step)) + 1)

  return (
    <Flex alignContent="center" gap=".5rem">
      <Flex onClick={arrowLeftIconClickFn} alignItems="center" style={{ cursor: 'pointer' }}>
        <Icon use="direction-arrow-left-outline" />
      </Flex>
      {!isViewSmall && (
        <For
          of={breadcrumbs}
          render={(breadcrumb, index) => {
            const { title, steps } = breadcrumb
            const color = steps.includes(step) ? 'neutralHigh' : 'neutral'
            return (
              <Flex key={breadcrumb.title} alignItems="center" gap=".5rem">
                <If condition={index > 0}>
                  <Text color="neutral">/</Text>
                </If>
                <Text color={color}>{title}</Text>
              </Flex>
            )
          }}
        />
      )}
    </Flex>
  )
}
