import { formatDate } from '@credit-web/common/date-utils'
import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
  theme
} from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { goToLoanDetails } from '~/domains/credit/shared/helpers/go-to-loan-details'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'

import { type HistoryItem } from '../entities'

const StyledButton = styled.button<{ showAction: boolean }>`
  border: 0;
  width: 100%;
  background: transparent;
  font-size: xx-small;
  text-align: start;
  padding-left: 12px;
  margin-bottom: 20px;

  &:hover {
    cursor: ${({ showAction }) => (showAction ? 'pointer' : 'not-allowed')};
  }
  &:disabled {
    opacity: 50;
  }
`

type LoanHistoryTableProps = {
  isLoading: boolean
  historyList?: HistoryItem[]
  fullTable?: boolean
  extraRowClickAction?: (rowInfo: HistoryItem) => void
}

const TableRowCustom = styled(TableRow)<{ fullTable: boolean }>`
  td {
    padding-right: ${({ fullTable }) => (fullTable ? theme.light.space.xSmall : '0')};
    padding-bottom: ${({ fullTable }) => (fullTable ? theme.light.space.xSmall : '0')};
    padding-top: 20px;
  }
`

export function LoanHistoryTable({
  historyList,
  isLoading,
  extraRowClickAction = () => {},
  fullTable = true
}: LoanHistoryTableProps) {
  const handleLoanClick = (item: HistoryItem) => {
    extraRowClickAction(item)
    if (item.showAction) {
      goToLoanDetails(item.id)
    }
  }
  return (
    <Table clickableRows>
      <Choose>
        <Choose.When condition={Boolean(historyList?.length)}>
          <If condition={fullTable}>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>Produto</TableHeaderCell>
                <TableHeaderCell>Valor</TableHeaderCell>
                <TableHeaderCell>Situação</TableHeaderCell>
                <TableHeaderCell>Finalizado em</TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
          </If>
          <TableBody>
            <For
              of={historyList || []}
              render={(item: HistoryItem, index: number) => {
                const { id, date, metadata, status, badge, type, showAction } = item

                const statusBadgeColor = status === 'cancelled' ? 'neutral' : 'positive'
                const statusBadgeText = status === 'cancelled' ? 'Cancelado' : 'Quitado'
                const loanHomeProductBadge = type === 'Revolving' ? 'info' : type === 'Loan' ? 'positive' : 'neutral'
                const shouldHideAction = showAction && fullTable ? false : true
                return (
                  <TableRowCustom
                    key={id}
                    uniqueId={index.toString()}
                    hideAction={shouldHideAction}
                    fullTable={fullTable}
                    onRowClick={() => handleLoanClick(item)}
                  >
                    <Choose>
                      <Choose.When condition={fullTable}>
                        <TableCell verticalAlignment="start">
                          <Badge color={loanHomeProductBadge} variant="solid">
                            {badge}
                          </Badge>
                        </TableCell>

                        <TableCell>
                          <Text color="neutral" size="small">
                            {metadata?.title}
                          </Text>
                        </TableCell>

                        <TableCell verticalAlignment="start">
                          <Badge color={statusBadgeColor} variant="light">
                            {statusBadgeText}
                          </Badge>
                        </TableCell>

                        <TableCell>
                          <Text color="neutral" size="small">
                            {formatDate(date)}
                          </Text>
                        </TableCell>
                      </Choose.When>
                      <Choose.When condition={!fullTable}>
                        <div>
                          <TableCell>
                            <Text color="neutralHigh" size="medium" weight="bold">
                              {metadata?.title}
                            </Text>
                            <Text color="neutral" size="small">
                              {metadata?.subtitle}
                            </Text>
                          </TableCell>
                          <StyledButton
                            onClick={() => handleLoanClick(item)}
                            disabled={!showAction}
                            showAction={showAction}
                          >
                            Conferir detalhes
                          </StyledButton>
                        </div>

                        <TableCell verticalAlignment="start">
                          <Badge color={loanHomeProductBadge} variant="solid">
                            {badge}
                          </Badge>
                        </TableCell>
                      </Choose.When>
                    </Choose>
                  </TableRowCustom>
                )
              }}
            />
          </TableBody>
        </Choose.When>
        <Choose.When condition={isLoading}>
          <LoanHistoryLoader />
        </Choose.When>
        <Choose.Otherwise>
          <TableEmptyState heading="">
            <Text color="neutral">Você não possui histórico de crédito</Text>
          </TableEmptyState>
        </Choose.Otherwise>
      </Choose>
    </Table>
  )
}

function LoaderRow() {
  return (
    <TableRow uniqueId="id">
      <TableCell width="4rem" alignment="center">
        <Skeleton width="90%" height="1rem" />
      </TableCell>

      <TableCell>
        <Skeleton width="90%" height="1rem" />
      </TableCell>

      <TableCell>
        <Skeleton width="90%" height="1rem" />
      </TableCell>

      <TableCell>
        <Skeleton width="90%" height="1rem" />
      </TableCell>
    </TableRow>
  )
}

function LoanHistoryLoader() {
  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>Produto</TableHeaderCell>
          <TableHeaderCell>Valor</TableHeaderCell>
          <TableHeaderCell>Situação</TableHeaderCell>
          <TableHeaderCell>Finalizado em</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        <LoaderRow />
        <LoaderRow />
        <LoaderRow />
        <LoaderRow />
      </TableBody>
    </Table>
  )
}
