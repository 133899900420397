import { type DiscountSettlementConditionType, type DocumentType } from '~/domains/credit/shared/entities'

export type SpotOfferPaymentMethod = 'Pix' | 'Boleto'

export interface CreditPage {
  currentPage: number
  pageCount: number
  pageSize: number
  totalItems: number
}

export interface CreditPaging<T> {
  items: T
  paging: CreditPage
}

export interface CreditLine {
  id: string
  borrowerId: string
  financialInstitution: FinancialInstitution
  availableCreditLimit: number
  totalCreditLimit: number
  tpv: number
  interestRate: number
  creationDate: string
  lastUpdateDate: string
  status: string
  minMonthsToExpireContract: number
  maxMonthsToExpireContract: number
  repayment: Repayment
  disbursement: {
    bankAccount: BankAccount
    fundingSource: { name: string }
  }
  warranty: {
    bankAccount: BankAccount
  }
}

export interface FinancialInstitution {
  id: number
  name: string
  numberCode?: string
  ispb?: string
  document?: string
}

export interface BankAccount {
  id: string
  bankNumber: string
  branchNumber: string
  branchDigit: string
  accountNumber: string
  accountDigit: string
  branch?: string
  accountVerificationNumber?: string
  financialInstitution?: FinancialInstitution
  bankName: string
  legalEntity: { name: string }
  accountType: string
}

export interface Repayment {
  method: string
  initialGracePeriod: {
    minimumInDays: number
    maximumInDays: number
  }
}

export interface CreditOffer {
  id: string
  type: string
  customer: {
    document: string
    documentType: DocumentType
  }
  expirationDate?: string
  roles: CreditOfferRoles[]
  creationDate: string
  negotiation: { status: string }
  lastUpdateDate: string
  discountSettlement?: {
    securities: [
      {
        type: string
        externalReference: string
        isOverdue: boolean
        installmentNumbers: number[]
        lastUpdateDate: string
      }
    ]
    condition: {
      type: DiscountSettlementConditionType
      calculationDirection: string
      range: { min: number; max: number }
      riskLevels: { level: number; proportionalPercentage: number }[]
    }
  }
}
export interface CreditOfferRenegotiation {
  id: string
  customer: {
    document: string
    documentType: DocumentType
  }
  type: 'DiscountSettlement' | 'Renegotiation'
  roles: CreditOfferRoles[]
  discountSettlement?: {
    condition: {
      type: DiscountSettlementConditionType
      calculationDirection: string
      range: { min: number; max: number }
      riskLevels: { level: number; proportionalPercentage: number }[]
    }
  }
}

export interface CreditOfferRoles {
  userType: 'Customer' | 'Operator' | 'Leadership'
  maxRiskLevel: number
  conditions: CreditOfferRolesConcessession[] | CreditOfferRolesRenegotiation[] | CreditOfferRolesRevolvingCredit[]
}

export interface CreditOfferRolesConcessession {
  minCreditLimit: number
  maxCreditLimit: number
  allowedTerms: number[]
}
export interface CreditOfferRolesRenegotiation {
  min: number
  max: number
  type: DiscountSettlementConditionType | null
}

export interface CreditOfferRolesRevolvingCredit {
  min: number
  max: number
  type: 'Amount' | 'RetentionRate' | null
}

export type InstallmentStatus = 'Open' | 'Overdue' | 'Settled' | 'DueSoon' | 'Future' | 'Suspended'
export const installmentStatusTranslationMap: Record<InstallmentStatus, string> = {
  Open: 'aberta',
  Overdue: 'em atraso',
  Settled: 'quitada',
  DueSoon: 'vence em breve',
  Future: 'futura',
  Suspended: 'pausada'
}

type OverdueInstallmentInfo = {
  installmentNumber: number
  dueDate: string
  presentBalance: number
}
export interface LoanSummaryOverdueInstallments {
  overdueInstallments: OverdueInstallmentInfo[]
  totalPresentBalance: number
  daysOverdue: number
  penaltyFeeAmount: number
  penaltyInterestAmount: number
  latePaymentInterestAmount: number
}
export interface UseCreditoffersReturn {
  offer?: CreditOffer
  maxCreditValue?: number
  minCreditValue?: number
}

export interface NextInstallment {
  installmentNumber: number
  startDate: string
  dueDate: string
  status: InstallmentStatus
  futureBalance: number
  presentBalance: number
}
export interface LoanSummary {
  currentInstallment: LoanInstallment
  creditAmount: number
  numberOfInstallments: number
  ddcUrl: string
  label: string
  initialAmount: number
  futureBalance: number
  presentBalance: number
  taxAmount: number
  interestEconomyAmount: number
  paidAmount: number
  numberOfSettledInstallments: number
  numberOfOverdueInstallments: number
  initialInstallmentAmount: number
  isRetentionEnabled: boolean
  retentionRate: number
  monthlyInterestRate: number
  yearlyInterestRate: number
  yearlyTotalEffectiveCostRate: number
  dueDate: string
  contractUri: string
  nextInstallment: NextInstallment
  overdueInstallmentsSummary: LoanSummaryOverdueInstallments
}

export interface LoanSummaryV2 extends LoanSummary {
  installmentPlanId: string
  plans?: {
    amountByInstallment: {
      value: number
      description: string
      type: string
    }
    installments: {
      value: number
      description: string
      type: string
    }
    total: {
      value: number
      description: string
      type: string
    }
    type: string
  }[]
  gracePeriodInDays?: number
  renegotiationSummary?: RenegotiationSummary
}

export interface LoanSummaryHistory {
  renegotiationSummary: RenegotiationSummary
  currentSummary: LoanSummaryV2
  previousSummary?: LoanSummaryV2
}

export type RenegotiationSummaryStatus =
  | 'DisbursementRequested'
  | 'Disbursed'
  | 'Created'
  | 'TreasuryOrderRequested'
  | 'TreasuryOrderApproved'
  | 'Active'
  | 'Settled'
  | 'TreasuryOrderFailed'
  | 'Cancelled'
export interface RenegotiationSummary {
  status: RenegotiationSummaryStatus
  statusDescription: string
  loanRenegotiationContractId: string
  contractSignatureDate: string
  creationDate: string
}

export interface LoanInstallment {
  id: string
  installmentNumber: number
  startDate: string
  dueDate: string
  daysUntilDueDate: number
  isDueSoon: boolean
  daysOverdue: number
  settlementDate?: string
  isCollectionEnabled: boolean
  isOverdue: boolean
  status: InstallmentStatus
  paidAmount: number
  initialAmount: number
  futureBalance: number
  presentBalance: number
  interestEconomyAmount: number
  penaltyFeeAmount: number
  penaltyInterestAmount: number
  latePaymentInterestAmount: number
  totalDebtAmount: number
}

export interface LoanInstallmentList {
  items: LoanInstallment[]
}

export type LoanStatus =
  | 'Accepted'
  | 'DisbursementRequested'
  | 'DisbursementConfirmed'
  | 'Active'
  | 'Settled'
  | 'Inactive'
  | 'Suspended'
  | 'Cancelled'
  | 'SuspendedDisbursement'
  | 'SuspendedNonDisbursement'
  | 'DelayedPayment'

export type LoanStatusDescription = 'Em análise' | 'Em andamento' | 'Ativo' | 'Quitado' | 'Cancelado' | 'Suspenso'

export interface LoanFinancialInstitution {
  id: number
  name: string
}

export interface LoanAddress {
  id?: string
  streetName: string
  streetNumber: string
  complement?: string
  neighborhood: string
  city: string
  state: string
  postalCode: string
}

export interface LoanDocumentType {
  id: number
  name: string
}

export interface LoanBorrower {
  id: string
  document: string
  name: string
  fantasyName: string
  affiliationKey: string
  affiliationCode: string
  phoneNumber: string
  email: string
  address: LoanAddress
  creationDate: string
  lastUpdateDate: string
  status: string
  documentType: LoanDocumentType
}

export interface LoanSigner {
  id: string
  name: string
  documentNumber: string
  phoneNumber: string
  email: string
  ipAddress: string
}

export interface LoanLender {
  id: string
  name: string
  document: string
}

export type LoanLegalEntity = unknown

export interface LoanBankAccount {
  id: string
  bankNumber: string
  bankName: string
  branchNumber: string
  branchDigit: string
  accountNumber: string
  accountDigit: string
  legalEntity: LoanLegalEntity
  accountType: string
}

export interface LoanDisbursement {
  bankAccount: LoanBankAccount
}

export interface LoanWarranty {
  bankAccount: LoanBankAccount
  endorsement?: LoanEndorsement
}

export interface LoanTax {
  additionalIofRatePercentage: number
  dailyIofRatePercentage: number
}

export interface LoanRepayment {
  isMaximumMonthlyRetentionAmountEnabled: boolean
  totalRetainedAmountInMonth: number
  monthlyDebitBalance: number
  maximumMonthlyRetentionAmount: number
  maximumRetentionAmount: number
}

export interface LoanEndorsementAddress {
  id: string
  postalCode: string
  neighborhood?: string
  streetName: string
  streetNumber: string
  referencePoint?: string
  extra?: string
  city: string
  state: string
}
export interface LoanEndorsement {
  legalName: string
  documentNumber: string
  nationality: string
  maritalStatus: string
  email: string
  address: LoanEndorsementAddress
  stoneAccount: {
    id: string
    bankNumber: string
    branchNumber: string
    accountNumber: string
    accountDigit: string
    legalEntity: LoanLegalEntity
    accountType: string
  }
}
