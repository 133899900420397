import { Stack } from '@dlpco/fluid-layout'
import { Button } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { type AssessmentCheck } from '~/domains/stone-account/ports/entities/KYC'

import { useKycContext } from '../context/kyc-context'
import { getStepTitle } from '../helpers/kyc-steps'

import { KycFlowFormAddressForm } from './kyc-flow-form-address-form'
import { KycFlowFormOptionList } from './kyc-flow-form-option-list'
import { KycFlowGrid } from './kyc-flow-grid'
import { KycFlowStepList } from './kyc-flow-step-list'

type KycFlowFormProps = {
  assessmentCheck: AssessmentCheck
}

export function KycFlowForm({ assessmentCheck }: KycFlowFormProps) {
  const { currentStep, steps, hasPrevStep, nextStep, prevStep, saveKycAnswerData, kycAnswerData } = useKycContext()

  function onBefore() {
    prevStep()
  }

  function onNext() {
    nextStep()
  }
  const currentValue = kycAnswerData[assessmentCheck.fieldType]
  const isDisabled = !currentValue

  function FormNavigation() {
    return (
      <Flex gap="1rem">
        <Button
          color="neutral"
          shape="pill"
          disabled={!hasPrevStep()}
          onClick={() => onBefore()}
          style={{ width: '100%' }}
        >
          Anterior
        </Button>
        <Button
          shape="pill"
          disabled={isDisabled}
          onClick={() => onNext()}
          style={{ width: '100%' }}
          data-test-id="kyc-next-step-button"
        >
          Próximo
        </Button>
      </Flex>
    )
  }

  // TODO: Talvez seja melhor mover isso pra um helper e mudar tbm no shared-kyc-flow-step-list
  // @ts-ignore
  const title = getStepTitle(assessmentCheck.fieldType)
  if (assessmentCheck.context?.formType === 'list' && assessmentCheck.context?.options)
    return (
      <KycFlowGrid
        left={
          <>
            <KycFlowStepList currentStep={currentStep} steps={steps} />
          </>
        }
        right={
          <Stack space="2rem">
            <Box minHeight={'400px'}>
              <KycFlowFormOptionList
                title={title}
                setCurrentValue={values => {
                  saveKycAnswerData({ ...kycAnswerData, [`${assessmentCheck.fieldType}`]: values })
                }}
                currentValue={currentValue}
                options={assessmentCheck.context.options}
              />
            </Box>
            <Box>
              <FormNavigation />
            </Box>
          </Stack>
        }
      />
    )

  return (
    <>
      <KycFlowGrid
        left={
          <>
            <KycFlowStepList currentStep={currentStep} steps={steps} />
          </>
        }
        right={
          <Stack space="2rem">
            <Box minHeight={'400px'}>
              <KycFlowFormAddressForm />
            </Box>
            <Box>
              <FormNavigation />
            </Box>
          </Stack>
        }
      />
    </>
  )
}
