import { type ReactNode } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

import { Box } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { theme } from '~/ui/theme'

import { useSpotOfferContext } from '../context/spot-offer-context'

import { SpotOfferSteps } from './spot-offer-flow'
import { SpotOfferLoading } from './spot-offer-loading'

export const loanSpotOfferBasePath = '/credito/emprestimo/${loan}/antecipar-pagamento/${step}'

export const SpotOfferFlowIndex = dynamic(() => import('./spot-offer-flow-index').then(mod => mod.SpotOfferFlowIndex), {
  loading: () => <SpotOfferLoading />
})

export const SpotOfferFlowNextInstallments = dynamic(
  () => import('./spot-offer-flow-next-installments').then(mod => mod.SpotOfferFlowNextInstallments),
  {
    loading: () => <SpotOfferLoading />
  }
)

export const SpotOfferFlowReview = dynamic(
  () => import('./spot-offer-flow-review').then(mod => mod.SpotOfferFlowReview),
  {
    loading: () => <SpotOfferLoading />
  }
)

export const SpotOfferFlowSpecificAmount = dynamic(
  () => import('./spot-offer-flow-specific-amount').then(mod => mod.SpotOfferFlowSpecificAmount),
  {
    loading: () => <SpotOfferLoading />
  }
)

export const SpotOfferPaymentMethod = dynamic(
  () => import('./spot-offer-payment-method').then(mod => mod.SpotOfferPaymentMethod),
  {
    loading: () => <SpotOfferLoading />
  }
)

export const SpotOfferPayment = dynamic(() => import('./spot-offer-payment').then(mod => mod.SpotOfferPayment), {
  loading: () => <SpotOfferLoading />
})

export function spotOfferNavigate(loan: string, step: SpotOfferSteps) {
  return BlackBird.travelTo({
    pathname: loanSpotOfferBasePath,
    urlParams: { loan, step }
  })
}

function dependencyRouteValidation(product: string, currentStep?: StepInfo) {
  if (currentStep?.dependency) {
    const allDependenciesTruthy = currentStep?.dependency.every(dep => !!dep)
    if (!allDependenciesTruthy) spotOfferNavigate(product, SpotOfferSteps.SPOT_OFFER_INDEX)
  }
}

interface StepInfo {
  dependency?: any[]
}

type RouterTitle = Partial<Record<SpotOfferSteps, StepInfo>>

type NavigationGuardProps = {
  children: ReactNode
}

export const ResponsiveBox = styled(Box)`
  min-height: 75vh;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.windowSizes.small}px) {
    padding: 2rem 1.5rem;
  }
`

export function SpotOfferNavigationGuard({ children }: NavigationGuardProps) {
  const { loan: productId } = BlackBird.getQuery()
  const step: SpotOfferSteps = BlackBird.getQuery().step || SpotOfferSteps.SPOT_OFFER_INDEX
  const { amount, paymentMethod, type } = useSpotOfferContext()

  const routerTitle: RouterTitle = {
    [SpotOfferSteps.SPOT_OFFER_INDEX]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_CURRENT_INSTALLMENT]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS_INPUT]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_NEXT_INSTALLMENTS]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_ALL_INSTALLMENTS]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_SPECIFIC_AMOUNT_INPUT]: {
      dependency: []
    },
    [SpotOfferSteps.SPOT_OFFER_PAYMENT_METHOD]: {
      dependency: [amount]
    },
    [SpotOfferSteps.SPOT_OFFER_PAYMENT]: {
      dependency: [amount, paymentMethod, type]
    }
  }

  const currentStep = routerTitle[step]

  dependencyRouteValidation(productId, currentStep)

  return <>{children}</>
}
