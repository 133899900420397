import { Stack } from '@dlpco/fluid-layout'
import { CardBody, CardHeader } from '@dlpco/ginga-stone'
import styled, { css } from 'styled-components'

import { Box } from '~/domains/platform/design-system'

export const GridCardContent = css`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  grid-column-gap: 70px;
`

export const CardBodyGrid = styled(CardBody)`
  ${GridCardContent}
`

export const FooterGrid = styled(Box)`
  display: grid;
  grid-template-columns: 3fr 1.5fr 1fr;
  grid-column-gap: 70px;
  padding: 1.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${({ theme }) => theme.colors.mediumGray4};
  max-width: 100%;
`
export const StackTextEnd = styled(Stack)`
  text-align: end;
`
export const BorderHeader = styled(CardHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGray4};
`
