import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Card, Description, Heading, Money, Overline, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Choose, If } from '~/domains/platform/lib/utilities-components'

import { type InstallmentStatus, type LoanInstallment } from '../../shared/entities'
import { statusColorMap } from '../../shared/helpers/status-colors-map'
import { type CurrentInstallmentStatus } from '../entities'

import { LoanInstallmentDetailsSummaryLoading } from './loan-installment-details-summary-loading'

import { BorderHeader, CardBodyGrid, FooterGrid, StackTextEnd } from './loan-installment-details-summary.styles'

interface InstallmentSummaryProps {
  isLoading: boolean
  installment?: LoanInstallment
  numberOfInstallments?: number
  isZeroTaxRenegotiation?: boolean
}

export const LoanInstallmentDetailsSummary = (props: InstallmentSummaryProps) => {
  const { isLoading, installment, numberOfInstallments, isZeroTaxRenegotiation } = props

  if (isLoading || !installment) return <LoanInstallmentDetailsSummaryLoading />

  const totalPenalty =
    installment.penaltyFeeAmount + installment.penaltyInterestAmount + installment.latePaymentInterestAmount

  const status: InstallmentStatus =
    installment.status === 'Open' && installment.isDueSoon ? 'DueSoon' : installment.status

  return (
    <Card>
      <BorderHeader
        verticalAlignment="center"
        slotEnd={
          <Badge
            color={statusColorMap[status as CurrentInstallmentStatus].color}
            variant={statusColorMap[status as CurrentInstallmentStatus].variant}
          >
            {statusColorMap[status as CurrentInstallmentStatus].text}
          </Badge>
        }
      >
        <Flex height="1.5rem" alignItems="center">
          <Choose>
            <Choose.When condition={status === 'Overdue'}>
              <Overline color="neutral">Em atraso há {installment.daysOverdue} dias</Overline>
            </Choose.When>
            <Choose.Otherwise>
              <Overline color="neutral">
                {formatDate(installment.startDate)} a {formatDate(installment.dueDate)}
              </Overline>
            </Choose.Otherwise>
          </Choose>
        </Flex>
      </BorderHeader>

      <CardBodyGrid>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text size="small">
              Parcela {installment.installmentNumber} de {numberOfInstallments}
            </Text>
            <Box>
              <Choose>
                <Choose.When condition={status === 'Settled'}>
                  <Heading weight="bold" color="primary">
                    Parabéns!
                  </Heading>
                  <Heading weight="bold" color="primary">
                    A parcela foi quitada.
                  </Heading>
                </Choose.When>

                <Choose.When condition={status === 'Overdue'}>
                  <Box color="danger">
                    <Heading size="xLarge" weight="bold" inheritColor>
                      <Money amount={installment.futureBalance} />
                    </Heading>
                    <Description color="neutralLow">Restante para quitar essa parcela</Description>
                  </Box>
                </Choose.When>

                <Choose.Otherwise>
                  <Heading size="xLarge" weight="bold">
                    <Money amount={installment.futureBalance} />
                  </Heading>
                  <Description color="neutralLow">Restante para quitar essa parcela</Description>
                </Choose.Otherwise>
              </Choose>
            </Box>
          </Box>
        </Flex>
        <StackTextEnd>
          <Description color="neutralLow">Abriu em</Description>

          <Text weight="bold" inheritColor>
            {formatDate(installment.startDate)}
          </Text>
        </StackTextEnd>

        <StackTextEnd>
          <Description color="neutralLow">Vence em</Description>

          <Text weight="bold" inheritColor>
            {formatDate(installment.dueDate)}
          </Text>
        </StackTextEnd>
      </CardBodyGrid>

      <FooterGrid>
        <Flex gap="6rem">
          <If condition={!isZeroTaxRenegotiation}>
            <Stack>
              <Flex alignItems="center">
                <Description color="neutralLow">Juros economizados</Description>
              </Flex>

              <Text weight="bold" inheritColor>
                <Money amount={Math.abs(installment.interestEconomyAmount)} />
              </Text>
            </Stack>
          </If>

          <Box>
            <If condition={status === 'Overdue' && Boolean(totalPenalty)}>
              <Stack>
                <Flex alignItems="center">
                  <Description color="neutralLow">Cobranças por atraso</Description>
                </Flex>
                <Box color="danger">
                  <Text weight="bold" inheritColor>
                    <Money amount={Math.abs(totalPenalty)} />
                  </Text>
                </Box>
              </Stack>
            </If>
          </Box>
        </Flex>

        <StackTextEnd>
          <Description color="neutralLow">Total quitado</Description>

          <Text weight="bold" inheritColor>
            <Money amount={Math.abs(installment.paidAmount)} />
          </Text>
        </StackTextEnd>

        <StackTextEnd>
          <Description color="neutralLow">Valor da parcela</Description>

          <Text weight="bold" inheritColor>
            <Money amount={Math.abs(installment.initialAmount)} />
          </Text>
        </StackTextEnd>
      </FooterGrid>
    </Card>
  )
}
