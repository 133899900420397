import { useEffect } from 'react'
import { useEffectOnce, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Heading, Text, theme } from '@dlpco/ginga-stone'
import {
  CoinFailedIllustration,
  CoinSuccessIllustration,
  PaperAirplaneLinkIllustration
} from 'design-elements/shared/illustrations'
import styled from 'styled-components'

import { useRenegotiationContext } from '../../context/renegotiation-context'
import { RenegotiationSteps } from '../../entities'
import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'
import { useRenegotiationSign } from '../../hooks/use-renegotiation-sign'
import { acceptRenegotiation } from '../../services/renegotiation'
import { SharedCreditIndeterminateProgressBar } from '~/domains/credit/shared/components/credit-indeterminate-progress-bar'
import { Box, Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Modal } from '~/ui/components/modals/modal'

export type ConfirmStates = 'START' | 'ERROR' | 'SUCCESS'

const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 130px;
  align-items: center;
`

export function RenegotiationConfirmStates({ state, loanId }: { loanId: string; state: ConfirmStates }) {
  const backToLoan = () => {
    renegotiationAnalitica.events.review.successClick('acompanhar')
    BlackBird.travelTo({
      pathname: '/credito/emprestimo/${loan}',
      urlParams: { loan: loanId }
    })
  }

  const openChat = () => chat.open()

  const reviewStates = {
    START: {
      title: 'Falta pouco para terminar',
      subtitle: 'Estamos indo o mais rápido possível.',
      illustration: <PaperAirplaneLinkIllustration />,
      bottom: <SharedCreditIndeterminateProgressBar />
    },
    ERROR: {
      title: 'A renegociação não aconteceu',
      subtitle: 'Tente de novo ou converse com a gente.',
      illustration: <CoinFailedIllustration />,
      bottom: (
        <>
          <Button
            onClick={() => backToLoan()}
            color="primary"
            size="large"
            style={{
              width: '100%'
            }}
          >
            Voltar para o início
          </Button>
          <Button
            onClick={() => openChat()}
            color="neutral"
            size="large"
            style={{
              width: '100%',
              marginTop: theme.light.space.medium
            }}
          >
            Conversar com a Stone
          </Button>
        </>
      )
    },
    SUCCESS: {
      title: 'Sua renegociação está sendo processada',
      subtitle: 'Vamos te dar o retorno em até 1 dia útil.',
      illustration: <CoinSuccessIllustration />,
      bottom: (
        <>
          <Button
            onClick={() => backToLoan()}
            color="primary"
            size="large"
            style={{
              width: '100%',
              marginTop: theme.light.space.medium
            }}
          >
            Acompanhar
          </Button>
        </>
      )
    }
  }

  return (
    <Box height="530px">
      <Flex height="100%" justifyContent="center" alignItems="center">
        <Box width="480px" textAlign="center" p={theme.light.space.xLarge2}>
          <Stack space="2rem" style={{ marginBottom: theme.light.space.xLarge4 }}>
            <IllustrationWrapper>{reviewStates[state].illustration}</IllustrationWrapper>
            <Stack space="1rem">
              <Heading size="xLarge" weight="bold">
                {reviewStates[state].title}
              </Heading>
              <Text color="neutral">{reviewStates[state].subtitle}</Text>
            </Stack>
          </Stack>
          {reviewStates[state].bottom}
        </Box>
      </Flex>
    </Box>
  )
}

interface ReviewConfirmProps {
  isOpen: boolean
}
export function RenegotiationReviewConfirm({ isOpen }: ReviewConfirmProps) {
  const [enableSign, setEnableSign] = useToggle(false)
  const [hasAcceptError, setHasAcceptError] = useToggle(false)

  const { loan: loanId, proposalId, challenge } = BlackBird.getQuery()
  const { saveContractId } = useRenegotiationContext()
  const { data } = useRenegotiationSign({ proposalId, challenge, enabled: enableSign })
  const { state, contractId } = data ?? { state: 'START' }
  useEffectOnce(() => {
    if (!challenge) {
      acceptRenegotiation({
        proposalId,
        onSuccess: () => setEnableSign(),
        onError: () => setHasAcceptError()
      })
    }
  })

  if (contractId) {
    saveContractId(contractId)
    renegotiationNavigate({ step: RenegotiationSteps.PIN })
  }

  useEffect(() => {
    if (state === 'SUCCESS') renegotiationAnalitica.events.review.success()
    if (state === 'ERROR') renegotiationAnalitica.events.review.error()
  }, [state])

  return (
    <Modal isOpen={isOpen}>
      <Modal.Content>
        {hasAcceptError ? (
          <RenegotiationConfirmStates state="ERROR" loanId={loanId} />
        ) : (
          <RenegotiationConfirmStates state={state || 'START'} loanId={loanId} />
        )}
      </Modal.Content>
    </Modal>
  )
}
