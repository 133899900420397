import { Heading, IconButton } from '@dlpco/ginga-stone'

import { creditHomeBasePath } from '~/domains/credit/ports/credit.routes'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'

// TODO: move this to shared
import { ResponsiveFlexContainer } from '../../loan-concession/helpers/loan-concession.styles'

interface LoanDetailsHeaderProps {
  title?: string
}

export function LoanDetailsHeader({ title }: LoanDetailsHeaderProps) {
  return (
    <ResponsiveFlexContainer>
      <Flex alignItems="center" gap="1rem">
        <IconButton
          icon="direction-arrow-left-outline"
          color="neutral"
          data-testid="back-button"
          variant="contentOnly"
          size="large"
          onClick={() => BlackBird.travelTo(creditHomeBasePath)}
        />
        <Heading size="xLarge" weight="semi">
          {title}
        </Heading>
      </Flex>
    </ResponsiveFlexContainer>
  )
}
