import { type TagProps, Tag } from '@stone-payments/jade'

import { type InstallmentStatus } from '../loan-details/entities'

export const jadeStatusColorMap: Record<InstallmentStatus, { color: TagProps['variant']; solid: boolean }> = {
  settled: {
    color: 'positive',
    solid: true
  },
  overdue: {
    color: 'negative',
    solid: false
  },
  active: {
    color: 'positive',
    solid: false
  },
  dueSoon: {
    color: 'warning',
    solid: false
  },
  future: {
    color: 'neutral',
    solid: false
  },
  multipleOverdue: {
    color: 'negative',
    solid: false
  }
}

export const StatusTag = ({ status, description }: { status: InstallmentStatus; description: string }) => (
  <Tag variant={jadeStatusColorMap[status].color} size="medium" solid={jadeStatusColorMap[status].solid}>
    {description}
  </Tag>
)
