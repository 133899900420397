import { type CreditPaging, type Loan } from '~/domains/credit/shared/entities'
import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

interface UseLoansProps {
  id?: string
  document: string
  pageSize?: number
  currentPage?: number
  status?: string[]
}

interface FetchLoansProps extends Omit<UseLoansProps, 'status'> {
  status: URLSearchParams
}

export const fetchLoans = ({ id, document, pageSize = 10, currentPage, status }: FetchLoansProps) => {
  return httpClientCredit<CreditPaging<Loan[]>>({
    url: `/experience-bff-service/api/legacy/web/v1/loans?${status.size ? status : ''}`,
    params: { id, document, pageSize, currentPage }
  })
}

export function useLoans(props: UseLoansProps, queryOptions?: UseQueryRequestOptions<CreditPaging<Loan[]>>) {
  const queryParam = new URLSearchParams()

  if (props.status)
    for (const item of props.status) {
      queryParam.append('status', item)
    }

  const { data: response, ...restQuery } = useQueryRequest<CreditPaging<Loan[]>>(
    ['useLoans', props],
    () => fetchLoans({ ...props, status: queryParam }),
    { ...queryOptions }
  )
  const data = response?.data

  return {
    ...restQuery,
    data
  }
}
