import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { useQueryRequest } from '~/ui/hooks/utils'
import { type UseQueryRequestOptions } from '~/ui/hooks/utils/service/use-query-request'

import { type BffLoanHome } from '../entities'

async function fetchLoanHome({ document }: { document: string }) {
  const hasRevolvingCredit = Heimdall.pass(['credit_revolving_credit_web', 'credit_beta_web'])
  const url =
    'experience-bff-service/api/web/v1/home' +
    (hasRevolvingCredit ? '?include_product_type=Revolving&include_product_type=Loan' : '')
  return await httpClientCredit<BffLoanHome>({
    url,
    method: 'GET',
    params: { document }
  })
}

export function useLoanHome({ document }: { document: string }, queryOptions?: UseQueryRequestOptions<BffLoanHome>) {
  const { data: response, ...restQuery } = useQueryRequest<BffLoanHome>(
    ['useLoanHome'],
    () => fetchLoanHome({ document }),
    {
      ...queryOptions
    }
  )

  const data = response?.data

  return {
    data,
    ...restQuery
  }
}
