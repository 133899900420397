import { useState } from 'react'
import { type AlertProps, Alert } from '@stone-payments/jade'

import { For } from '~/domains/platform/lib/utilities-components'

import { type LoanDetails } from '../entities'

import { OverdueConsequencesDrawer } from './loan-details-jade-drawers'

export function LoanDetailsJadeNotifications({ notifications }: { notifications: LoanDetails['notifications'] }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <For
        of={notifications}
        render={(item, index) => {
          const getAction = () => {
            if (item.notificationButton) {
              if (item.notificationButton?.action === 'overdueDrawer') return () => setIsOpen(true)
              if (item.notificationButton?.action === 'link') return () => window.open(item.notificationButton?.link)
            }
          }

          return (
            <Alert
              key={'notification' + index}
              variant={item.notificationType as AlertProps['variant']}
              title={item.title}
              description={item.subtitle}
              showIcon
              link={
                item.notificationButton
                  ? { text: item.notificationButton?.text || '', onClick: getAction() }
                  : undefined
              }
            />
          )
        }}
      />
      <OverdueConsequencesDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}
