import { useEffectOnce, useToggle } from 'react-use'
import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Alert, Button, Checkbox, Description, Heading, Icon, Money, Overline, Text, Tooltip } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For } from '~/domains/platform/lib/utilities-components'

import { type RenegotiationProposal } from '../../../loan-concession/entities'
import { CustomBox, CustomDivider } from '../../../shared/components/shared-custom-styles'
import { type LoanInstallment } from '../../../shared/entities'
import { useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { DiscountSettlementSteps } from '../../entities'
import discountSettlementAnalitica from '../../helpers/discount-settlement-analitica'
import { basePath } from '../../helpers/renegotiation'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'
import { useRenegotiationSign } from '../../hooks/use-renegotiation-sign'
import { acceptRenegotiation, voidRenegotiationProposal } from '../../services/renegotiation'

export function RenegotiationDiscountSettlementRateReview() {
  const loanId = BlackBird.getQuery().loan
  const { addToast } = useToast()
  const addErrorToast = () => addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })

  const [acceptedTerms, toggleAcceptedTerms] = useToggle(false)
  const [isLoading, toggleLoading] = useToggle(false)
  const [enableSign, toggleEnableSign] = useToggle(false)
  const { proposal, installments, resetProposal, resetSimulation, saveContractId } = useDiscountSettlementContext()

  const { data: contractData } = useRenegotiationSign({
    proposalId: proposal?.id ?? '',
    enabled: enableSign
  })

  const { contractId, state: contractPollingState, resetCount: resetContractPollingCount } = contractData || {}

  if (contractPollingState == 'ERROR' && isLoading) {
    toggleLoading(false)
    addErrorToast()
  }

  if (contractId) {
    saveContractId(contractId)
    renegotiationNavigate({ step: DiscountSettlementSteps.PIN })
  }

  useEffectOnce(() => {
    discountSettlementAnalitica.events.review.view()
  })

  const navigateTo = (step: DiscountSettlementSteps) => {
    BlackBird.travelTo({
      pathname: basePath,
      urlParams: { loan: loanId, step }
    })
    return null
  }
  if (!proposal) {
    return navigateTo(DiscountSettlementSteps.PAYMENT_METHOD)
  }

  const isButtonDisabled = !(acceptedTerms && proposal)

  const debtAmount = proposal?.discountSettlement?.negotiationDebtAmount ?? 0
  const amount = proposal?.discountSettlement?.paymentMethod?.amount ?? 0

  function handlePaymentReviewClick() {
    toggleLoading(true)

    acceptRenegotiation({
      proposalId: proposal?.id || '',
      onSuccess: () => {
        resetContractPollingCount()
        toggleEnableSign(true)
      },
      onError: () => {
        addErrorToast()
        toggleLoading(false)
      }
    })
  }

  return (
    <>
      <Stack space="1.75rem">
        <CustomBox>
          <Stack space="1rem">
            <Stack space="0.125rem">
              <Text color="neutral">Total com desconto</Text>
              <Description color="neutral" style={{ textDecoration: 'line-through' }}>
                <Money amount={debtAmount} />
              </Description>
              <Heading weight="semi">
                <Money amount={amount} />
              </Heading>
            </Stack>

            <CustomDivider />

            <Flex justifyContent="space-between">
              <Stack>
                <Description color="neutral">Você está quitando</Description>
                <Text weight="semi">{installments?.length} parcelas</Text>
              </Stack>
              <Button
                variant="contentOnly"
                color="primary"
                onClick={() => {
                  discountSettlementAnalitica.events.review.clicked('voltar para simulação')
                  resetProposal()
                  resetSimulation()
                }}
              >
                Alterar
              </Button>
            </Flex>
          </Stack>
        </CustomBox>
        <Stack space="1rem">
          <Overline color="neutral">{'Confira o que você vai pagar'}</Overline>

          <PaymentReviewSummary
            acceptedTerms={acceptedTerms}
            toggleTerms={toggleAcceptedTerms}
            proposal={proposal as RenegotiationProposal}
            installments={installments ?? []}
            loanId={loanId}
          />
        </Stack>
        <Button
          disabled={isButtonDisabled}
          shape="pill"
          iconPosition="end"
          {...(!isButtonDisabled && { icon: 'direction-arrow-right-outline' })}
          onClick={() => {
            discountSettlementAnalitica.events.review.clicked('ir para o pagamento')
            handlePaymentReviewClick()
          }}
        >
          {isButtonDisabled ? 'Aceite a condição para continuar' : 'Ir para o pagamento'}
        </Button>
      </Stack>

      <Dimmer isVisible={isLoading}>
        <Loader />
      </Dimmer>
    </>
  )
}

function PaymentReviewSummary({
  toggleTerms,
  acceptedTerms,
  installments,
  proposal,
  loanId
}: {
  toggleTerms: (param?: boolean) => void
  acceptedTerms: boolean
  installments: LoanInstallment[]
  proposal: RenegotiationProposal
  loanId: string
}) {
  const { resetProposal, resetPaymentMethod } = useDiscountSettlementContext()
  return (
    <Stack space="1rem">
      <CustomBox>
        <Stack space="1rem">
          <Flex justifyContent="space-between">
            <Stack>
              <Text color="neutral">Forma de pagamento</Text>
              <Text weight="bold">{proposal.discountSettlement?.paymentMethod.type || ''}</Text>
            </Stack>
            <Button
              variant="contentOnly"
              color="primary"
              onClick={() => {
                discountSettlementAnalitica.events.review.clicked('alterar')
                toggleTerms(false)
                voidRenegotiationProposal(proposal.id)
                resetPaymentMethod()
                resetProposal()
                BlackBird.travelTo({
                  pathname: basePath,
                  urlParams: { loan: loanId, step: DiscountSettlementSteps.PAYMENT_METHOD }
                })
              }}
            >
              Alterar
            </Button>
          </Flex>

          <CustomDivider />

          <Flex justifyContent="space-between">
            <Text color="neutral">Vence em</Text>
            <Text weight="bold">{formatDate(proposal?.expirationDate)}</Text>
          </Flex>
          <Box pt="0.5rem">
            <Alert color="warning">
              O desconto vale para pagamento até a data de vencimento. Se passar da data, você perde o desconto.
            </Alert>
          </Box>
        </Stack>
      </CustomBox>
      <CustomBox>
        <Stack space="1rem">
          <For
            of={installments}
            render={installment => (
              <Flex key={installment.id} alignItems="center" justifyContent="space-between">
                <Text color="neutral">Parcela {installment.installmentNumber}</Text>
                <Text weight="semi" color="neutral">
                  <Money amount={Number(installment.presentBalance)} />
                </Text>
              </Flex>
            )}
          />
          <CustomDivider />
          <Stack space="0.5rem">
            <Flex justifyContent="space-between">
              <Text color="neutral">Valor das parcelas selecionadas</Text>
              <Text style={{ textDecoration: 'line-through' }}>
                <Money amount={proposal.discountSettlement?.negotiationDebtAmount || 0} />
              </Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Flex gap="0.5rem" alignItems="center">
                <Text color="neutral">Desconto</Text>
                <Tooltip content="O desconto inclui o valor de juros economizados, se houver.">
                  <Icon use="round-info-outline" color="neutral" />
                </Tooltip>
              </Flex>
              <Text color="neutral">
                - <Money amount={proposal?.discountSettlement?.discountAmount || 0} />
              </Text>
            </Flex>

            <Flex justifyContent="space-between">
              <Text color="neutral">Total a pagar</Text>
              <Text color="primary" weight="semi" size="large">
                <Money amount={proposal?.discountSettlement?.paymentMethod?.amount || 0} />
              </Text>
            </Flex>
          </Stack>
        </Stack>
      </CustomBox>
      <Flex gap="1rem">
        <Checkbox checked={acceptedTerms} onChange={() => toggleTerms()} />
        <Text>
          <b>Aceito</b> as condições anteriores e <b>estou ciente</b> de que o desconto só se aplica se eu pagar o valor
          total até a data de vencimento.
        </Text>
      </Flex>
    </Stack>
  )
}
