import { analiticaInstance } from 'shared/analitica'

export default analiticaInstance.eventFactory('Credit loan installment details', {
  installmentDetails: {
    view: (loanId: string) => ({
      label: 'credit - loan installment details view',
      metadata: { 'loan id': loanId }
    }),
    click: () => ({ label: 'credit - loan installment details clicked', metadata: { action: 'antecipar pagamento' } })
  },
  statementDetails: {
    view: (loanId: string) => ({
      label: 'credit - loan statements view',
      metadata: { 'loan id': loanId }
    })
  },
  originalContract: {
    view: (loanId: string) => ({
      label: 'credit - original loan installment details view',
      metadata: { 'loan id': loanId }
    }),
    statementsView: (loanId: string) => ({
      label: 'credit - original loan statements view',
      metadata: { 'loan id': loanId }
    })
  }
})
