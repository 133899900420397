import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { BFF_LEGACY_V1 } from '~/domains/credit/shared/infra/apis-paths'
import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type Assessment } from '~/domains/stone-account/ports/entities/KYC'

import { type CreditOffer, type CreditOfferRolesConcessession } from '../../shared/entities'
import {
  type LoanConcessionKycChecksAnswers,
  type NegotiationsProposal,
  type NegotiationsSimulationResult,
  type Proposal
} from '../entities'
import { httpClientCreditInsurance } from '../infra/http-client-credit-insurance'
import { httpClientCreditNegotiations } from '../infra/http-client-credit-negotiations'

interface SimulateProposal {
  borrowerId?: string
  creditLineId?: string
  requestedAmount: number
}

export async function simulateProposal({ borrowerId, creditLineId, requestedAmount }: SimulateProposal) {
  return await httpClientCredit<Proposal>({
    url: `/api/v3/borrowers/${borrowerId}/credit-lines/${creditLineId}/proposals`,
    method: 'POST',
    data: {
      requestedAmount
    }
  })
}

interface answerProposalKYCProps {
  userId: string
  assessment: Assessment
  kycAnswerData: LoanConcessionKycChecksAnswers
}

export async function answerProposalKYC({ userId: subjectUserId, assessment, kycAnswerData }: answerProposalKYCProps) {
  const userId = subjectUserId.split('user:')[1]
  const answers = assessment.checks.map(check => {
    return {
      checkId: check.id,
      value: kycAnswerData[check.fieldType]
    }
  })
  return httpClientBanking({
    url: `/users/${userId}/kyc/answer`,
    method: 'POST',
    data: {
      assessment_id: assessment.id,
      answers
    }
  })
}

export async function updateProposalCustomer(proposalId: string) {
  return await httpClientCreditNegotiations({
    url: `${BFF_LEGACY_V1}/proposals/${proposalId}/update-customer`,
    method: 'POST'
  })
}

export async function voidProposal(proposalId: string) {
  return await httpClientCreditNegotiations({
    url: `${BFF_LEGACY_V1}/proposals/${proposalId}/void`,
    method: 'POST'
  })
}

type getNumberOfInstallmentsProps = {
  requestedAmount: number
  creditOffer?: CreditOffer
}

export function getNumberOfInstallments({ creditOffer, requestedAmount }: getNumberOfInstallmentsProps) {
  return Math.max(
    ...(creditOffer?.roles
      .flatMap(role => role.conditions as CreditOfferRolesConcessession[])
      .find(condition => condition.minCreditLimit <= requestedAmount && condition.maxCreditLimit >= requestedAmount)
      ?.allowedTerms || [])
  )
}

type SimulateProposalWithOfferProps = {
  requestedAmount: number
  proposalId?: string
  creditOffer?: CreditOffer
}

export async function offerSimulate({ requestedAmount, proposalId, creditOffer }: SimulateProposalWithOfferProps) {
  const offerId = creditOffer?.id || ''

  if (offerId && proposalId) await voidProposal(proposalId)
  return await httpClientCreditNegotiations<NegotiationsSimulationResult>({
    url: `/experience-bff-service/api/legacy/web/v1/offers/${offerId}/simulate`,
    method: 'POST',
    data: {
      requestedAmount: requestedAmount,
      numberOfInstallments: getNumberOfInstallments({ creditOffer, requestedAmount })
    }
  })
}

export async function loanConcessionSimulate({
  requestedAmount,
  proposalId,
  creditOffer
}: SimulateProposalWithOfferProps) {
  return await offerSimulate({ requestedAmount, proposalId, creditOffer })
}

interface SaveNegotiationsProposalProps {
  offerId: string
  requestedAmount: number
  numberOfInstallments?: number
  retentionRate?: number
}

export async function createProposal({
  offerId,
  requestedAmount,
  numberOfInstallments,
  retentionRate
}: SaveNegotiationsProposalProps) {
  return await httpClientCreditNegotiations<NegotiationsProposal>({
    url: `/experience-bff-service/api/legacy/web/v1/proposals`,
    method: 'POST',
    data: {
      offerId,
      requestedAmount,
      numberOfInstallments,
      ...(numberOfInstallments !== undefined && { numberOfInstallments }),
      ...(retentionRate !== undefined && { retentionRate })
    }
  })
}

export async function openContract() {
  return httpClientCreditNegotiations({
    url: `/contracts-service/api/v1/contracts/terms/CCB_SINGLE_PARTNER`,
    method: 'GET',
    responseType: 'blob'
  })
}

/**
 * Fetch the insurance proposal contract or certificate
 * @param proposalId the proposal string id
 * @param documentType 'CONTRACT' for the insurance PROPOSAL CONTRACT PDF or 'CERTIFICATE' for the INSURANCE CERTIFICATE PDF
 */
export async function openInsuranceContract(proposalId: string, documentType: 'CONTRACT' | 'CERTIFICATE') {
  return httpClientCreditInsurance({
    url: `/v1/proposal/${proposalId}/${documentType}`,
    method: 'GET',
    responseType: 'blob'
  })
}

export async function patchInsuranceContract(proposalId: string, option: boolean) {
  return httpClientCreditInsurance({
    url: `/v1/proposal/${proposalId}`,
    data: {
      option
    },
    method: 'PATCH'
  })
}
