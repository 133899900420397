/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react'
import { useToggle } from 'react-use'
import dynamic from 'next/dynamic'
import { formatDate } from '@credit-web/common/date-utils'
import { rateUtils } from '@credit-web/common/number-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, CardHeader, Description, IconButton, Money, Overline, Text } from '@dlpco/ginga-stone'
import { Spinner } from '@stone-payments/jade'

import { type Warranty } from '~/domains/credit/shared/entities'
import analiticaDDC from '~/domains/credit/shared/helpers/ddc-analitica'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box, Flex } from '~/domains/platform/design-system'
import { Accordion } from '~/domains/platform/design-system/accordion/accordion'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { exportFile, stringFormat } from '~/lib/helpers'

import { useLoanConcessionInsurance } from '../../loan-concession/hooks/use-check-proposal-insurance'
import { SharedModalDownloadDocument } from '../../shared/components/shared-modal-download-document'
import { type RenegotiationSummary } from '../../shared/entities'
import analitica from '../analitica'
import { openContract } from '../services/contracts'
import { openDDC } from '../services/ddc-pdf'

import { LoanDetailsCustomTooltip } from './loan-details-custom-tooltip'
import { type TableCellType } from './loan-details-documents-drawer'

const LoanDetailsDocumentsDrawer = dynamic(
  () => import('./loan-details-documents-drawer').then(mod => mod.LoanDetailsDocumentsDrawer),
  {
    loading: () => <Spinner size="large" />
  }
)
export type LoanInformations = {
  creditAmount?: number
  numberOfInstallments?: number
  ddcUrl?: string
  installmentAmount?: number
  totalAmount?: number
  iofAmount?: number
  monthlyInterestRate?: number
  yearlyInterestRate?: number
  yearlyTotalEffectiveCostRate?: number
  dueDate?: string
  contractUri?: string
  guarantors?: Warranty
  renegotiationData?: {
    originalContractDate: string
    originalContractUri: string
    renegotiationContractDate: string
    renegotiationContractUri: string
    hasRenegotiation: boolean
  }
  plans?: {
    amountByInstallment: {
      value: number
      description: string
      type: string
    }
    installments: {
      value: number
      description: string
      type: string
    }
    total: {
      value: number
      description: string
      type: string
    }
    type: string
  }[]
  gracePeriodInDays?: number
  renegotiationSummary?: RenegotiationSummary
}

type ModalDownloadDocumentProps = {
  title: string
  onConfirm: () => Promise<void>
  onErrorConfirm: () => void
  onErrorView?: () => void
  isOpen: boolean
}

type LoanDetailsContractInfo = {
  loanInformation?: LoanInformations
  isLoading: boolean
  loanId: string
  concessionProposalId: string
  document: string
}

function LoanDetailsContractInfoLoader() {
  return (
    <Card aria-label="carregando informações do contrato">
      <Box width="100%" p="1rem 1.5rem">
        <Skeleton width="90%" height="2rem" />
      </Box>
      <CardBody removeSpacing="top">
        <Stack space="1rem">
          <Skeleton width="90%" height="1rem" />

          <Skeleton width="90%" height="1rem" />

          <Skeleton width="90%" height="1rem" />

          <Skeleton width="90%" height="1rem" />
        </Stack>
      </CardBody>
    </Card>
  )
}

export function LoanDetailsContractInfo({
  loanInformation,
  isLoading,
  loanId,
  concessionProposalId,
  document
}: LoanDetailsContractInfo) {
  const {
    creditAmount,
    numberOfInstallments,
    installmentAmount,
    totalAmount,
    iofAmount,
    monthlyInterestRate,
    yearlyInterestRate,
    yearlyTotalEffectiveCostRate,
    dueDate,
    contractUri,
    guarantors,
    renegotiationData,
    plans,
    gracePeriodInDays,
    renegotiationSummary,
    ddcUrl
  } = loanInformation as LoanInformations

  const orderedPlans =
    plans && plans.length > 1
      ? plans.sort((a, b) => {
          if (a.type === 'GraceWithInterest' || a.type === 'InterestOnly') return -1
          if (b.type === 'GraceWithInterest' || b.type === 'InterestOnly') return 1
          return 0
        })
      : []

  const hasInsuranceFlag = Heimdall.pass(['credit_insurance_web'])

  const [drawerDocumentIsOpen, setDrawerDocumentIsOpen] = useToggle(false)
  const [modalDownloadDocument, setModalDownloadDocument] = useState<ModalDownloadDocumentProps | null>(null)

  const { data: insuranceData } = useLoanConcessionInsurance(concessionProposalId, { enabled: hasInsuranceFlag })
  const hasCreditInsurance = hasInsuranceFlag && !!insuranceData?.id && insuranceData.status === 'ACTIVE'
  const analiticaErrorEvents = {
    ddc: () => analiticaDDC.events.loans.negativeFeedbackClick(),
    contract: () => {}
  }

  const onErrorConfirm = ({ type }: { type: 'ddc' | 'contract' }) => {
    chat.open('LOANS')
    return analiticaErrorEvents[type]
  }

  const handleDownloadContract = useCallback(async (uri?: string) => {
    const response = await openContract(uri || '')
    const { data } = response
    if (data) {
      await exportFile({
        data,
        name: 'Contrato.pdf'
      })
    }
  }, [])

  const handleDownloadDDC = useCallback(async () => {
    const response = await openDDC(document, ddcUrl)
    const { data } = response
    if (data) {
      await exportFile({
        data,
        name: 'ddc.pdf'
      })
      analiticaDDC.events.loans.downloadDDC()
    }
  }, [document, ddcUrl])

  const handleOpenModalDownloadContract = () => {
    analitica.events.loanDetails.contractCardClicked('baixar contrato')
    setModalDownloadDocument({
      title: 'Baixar contrato?',
      onConfirm: () => handleDownloadContract(contractUri),
      onErrorConfirm: () => onErrorConfirm({ type: 'contract' }),
      isOpen: true
    })
  }

  const handleOpenModalDownloadDDC = () => {
    analiticaDDC.events.loans.clickInRequestDDC()
    setModalDownloadDocument({
      title: 'Baixar DDC?',
      onConfirm: handleDownloadDDC,
      onErrorConfirm: () => onErrorConfirm({ type: 'ddc' }),
      onErrorView: () => analiticaDDC.events.loans.negativeFeedbackView(),
      isOpen: true
    })
  }

  const handleOpenModalDownloadOriginalContract = () => {
    analitica.events.loanDetails.contractCardClicked('baixar contrato')
    setModalDownloadDocument({
      title: 'Baixar contrato?',
      onConfirm: () => handleDownloadContract(renegotiationData?.originalContractUri),
      onErrorConfirm: () => onErrorConfirm({ type: 'contract' }),
      isOpen: true
    })
  }

  const handleOpenModalDownloadRenegotiationTerms = () => {
    analitica.events.loanDetails.contractCardClicked('baixar contrato')
    setModalDownloadDocument({
      title: 'Baixar termos da renegociação?',
      onConfirm: () => handleDownloadContract(renegotiationData?.renegotiationContractUri),
      onErrorConfirm: () => onErrorConfirm({ type: 'contract' }),
      isOpen: true
    })
  }

  const documentTableRows = useMemo(
    () => [
      {
        uniqueId: 'loan-contract',
        cells: [
          {
            alignment: 'start',
            content: (
              <Text size="medium" color="neutralHigh" weight="semi">
                Contrato
              </Text>
            ),
            padding: '0.5rem'
          },
          {
            alignment: 'end',
            content: (
              <IconButton
                shape="pill"
                size="small"
                color="neutral"
                icon="action-arrow-download-outline"
                aria-label="Baixar contrato"
                onClick={handleOpenModalDownloadContract}
              />
            ),
            padding: '0.8rem'
          }
        ] as TableCellType[]
      },
      {
        uniqueId: 'loan-ddc',
        cells: [
          {
            alignment: 'start',
            content: (
              <>
                <Text size="medium" color="neutralHigh" weight="semi">
                  Descritivo de Crédito
                </Text>
                <Text size="small" color="neutralHigh" weight="regular">
                  O Documento Descritivo de Crédito (DDC) é um relatório que detalha as condições do seu contrato de
                  crédito.
                </Text>
              </>
            )
          },
          {
            alignment: 'end',
            content: (
              <IconButton
                shape="pill"
                size="small"
                color="neutral"
                icon="action-arrow-download-outline"
                aria-label="Baixar ddc"
                name="Baixar ddc"
                onClick={handleOpenModalDownloadDDC}
              />
            )
          }
        ] as TableCellType[]
      }
    ],
    [handleOpenModalDownloadContract, handleOpenModalDownloadDDC]
  )
  const goToRenegotiationComparisson = () => {
    BlackBird.travelTo({
      pathname: '/credito/emprestimo/${loan}/renegociacao-condicoes',
      urlParams: { loan: loanId }
    })
  }

  const formattedDate = formatDate(dueDate ?? '')

  if (isLoading || !contractUri) return <LoanDetailsContractInfoLoader />

  const monthlyInterestRateFormatted = rateUtils.formatToLimitedDecimalPlaces(monthlyInterestRate || 0)
  const monthlyInterestRateText = monthlyInterestRate ? `${monthlyInterestRateFormatted}% ao mês` : 'Sem juros'

  const yearlyInterestRateFormatted = rateUtils.formatToLimitedDecimalPlaces(yearlyInterestRate || 0)
  const yearlyInterestRateText = yearlyInterestRate ? `${yearlyInterestRateFormatted}% ao ano` : 'Sem juros'
  const handleCloseModal = () => {
    setModalDownloadDocument(null)
  }
  return (
    <>
      {modalDownloadDocument && (
        <SharedModalDownloadDocument
          {...modalDownloadDocument}
          onClose={handleCloseModal}
          isOpen={modalDownloadDocument.isOpen}
        />
      )}
      <Card>
        <CardHeader>
          <Overline color="neutral">Informações do contrato</Overline>
        </CardHeader>
        <CardBody removeSpacing="all">
          {renegotiationSummary?.status === 'Active' ? (
            <Box p="0 0.5rem">
              <Accordion
                trigger={
                  <Text
                    size="small"
                    weight="bold"
                    onClick={() => analitica.events.loanDetails.contractCardClicked('expandir informações')}
                  >
                    Detalhes do empréstimo
                  </Text>
                }
              >
                <Box backgroundColor="lightGray" padding="1.5rem 1rem" height={'auto'}>
                  <Stack space="0.75rem">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Valor renegociado</Description>
                      <Description>
                        <Money amount={creditAmount || 0} />
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Total a pagar</Description>
                      <Description>
                        <Money amount={totalAmount || 0} />
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Número de parcelas</Description>
                      <Description>{numberOfInstallments}</Description>
                    </Flex>

                    {plans && plans.length > 1 ? (
                      orderedPlans.map((plan, index) => (
                        <Flex justifyContent="space-between" alignItems="center" key={`${loanId}-plan-detail-${index}`}>
                          <Flex alignItems="center" gap="0.2rem">
                            <Description color="neutral">{plan.installments?.value} parcelas de</Description>
                            {plan.type === 'GraceWithInterest' || plan.type === 'InterestOnly' ? (
                              <LoanDetailsCustomTooltip
                                text={'Parcelas referentes aos juros acumulados sobre o tempo de carência.'}
                              />
                            ) : null}
                          </Flex>
                          <Description>{plan.amountByInstallment?.description}</Description>
                        </Flex>
                      ))
                    ) : plans && plans.length === 1 ? (
                      <Flex justifyContent="space-between" alignItems="center">
                        <Description color="neutral">Valor da parcela</Description>
                        <Description>{plans[0].amountByInstallment?.description}</Description>
                      </Flex>
                    ) : (
                      <Flex justifyContent="space-between" alignItems="center">
                        <Description color="neutral">Valor da parcela</Description>
                        <Description>
                          <Money amount={installmentAmount || 0} />
                        </Description>
                      </Flex>
                    )}

                    {gracePeriodInDays && gracePeriodInDays > 0 ? (
                      <>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Description color="neutral">Juros durante a carência?</Description>
                          <Description>Sim</Description>
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Flex alignItems="center" gap="0.2rem">
                            <Description color="neutral">Tempo de carência</Description>
                            <LoanDetailsCustomTooltip
                              text={
                                'O tempo de carência começa a ser contado após 30 dias a partir da data de criação da proposta.'
                              }
                            />
                          </Flex>
                          <Description>{gracePeriodInDays} dias</Description>
                        </Flex>
                      </>
                    ) : null}

                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Juros ao mês</Description>
                      <Description>{monthlyInterestRateText}</Description>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Juros ao ano</Description>
                      <Description>{yearlyInterestRateText}</Description>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Contrato vence em</Description>
                      <Description>{formattedDate}</Description>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex alignItems="center" gap="0.2rem">
                        <Description color="neutral">IOF financiado</Description>
                        <LoanDetailsCustomTooltip
                          text={'O IOF é um imposto federal aplicado sobre operações financeiras.'}
                        />
                      </Flex>
                      <Description>
                        <Money amount={iofAmount || 0} />
                      </Description>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex alignItems="center" gap="0.2rem">
                        <Description color="neutral">Custo Efetivo Total</Description>
                        <LoanDetailsCustomTooltip
                          text={
                            'O Custo Efetivo Total (CET) é a soma de todos os custos da operação de empréstimo, ou seja, juros e IOF.'
                          }
                        />
                      </Flex>
                      <Description>
                        {rateUtils.formatToLimitedDecimalPlaces(yearlyTotalEffectiveCostRate || 0)}% ao ano
                      </Description>
                    </Flex>

                    <If condition={Boolean(hasCreditInsurance)}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Description color="neutral">Seguro do empréstimo</Description>
                        <Description>Incluído</Description>
                      </Flex>
                    </If>
                  </Stack>
                </Box>
              </Accordion>
            </Box>
          ) : (
            <Box p="0 0.5rem">
              <Accordion
                trigger={
                  <Text
                    size="small"
                    weight="bold"
                    onClick={() => analitica.events.loanDetails.contractCardClicked('expandir informações')}
                  >
                    Detalhes do empréstimo
                  </Text>
                }
              >
                <Box backgroundColor="lightGray" padding="1.5rem 1rem">
                  <Stack space="0.75rem">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Valor do pedido</Description>
                      <Description>
                        <Money amount={creditAmount || 0} />
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">N.º de parcelas</Description>
                      <Description>
                        {numberOfInstallments}x de <Money amount={installmentAmount || 0} />
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Total das parcelas</Description>
                      <Description>
                        <Money amount={totalAmount || 0} />
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex alignItems="center">
                        <Description color="neutral">IOF financiado</Description>
                        <LoanDetailsCustomTooltip
                          text={'O IOF é um imposto federal aplicado sobre operações financeiras.'}
                        />
                      </Flex>
                      <Description>
                        <Money amount={iofAmount || 0} />
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Juros ao mês</Description>
                      <Description>{monthlyInterestRateText}</Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Juros ao ano</Description>
                      <Description>{yearlyInterestRateText}</Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex alignItems="center">
                        <Description color="neutral">Custo Efetivo Total</Description>
                        <LoanDetailsCustomTooltip
                          text={
                            'O Custo Efetivo Total (CET) é a soma de todos os custos da operação de empréstimo, ou seja, juros e IOF.'
                          }
                        />
                      </Flex>
                      <Description>
                        {rateUtils.formatToLimitedDecimalPlaces(yearlyTotalEffectiveCostRate || 0)}% ao ano
                      </Description>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Description color="neutral">Contrato vence em</Description>
                      <Description>{formattedDate}</Description>
                    </Flex>
                    <If condition={Boolean(hasCreditInsurance)}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Description color="neutral">Seguro do empréstimo</Description>
                        <Description>Incluído</Description>
                      </Flex>
                    </If>
                  </Stack>
                </Box>
              </Accordion>
            </Box>
          )}
          <Box p="0 0.5rem">
            <If condition={Boolean(guarantors)}>
              <Accordion
                trigger={
                  <Text size="small" weight="bold">
                    Garantidores
                  </Text>
                }
              >
                <Box backgroundColor="lightGray" padding="1.5rem 1rem">
                  <Stack space="0.25rem">
                    <Description>{guarantors?.owner.name}</Description>
                    <Description color="neutral">
                      CPF: {stringFormat.document(guarantors?.owner.document || '')}
                    </Description>
                  </Stack>
                </Box>
              </Accordion>
            </If>
            <Choose>
              <Choose.When condition={renegotiationData?.hasRenegotiation === true}>
                <Stack space="1rem">
                  <Accordion
                    trigger={
                      <Text
                        size="small"
                        weight="bold"
                        onClick={() => analitica.events.loanDetails.contractCardClicked('ver todos os contratos')}
                      >
                        Todos os contratos
                      </Text>
                    }
                  >
                    <Box backgroundColor="lightGray" padding="1.5rem 1rem">
                      <Stack space="1rem">
                        <Flex justifyContent="space-between" alignItems="center">
                          <Stack>
                            <Text size="small">Contrato original</Text>
                            <Text size="xSmall" color="neutral">
                              Assinado em {formatDate(renegotiationData?.originalContractDate || '')}
                            </Text>
                          </Stack>
                          <Button
                            color="neutral"
                            icon="action-arrow-download-outline"
                            aria-label="Baixar contrato original"
                            onClick={handleOpenModalDownloadOriginalContract}
                          />
                        </Flex>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Stack>
                            <Text size="small">Termos da renegociação</Text>
                            <Text size="xSmall" color="neutral">
                              Assinado em {formatDate(renegotiationData?.renegotiationContractDate || '')}
                            </Text>
                          </Stack>
                          <Button
                            color="neutral"
                            icon="action-arrow-download-outline"
                            aria-label="Baixar termos da renegociação"
                            onClick={handleOpenModalDownloadRenegotiationTerms}
                          />
                        </Flex>
                      </Stack>
                    </Box>
                  </Accordion>
                  <Button
                    color="neutral"
                    style={{ width: '100%' }}
                    onClick={() => {
                      goToRenegotiationComparisson()
                      analitica.events.loanDetails.contractCardClicked('conferir condições originais')
                    }}
                  >
                    Conferir condições originais
                  </Button>
                </Stack>
              </Choose.When>
              <Choose.Otherwise>
                <Box padding="0.5rem">
                  <Button
                    color="neutral"
                    style={{ width: '100%' }}
                    aria-label="Consultar documentos"
                    onClick={() => setDrawerDocumentIsOpen(true)}
                  >
                    Consultar documentos
                  </Button>
                </Box>
                <If condition={Boolean(hasCreditInsurance)}>
                  <Box padding="0.5rem">
                    <Button
                      color="neutral"
                      variant="contentOnly"
                      style={{ width: '100%' }}
                      aria-label="Conferir seguro"
                      onClick={() => {
                        BlackBird.travelTo({ pathname: '/seguros' })
                      }}
                    >
                      Conferir seguro
                    </Button>
                  </Box>
                </If>
              </Choose.Otherwise>
            </Choose>
            <Box mt="1.5rem" p="0.5rem 1rem 2.5rem">
              <Description color="neutral">
                Até a quitação, pedidos de novos empréstimos em instituições que também usem seus recebimentos como
                garantia podem ser recusados.
              </Description>
            </Box>
          </Box>
        </CardBody>
      </Card>
      <LoanDetailsDocumentsDrawer
        isOpen={drawerDocumentIsOpen}
        toggle={setDrawerDocumentIsOpen}
        tableRows={documentTableRows}
      />
    </>
  )
}
