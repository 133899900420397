import { Heimdall } from '~/domains/platform/core/heimdall'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type NegotiationsProposal, type RenegotiationProposal } from '../../loan-concession/entities'
import { type CreditOffer } from '../../shared'
import {
  fetchCreditOffer,
  fetchCreditOffers,
  fetchNegotiationsProposal,
  fetchProposalsByDocument
} from '../../shared/services/loans'
import { isAdminProposalBanner } from '../helpers/renegotiation'

const RENEGOTIATION_TYPES = ['Renegotiation', 'DiscountSettlement']

export interface RenegotiationData {
  offer?: CreditOffer
  proposal?: RenegotiationProposal
}

interface FetchRenegotiationProps {
  loanId: string
  offerId?: string
  document: string
}

const filterValidRenegotiationProposals = (proposals: NegotiationsProposal[]) => {
  return proposals.filter(({ type }) => RENEGOTIATION_TYPES.includes(type))
}

const getLastProposal = (proposals: NegotiationsProposal[]) => {
  return proposals[proposals.length - 1]
}

const getLastAdminProposal = (proposals: NegotiationsProposal[]) => {
  const adminProposals = proposals.filter(proposal =>
    isAdminProposalBanner(proposal as unknown as RenegotiationProposal)
  )
  return adminProposals[adminProposals.length - 1]
}

const buildReturn = (offer: CreditOffer, proposal: NegotiationsProposal) => {
  return { data: { offer, proposal } }
}

export async function fetchRenegotiation({ loanId, offerId, document }: FetchRenegotiationProps) {
  const offersResponse = offerId
    ? await fetchCreditOffer(offerId)
    : await fetchCreditOffers({ SecurityExternalReference: loanId })

  const { data: dataOffersResponse } = offersResponse
  let offersList: CreditOffer[] = []
  if (offerId) offersList.push(dataOffersResponse as CreditOffer)
  else if (dataOffersResponse && 'items' in dataOffersResponse) offersList = dataOffersResponse.items

  const renegotiationOffer = offersList.find(({ type }) => {
    return RENEGOTIATION_TYPES.includes(type)
  })

  if (renegotiationOffer?.id) {
    const hasMigrateBff = Heimdall.pass(['migrate_bff_proposals_renegotiaions'])
    const statusProcessing = 'Processing'
    const statusAvailable = 'Available'

    const processingProposals = await (hasMigrateBff
      ? fetchProposalsByDocument({
          offerId: renegotiationOffer?.id,
          status: statusProcessing,
          document
        })
      : fetchNegotiationsProposal({
          offerId: renegotiationOffer?.id,
          status: statusProcessing
        }))
    const validProcessingProposals = filterValidRenegotiationProposals(processingProposals.data.items)
    const processingAdminProposal = getLastAdminProposal(validProcessingProposals)

    if (validProcessingProposals.length > 0 && processingAdminProposal)
      return buildReturn(renegotiationOffer, processingAdminProposal)
    if (validProcessingProposals.length > 0)
      return buildReturn(renegotiationOffer, getLastProposal(validProcessingProposals))

    const availableProposals = await (hasMigrateBff
      ? fetchProposalsByDocument({
          offerId: renegotiationOffer?.id,
          status: statusAvailable,
          document
        })
      : fetchNegotiationsProposal({ offerId: renegotiationOffer?.id, status: statusAvailable }))
    const validAvailableProposals = filterValidRenegotiationProposals(availableProposals.data.items)
    const availableAdminProposals = getLastAdminProposal(validAvailableProposals)
    if (validAvailableProposals.length > 0 && availableAdminProposals)
      return buildReturn(renegotiationOffer, availableAdminProposals)
    if (validProcessingProposals.length > 0)
      return buildReturn(renegotiationOffer, getLastProposal(validAvailableProposals))
  }

  return { data: { offer: renegotiationOffer, proposal: undefined } }
}

interface RenegotiationDataProps {
  loanId: string
  offerId?: string
  document: string
}

export function useRenegotiationsData(
  { loanId, offerId, document }: RenegotiationDataProps,
  queryOptions?: UseQueryRequestOptions<RenegotiationData>
) {
  const { data: response, ...restQuery } = useQueryRequest<RenegotiationData>(
    ['useRenegotiationData', loanId, offerId],
    () => fetchRenegotiation({ loanId, offerId, document }),
    { ...queryOptions }
  )

  return { data: response?.data, ...restQuery }
}
