import { Stack } from '@dlpco/fluid-layout'
import { Alert, Heading, IconButton } from '@dlpco/ginga-stone'

import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { Box, Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'

import { LoanDetailsInstallmentList } from '../../loan-details/components/loan-detals-installment-list'
import { useLoanSummary } from '../../renegotiation/hooks/use-loan-summary'
import { useInstallmentPlans } from '../../shared/hooks/use-installments-plans'

import { RenegotiationFollowUpBreadcrumb } from './renegotiation-follow-up-breadcrumb'

export function RenegotiationFollowUpInstallments() {
  const { loan: loanId } = BlackBird.getQuery()

  const { data: summaryResponse, isLoading: isSummaryLoading, isError: isSummaryError } = useLoanSummary(loanId)
  const summary = summaryResponse?.previousSummary

  const {
    data: installments,
    isLoading: isInstallmentsLoading,
    isError: isInstallmentsError
  } = useInstallmentPlans({ installmentPlanId: summary?.installmentPlanId })

  if (isInstallmentsError || isSummaryError) return <SharedGenericCardError />

  return (
    <Box>
      <Stack space="2rem">
        <RenegotiationFollowUpBreadcrumb showArrow={false} currentStep="renegociacao-parcelas" />

        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="1rem">
            <IconButton
              icon="direction-arrow-left-outline"
              color="neutral"
              variant="contentOnly"
              size="large"
              onClick={BlackBird.back}
            />
            <Heading size="xLarge" weight="semi">
              Parcelas do contrato original
            </Heading>
          </Flex>
        </Flex>
        <Alert color="info" dismissible={false}>
          No empréstimo renegociado, as parcelas do seu contrato original ficam inativadas.
        </Alert>

        <LoanDetailsInstallmentList
          installmentPlanId={summary?.installmentPlanId}
          installments={installments?.items}
          isLoading={isSummaryLoading || isInstallmentsLoading}
          renegotiationOriginalContract={true}
        />
      </Stack>
    </Box>
  )
}
