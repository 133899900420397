import { Stack } from '@dlpco/fluid-layout'
import { Spinner, Text } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'

const SharedDownloadingDocument = () => {
  return (
    <Box textAlign="center" width="100%" height="100%" alignContent="center">
      <Stack space="1.5rem">
        <Spinner size="xlarge" color="brand" style={{ margin: '0 auto' }} />
        <Text variant="heading-medium">Estamos gerando seu documento</Text>
      </Stack>
    </Box>
  )
}

export { SharedDownloadingDocument }
