import { createContext, useContext, useState } from 'react'

import { type RenegotiationProposal } from '../../loan-concession/entities'
import { type CreditOffer, type LoanInstallment } from '../../shared/entities'
import { type RenegotiationPaymentMethod, type RenegotiationSimulation } from '../entities'

export function useDiscountSettlementContext() {
  const context = useContext(DiscountSettlementContext)
  return context
}

export function DiscountSettlementProvider({ children }: { children: React.ReactNode }) {
  const [proposal, setProposal] = useState<RenegotiationProposal | null>(null)
  const [offer, setOffer] = useState<CreditOffer | null>(null)
  const [simulation, setSimulation] = useState<RenegotiationSimulation | null>(null)
  const [installments, setInstallments] = useState<LoanInstallment[] | null>(null)
  const [paymentMethod, setPaymentMethod] = useState<RenegotiationPaymentMethod | null>(null)
  const [contractId, setContractId] = useState<string>('')

  const saveProposal = (data: RenegotiationProposal) => setProposal(data)
  const resetProposal = () => setProposal(null)
  const saveOffer = (data: CreditOffer) => setOffer(data)
  const saveSimulation = (data: RenegotiationSimulation) => setSimulation(data)
  const resetSimulation = () => setSimulation(null)
  const saveInstallments = (data: LoanInstallment[]) => setInstallments(data)
  const resetInstallments = () => setInstallments(null)
  const savePaymentMethod = (data: RenegotiationPaymentMethod) => setPaymentMethod(data)
  const resetPaymentMethod = () => setPaymentMethod(null)
  const saveContractId = (data: string) => setContractId(data)

  const contextValue = {
    proposal,
    saveProposal,
    resetProposal,
    offer,
    saveOffer,
    simulation,
    saveSimulation,
    resetSimulation,
    installments,
    saveInstallments,
    resetInstallments,
    paymentMethod,
    savePaymentMethod,
    resetPaymentMethod,
    contractId,
    saveContractId
  }
  return <DiscountSettlementContext.Provider value={contextValue}>{children}</DiscountSettlementContext.Provider>
}
interface ContextProps {
  proposal: RenegotiationProposal | null
  saveProposal: (data: RenegotiationProposal) => void
  resetProposal: () => void
  offer: CreditOffer | null
  saveOffer: (data: CreditOffer) => void
  simulation: RenegotiationSimulation | null
  saveSimulation: (data: RenegotiationSimulation) => void
  resetSimulation: () => void
  installments: LoanInstallment[] | null
  saveInstallments: (data: LoanInstallment[]) => void
  resetInstallments: () => void
  paymentMethod: RenegotiationPaymentMethod | null
  savePaymentMethod: (data: RenegotiationPaymentMethod) => void
  resetPaymentMethod: () => void
  contractId: string
  saveContractId: (data: string) => void
}

export const DiscountSettlementContext = createContext<ContextProps>({
  proposal: null,
  saveProposal: () => {},
  resetProposal: () => {},
  offer: null,
  saveOffer: () => {},
  simulation: null,
  saveSimulation: () => {},
  resetSimulation: () => {},
  installments: null,
  saveInstallments: () => {},
  resetInstallments: () => {},
  paymentMethod: null,
  savePaymentMethod: () => {},
  resetPaymentMethod: () => {},
  contractId: '',
  saveContractId: () => {}
})
