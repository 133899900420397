import { Icon } from '@dlpco/ginga-stone'

import { theme } from '~/ui/theme/theme'

import { Circle, GreenCircle } from './styles'
import { type Variant, type Variants } from './types'

export const variants: Record<Variants, Variant> = {
  checked: {
    icon: <Icon use="check-round-solid" color="primary" size="large" />,
    textColor: theme.colors.black
  },

  empty: {
    icon: <Circle />,
    textColor: theme.colors.mediumGray3
  },

  error: {
    icon: <Icon use="alert-round-solid" color="negative" size="large" />,
    textColor: theme.colors.highDanger
  },

  warning: {
    icon: <Icon use="alert-round-solid" color="warning" size="large" />,
    textColor: theme.colors.black
  },

  'in-progress': {
    icon: <GreenCircle />,
    textColor: theme.colors.black
  }
}
