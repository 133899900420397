import BlackBird from '~/domains/platform/lib/blackbird'

import { type RenegotiationProposal } from '../../loan-concession/entities'

export const RENEGOTIATON_VALID_STATUS = ['Processing', 'Available']
export const basePath = '/credito/emprestimo/${loan}/renegociacao/${step}'
export const basePathWithoutProposal = '/credito/emprestimo/${loan}/renegociacao/simulacao'

export const goToRenegotiationConditions = (loanId: string) => {
  BlackBird.travelTo({
    pathname: '/credito/emprestimo/${loan}/renegociacao-condicoes',
    urlParams: { loan: loanId }
  })
}
// TODO refatorar para não ficar duplicado
export function isAdminProposal(proposal?: RenegotiationProposal): boolean {
  return Boolean(proposal) && proposal?.type === 'DiscountSettlement' && proposal?.origin?.userType !== 'Customer'
}

export const acceptedRenegotiationTypeErrors = [
  'srn:error:credit_proposal_invalid_for_acceptance',
  'srn:error:credit_invalid_proposal_status_for_update_customer'
]

export function isAdminProposalBanner(proposal?: RenegotiationProposal): boolean {
  return (
    Boolean(proposal) &&
    ['DiscountSettlement', 'Renegotiation'].includes(proposal?.type || '') &&
    proposal?.origin?.userType !== 'Customer'
  )
}
