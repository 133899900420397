import { useEffect, useState } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Button, CardBody, Heading, Text } from '@dlpco/ginga-stone'
import { CoinNegativeError, CoinRefresh } from 'design-elements/shared/illustrations'

import analitica from '~/domains/credit/shared/helpers/spot-offer-analitica'
import { Box, Flex } from '~/domains/platform/design-system'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useDeusExMachina } from '~/domains/platform/infra/deus-ex-machina/hocs/with-deus-ex-machina'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { Modal } from '~/ui/components'

import { type SpotOfferPaymentMethod } from '../../../shared/entities'
import { type SpotOffer } from '../../../spot-offer/entities'
import { translatePaymentMethod } from '../../../spot-offer/helpers/spot-offer'
import { useSpotOffer } from '../../../spot-offer/hooks/use-spot-offer'
import { useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { findOfferConditionsType } from '../../helpers/discount-settlement'
import { useRenegotiationSpotOfferList } from '../../hooks/use-renegotiation-spot-offer'

import { renegotiationGoToLoan } from './discount-settlement-rate-flow'

interface SpotOfferDialogProps {
  isOpen: boolean
  onClose: () => void
  saveSpotOffer: (spotOffer?: SpotOffer) => void
  paymentMethod: SpotOfferPaymentMethod
}

type dialogStatus = 'loading' | 'success' | 'error' | 'email'

export function RenegotiationPaymentDialog(props: SpotOfferDialogProps) {
  const { isOpen, onClose, paymentMethod, saveSpotOffer } = props
  const { loan: loanId } = BlackBird.getQuery()
  const { deusExMachina } = useDeusExMachina()
  const entity = deusExMachina?.entity
  const [status, setStatus] = useState<dialogStatus>('loading')

  const { proposal, offer } = useDiscountSettlementContext()
  const {
    data: spotOffer,
    isError: hasError,
    refetch
  } = useRenegotiationSpotOfferList(
    { OriginExternalReference: proposal?.id ?? '', productExternalId: loanId, document: entity?.document || '' },
    {
      enabled: isOpen,
      onError: () => {
        if (offer?.type === 'DiscountSettlement') {
          analitica.events.flow.errorView('quitação com desconto')
        }
        analitica.events.flow.errorSpotOffer({
          paymentMethodStatus: '',
          paymentType: paymentMethod,
          spotOfferStatus: 'error',
          proposalId: proposal?.id ?? '',
          product: findOfferConditionsType(offer ?? undefined) ?? ''
        })
      }
    }
  )
  useEffect(() => {
    if (hasError) setStatus('error')
  }, [hasError, setStatus])

  const showSpotOffer = (spotOffer?: SpotOffer) => {
    onClose()
    saveSpotOffer(spotOffer)
  }

  const showEmail = () => setStatus('email')

  useSpotOffer({
    spotOfferId: spotOffer?.id ?? '',
    showEmail,
    showSpotOffer,
    productName: findOfferConditionsType(offer ?? undefined) ?? '',
    proposalId: proposal?.id ?? '',
    queryOptions: {
      enabled: !!spotOffer?.id,
      onError: () => {
        showEmail()
      }
    }
  })

  const tryAgainButton = () => {
    setStatus('loading')
    refetch()
  }

  const goBackButton = () => renegotiationGoToLoan()

  return (
    <Modal isOpen={isOpen}>
      <If condition={status !== 'loading'}>
        <Modal.Header>
          <Flex justifyContent="space-between" alignItems="center" p="2rem 2rem 1rem">
            <Heading size="large" weight="semi">
              Pagar com {translatePaymentMethod(paymentMethod)}
            </Heading>
          </Flex>
        </Modal.Header>
      </If>
      <Modal.Content>
        <Box p="0 2rem 2rem">
          <CardBody>
            <Box width="42rem">
              <Choose>
                <Choose.When condition={status == 'loading'}>
                  <Box height="30rem">
                    <Flex height="90%" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem">
                      <Loader size={64} />
                      <Heading size="large" weight="semi">
                        Estamos criando seu {translatePaymentMethod(paymentMethod, 'action')}
                      </Heading>
                    </Flex>
                  </Box>
                </Choose.When>
                <Choose.When condition={status !== 'loading'}>
                  <Stack space="2.75rem">
                    <Flex justifyContent="center">
                      <If condition={hasError}>
                        <CoinNegativeError />
                      </If>
                      <If condition={status === 'email'}>
                        <CoinRefresh />
                      </If>
                    </Flex>
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" gap="1rem">
                      <Heading weight="semi">
                        <If condition={status === 'email'}>
                          Estamos criando seu {translatePaymentMethod(paymentMethod, 'action')}
                        </If>
                        <If condition={hasError}>
                          Não conseguimos criar seu {translatePaymentMethod(paymentMethod, 'action')}
                        </If>
                      </Heading>
                      <Text color="neutral">
                        <If condition={status === 'email'}>
                          Vamos te enviar o código por e-mail assim que ele estiver pronto.
                        </If>
                        <If condition={hasError}>Verifique sua conexão e tente de novo.</If>
                      </Text>
                    </Flex>
                    <Flex justifyContent="center" gap="1.75rem">
                      <Button color="neutral" shape="pill" onClick={goBackButton}>
                        Voltar para empréstimo
                      </Button>
                      <If condition={hasError}>
                        <Button shape="pill" onClick={tryAgainButton}>
                          Tentar de novo
                        </Button>
                      </If>
                    </Flex>
                  </Stack>
                </Choose.When>
              </Choose>
            </Box>
          </CardBody>
        </Box>
      </Modal.Content>
    </Modal>
  )
}
