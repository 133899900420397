import { type CSSProperties } from 'react'

import { Box, Flex } from '~/domains/platform/design-system'

type DynamicProgressBarProps = {
  progressBarList: { maxValue: number; currentValue: number | 'fill'; surfaceColor: string }[]
  style: CSSProperties
}
export function DynamicProgressBar({ progressBarList, style }: DynamicProgressBarProps) {
  const receivedBorderRadius = style.borderRadius || 0
  const borderRadiusFirstIndex = `${receivedBorderRadius} 0 0 ${receivedBorderRadius}`
  const borderRadiusLastIndex = `0 ${receivedBorderRadius} ${receivedBorderRadius} 0`

  return (
    <Flex style={style}>
      {progressBarList.map((item, index) => {
        const currentBorderRadius =
          index === 0 ? borderRadiusFirstIndex : index === progressBarList.length - 1 ? borderRadiusLastIndex : ''

        const progressBarWidth =
          item.currentValue === 'fill' ? 'unset' : ((item.currentValue * 100) / item.maxValue).toString() + '%'

        return (
          <Box
            data-testid={'progressBar' + index}
            key={'progressBar' + index}
            backgroundColor={item.surfaceColor}
            borderRadius={currentBorderRadius}
            width={progressBarWidth}
            flex={item.currentValue === 'fill' ? 1 : 'unset'}
          />
        )
      })}
    </Flex>
  )
}
