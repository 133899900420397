import { createContext, useContext, useState } from 'react'

import { type Assessment, type AssessmentReason } from '~/domains/stone-account/ports/entities/KYC'

import { type LoanConcessionKycChecksAnswers } from '../entities'
import { type KycStepperData, type KycSteps } from '../helpers/kyc-steps'

export function useKycContext() {
  const context = useContext(KycContext)
  return context
}

export type KYCReason = {
  reason: AssessmentReason | 'credit'
}

export type StepDirection = 'NEXT' | 'PREV'

export type StepChange = {
  step: KycSteps
  direction: StepDirection
}

export function KycProvider({ children }: { children: React.ReactNode }) {
  const [reason, setReason] = useState<KYCReason | null>(null)
  const [steps, setSteps] = useState<KycStepperData[]>([] as KycStepperData[])
  const [currentStep, setCurrentStep] = useState<KycStepperData>({} as KycStepperData)
  const [kycAnswerData, setKycAnswerData] = useState<LoanConcessionKycChecksAnswers>(
    {} as LoanConcessionKycChecksAnswers
  )
  const [assessment, setAssessment] = useState<Assessment>({} as Assessment)
  const [stepChange, setStepChange] = useState<StepChange | undefined>()

  const saveReason = (reason: KYCReason) => {
    setReason(reason)
  }
  const saveSteps = (steps: KycStepperData[]) => {
    setSteps(steps)
    setCurrentStep(steps[0])
  }

  const generateStepChange = (step: KycSteps, direction: StepDirection) => {
    setStepChange({
      step,
      direction
    })
  }

  const resetStepChange = () => {
    setStepChange(undefined)
  }

  const nextStep = () => {
    generateStepChange(currentStep.key, 'NEXT')
    const stepIndex = steps.indexOf(currentStep)
    if (stepIndex !== -1 && stepIndex + 1 < steps.length) {
      setCurrentStep(steps[stepIndex + 1])
    } else if (stepIndex + 1 >= steps.length) {
      setCurrentStep(steps[0])
    }
  }

  const hasNextStep = () => {
    return !!steps[steps.indexOf(currentStep) + 1]
  }

  const prevStep = () => {
    generateStepChange(currentStep.key, 'PREV')
    const stepIndex = steps.indexOf(currentStep)
    if (stepIndex !== -1) setCurrentStep(steps[stepIndex - 1])
  }

  const hasPrevStep = () => {
    return !!steps[steps.indexOf(currentStep) - 1]
  }

  const saveKycAnswerData = (data: LoanConcessionKycChecksAnswers) => {
    setKycAnswerData(data)
  }

  const saveAssessment = (data: Assessment) => {
    setAssessment(data)
  }

  const contextValue = {
    nextStep,
    prevStep,
    hasNextStep,
    hasPrevStep,
    stepChange,
    resetStepChange,
    saveSteps,
    steps,
    currentStep,

    reason,
    saveReason,

    saveKycAnswerData,
    kycAnswerData,
    saveAssessment,
    assessment
  }
  return <KycContext.Provider value={contextValue}>{children}</KycContext.Provider>
}

interface KycContextProps {
  currentStep: KycStepperData
  steps: KycStepperData[]
  saveSteps: (data: KycStepperData[]) => void
  nextStep: () => void
  prevStep: () => void
  hasNextStep: () => boolean
  hasPrevStep: () => boolean
  stepChange: StepChange | undefined
  resetStepChange: () => void
  kycAnswerData: LoanConcessionKycChecksAnswers
  saveKycAnswerData: (data: LoanConcessionKycChecksAnswers) => void
  assessment: Assessment
  saveAssessment: (data: Assessment) => void
  saveReason: (data: KYCReason) => void
  reason: KYCReason | null
}

export const KycContext = createContext<KycContextProps>({
  currentStep: {} as KycStepperData,
  steps: [],
  saveSteps: () => {},
  nextStep: () => {},
  prevStep: () => {},
  hasNextStep: () => false,
  hasPrevStep: () => false,
  stepChange: undefined,
  resetStepChange: () => {},
  kycAnswerData: {} as LoanConcessionKycChecksAnswers,
  saveKycAnswerData: () => {},
  assessment: {} as Assessment,
  saveAssessment: () => {},
  saveReason: () => {},
  reason: null
})
