import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type UseCreditoffersReturn } from '../../shared/entities'
import { useLoanConcessionContext } from '../context/loan-concession-context'
import { type NegotiationsProposal, type NegotiationsSimulationResult, type UniversalProposalData } from '../entities'
import { formatSimulationData } from '../helpers/loan-concession-simulation-data'
import { offerSimulate } from '../services/proposal'

type useLoanConcessionSimulationProps = {
  creditOffer: UseCreditoffersReturn
  amount: number
  proposalAvailable?: NegotiationsProposal
  proposalProcessing?: NegotiationsProposal
}

export function useLoanConcessionSimulation(
  { creditOffer, amount, proposalAvailable, proposalProcessing }: useLoanConcessionSimulationProps,
  queryOptions?: UseQueryRequestOptions<UniversalProposalData>
) {
  const { saveCanSimulate } = useLoanConcessionContext()
  const hasNoProposal = Boolean(!proposalAvailable) && Boolean(!proposalProcessing)
  const simulateProps = { creditOffer: creditOffer?.offer, requestedAmount: amount }

  const { data: response, ...restQuery } = useQueryRequest<UniversalProposalData>(
    ['useLoanConcessionSimulation'],
    () => offerSimulate(simulateProps),
    { ...queryOptions, enabled: hasNoProposal }
  )

  if (hasNoProposal || proposalAvailable?.origin?.userType === 'Customer') saveCanSimulate(true)

  const proposalData = hasNoProposal
    ? (formatSimulationData({
        creditOffer,
        simulationProposal: response?.data as unknown as NegotiationsSimulationResult
      }) as UniversalProposalData)
    : formatSimulationData({ creditOffer, proposal: proposalAvailable })
  return { data: proposalData, ...restQuery }
}
