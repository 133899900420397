import { type CreditPaging } from '~/domains/credit/shared/entities'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type Contract } from '../../loan-concession/entities'
import { httpClientCreditNegotiations } from '../../loan-concession/infra/http-client-credit-negotiations'

type GetContractParams = {
  id?: string
  originId?: string
}

const fetchContracts = (getContractParams?: GetContractParams) => {
  return httpClientCreditNegotiations<CreditPaging<Contract[]>>({
    url: '/contracts-service/api/v1/contracts',
    method: 'GET',
    params: getContractParams
  })
}

export function useContracts(params?: GetContractParams, enabled = false) {
  const { data: response, ...restQuery } = useQueryRequest<CreditPaging<Contract[]>>(
    ['useContracts'],
    () => fetchContracts(params),
    { enabled }
  )

  return {
    ...restQuery,
    data: response?.data
  }
}
