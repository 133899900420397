import Image from 'next/image'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Text } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'

type SharedConfirmDownloadProps = {
  title: string
  onConfirm: () => void
}
const SharedConfirmDownload = ({ title, onConfirm }: SharedConfirmDownloadProps) => {
  return (
    <>
      <Box textAlign="center" width="100%" alignContent="center">
        <Stack space="1.5rem">
          <Image src="/document.svg" width={150} height={150} alt="" style={{ margin: '0 auto' }} />
          <Stack space=".5rem">
            <Text variant="heading-medium">{title}</Text>
            <Text variant="text-medium" color="high">
              A transferência do documento leva alguns segundos.
            </Text>
          </Stack>
        </Stack>
      </Box>
      <Button variant="primary-solid" style={{ marginTop: '1.5rem' }} onClick={onConfirm} size="large">
        Confirmar
      </Button>
    </>
  )
}
export { SharedConfirmDownload }
