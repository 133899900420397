import { useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { useRouter } from 'next/router'
import {
  DateTime,
  Heading,
  IconButton,
  Money,
  Table,
  TableBody,
  TableCell,
  TableEmptyState,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text,
  ToggleChip
} from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Choose, For } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { type InstallmentStatementItem } from '../entities'
import installmentDetailsAnalitica from '../helpers/installment-details-analitica'
import { useInstallmentStatements } from '../hooks/use-installment-statements'

import { LoanInstallmentDetailsListLoading } from './loan-installment-details-list-loading'
import { LoanInstallmentDetailsSidebar } from './loan-installment-details-sidebar'

import { BoxTable } from './loan-installment-details-list.styles'

interface InstallmentListProps {
  renegotiationLoan?: boolean
  installmentNumber?: string
  installmentPlanId?: string
  hideInterestEconomies?: boolean
}

function getPaginationRangeArray(currentPage: number, pageCount: number) {
  const start = Math.max(currentPage - 2, 1)
  const end = Math.min(currentPage + 2, pageCount)

  return Array.from({ length: end - start + 1 }, (_, index) => start + index)
}

export const LoanInstallmentDetailsList = (props: InstallmentListProps) => {
  const { installmentNumber, installmentPlanId, renegotiationLoan, hideInterestEconomies } = props

  const { loan: loanId }: { loan?: string } = useRouter().query

  const [currentPage, setCurrentPage] = useState(1)
  const [openToggle, setToggle] = useToggle(false)
  const [currentStatement, setCurrentStatement] = useState<InstallmentStatementItem | any>(
    {} as InstallmentStatementItem
  )

  const { data, isLoading } = useInstallmentStatements({
    installmentPlanId,
    installmentNumber,
    pageNumber: currentPage
  })

  useEffect(() => {
    if (!isLoading) {
      if (!renegotiationLoan) installmentDetailsAnalitica.events.statementDetails.view(loanId ?? '')
      else installmentDetailsAnalitica.events.originalContract.statementsView(loanId ?? '')
    }
  }, [isLoading, loanId, renegotiationLoan])

  if (isLoading || data === undefined) return <LoanInstallmentDetailsListLoading />

  return (
    <>
      <BoxTable>
        <Heading size="xSmall" weight="semi">
          Detalhes da Parcela {installmentNumber}
        </Heading>

        <Table clickableRows style={{ marginTop: `${theme.space.medium}` }}>
          <Choose>
            <Choose.When condition={data?.items.length > 0}>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>Data</TableHeaderCell>
                  <TableHeaderCell alignment="end">Somatório do dia</TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>
              <TableBody>
                <For
                  of={data.items}
                  render={({ date, entriesSum }, index) => {
                    return (
                      <TableRow
                        key={date + index}
                        uniqueId={date + index}
                        onRowClick={() => {
                          const statement = data.items.find(item => item.date === date) || {}
                          setCurrentStatement(statement)
                          setToggle(true)
                        }}
                      >
                        <TableCell>{<DateTime date={date} format="DD MMM YYYY" />}</TableCell>
                        <TableCell alignment="end">
                          <Text weight="semi">
                            <Money amount={Math.abs(entriesSum)} />
                          </Text>
                        </TableCell>
                      </TableRow>
                    )
                  }}
                />
                <TableRow hideAction uniqueId="paging">
                  <TableCell alignment="end" colSpan={2}>
                    <Flex justifyContent="flex-end">
                      <IconButton
                        size="small"
                        color="neutral"
                        icon="chevron-left-outline"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      />

                      <For
                        of={getPaginationRangeArray(currentPage, data.paging.pageCount)}
                        render={item => {
                          const isCurrentPage = item === currentPage
                          return (
                            <Box ml="0.5rem" key={item}>
                              <ToggleChip
                                name="current-page"
                                type="radio"
                                defaultChecked={isCurrentPage}
                                onChange={() => setCurrentPage(item)}
                              >
                                {item}
                              </ToggleChip>
                            </Box>
                          )
                        }}
                      />
                      <Box ml="0.5rem">
                        <IconButton
                          size="small"
                          color="neutral"
                          icon="chevron-right-outline"
                          disabled={currentPage === data.paging.pageCount}
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </Box>
                    </Flex>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Choose.When>
            <Choose.Otherwise>
              <TableBody>
                <TableEmptyState heading="Essa parcela ainda não recebeu pagamentos">
                  Você vai poder conferir aqui pagamentos e cobranças da parcela.
                </TableEmptyState>
              </TableBody>
            </Choose.Otherwise>
          </Choose>
        </Table>
      </BoxTable>

      <LoanInstallmentDetailsSidebar
        isOpen={openToggle}
        toggleSidebar={(toggle: boolean) => setToggle(toggle)}
        entries={currentStatement?.entries}
        date={currentStatement.date}
        entriesSum={currentStatement.entriesSum}
        hideInterestEconomies={hideInterestEconomies}
      />
    </>
  )
}
