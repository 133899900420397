import { Center, Stack } from '@dlpco/fluid-layout'

import { date as dateHelper } from '~/lib/helpers'

import { Day, Month } from './styles'

export interface CompactDateProps {
  date: string
}

export const CompactDate = ({ date }: CompactDateProps) => {
  return (
    <Stack space="0.375rem">
      <Center>
        <Day>{dateHelper.simpleFormat('D', date)}</Day>
      </Center>
      <Center>
        <Month>{dateHelper.simpleFormat('MMM', date)}</Month>
      </Center>
    </Stack>
  )
}
