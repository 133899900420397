import { type ReactElement } from 'react'
import { type NavigationBarProps, NavigationBar } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Flex } from '~/domains/platform/design-system'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'
import { type BlackBirdUrl } from '~/domains/platform/lib/blackbird/blackbird'

type WithCustomNavbarProps = {
  children: ReactElement
  navigationBarTitle: string
  navigationBarNavigateButtonTarget: string | BlackBirdUrl<string> | (() => void)
  navigationBarNavigateButtonType: 'back' | 'close'
  backgroundColor?: 'body' | 'surface'
  onNavigateEvent?: () => void
  trailingItem?: NavigationBarProps['trailingItem']
}

export function SharedWithCustomNavbar({
  children,
  navigationBarTitle,
  navigationBarNavigateButtonTarget,
  navigationBarNavigateButtonType,
  backgroundColor = 'body',
  onNavigateEvent,
  trailingItem
}: WithCustomNavbarProps) {
  const handleOnNavigate = () => {
    if (typeof onNavigateEvent === 'function') {
      onNavigateEvent()
    }
    if (typeof navigationBarNavigateButtonTarget === 'function') {
      navigationBarNavigateButtonTarget()
    } else {
      BlackBird.travelTo(navigationBarNavigateButtonTarget as Routes)
    }
  }

  return (
    <>
      <NavigationBar
        background={backgroundColor}
        label={navigationBarTitle}
        navigateType={navigationBarNavigateButtonType}
        onNavigate={() => handleOnNavigate()}
        trailingItem={trailingItem}
      />
      <Flex style={{ background: jadeTheme.theme.color.background[backgroundColor] }} justifyContent="center">
        {children}
      </Flex>
    </>
  )
}
