import { Stack } from '@dlpco/fluid-layout'
import { Divider } from '@stone-payments/jade'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'

import { type LoanDetails } from '../entities'

import { LoanDetailsJadeAsideContractInformations } from './loan-details-jade-aside-contract-informations'
import { LoanDetailsJadeAsideGeneralLoanInformations } from './loan-details-jade-aside-general-loan-informations'
import { LoanDetailsJadeAsideGuarantorInformation } from './loan-details-jade-aside-guarantor-information'
import { LoanDetailsJadeAsideRetentionStatus } from './loan-details-jade-aside-retention-status'

export function Aside({ aboutLoan, generalInformation, warranties, retention }: LoanDetails) {
  return (
    <Stack space="1.5rem">
      <SharedCustomCard style={{ width: '280px', height: 'fit-content', paddingBottom: '0.75rem' }}>
        <LoanDetailsJadeAsideGeneralLoanInformations generalInformation={generalInformation} />

        <Divider />

        <LoanDetailsJadeAsideContractInformations aboutLoan={aboutLoan} />

        <Divider />

        <LoanDetailsJadeAsideGuarantorInformation warranties={warranties} />
      </SharedCustomCard>

      <LoanDetailsJadeAsideRetentionStatus retention={retention} loanStatus={generalInformation.status} />
    </Stack>
  )
}
