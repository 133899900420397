import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Heading } from '@dlpco/ginga-stone'

import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { Box } from '~/domains/platform/design-system'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import Wong from '~/domains/platform/lib/wong'

import { SharedKycFlow } from '../../../shared/components/shared-kyc-flow'
import { RenegotiationKyc } from '../../components/renegotiation-pre-kyc'
import { RenegotiationProvider } from '../../context/renegotiation-context'
import { type RenegotiationProposalId, RenegotiationSteps } from '../../entities'
import { basePath } from '../../helpers/renegotiation'
import { useProposalByIdV2 } from '../../hooks/use-proposal-by-id-v2'

import { RenegotiationChallenge } from './renegotiation-challenge'
import { RenegotiationFlowBreadcrumb } from './renegotiation-flow-breadcrumb'
import { RenegotiationFlowGuard } from './renegotiation-flow-guard'
import { RenegotiationFlowLoading } from './renegotiation-flow-loading'
import { RenegotiationInstallmentPlanDrawer } from './renegotiation-installment-plan-drawer'
import { RenegotiationProposal } from './renegotiation-proposal'
import { RenegotiationReview } from './renegotiation-review'

interface RenegotiationFlowProps extends WithPageConfigProps<'stone_account' | 'banking' | 'stone_account_kyc'> {
  proposalId?: string
}

export function RenegotiationFlow({ subject, proposalId }: RenegotiationFlowProps) {
  const { proposalId: proposalQueryId, step } = BlackBird.getQuery()
  const proposal = proposalId ?? proposalQueryId
  const { data, isLoading, isError, isFetched, refetch: proposalRefetch } = useProposalByIdV2(proposal)
  const [isDrawerOpen, toggleInstallmentsDrawer] = useToggle(false)

  const steps: { key: RenegotiationSteps; render: () => JSX.Element }[] = [
    {
      key: RenegotiationSteps.PROPOSAL,
      render: () => (
        <RenegotiationProposal
          renegotiation={data ?? ({} as RenegotiationProposalId)}
          proposalRefetch={proposalRefetch}
          showInstallmentsPlan={() => toggleInstallmentsDrawer()}
        />
      )
    },
    {
      key: RenegotiationSteps.KYC,
      render: () => (
        <SharedKycFlow
          review={
            <RenegotiationKyc
              userId={subject.id}
              proposalData={data as RenegotiationProposalId}
              proposalRefetch={proposalRefetch}
            />
          }
        />
      )
    },
    {
      key: RenegotiationSteps.REVIEW,
      render: () => (
        <RenegotiationReview
          proposal={data ?? ({} as RenegotiationProposalId)}
          showInstallmentsPlan={() => toggleInstallmentsDrawer()}
        />
      )
    },
    {
      key: RenegotiationSteps.PIN,
      render: () => <RenegotiationChallenge />
    }
  ]

  if (isLoading) return <RenegotiationFlowLoading />

  if (isError || (isFetched && !data) || !proposal) return <SharedGenericCardError />

  return (
    <Box>
      <Stack space="1rem">
        <RenegotiationFlowBreadcrumb step={step ?? ''} />
        <Heading size="xLarge" weight="semi">
          Renegociação
        </Heading>

        <RenegotiationFlowGuard proposal={data}>
          <RenegotiationProvider>
            <Wong steps={steps} basePath={basePath} />
            <RenegotiationInstallmentPlanDrawer
              isOpen={isDrawerOpen}
              toggle={toggleInstallmentsDrawer}
              installments={data?.proposal.installments}
              paymentInfo={{
                gracePeriod: data?.proposal.conditions.gracePeriod,
                hasGracePeriodInterest: data?.proposal.conditions.hasGracePeriodInterest
              }}
            />
          </RenegotiationProvider>
        </RenegotiationFlowGuard>
      </Stack>
    </Box>
  )
}
