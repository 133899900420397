import { useState } from 'react'

import { type CreditPaging } from '~/domains/credit/shared/entities'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type SpotOffer } from '../../spot-offer/entities'
import { type fetchSpotOffersProps, fetchSpotOffers } from '../../spot-offer/hooks/use-spot-offer'

export function useRenegotiationSpotOfferList(
  params: fetchSpotOffersProps,
  queryOptions?: UseQueryRequestOptions<CreditPaging<SpotOffer[]>>
) {
  const [shouldRefetch, setShouldRefetch] = useState(true)
  const [count, setCount] = useState(0)
  const { data: response, ...restQuery } = useQueryRequest<CreditPaging<SpotOffer[]>>(
    ['useRenegotiationSpotOfferList'],
    () => fetchSpotOffers(params),
    {
      ...queryOptions,
      onSettled: (response: any) => {
        const { data } = response
        const spotOffer = data?.items[0]
        if (spotOffer) setShouldRefetch(false)
        else if (count > 7) setShouldRefetch(false)
        setCount(current => current + 1)
      },
      refetchInterval: shouldRefetch ? 3000 : false,
      retry: 7
    }
  )
  return {
    ...restQuery,
    data: response?.data.items[0],
    isError: (count > 7 && !response?.data.items[0]) || restQuery.isError
  }
}
