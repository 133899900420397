import { useEffectOnce, useToggle } from 'react-use'

import { ConcessionOnboardingModal } from '~/domains/credit/revolving-credit/concession/components/concession-onboarding-modal'
import { SharedCreditBanner } from '~/domains/credit/shared/components/credit-banner'
import { Choose } from '~/domains/platform/lib/utilities-components'

import { type OpportunitySectionElement } from '../entities'
import { type BannerClickProps, type BannerViewProps } from '../helpers/analitica'
import { useLoanHomeProposalBanner } from '../hooks/use-loan-home-proposal-banner'

import { LoanHomeFullBannerBase } from './loan-home-full-banner-base'

type LoanHomeProposalBannerProps = {
  opportunity: OpportunitySectionElement
}

export function LoanHomeProposalBanner(props: Readonly<LoanHomeProposalBannerProps>) {
  const [revolvingCreditIsOpen, toggleOpenRevolvingCredit] = useToggle(false)
  const { opportunity } = props
  const { bannerEventActions, banner, type, size, loanHomeNavigation } = useLoanHomeProposalBanner(opportunity)
  const shouldConsiderBannerType = type !== 'NotMapped'
  const cardLayout = size === 'large' ? 'com destaque' : 'sem destaque'
  const { offerId } = opportunity
  const { id, amount, expirationDate, type: opportunityType } = opportunity

  useEffectOnce(() => {
    const eventProps: BannerViewProps = {
      id,
      amount,
      expirationDate,
      type: opportunityType,
      cardLayout
    }
    if (shouldConsiderBannerType) {
      bannerEventActions({ eventProps, eventType: 'view', productType: type })
    }
  })

  const handleButtonClick = () => {
    const eventProps: BannerClickProps = {
      id,
      expirationDate,
      type: opportunityType,
      amount
    }
    if (shouldConsiderBannerType) {
      bannerEventActions({ eventProps, eventType: 'click', productType: type })
      loanHomeNavigation({ offerId, type, id, openRevolvingCredit: toggleOpenRevolvingCredit })
    }
  }
  if (shouldConsiderBannerType) {
    return (
      <>
        <Choose>
          <Choose.When condition={size === 'large'}>
            <LoanHomeFullBannerBase {...banner[type][size]} buttonAction={() => handleButtonClick()} />
          </Choose.When>
          <Choose.When condition={size === 'small'}>
            <SharedCreditBanner
              {...(banner[type][size] as any)}
              showButton={true}
              buttonAction={() => handleButtonClick()}
            />
          </Choose.When>
        </Choose>
        <div style={{ position: 'absolute' }}>
          <ConcessionOnboardingModal
            amount={amount}
            open={revolvingCreditIsOpen}
            offerId={id}
            toggle={toggleOpenRevolvingCredit}
          />
        </div>
      </>
    )
  }
  return null
}
