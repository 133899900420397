import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, Heading } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { trackEvents } from '~/lib/helpers/utils/ports/analytics'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { type UniversalProposalData } from '../entities'
import { hasProposalContent } from '../helpers/loan-concession-simulation-data'

import { LoanConcessionSimulationInput } from './loan-concession-simulation-input'
import { LoanConcessionSimulationResult } from './loan-concession-simulation-results'

import { ResponsiveFlexContainer } from '../helpers/loan-concession.styles'

interface LoanConcessionSimulationProps {
  proposalData: UniversalProposalData
  isLoading: boolean
  onSimulate: (amount: number) => void
  onContinue?: () => void
}

export function LoanConcessionSimulationContent({
  proposalData,
  isLoading,
  onSimulate,
  onContinue
}: LoanConcessionSimulationProps) {
  const { isViewSmall } = useViewSize()
  const [inputChange, setInputChange] = useState<number>()
  const [loadingPage, setLoadingPage] = useState(false)
  const [disableContinue, setDisableContinue] = useState(false)

  const hasProposalDataContent = hasProposalContent(proposalData)

  useEffectOnce(() => {
    trackEvents({
      trackServices: ['analytics'],
      action: 'credit_simulation_view',
      category: 'Credit',
      label: 'Cliente visualiza a tela de simulação'
    })
  })

  return (
    <Stack space="2rem">
      <Heading size="large" weight="bold">
        Simular empréstimo
      </Heading>
      <Card spacing={isViewSmall ? 'small' : 'large'}>
        <CardBody>
          <ResponsiveFlexContainer>
            <Box width={isViewSmall ? 'auto' : '40%'}>
              <Flex flexDirection="column">
                <LoanConcessionSimulationInput
                  onValue={onSimulate}
                  minValue={proposalData?.minValue || 0}
                  maxValue={proposalData?.maxValue || 0}
                  initialValue={proposalData?.inputValue}
                  loading={isLoading}
                  setInputChange={setInputChange}
                  requestedAmount={proposalData?.requestedAmount}
                />
              </Flex>
            </Box>

            <Box width={isViewSmall ? 'auto' : '60%'}>
              {hasProposalDataContent ? (
                <>
                  <LoanConcessionSimulationResult
                    proposalData={proposalData}
                    inputChange={inputChange}
                    isLoading={isLoading}
                  />
                  <Flex mt="1.5rem">
                    <Box width="100%">
                      <Button
                        shape="pill"
                        style={{
                          width: '100%'
                        }}
                        icon="direction-arrow-right-outline"
                        iconPosition="end"
                        data-test-id="simulation-continue-button"
                        onClick={() => {
                          setDisableContinue(true)
                          setLoadingPage(true)
                          onContinue && onContinue()
                          trackEvents({
                            trackServices: ['analytics'],
                            action: 'credit_update_guarantor_click',
                            category: 'Credit',
                            label: 'Monitora quantos usuários vão pra a atualização de dados.'
                          })
                        }}
                        disabled={isLoading || disableContinue}
                      >
                        Continuar
                      </Button>
                    </Box>
                  </Flex>
                </>
              ) : (
                <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                  <Loader />
                </Flex>
              )}
            </Box>
          </ResponsiveFlexContainer>

          {(isLoading || loadingPage || disableContinue) && (
            <Dimmer isVisible>
              <Loader />
            </Dimmer>
          )}
        </CardBody>
      </Card>
    </Stack>
  )
}
