export const retryQueryOnError = (failureCount: number, error: any): boolean => {
  if (error.response?.status >= 400 && error.response?.status <= 499) {
    return false
  }

  const retryAttempt = failureCount + 1
  if (retryAttempt >= 3) {
    return false
  }

  return true
}
