import { Box } from '@dlpco/fluid-layout'
import { Alert, GroupHeader, ListItem, Tag, Text } from '@stone-payments/jade'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'
import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

import { type LoanDetails } from '../entities'

export function LoanDetailsJadeAsideRetentionStatus({
  retention,
  loanStatus
}: {
  retention: LoanDetails['retention']
  loanStatus: LoanDetails['generalInformation']['status']
}) {
  return (
    <SharedCustomCard style={{ width: '280px', height: 'fit-content', paddingBottom: '0.75rem' }}>
      <GroupHeader title="Pagamento automático" />
      <ListItem
        content={<Text color="medium">Situação</Text>}
        trailing={
          <Tag variant={retention.status.value === 'enabled' ? 'positive' : 'warning'} size="small" solid={false}>
            {retention.status.description}
          </Tag>
        }
        variant="neutral"
      />
      <If condition={retention.status.value === 'enabled'}>
        <ListItem
          content={<Text color="medium">Forma</Text>}
          trailing={
            <Flex flexDirection="column" alignItems="flex-end">
              <Text color="high" weight="semibold">
                {retention.rate.description}
              </Text>
              <Text color="medium" variant="text-small">
                dos recebimentos diários
              </Text>
            </Flex>
          }
          variant="neutral"
        />
      </If>
      <If condition={loanStatus === 'overdue' && retention.rate.value === 1.0}>
        <Box padding="0.5rem 1.25rem 1rem">
          <Alert
            description="Como sua parcela está em atraso, o percentual aumentou para 100%."
            showIcon
            variant="neutral"
          />
        </Box>
      </If>
    </SharedCustomCard>
  )
}
