import { createContext, useContext, useState } from 'react'

import {
  type InsuranceContract,
  type LoanConcessionKycChecksAnswers,
  type MainPartnerReviewGuarantors,
  type NegotiationsProposal,
  type UniversalProposalData
} from '../entities'

export function useLoanConcessionContext() {
  const context = useContext(ProposalContext)
  return context
}
export interface MainPartnerProps {
  kycAnswerData: LoanConcessionKycChecksAnswers
  document: string
  name: string
}

export function ProposalProvider({ children }: { children: React.ReactNode }) {
  const [proposalSimulationData, setLoanConcessionSimulationData] = useState<UniversalProposalData>(
    {} as UniversalProposalData
  )
  const [negotiationsProposalData, setNegotiationsProposalData] = useState<NegotiationsProposal>(
    {} as NegotiationsProposal
  )
  const [contractId, setContractId] = useState<string>('')
  const [mainPartnerData, setMainPartnerData] = useState<MainPartnerReviewGuarantors>({} as MainPartnerReviewGuarantors)
  const [canSimulate, setCanSimulate] = useState<boolean>(false)

  const [insuranceContract, setInsuranceContract] = useState<InsuranceContract>({} as InsuranceContract)

  function saveInsuranceContract(data: InsuranceContract) {
    setInsuranceContract(data)
  }

  function saveContractId(data: string) {
    setContractId(data)
  }

  function saveLoanConcessionSimulationData(data: UniversalProposalData) {
    setLoanConcessionSimulationData(data)
  }

  function saveNegotiationsProposalData(data: NegotiationsProposal) {
    setNegotiationsProposalData(data)
  }

  function saveMainPartnerData({ kycAnswerData, document, name }: MainPartnerProps) {
    const data = {
      address: {
        ...kycAnswerData.address,
        complement: kycAnswerData.address.extra
      },
      nationality: kycAnswerData.nationality.referenceName,
      maritalStatus: kycAnswerData.marital_status?.referenceName || '',
      document,
      name
    } as MainPartnerReviewGuarantors

    setMainPartnerData(data)
  }

  function saveCanSimulate(val: boolean) {
    setCanSimulate(val)
  }

  const contextValue = {
    insuranceContract,
    saveInsuranceContract,
    proposalSimulationData,
    saveLoanConcessionSimulationData,
    negotiationsProposalData,
    saveNegotiationsProposalData,
    contractId,
    saveContractId,
    mainPartnerData,
    saveMainPartnerData,
    canSimulate,
    saveCanSimulate
  }
  return <ProposalContext.Provider value={contextValue}>{children}</ProposalContext.Provider>
}
interface ContextProps {
  insuranceContract: InsuranceContract
  saveInsuranceContract: (data: InsuranceContract) => void
  proposalSimulationData: UniversalProposalData
  saveLoanConcessionSimulationData: (data: UniversalProposalData) => void
  negotiationsProposalData: NegotiationsProposal
  saveNegotiationsProposalData: (data: NegotiationsProposal) => void
  contractId: string
  saveContractId: (data: string) => void
  mainPartnerData: MainPartnerReviewGuarantors
  saveMainPartnerData: (data: MainPartnerProps) => void
  canSimulate: boolean
  saveCanSimulate: (val: boolean) => void
}

export const ProposalContext = createContext<ContextProps>({
  insuranceContract: {} as InsuranceContract,
  saveInsuranceContract: () => {},
  proposalSimulationData: {} as UniversalProposalData,
  saveLoanConcessionSimulationData: () => {},
  negotiationsProposalData: {} as NegotiationsProposal,
  saveNegotiationsProposalData: () => {},
  contractId: '',
  saveContractId: () => {},
  mainPartnerData: {} as MainPartnerReviewGuarantors,
  saveMainPartnerData: () => {},
  canSimulate: false,
  saveCanSimulate: () => {}
})
