import styled from 'styled-components'

import { Box } from '~/domains/platform/design-system'

export const BoxTable = styled(Box)`
  padding: 16px;
  margin-top: ${({ theme }) => theme.space.large};
  background: ${({ theme }) => theme.colors.lightGray6};
  border-radius: ${({ theme }) => theme.radii.medium};
`
