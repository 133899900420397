import { formatDate } from '@credit-web/common/date-utils'
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Description,
  Heading,
  Icon,
  Money,
  Overline,
  Text
} from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { statusColorMap } from '../../shared/helpers/status-colors-map'

import { LoanDetailsCustomTooltip } from './loan-details-custom-tooltip'

const BorderHeader = styled(CardHeader)`
  border-bottom: 1px solid ${theme.colors.mediumGray4};
  width: 100%;
`

const FooterGrid = styled(Box)`
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: 1.25fr 1.25fr 1fr 1fr;
  width: '100%';
  padding: 1.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${theme.colors.mediumGray4};
`
const CustomCard = styled(Card)`
  height: 17.5rem;
  cursor: pointer;
`

function LoanDetailsSettledLoanCardStatusLoader() {
  return (
    <CustomCard>
      <Box width="100%" p="1rem 1.5rem">
        <Skeleton width="90%" height="2rem" />
      </Box>

      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Box width="100%">
            <Skeleton width="90%" height="1rem" />

            <Skeleton width="90%" height="3rem" />
          </Box>
          <Icon use="chevron-right-outline" size="large" />
        </Flex>
      </CardBody>

      <FooterGrid>
        <Skeleton width="25%%" height="2rem" />

        <Skeleton width="25%%" height="2rem" />

        <Skeleton width="25%%" height="2rem" />

        <Skeleton width="25%%" height="2rem" />
      </FooterGrid>
    </CustomCard>
  )
}

interface LoanDetailsSettledLoanCardProps {
  loanId?: string
  lastInstallmentNumber?: number
  interestEconomyAmount?: number
  paidAmount?: number
  numberOfInstallments?: number
  startDate?: string
  endDate?: string
  isLoading?: boolean
}

export function LoanDetailsSettledLoanCard({
  loanId,
  lastInstallmentNumber,
  interestEconomyAmount,
  paidAmount,
  numberOfInstallments,
  startDate,
  endDate,
  isLoading
}: LoanDetailsSettledLoanCardProps) {
  if (isLoading) return <LoanDetailsSettledLoanCardStatusLoader />

  const dateText = `${formatDate(startDate ?? '')} a ${formatDate(endDate ?? '')}`

  return (
    <CustomCard
      onClick={() =>
        BlackBird.travelTo({
          pathname: '/credito/emprestimo/${loan}/parcela/${installment}',
          urlParams: { loan: loanId, installment: lastInstallmentNumber }
        })
      }
    >
      <BorderHeader
        verticalAlignment="center"
        slotEnd={
          <Badge color={statusColorMap['Settled'].color} variant={statusColorMap['Settled'].variant}>
            {statusColorMap['Settled'].text}
          </Badge>
        }
      >
        <Flex height="1.5rem" alignItems="center">
          <Overline color="neutral">{dateText}</Overline>
        </Flex>
      </BorderHeader>

      <CardBody>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text size="small">{numberOfInstallments} Parcelas quitadas</Text>
            <Box>
              <Heading weight="bold" color="primary">
                Parabéns!
              </Heading>
              <Heading weight="bold" color="primary">
                Você quitou seu empréstimo.
              </Heading>
            </Box>
          </Box>
          <Icon use="chevron-right-outline" size="large" />
        </Flex>
      </CardBody>

      <Flex
        justifyContent="space-between"
        p="1.5rem 1.25rem 1.25rem"
        borderTop={`1px solid ${theme.colors.mediumGray4}`}
      >
        <Flex flexDirection="column" justifyContent="space-around">
          <Flex alignItems="center">
            <Box mr="2px">
              <Description color="neutralLow">Juros economizados</Description>
            </Box>
            <LoanDetailsCustomTooltip
              text={
                'Com a retenção diária, você economiza o valor dos juros do dia, mesmo se não antecipar pagamentos. O valor desses juros economizados são usados no pagamento da sua parcela atual e ajudam a acelerar sua quitação.'
              }
            />
          </Flex>

          <Text weight="bold" inheritColor>
            <Choose>
              <Choose.When condition={interestEconomyAmount !== undefined}>
                <Money amount={Math.abs(interestEconomyAmount || 0)} />
              </Choose.When>
              <Choose.Otherwise>
                <Skeleton width="25%%" height="2rem" />
              </Choose.Otherwise>
            </Choose>
          </Text>
        </Flex>

        <Flex flexDirection="column" justifyContent="space-around">
          <Description color="neutralLow">Total quitado</Description>

          <Text weight="bold" inheritColor>
            <Choose>
              <Choose.When condition={paidAmount !== undefined}>
                <Money amount={Math.abs(paidAmount || 0)} />
              </Choose.When>
              <Choose.Otherwise>
                <Skeleton width="25%%" height="2rem" />
              </Choose.Otherwise>
            </Choose>
          </Text>
        </Flex>
      </Flex>
    </CustomCard>
  )
}
