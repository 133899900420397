import { type AxiosError } from 'axios'

import { CREDIT_NEGOTIATIONS_V1 } from '~/domains/credit/shared/infra/apis-paths'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { exportFile } from '~/lib/helpers'

import { type RenegotiationProposal } from '../../loan-concession/entities'
import { httpClientCreditNegotiations } from '../../loan-concession/infra/http-client-credit-negotiations'
import { type SpotOfferPaymentMethod } from '../../shared/entities'
import { acceptNegotiationProposal, acceptProposal } from '../../shared/services/loans'
import { type RenegotiationPaymentMethod, type RenegotiationSimulation } from '../entities'
import { acceptedRenegotiationTypeErrors } from '../helpers/renegotiation'

export async function getRenegotiationContract({
  templateContractUrl,
  onError
}: {
  templateContractUrl: string
  onError: () => void
}) {
  try {
    const response = await httpClientCreditNegotiations({
      url: templateContractUrl,
      method: 'GET',
      responseType: 'blob'
    })

    const { data } = response
    if (data) {
      await exportFile({ data, name: 'Contrato.pdf' })
    }
  } catch (e) {
    onError()
  }
}

interface AcceptRenegotiationProps {
  proposalId: string
  onSuccess: () => void
  onError: () => void
}

export async function acceptRenegotiation({ proposalId, onSuccess, onError }: AcceptRenegotiationProps) {
  try {
    const hasMigrateBff = Heimdall.pass(['migrate_bff_proposals_renegotiaions'])
    await (hasMigrateBff ? acceptProposal(proposalId) : acceptNegotiationProposal(proposalId))
    onSuccess()
  } catch (err) {
    const error = err as AxiosError
    const status = error?.response?.data?.status
    const type = error?.response?.data?.type

    if (status === 422 && acceptedRenegotiationTypeErrors.includes(type)) onSuccess()
    else onError()
  }
}

export interface SimulateRenegotiationProposalProps {
  offerId: string
  installments: number[]
  rate?: number
  amount?: number
  paymentMethod?: SpotOfferPaymentMethod
}

export async function simulateRenegotiationProposal({
  offerId,
  installments,
  rate,
  amount,
  paymentMethod
}: SimulateRenegotiationProposalProps) {
  return await httpClientCreditNegotiations<RenegotiationSimulation>({
    url: `${CREDIT_NEGOTIATIONS_V1}/offers/${offerId}/simulate`,
    method: 'POST',
    data: {
      installmentNumbers: installments,
      discountOfferRate: rate,
      discountAmount: amount,
      paymentMethod
    }
  })
}

interface SaveRenegotiationProposalProps {
  offerId: string
  installmentNumbers: number[]
  paymentMethod: RenegotiationPaymentMethod
  rate?: number
  amount?: number
  email: string
}

export async function createRenegotiationsProposal({
  offerId,
  installmentNumbers,
  paymentMethod,
  rate,
  amount,
  email
}: SaveRenegotiationProposalProps) {
  return await httpClientCreditNegotiations<RenegotiationProposal>({
    url: `${CREDIT_NEGOTIATIONS_V1}/proposals`,
    method: 'POST',
    data: {
      offerId,
      installmentNumbers,
      paymentMethod,
      discountOfferRate: rate,
      discountAmount: amount,
      emailNotification: email
    }
  })
}

export async function voidRenegotiationProposal(proposalId?: string) {
  return httpClientCreditNegotiations({
    url: `${CREDIT_NEGOTIATIONS_V1}/proposals/${proposalId}/void`,
    method: 'POST'
  })
}
