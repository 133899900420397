import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled, { css } from 'styled-components'

export const ColorIndicator = styled.div<{ color: string }>`
  height: 8px;
  width: 8px;
  border-radius: ${jadeTheme.global['corner-radius'].pill};
  ${({ color }) => css`
    background-color: ${color};
  `};
`
