import { useCallback, useEffect, useState } from 'react'
import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Heading, Money } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box } from '~/domains/platform/design-system'
import { Input } from '~/domains/platform/design-system/input/input'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { theme } from '~/ui/theme'

import { useLoanConcessionContext } from '../context/loan-concession-context'
import analitica from '../helpers/analitica'

export interface LoanConcessionSimulationInputProps {
  minValue: number
  maxValue: number
  initialValue?: number
  onValue(value: number): void
  loading?: boolean
  setInputChange?: React.Dispatch<React.SetStateAction<number | undefined>>
  requestedAmount?: number
}

const { onlyNumbers, currency } = stringFormat
const DECIMALS = 100

const InputContainer = styled(Stack)`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid ${theme.colors.mediumGray3};
  border-radius: 0.5rem;
`

const ResponsiveBox = styled(Box)`
  width: calc(100% - 2rem);

  @media (max-width: ${theme.windowSizes.small}px) {
    width: auto;
    margin-bottom: ${theme.space.medium};
  }
`

export function LoanConcessionSimulationInput({
  onValue,
  maxValue,
  initialValue,
  minValue,
  loading,
  setInputChange,
  requestedAmount
}: LoanConcessionSimulationInputProps) {
  const { canSimulate } = useLoanConcessionContext()
  const [showField, toggleField] = useToggle(false)
  const [value, setValue] = useState<number>(maxValue)
  const [error, setError] = useState<string>('')

  function clearError() {
    setError('')
  }
  const fixDecimal = (v: number) => Math.floor(v * 100) / 100

  useEffect(() => {
    if (requestedAmount) return setValue(requestedAmount)
    setValue(initialValue || maxValue)
  }, [maxValue, initialValue, requestedAmount])

  const currencyMinValue = fixDecimal(minValue) * DECIMALS
  const currencyMaxValue = fixDecimal(maxValue) * DECIMALS

  const makeMinValueError = useCallback(() => {
    setError(`O valor mínimo é de ${currency(currencyMinValue)}`)
  }, [currencyMinValue])

  const makeMaxValueError = useCallback(() => {
    setError(`O valor máximo é de ${currency(currencyMaxValue)}`)
  }, [currencyMaxValue])

  const handleInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const normalizedValue = +onlyNumbers(e.currentTarget.value)
      const value = normalizedValue / DECIMALS

      setInputChange && setInputChange(value)
      setValue(value)
      clearError()

      if (value < minValue) {
        makeMinValueError()
      }
      if (value > maxValue) {
        makeMaxValueError()
      }
    },
    [maxValue, setInputChange, minValue, makeMaxValueError, makeMinValueError]
  )

  const handleSubmit = useCallback(() => {
    toggleField()

    if (value < minValue) {
      makeMinValueError()
      return
    }

    if (value > maxValue) {
      makeMaxValueError()
      return
    }
    setInputChange && setInputChange(value)
    clearError()
    onValue(value)
    analitica.events.simulation.click('alterar')
  }, [value, onValue, makeMaxValueError, makeMinValueError, maxValue, minValue, setInputChange, toggleField])

  return (
    <ResponsiveBox>
      <InputContainer space="1.5rem">
        <Heading size="large" weight="bold">
          Valor do empréstimo
        </Heading>

        <Stack space="0.5rem">
          <Choose>
            <Choose.When condition={showField}>
              <Input
                onChange={handleInput}
                value={currency(value * DECIMALS)}
                error={error}
                labelBottom={'Seu limite atual é de ' + currency(currencyMaxValue)}
                data-test-id="simulation-input"
              />
            </Choose.When>
            <Choose.Otherwise>
              <Heading size="large" color="primary" weight="bold">
                <Money amount={value} />
              </Heading>
            </Choose.Otherwise>
          </Choose>
        </Stack>

        <If condition={canSimulate}>
          <Choose>
            <Choose.When condition={showField}>
              <Button
                shape="pill"
                size="small"
                onClick={handleSubmit}
                disabled={loading || Boolean(error)}
                style={{ width: 'fit-content' }}
                data-test-id="simulation-update-value-confirm"
              >
                Alterar valor
              </Button>
            </Choose.When>
            <Choose.Otherwise>
              <Button
                shape="pill"
                size="small"
                onClick={toggleField}
                disabled={loading}
                style={{ width: 'fit-content' }}
                data-test-id="simulation-update-value"
              >
                Alterar valor
              </Button>
            </Choose.Otherwise>
          </Choose>
        </If>
      </InputContainer>
    </ResponsiveBox>
  )
}
