import { analiticaInstance } from 'shared/analitica'

import { type InstallmentStatus, type RenegotiationSummaryStatus } from '../shared/entities'

type contractCardClickedAction =
  | 'expandir informações'
  | 'ver todos os contratos'
  | 'baixar contrato'
  | 'conferir condições originais'

type ProposalViewProps = {
  type: 'renegociação' | 'quitação com desconto'
  status: string
  proposalId: string
  expirationDate: string
  origin: string
}

type FollowUpEventProps = {
  status: string
  type: 'renegociação' | 'quitação com desconto - discount amount' | 'quitação com desconto - discount rate'
}

export default analiticaInstance.eventFactory('Credit loan details', {
  loanDetails: {
    /**
     * @analitica The user saw the loan details page
     */
    view: (loanId: string, loanAmount: number, renegotiationStatus?: RenegotiationSummaryStatus) => ({
      label: 'credit - loan details view',
      metadata: {
        'loan id': loanId,
        'loan amount': loanAmount,
        'renegotiation status': renegotiationStatus?.toLowerCase()
      }
    }),
    currentInstallmentCardClick: () => ({
      label: 'credit - loan details installment card clicked',
      metadata: {
        action: 'visualizar parcela'
      }
    }),
    contractCardClicked: (action: contractCardClickedAction) => ({
      label: 'credit - loan details contract card clicked',
      metadata: { action }
    })
  },
  installmentList: {
    view: (loanId: string, installmentPlanId: string) => ({
      label: 'credit - loan installments list view',
      metadata: { 'loan id': loanId, 'installment plan id': installmentPlanId }
    }),
    click: (installmentNumber: number, installmentStatus: InstallmentStatus) => ({
      label: 'credit - loan installments list clicked',
      metadata: {
        action: 'visualizar detalhes da parcela',
        'installment number': installmentNumber,
        'installment status': installmentStatus.toLowerCase()
      }
    })
  },
  originalContract: {
    installmentListView: (loanId: string, installmentPlanId: string) => ({
      label: 'credit - original loan installment list view',
      metadata: { 'loan id': loanId, 'installment plan id': installmentPlanId }
    }),
    installmentListClick: (installmentNumber: number, installmentStatus: InstallmentStatus) => ({
      label: 'credit - original loan installment list clicked',
      metadata: {
        action: 'visualizar detalhes da parcela',
        'installment number': installmentNumber,
        'installment status': installmentStatus.toLowerCase()
      }
    })
  },
  banner: {
    proposal: (eventType: string, { type, status, proposalId, expirationDate, origin }: ProposalViewProps) => ({
      label: `credit - loan details proposal card ${eventType}`,
      metadata: {
        type,
        status,
        origin,
        'expiration date': expirationDate,
        'proposal id': proposalId
      }
    }),
    followUp: (eventType: 'clicked' | 'view', { status, type }: FollowUpEventProps) => ({
      label: `credit - loan details follow up card ${eventType}`,
      metadata: { status, type }
    })
  }
})
