/* eslint-disable @nx/enforce-module-boundaries */
import { type Dispatch, type ReactNode, type SetStateAction, createContext, useContext, useState } from 'react'
import { type Stores } from 'swh/shared/http'

import { type PurchaseStoneCode } from '~/lib/types'

export type SwhConfigStore = {
  store: Stores.Item
  affiliationKey: string | null
  stonecodes?: PurchaseStoneCode[]
  user?: { id?: string }
  merchant?: { document: string | null }
}

export const MicroFrontEndConfigStoreDefault: SwhConfigStore = {
  affiliationKey: null,
  merchant: { document: '' },
  user: { id: '' },
  stonecodes: [],
  store: {
    cnae: '',
    document: '',
    enabledModules: [],
    id: '',
    name: '',
    stateRegistration: ''
  }
}

const SwhConfigContext = createContext<[state: SwhConfigStore, setState: Dispatch<SetStateAction<SwhConfigStore>>]>([
  {} as SwhConfigStore,
  () => {}
])

export const SwhConfigContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useState<SwhConfigStore>({} as SwhConfigStore)
  return <SwhConfigContext.Provider value={value}>{children}</SwhConfigContext.Provider>
}

export const useSwhConfigContext = () => useContext(SwhConfigContext)

export const useSwhConfig = (): SwhConfigStore => {
  const [state] = useContext(SwhConfigContext)
  return state || MicroFrontEndConfigStoreDefault
}
