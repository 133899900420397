import { type BadgeColor, type BadgeVariant } from '@dlpco/ginga-components/cjs/components/badge/props/types'

import { type InstallmentStatus } from '../entities'

export interface StatusBadgePropsItem {
  text: string
  color: BadgeColor
  variant: BadgeVariant
}

export const statusColorMap: Record<InstallmentStatus, StatusBadgePropsItem> = {
  Open: { text: 'Aberta', color: 'positive', variant: 'solid' },
  Settled: { text: 'Quitada', color: 'positive', variant: 'light' },
  Overdue: { text: 'Em atraso', color: 'negative', variant: 'solid' },
  Future: { text: 'Futura', color: 'neutral', variant: 'light' },
  DueSoon: { text: 'Vence em breve', color: 'warning', variant: 'solid' },
  Suspended: { text: 'Pausada', color: 'info', variant: 'light' }
}

export const renegotiationStatusColorMap: Record<InstallmentStatus, StatusBadgePropsItem> = {
  ...statusColorMap,
  Open: { text: 'Inativa', color: 'neutral', variant: 'light' },
  Settled: { text: 'Quitada', color: 'positive', variant: 'light' },
  Overdue: { text: 'Atrasada', color: 'negative', variant: 'solid' },
  Future: { text: 'Inativa', color: 'neutral', variant: 'light' },
  DueSoon: { text: 'Inativa', color: 'neutral', variant: 'light' }
}

export const loanStatusColorMap: Record<InstallmentStatus, StatusBadgePropsItem> = {
  ...statusColorMap,
  Open: { text: 'Aberta', color: 'positive', variant: 'light' },
  Settled: { text: 'Quitada', color: 'positive', variant: 'solid' },
  Overdue: { text: 'Em atraso', color: 'negative', variant: 'light' },
  DueSoon: { text: 'Vence em breve', color: 'warning', variant: 'light' }
}
