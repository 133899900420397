import { Button, Icon, Overline, Text, theme } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { type MaritalStatus } from '~/domains/credit/shared/entities'
import { Box, Flex } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type RenegotiationProposalId } from '../../entities'
import { RenegotiationAccordion } from '../renegotiation-accordion'

import { RenegotiationReviewList } from './renegotiation-review-list'

const Ul = styled.ul`
  list-style-type: '•';
  margin-top: ${theme.light.space.xSmall};
  margin-left: ${theme.light.space.small};
  line-height: 2;
  list-style-position: 'inside';

  li {
    padding-left: ${theme.light.space.xSmall};
  }
`

export function RenegotiationReviewAccordionContainer({
  handleContract,
  proposal
}: {
  handleContract: () => void
  proposal: RenegotiationProposalId
}) {
  const { customer } = proposal
  const { mainPartner } = customer
  const { conditions } = proposal.proposal
  const openChat = () => chat.open()
  const hasInterestRate = conditions.annualInterestRate.value > 0
  const hasGracePeriodInterest = conditions.hasGracePeriodInterest.value

  return (
    <>
      <RenegotiationAccordion
        title="Forma de pagamento"
        onOpen={() => renegotiationAnalitica.events.review.click('chevron forma de pagamento')}
        content={
          <>
            <Text size="small" weight="regular" color="neutralHigh" style={{ marginBottom: theme.light.space.medium }}>
              O pagamento da renegociação é automático, feito com retenção diária de{' '}
              <strong>{conditions.retentionRate.description} dos seus recebimentos no débito e crédito</strong> de
              vendas nos seguintes canais:
            </Text>
            <RenegotiationReviewList
              items={[
                'Maquininha Stone ou outras',
                'Links de pagamento e e-commerce',
                'Pix na maquininha, QR Code ou chave',
                'Boleto'
              ]}
            />
          </>
        }
      />

      <RenegotiationAccordion
        title="Informações sobre juros"
        onOpen={() => renegotiationAnalitica.events.review.click('chevron informações sobre juros')}
        content={
          <>
            <If condition={hasInterestRate}>
              <Text
                size="small"
                weight="regular"
                color="neutralHigh"
                style={{ marginBottom: theme.light.space.medium }}
              >
                O cálculo dos juros já está incluído no valor renegociado. Ou seja: pagando em dia,{' '}
                <strong>o total a pagar não vai aumentar.</strong>
              </Text>

              <RenegotiationReviewList
                items={[
                  `Juros ao mês: ${conditions.interestRate.description}`,
                  'Economize juros e reduza o valor da parcela em qualquer pagamento (por retenção, Pix ou boleto) feito antes do vencimento da parcela'
                ]}
              />

              <If condition={hasGracePeriodInterest}>
                <Text size="small" weight="bold" color="neutralHigh">
                  Cobrança de juros na carência
                </Text>

                <Text
                  size="small"
                  weight="regular"
                  color="neutralHigh"
                  style={{ marginBottom: theme.light.space.medium }}
                >
                  Se a primeira parcela vencer após 30 dias a partir de hoje, os juros referentes ao tempo de carência
                  vão ser cobrados em parcelas mensais na sua renegociação.
                </Text>
              </If>

              <Button
                onClick={openChat}
                color="neutral"
                size="small"
                style={{
                  width: '100%',
                  marginTop: theme.light.space.medium
                }}
              >
                Ler mais sobre juros
              </Button>
            </If>

            <If condition={!hasInterestRate}>
              <Text size="small" weight="regular" color="neutralHigh" data-testid="renegotiation-zero-annual-rate">
                Não adicionamos juros ao valor renegociado, mas <strong>se houver atrasos no pagamento</strong>, juros
                vão ser aplicados.
              </Text>
            </If>
          </>
        }
      />

      <RenegotiationAccordion
        title="Garantias de pagamento"
        onOpen={() => renegotiationAnalitica.events.review.click('chevron tipos de garantia')}
        content={
          <>
            <Text size="small" weight="regular" color="neutralHigh">
              As garantias <strong>são as mesmas do empréstimo renegociado</strong> e se estendem até a nova data de
              vencimento do contrato.
            </Text>

            <Overline
              size="small"
              color="neutral"
              style={{ marginTop: theme.light.space.medium, marginBottom: theme.light.space.small }}
            >
              Garantidores do empréstimo
            </Overline>
            <Flex
              gap={theme.light.space.xSmall}
              style={{
                marginBottom: theme.light.space.medium,
                borderBottom: `1px solid ${theme.light.color.borderLow}`,
                paddingBottom: theme.light.space.medium
              }}
            >
              <Icon use="shop-outline" color="info" />
              <Box>
                <Text size="small" weight="bold" color="neutralHigh">
                  {customer.name}
                </Text>
                <Text size="small" weight="regular" color="neutral">
                  CNPJ: {stringFormat.document(customer.document)}
                  <br />
                  {customer.address?.street}, {customer.address?.streetNumber}, {customer.address?.neighborhood}
                  {customer.address?.complement && `, ${customer.address?.complement}`}
                  <br />
                  {customer.address?.city} - {customer.address?.state}
                </Text>
              </Box>
            </Flex>
            <Flex gap={theme.light.space.xSmall}>
              <Icon use="avatar-outline" color="info" />
              <Box>
                <Text size="small" weight="bold" color="neutralHigh">
                  {mainPartner?.name}
                </Text>
                <Text size="small" weight="regular" color="neutral">
                  {stringFormat.document(mainPartner?.document ?? '')},{' '}
                  {mainPartner?.maritalStatus.description ?? ('' as MaritalStatus)}, {mainPartner?.nationality}
                  <br />
                  {mainPartner?.address?.street}, {mainPartner?.address?.streetNumber},{' '}
                  {mainPartner?.address?.neighborhood}
                  {mainPartner?.address?.complement && `, ${mainPartner?.address?.complement}`}
                  <br />
                  {mainPartner?.address?.postalCode && `${mainPartner?.address?.postalCode}, `}
                  {mainPartner?.address?.city} - {mainPartner?.address?.state}
                </Text>
              </Box>
            </Flex>
            <Button
              onClick={openChat}
              color="neutral"
              size="small"
              style={{
                width: '100%',
                marginTop: theme.light.space.medium
              }}
            >
              Ler mais sobre garantias
            </Button>
          </>
        }
      />

      <RenegotiationAccordion
        title="Atrasos e quebra de acordo"
        onOpen={() => renegotiationAnalitica.events.review.click('atrasos e quebra de acordo')}
        withIndicator={false}
        initialOpen
        content={
          <>
            <Flex gap={theme.light.space.xSmall}>
              <Icon use="round-alert-outline" color="negative" size="medium" />
              <Box>
                <Text size="small" color="neutralHigh" style={{ maxWidth: '90%' }}>
                  A renegociação <strong>não pode sofrer atrasos no pagamento</strong>. Qualquer atraso pode gerar as
                  seguintes consequências:
                </Text>
                <Ul>
                  <li>
                    <Text size="small" color="neutralHigh">
                      Cobrança diária de juros
                    </Text>
                  </li>
                  <li>
                    <Text size="small" color="neutralHigh">
                      Retenção automática em 100% até regularizar a situação
                    </Text>
                  </li>
                  <li>
                    <Text size="small" color="neutralHigh">
                      Encerramento do contrato antes do seu vencimento
                    </Text>
                  </li>
                </Ul>
              </Box>
            </Flex>
            <Button
              onClick={openChat}
              color="neutral"
              size="small"
              style={{
                width: '100%',
                marginTop: theme.light.space.medium
              }}
            >
              Ler sobre quebra de acordo
            </Button>
          </>
        }
      />

      <Divider style={{ marginTop: theme.light.space.medium }} />
      <Flex
        gap={theme.light.space.xSmall}
        justifyContent="space-between"
        style={{
          padding: theme.light.space.medium,
          border: `1px solid ${theme.light.color.borderLow}`,
          borderRadius: theme.light.space.small,
          marginTop: theme.light.space.medium,
          cursor: 'pointer'
        }}
        onClick={() => {
          renegotiationAnalitica.events.review.click('ler os termos e condições')
          handleContract()
        }}
      >
        <Text color="neutralHigh" weight="semi">
          Ler os Termos e Condições
        </Text>
        <Icon use="chevron-right-outline" color="primary" size="medium" />
      </Flex>
    </>
  )
}
