import styled from 'styled-components'

const ProgressBar = styled.div`
  height: 10px;
  width: 100%;
  margin-top: 4rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  overflow: hidden;
  border-radius: 20px;
`

const ProgressBarValue = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.stoneGreen};
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
  border-radius: 20px;

  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
`
export function SharedCreditIndeterminateProgressBar() {
  return (
    <ProgressBar data-testid="indeterminate-progressbar">
      <ProgressBarValue />
    </ProgressBar>
  )
}
