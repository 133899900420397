import { build, fake } from '@jackfranklin/test-data-bot'

import { type Loan, type LoanStatus, type LoanStatusDescription } from '~/domains/credit/shared/entities'

export const loanBuilder = build('Loan', {
  fields: {
    acceptanceDate: fake(f => f.date.past()),
    id: fake(f => f.datatype.uuid()),
    creditLineId: fake(f => f.datatype.uuid()),
    financialInstitution: {
      id: 1,
      name: 'Teste 001'
    },
    borrower: {
      id: fake(f => f.datatype.uuid()),
      document: '123123123123',
      name: fake(f => f.name.findName),
      fantasyName: fake(f => f.name.findName),
      affiliationKey: fake(f => f.datatype.uuid()),
      affiliationCode: fake(f => f.datatype.number()),
      phoneNumber: fake(f => f.phone.phoneNumber()),
      email: fake(f => f.internet.email()),
      address: {
        id: fake(f => f.datatype.uuid()),
        streetName: fake(f => f.address.streetName()),
        streetNumber: fake(f => f.datatype.number()),
        complement: '',
        neighborhood: fake(f => f.address.city()),
        city: fake(f => f.address.city()),
        state: fake(f => f.address.stateAbbr()),
        postalCode: fake(f => f.address.zipCode())
      },
      creationDate: fake(f => f.date.past()),
      lastUpdateDate: fake(f => f.date.past()),
      status: 'Active',
      statusDescription: 'Ativo',
      documentType: {
        id: 0,
        name: 'CNPJ'
      }
    },
    signer: {
      id: fake(f => f.datatype.uuid()),
      name: fake(f => f.name.findName()),
      documentNumber: '123123123123',
      phoneNumber: fake(f => f.phone.phoneNumber()),
      email: fake(f => f.internet.email()),
      ipAddress: '8.8.8.8'
    },
    borrowerId: fake(f => f.datatype.uuid()),
    proposalId: fake(f => f.datatype.uuid()),
    dueDate: fake(f => f.date.future()),
    maximumDisbursementDate: fake(f => f.date.past()),
    creditAmount: 10000.0,
    interestRate: 3.99,
    annualInterestRate: 50.0,
    contractNumber: 0,
    contractUri: 'localhost://3000/api/v3/borrowers/123123123123/proposals/12312123/contract',
    creationDate: fake(f => f.date.past()),
    lastUpdateDate: fake(f => f.date.past()),
    settlementDate: fake(f => f.date.past()),
    lastDebtAmountProcessingDate: fake(f => f.date.past()),
    status: 'Active',
    statusDescription: 'Ativo',
    retentionRate: 3.9,
    totalPaidAmount: 0,
    currentDebtAmount: 9531.08,
    monthlyTotalEffectiveCostRate: 4.45,
    annualTotalEffectiveCostRate: 68.71,
    operationFeeAmount: 100.0,
    taxAmount: 113.0,
    disbursementDate: fake(f => f.date.past()),
    lastRepaymentDate: fake(f => f.date.past()),
    lender: {
      id: 'FIDC',
      name: 'FIDC Stone',
      document: '123123123123'
    },
    repaymentMethod: 'Balance Retention',
    disbursement: {
      bankAccount: {
        id: fake(f => f.datatype.uuid()),
        bankNumber: fake(f => f.datatype.number()),
        bankName: 'Banco banquinho',
        branchNumber: fake(f => f.datatype.number()),
        branchDigit: fake(f => f.datatype.number()),
        accountNumber: fake(f => f.datatype.number()),
        accountDigit: fake(f => f.datatype.number()),
        legalEntity: {},
        accountType: 'Checking'
      }
    },
    warranty: {
      bankAccount: {
        id: fake(f => f.datatype.uuid()),
        bankNumber: fake(f => f.datatype.number()),
        bankName: 'Banco banquinho',
        branchNumber: fake(f => f.datatype.number()),
        branchDigit: fake(f => f.datatype.number()),
        accountNumber: fake(f => f.datatype.number()),
        accountDigit: fake(f => f.datatype.number()),
        legalEntity: {},
        accountType: 'Checking'
      }
    },
    tax: {
      additionalIofRatePercentage: 1.0,
      dailyIofRatePercentage: 1.0
    }
  }
})

export const loanBase = {
  id: 'jr3mwrhpmuvsg8vnuvvmdpbwz',
  document: '02435144000104',
  type: 'Installments',
  creationDate: '2024-04-03T13:56:38Z',
  lastUpdateDate: '2024-04-03T14:28:23Z',
  status: 'Active',
  concessionProposalId: 'jr61xkx8cf55euma1e9b9c3yt',
  disbursementDate: '2024-04-03T14:00:51Z',
  repaymentMethod: 'PaymentService',
  contract: {
    dueDate: '2024-10-15',
    creditAmount: 20000.0,
    interestRate: 0.02,
    annualInterestRate: 0.27,
    contractNumber: '4956',
    contractUri: 'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr3mwppk78rm8r6r9t5n9a3an',
    acceptanceDate: '2024-04-03T13:56:23Z',
    annualTotalEffectiveCostRate: 0.32,
    operationFeeAmount: 0.0,
    taxAmount: 228.64,
    borrower: {
      document: '02435144000104',
      name: 'GUEDES ROGERIO AIRES LEAL',
      fantasyName: 'GUEDES ROGERIO AIRES LEAL',
      email: 'credito.stone+3+mlp@gmail.com',
      address: {
        streetName: 'Rua Oliveira Fonseca',
        number: '5142',
        complement: '123',
        neighborhood: 'Campo Grande',
        city: 'Recife',
        state: 'PE',
        postalCode: '52040250'
      }
    },
    paymentTermInMonths: 6,
    disbursement: {
      owner: {
        name: 'Nome do CPF 39117514860',
        document: '39117514860'
      },
      bankNumber: '197',
      bankName: 'Stone Pagamentos S.A.',
      branchNumber: '1',
      branchDigit: '',
      number: '145854',
      digit: ''
    },
    warranty: {
      owner: {
        name: 'Nome do CPF 39117514860',
        document: '39117514860'
      },
      bankNumber: '197',
      bankName: 'Stone Pagamentos S.A.',
      branchNumber: '1',
      branchDigit: '',
      number: '218354',
      digit: ''
    }
  }
}

interface loanBuilderV2Props {
  status?: LoanStatus
  statusDescription?: LoanStatusDescription
  id: string
}

export const loanBuilderV2 = ({ status = 'Active', statusDescription = 'Ativo', id, ...props }: loanBuilderV2Props) => {
  const newLoan = {
    ...loanBase,
    ...props,
    status,
    statusDescription,
    id
  } as Loan

  return {
    items: [newLoan],
    paging: {
      totalItems: 1,
      currentPage: 1,
      pageSize: 100,
      pageCount: 1
    }
  }
}

export const loanBuilderV3 = build('Loan', {
  fields: {
    ...loanBase,
    id: fake(f => f.datatype.uuid()),
    contract: {
      ...loanBase.contract,
      dueDate: fake(f => f.date.future())
    }
  }
})
