import { Stack } from '@dlpco/fluid-layout'
import { Heading, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { Choose, For } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { type KycStepperData, getStepTitle } from '../helpers/kyc-steps'

const StepNumber = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-color: ${theme.colors.lightGray};
  margin-right: 8px;
`

type KycFlowStepListProps = {
  currentStep: KycStepperData
  steps: KycStepperData[]
}

export function KycFlowStepList({ currentStep, steps }: KycFlowStepListProps) {
  const currentStepIndex = steps.indexOf(currentStep)
  return (
    <Stack space="2rem">
      <For
        of={steps}
        render={(step: KycStepperData, index) => {
          const title = getStepTitle(step.key)
          const displayIndex = index + 1
          return (
            <Choose key={index}>
              <Choose.When condition={step === currentStep}>
                <Flex alignItems="center">
                  <StepNumber>
                    <Text weight="bold">{displayIndex}</Text>
                  </StepNumber>
                  <Heading size="xSmall" weight="bold" aria-label="Etapa atual">
                    {title}
                  </Heading>
                </Flex>
              </Choose.When>
              <Choose.When condition={index < currentStepIndex}>
                <Box>
                  <Flex alignItems="center">
                    <StepNumber>
                      <Text weight="bold" color="primary">
                        {displayIndex}
                      </Text>
                    </StepNumber>
                    <Heading color="primary" size="xSmall" weight="bold">
                      {title}
                    </Heading>
                  </Flex>
                </Box>
              </Choose.When>
              <Choose.Otherwise>
                <Box>
                  <Flex alignItems="center">
                    <StepNumber>
                      <Text weight="bold" color="neutral">
                        {displayIndex}
                      </Text>
                    </StepNumber>
                    <Heading color="neutral" size="xSmall" weight="bold">
                      {title}
                    </Heading>
                  </Flex>
                </Box>
              </Choose.Otherwise>
            </Choose>
          )
        }}
      />
    </Stack>
  )
}
