import { Badge, Divider, Icon, ListItem, Text, Tooltip } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { type Target, eventHelper } from '~/domains/home-v2/shared/helpers/event-helper'
import { useGetHome } from '~/domains/home-v2/shared/hooks/get-bff'
import { type Analytics } from '~/domains/home-v2/types'
import { Box, Flex } from '~/domains/platform/design-system'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'
import { publish } from '~/domains/platform/lib/pub-sub'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'

import analitica from '../analitica'

export function MainMenu({
  entity,
  subject
}: {
  entity: Entity<'acquirer' | 'banking'>
  subject: Subject<'stone_account_resources'>
}) {
  const { menu } = useGetHome({
    document: subject?.document,
    accountId: entity?.paymentAccount?.id,
    globalId: entity.id,
    affiliationKey: entity?.stoneCodes[0]?.affiliationKey
  })

  function handleAction({ uri, analytics, label }: { uri?: Routes; analytics?: Analytics; label?: string }) {
    const event = eventHelper[label as Target]
    if (analytics !== undefined && event === null) {
      analitica.events.mainMenu.click(analytics)
    } else {
      analitica.events.mainMenu.click({
        eventName: event.label,
        type: event.type,
        properties: event.properties,
        option: event.option
      })
    }

    if (uri === null && label === 'Equipe') {
      publish('show:team:modal')

      return
    }

    if (uri != null) {
      BlackBird.travelTo(uri)
    }
  }

  return (
    <>
      <Divider direction="horizontal" size="small" />
      <Box p={`${jadeTheme.global.space[100]} 0 ${jadeTheme.global.space[300]} 0`} data-testid={menu?.id}>
        <If
          condition={menu?.data?.length > 0}
          render={() => (
            <For
              of={menu?.data ?? []}
              render={item => {
                const { analytics, icon, label, locked, navigation, order, show, tag, tooltip } = item

                const active = () => [navigation].includes(BlackBird.getPathname() as Routes)

                const isLongLabel = label.length > 19 && Boolean(tag)
                const buttonLabel = isLongLabel ? `${label.slice(0, 16)}...` : label

                return (
                  <If key={order} condition={Boolean(show)}>
                    <ListItem
                      variant={active() ? 'active' : 'neutral'}
                      compact
                      content={
                        <Flex alignItems="center" gap="0.5rem">
                          <Text
                            role="menuitem"
                            aria-label={buttonLabel}
                            color={active() ? 'brand' : 'high'}
                            variant="text-medium"
                            weight="medium"
                            style={{ opacity: locked ? 0.5 : 1 }}
                          >
                            {buttonLabel}
                          </Text>
                          <If condition={Boolean(tooltip)}>
                            <Tooltip
                              placement="right"
                              text={tooltip}
                              trigger={<Icon size="small" use="circle-alert" />}
                            />
                          </If>
                        </Flex>
                      }
                      leading={
                        <Icon
                          role="menuitem"
                          size="medium"
                          color={active() ? 'brand' : 'medium'}
                          use={icon}
                          aria-label={`Ícone de ${label}`}
                          style={{ opacity: locked ? 0.5 : 1 }}
                        />
                      }
                      onClick={
                        !locked
                          ? () => {
                              handleAction({
                                uri: navigation,
                                analytics,
                                label
                              })
                            }
                          : undefined
                      }
                      trailing={
                        <Choose>
                          <Choose.When condition={Boolean(locked)}>
                            <Flex
                              role="menuitem"
                              aria-label="Fechar ou bloquear"
                              bg={jadeTheme.theme.color.background.info}
                              p={jadeTheme.global.space[50]}
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="1rem"
                            >
                              <Icon use="lock-close" data-testid="menu-lock-icon" size="small" color="on-color" />
                            </Flex>
                          </Choose.When>
                          <Choose.When condition={Boolean(tag)}>
                            <Badge label={tag as string} variant="text" voice="info" />
                          </Choose.When>
                        </Choose>
                      }
                    />
                  </If>
                )
              }}
            />
          )}
        />
      </Box>
    </>
  )
}
