import { type CurrentInstallment } from '../entities'

type findPercentageValueProps = {
  value: number
  total: number
}

const findPercentageValue = ({ value, total }: findPercentageValueProps) => Math.round((Math.abs(value) * 100) / total)

export const getProgressBarValues = (currentInstallment?: CurrentInstallment): { paid?: number; economy?: number } => {
  if (!currentInstallment) return {}

  const { paidAmount, presentBalance, interestEconomyAmount } = currentInstallment
  const total = Math.abs(paidAmount) + Math.abs(presentBalance) + Math.abs(interestEconomyAmount)

  return {
    paid: findPercentageValue({ value: paidAmount, total }),
    economy: findPercentageValue({ value: interestEconomyAmount, total })
  }
}

type progressBarRevolvingCreditProps = {
  amountLimit: number
  contractedAmount: number
  withdrawalAmount: number
}

export function progressBarRevolvingCredit({
  amountLimit,
  contractedAmount,
  withdrawalAmount
}: progressBarRevolvingCreditProps) {
  return {
    withdrawn: findPercentageValue({ value: withdrawalAmount, total: contractedAmount }),
    available: findPercentageValue({ value: amountLimit, total: contractedAmount })
  }
}
