import styled from 'styled-components'
import { ifProp } from 'styled-tools'

export const TurnIcon = styled.div<{ turn: boolean; disableTrigger?: boolean; inheritColor?: boolean }>`
  background-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
  transform: ${ifProp('disableTrigger', 'rotate(0deg)', ifProp('turn', 'rotate(-180deg)'))};
  transform-origin: 50% 50%;
  svg {
    color: ${ifProp('inheritColor', 'null', props => props.theme.colors.primary)};
  }
`
