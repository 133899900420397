import { Button } from '@dlpco/ginga-stone'

import { type Loan } from '~/domains/credit/shared/entities'
import analitica from '~/domains/credit/shared/helpers/spot-offer-analitica'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import installmentDetailsAnalitica from '../../loan-installment-details/helpers/installment-details-analitica'
import { type LoanInstallment, installmentStatusTranslationMap } from '../entities'

interface SharedSpotOfferButtonProps {
  loan?: Loan
  currentInstallment?: LoanInstallment
}

export function SharedSpotOfferButton({ loan, currentInstallment }: SharedSpotOfferButtonProps) {
  const { loan: loanId } = BlackBird.getQuery()

  const buttonClick = () => {
    installmentDetailsAnalitica.events.installmentDetails.click()
    analitica.events.button.requested(installmentStatusTranslationMap[currentInstallment?.status ?? 'Open'])

    BlackBird.travelTo({
      pathname: '/credito/emprestimo/${loan}/antecipar-pagamento/inicio',
      urlParams: { loan: loanId }
    })
  }

  const checkLoanStatus = (loan: Loan) => ['Active'].includes(loan?.status)
  return (
    <If
      condition={Boolean(loan ? checkLoanStatus(loan) : currentInstallment && currentInstallment?.status !== 'Settled')}
    >
      <Button color="primary" onClick={buttonClick} style={{ width: '100%' }}>
        Fazer um pagamento
      </Button>
    </If>
  )
}
