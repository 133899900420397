import { type CreditOffer } from '../../shared'
import { type LoanSection } from '../entities'
const offerId = 'zka23uw0cq9y8pql160hyds4m'

export const loanHomeMock = {
  content: {
    notifications: [
      {
        id: 'maintenance-notification',
        metadata: {
          type: 'warning | success | information | error',
          title: 'Alguns serviços estão indisponíveis',
          subtitle: 'As informações de empréstimo estão temporariamente indisponíveis, tente novamente mais tarde.',
          button: {
            text: 'Conferir detalhes',
            action: 'deeplink/url'
          }
        }
      }
    ],
    sections: [
      {
        id: 'loans',
        title: 'Home.Sections.Loans.Title',
        elements: [
          {
            id: 'jqx91kdp765tawqdpk5rtfcu1',
            type: 'current_loan',
            status: 'Active',
            statusDescription: 'Ativo',
            creditAmount: 2000000,
            totalOfInstallment: 7,
            initialInstallmentAmount: 227079,
            creationDate: '2023-08-28T20:23:12Z',
            lastUpdateDate: '2023-10-24T04:43:46Z',
            currentInstallment: {
              installmentNumber: 1,
              startDate: '2023-09-11',
              dueDate: '2023-10-12',
              daysUntilDueDate: 0,
              isDueSoon: false,
              daysOverdue: 12,
              isOverdue: true,
              status: 'Overdue',
              paidAmount: 0,
              initialAmount: 227079,
              futureBalance: 228876,
              presentBalance: 228876,
              interestEconomyAmount: 12332,
              penaltyFeeAmount: 0,
              penaltyInterestAmount: 0,
              latePaymentInterestAmount: 1797,
              totalDebtAmount: 6228
            },
            nextInstallment: {
              installmentNumber: 2,
              startDate: '2023-10-13T00:00:00Z',
              dueDate: '2023-11-13T00:00:00Z',
              futureBalance: 227079,
              presentBalance: 224256
            },
            overdueInstallment: {
              overdueInstallments: [
                {
                  installmentNumber: 1,
                  dueDate: '2023-10-12',
                  presentBalance: 228876
                }
              ],
              totalPresentBalance: 228876,
              daysOverdue: 12,
              penaltyFeeAmount: 0,
              penaltyInterestAmount: 0,
              latePaymentInterestAmount: 1797
            }
          },
          {
            id: 'jqx3gkrmnkv17gex2rwum785f',
            type: 'current_loan',
            status: 'Active',
            statusDescription: 'Ativo',
            creditAmount: 1500000,
            totalOfInstallment: 6,
            initialInstallmentAmount: 168803,
            creationDate: '2023-08-21T19:28:03Z',
            lastUpdateDate: '2023-11-13T04:43:19Z',
            currentInstallment: {
              installmentNumber: 1,
              startDate: '2023-08-21',
              dueDate: '2023-09-22',
              daysUntilDueDate: 0,
              isDueSoon: false,
              daysOverdue: 52,
              isOverdue: true,
              status: 'Overdue',
              paidAmount: -100000,
              initialAmount: 168803,
              futureBalance: 71066,
              presentBalance: 71066,
              interestEconomyAmount: -123.0,
              penaltyFeeAmount: 0,
              penaltyInterestAmount: 0,
              latePaymentInterestAmount: 2386,
              totalDebtAmount: 5680
            },
            nextInstallment: {
              installmentNumber: 2,
              startDate: '2023-09-23T00:00:00Z',
              dueDate: '2023-10-23T00:00:00Z',
              futureBalance: 171148,
              presentBalance: 171148
            },
            overdueInstallment: {
              overdueInstallments: [
                {
                  installmentNumber: 1,
                  dueDate: '2023-09-22',
                  presentBalance: 71066
                },
                {
                  installmentNumber: 2,
                  dueDate: '2023-10-23',
                  presentBalance: 171148
                }
              ],
              totalPresentBalance: 242214,
              daysOverdue: 52,
              penaltyFeeAmount: 0,
              penaltyInterestAmount: 0,
              latePaymentInterestAmount: 4731
            }
          }
        ]
      }
    ]
  },
  traceKey: 'jqyngge7hhkycx87vdjmqqqw1',
  isValid: true
}

export const opportunities = {
  proposal: {
    type: 'loan_concession',
    installmentAmount: 1000000,
    interestRate: 0.03,
    dueDate: '2042-01-01',
    offerId,
    expirationDate: '2042-01-01',
    id: 'gsf875mkq2ptxakyd8t9tycsm',
    amount: 10000000,
    installments: 10,
    creationDate: '2023-12-25',
    negotiationType: 'proposal',
    daysToReceive: 4,
    highlight: true
  },
  offer: {
    type: 'loan_concession',
    id: 'xnuf6etr86wghidamn0p2hs1s',
    amount: 10000000,
    installments: 10,
    creationDate: '2023-12-25',
    expirationDate: '2024-12-25',
    negotiationType: 'offer',
    daysToReceive: 4,
    highlight: true
  },
  renegotiation: {
    type: 'renegotiation',
    installmentAmount: 10000,
    interestRate: 0.03,
    dueDate: '2042-01-01',
    offerId,
    expirationDate: '2042-01-01',
    id: 'gsf875mkq2ptxakyd8t9tycsm',
    amount: 100000,
    installments: 10,
    creationDate: '2023-12-25',
    negotiationType: 'proposal',
    daysToReceive: 4,
    highlight: true
  },
  revolvingCredit: {
    minimal_retention_rate: 0.7,
    maximal_retention_rate: 1.0,
    type: 'revolving_credit',
    negotiation_status: 'available',
    id: 'jr41k9sbg97ycehwdqkaz3awd',
    amount: 7800000,
    creation_date: '2024-04-19T17:00:17Z',
    negotiation_type: 'offer',
    days_to_receive: 0,
    highlight: false,
    badge: 'Giro Fácil'
  }
}

export const opportunitiesSection = {
  id: 'opportunities',
  title: 'Home.Sections.Opportunities.Title',
  elements: []
}

export const loanHomeWithOpportunitiesMock = {
  ...loanHomeMock,
  content: {
    sections: [
      loanHomeMock.content.sections[0],
      {
        ...opportunitiesSection,
        elements: [opportunities.offer]
      }
    ]
  }
}

export const proposalById = {
  id: 'jqzvwd5qjwmx5ffpg1qmuzcyj',
  offerId: 'jqzvwayvzvzr2e711gpmhqjfj',
  type: 'Renegotiation',
  status: 'Available',
  origin: { created_by: 'danillo.andrade@stone.com.br', user_type: 'PricingTeam' },
  formalization: { type: 'AmendmentToCreditBankingAgreement' },
  expirationDate: '2023-12-18',
  customer: {
    externalId: '9538d541-6cb3-4fe4-85a3-7d3de0429063',
    name: 'ROSEMERI APARECIDA FIGUEIREDO',
    document: '03095185000153',
    documentType: 'CNPJ',
    bankAccount: { external_id: '63df3805-0f39-4a45-b683-0e3b1ec6382b', account_number: '141069', branch_number: '1' },
    lastUpdateDate: '2023-12-13T13:54:35Z'
  },
  renegotiation: {
    securities: [
      {
        type: 'Credit',
        externalReference: 'jqyr2x8xa7bx5pk1sm8crtzzs',
        isOverdue: false,
        installmentPlan: {
          amount: 390.7920306768,
          count: 6,
          totalSum: 2344.7521840608,
          paymentReferenceDate: '2023-10-30'
        },
        rate: {
          taxAmount: 24.99,
          annualInterestRate: 0.1942,
          annualTotalEffectiveCostRate: 0.242,
          retentionRate: 0.1954,
          interestRate: 0.0149,
          iofRate: 0.000041
        },
        summary: {
          totalPaid: 0.0,
          currentDebtAmount: 2285.8257053968,
          numberOfOpenInstallments: 6,
          dueDate: '2024-04-30',
          days_overdue: 13
        },
        last_update_date: '2023-12-13T13:54:35Z'
      }
    ],
    amount: 2285.8257053968,
    dueDate: '2024-10-15',
    rate: {
      taxAmount: 0.0,
      annualInterestRate: 0.1942053984,
      annualTotalEffectiveCostRate: 0.1942053984,
      retentionRate: 0.01,
      interestRate: 0.0149,
      iofRate: 0.0
    },
    installment_plan: {
      amount: 247.7303386503,
      count: 10,
      totalSum: 2477.303386503,
      paymentReferenceDate: '2023-12-15'
    }
  },
  creationDate: '2023-12-13T13:54:35Z',
  lastUpdateDate: '2023-12-13T13:54:35Z'
}

export const processingLoan: LoanSection = {
  id: 'jqzv8wnfmpcf35dpxt3vtp3bp',
  type: 'processing_loan',
  status: 'disbursement_requested',
  statusDescription: 'Em andamento',
  creditAmount: 2000000,
  totalOfInstallment: 7,
  daysToReceive: 4,
  creationDate: '2023-12-12T18:21:29Z',
  lastUpdateDate: '2023-12-13T12:00:12Z'
}

export const revolvingCreditLoan: LoanSection = {
  id: 'jqzv8wnfmpcf35dsssaaaaapxt3vtp3bp',
  type: 'revolving_credit',
  amountLimit: 4000,
  badge: 'giro fácil',
  contractedAmount: 3000,
  withdrawalAmount: 1000
}

export const currentLoan: LoanSection = {
  id: 'jqx91kdp765tawqdpk5rtfcu1',
  type: 'current_loan',
  status: 'active',
  statusDescription: 'Ativo',
  creditAmount: 2000000,
  totalOfInstallment: 10,
  initialInstallmentAmount: 227079,
  creationDate: '2023-08-28T20:23:12Z',
  lastUpdateDate: '2024-03-13T03:43:06Z',
  isRetentionEnabled: true,
  currentInstallment: {
    installmentNumber: 1,
    startDate: '2023-09-11T00:00:00Z',
    dueDate: '2023-10-12',
    daysUntilDueDate: 0,
    isDueSoon: false,
    daysOverdue: 153,
    isOverdue: true,
    status: 'overdue',
    paidAmount: 0,
    initialAmount: 227079,
    futureBalance: 251085,
    presentBalance: 251085,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 24006,
    totalDebtAmount: 28437
  },
  nextInstallment: {
    installmentNumber: 2,
    startDate: '2023-10-13T00:00:00Z',
    dueDate: '2023-11-13T00:00:00Z',
    futureBalance: 259563,
    presentBalance: 259563
  },
  overdueInstallment: {
    overdueInstallments: [
      {
        installmentNumber: 1,
        dueDate: '2023-10-12',
        presentBalance: 251085
      },
      {
        installmentNumber: 2,
        dueDate: '2023-11-13',
        presentBalance: 259563
      },
      {
        installmentNumber: 3,
        dueDate: '2023-12-12',
        presentBalance: 252729
      },
      {
        installmentNumber: 4,
        dueDate: '2024-01-12',
        presentBalance: 245521
      },
      {
        installmentNumber: 5,
        dueDate: '2024-02-12',
        presentBalance: 238410
      },
      {
        installmentNumber: 6,
        dueDate: '2024-03-12',
        presentBalance: 231845
      }
    ],
    totalPresentBalance: 1479154,
    daysOverdue: 153,
    penaltyFeeAmount: 22708,
    penaltyInterestAmount: 23086,
    latePaymentInterestAmount: 70885
  }
}

export const currentLoanWithGrace: LoanSection = {
  id: 'jqx91kdp765tawqdpk5rtfcu1',
  type: 'current_loan',
  status: 'active',
  statusDescription: 'Ativo',
  creditAmount: 2000000,
  totalOfInstallment: 10,
  initialInstallmentAmount: 227079,
  creationDate: '2023-08-28T20:23:12Z',
  lastUpdateDate: '2024-03-13T03:43:06Z',
  isRetentionEnabled: true,
  currentInstallment: {
    installmentNumber: 1,
    startDate: '2023-09-11T00:00:00Z',
    dueDate: '2023-10-12',
    daysUntilDueDate: 0,
    isDueSoon: false,
    daysOverdue: 153,
    isOverdue: true,
    status: 'overdue',
    paidAmount: 0,
    initialAmount: 227079,
    futureBalance: 251085,
    presentBalance: 251085,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 24006,
    totalDebtAmount: 28437
  },
  nextInstallment: {
    installmentNumber: 2,
    startDate: '2023-10-13T00:00:00Z',
    dueDate: '2023-11-13T00:00:00Z',
    futureBalance: 259563,
    presentBalance: 259563
  },
  overdueInstallment: {
    overdueInstallments: [
      {
        installmentNumber: 1,
        dueDate: '2023-10-12',
        presentBalance: 251085
      },
      {
        installmentNumber: 2,
        dueDate: '2023-11-13',
        presentBalance: 259563
      },
      {
        installmentNumber: 3,
        dueDate: '2023-12-12',
        presentBalance: 252729
      },
      {
        installmentNumber: 4,
        dueDate: '2024-01-12',
        presentBalance: 245521
      },
      {
        installmentNumber: 5,
        dueDate: '2024-02-12',
        presentBalance: 238410
      },
      {
        installmentNumber: 6,
        dueDate: '2024-03-12',
        presentBalance: 231845
      }
    ],
    totalPresentBalance: 1479154,
    daysOverdue: 153,
    penaltyFeeAmount: 22708,
    penaltyInterestAmount: 23086,
    latePaymentInterestAmount: 70885
  },
  gracePeriodInDays: 60,
  plans: [
    {
      amountByInstallment: {
        value: 1020.33,
        description: 'R$ 1020,33',
        type: 'money'
      },
      installments: {
        value: 2,
        description: '2x',
        type: 'integer'
      },
      total: {
        value: 2040.67,
        description: 'R$ 2040,67',
        type: 'money'
      },
      type: 'GraceWithInterest'
    },
    {
      amountByInstallment: {
        value: 502.33,
        description: 'R$ 502,33',
        type: 'money'
      },
      installments: {
        value: 4,
        description: '4x',
        type: 'integer'
      },
      total: {
        value: 2040.67,
        description: 'R$ 2040,67',
        type: 'money'
      },
      type: 'Regular'
    }
  ],
  renegotiationSummary: {
    status: 'Disbursed',
    statusDescription: 'Renegociação em análise',
    loanRenegotiationContractId: 'jr3kfbqmc1ag9asfvsn8wzg3u',
    contractSignatureDate: '2024-06-01',
    creationDate: '2024-06-01'
  }
}

export const loanConcessionOffer: CreditOffer = {
  id: 'jr3xj93pbbte3t9kudzm6ymgq',
  type: 'LoanConcession',
  customer: {
    document: '74665340000178',
    documentType: 'CNPJ'
  },
  discountSettlement: {
    securities: [
      {
        type: 'Credit',
        externalReference: 'jqt2j2dmde6zfhnbkke1pd114',
        isOverdue: true,
        installmentNumbers: [1, 2, 3, 4, 5, 6],
        lastUpdateDate: '2023-05-19T15:08:17Z'
      }
    ],
    condition: {
      type: 'DiscountAmount',
      calculationDirection: 'Up',
      range: { min: 6, max: 12 },
      riskLevels: [
        { level: 1, proportionalPercentage: 30 },
        { level: 2, proportionalPercentage: 79 },
        { level: 3, proportionalPercentage: 100 }
      ]
    }
  },
  negotiation: {
    status: 'Available'
  },
  roles: [
    {
      userType: 'Customer',
      maxRiskLevel: 1,
      conditions: [
        {
          minCreditLimit: 3000.0,
          maxCreditLimit: 20000.0,
          allowedTerms: [6]
        }
      ]
    }
  ],
  creationDate: '2024-04-15T20:24:15Z',
  lastUpdateDate: '2024-04-15T20:24:17Z'
}

export const offersResponse = {
  items: [
    {
      id: 'jr3enr2rq9cj7qn4qk7z3njex',
      type: 'Renegotiation',
      customer: {
        document: '74665340000178',
        document_type: 'CNPJ'
      },
      expiration_date: '2024-12-20',
      negotiation: {
        status: 'Available'
      },
      roles: [
        {
          user_type: 'Customer',
          max_risk_level: 1,
          conditions: [
            {
              type: 'Term',
              min: 6.0,
              max: 7.0
            },
            {
              type: 'InterestRate',
              min: 0.0062,
              max: 0.008
            }
          ]
        }
      ],
      creation_date: '2024-03-26T15:02:37Z',
      last_update_date: '2024-03-26T15:02:38Z'
    },
    {
      id: 'jr3xj93pbbte3t9kudzm6ymgq',
      type: 'LoanConcession',
      customer: {
        document: '74665340000178',
        document_type: 'CNPJ'
      },
      expiration_date: '2024-05-15',
      negotiation: {
        status: 'Available'
      },
      roles: [
        {
          user_type: 'Customer',
          max_risk_level: 1,
          conditions: [
            {
              min_credit_limit: 3000.0,
              max_credit_limit: 20000.0,
              allowed_terms: [6]
            }
          ]
        }
      ],
      creation_date: '2024-04-15T20:24:15Z',
      last_update_date: '2024-04-15T20:24:17Z'
    }
  ],
  paging: {
    total_items: 2,
    current_page: 1,
    page_size: 100,
    page_count: 1
  }
}

export const proposalsResponse = {
  items: [
    {
      id: 'jr3enr6cbfy8851fhk5ebvyv3',
      offer_id: 'jr3enr2rq9cj7qn4qk7z3njex',
      type: 'Renegotiation',
      status: 'Processing',
      origin: { created_by: 'mateus.lazarus@stone.com.br', user_type: 'Customer' },
      formalization: { type: 'AmendmentToCreditBankingAgreement' },
      expiration_date: '2024-03-31',
      accepted_at: '2024-03-26T15:02:53Z',
      customer: {
        main_partner: {
          external_id: '11d584a4-919d-4eae-b33e-2b42581654c5',
          name: 'Keane Fletcher',
          document: '14402914701',
          document_type: 'CPF',
          marital_status: 'Single',
          email: 'voliveira+testerisco3@stone.com.br',
          nationality: 'Brasil',
          address: {
            city: 'Rio de Janeiro',
            neighborhood: 'Taua',
            postal_code: '21920-115',
            state: 'RJ',
            street: 'Rua Manuel Pereira da Costa',
            street_number: '76',
            complement: ''
          },
          bank_account: {
            external_id: '1d410698-3fa3-4045-9461-03bfece1f8a3',
            account_number: '321463',
            branch_number: '1'
          },
          last_update_date: '2024-03-26T15:02:45Z'
        },
        external_id: '0907797f-b8a9-4d7e-9580-3f3e3fb4bc03',
        name: 'APARECIDO DARI DE MATTOS',
        document: '74665340000178',
        document_type: 'CNPJ',
        email: 'voliveira+testerisco3@stone.com.br',
        address: {
          city: 'Rio de Janeiro',
          neighborhood: 'Taua',
          postal_code: '21920-115',
          state: 'RJ',
          street: 'Rua Manuel Pereira da Costa',
          street_number: '76',
          complement: ''
        },
        bank_account: {
          external_id: '351c01af-9e27-4a94-a24d-1cc6d3c82adf',
          account_number: '240911',
          branch_number: '1'
        },
        last_update_date: '2024-03-26T15:02:45Z'
      },
      renegotiation: {
        securities: [
          {
            type: 'Credit',
            external_reference: 'jr3en9p8mv69az6bvukf46j48',
            is_overdue: false,
            installment_plan: { amount: 344.75, count: 12, total_sum: 4137.06, payment_reference_date: '2024-04-05' },
            rate: {
              tax_amount: 57.37,
              annual_interest_rate: 0.7938,
              annual_total_effective_cost_rate: 0.8642,
              retention_rate: 0.01,
              interest_rate: 0.0499,
              iof_rate: 0.000041
            },
            summary: {
              total_paid: 0.0,
              current_debt_amount: 3057.3715837329,
              number_of_open_installments: 12,
              due_date: '2025-04-07',
              days_overdue: 0
            },
            last_update_date: '2024-03-26T15:02:45Z'
          }
        ],
        amount: 3057.37,
        due_date: '2024-09-30',
        rate: {
          tax_amount: 0.0,
          annual_interest_rate: 0.077,
          annual_total_effective_cost_rate: 0.077,
          retention_rate: 0.03,
          interest_rate: 0.0062,
          iof_rate: 0.0
        },
        installment_plan: { amount: 520.68, count: 6, total_sum: 3124.06, payment_reference_date: '2024-03-28' }
      },
      creation_date: '2024-03-26T15:02:45Z',
      last_update_date: '2024-03-26T15:02:53Z'
    }
  ],
  paging: { total_items: 1, current_page: 1, page_size: 100, page_count: 1 }
}

export const mockHistoryResponse = {
  content: [
    {
      id: 'jr67f9mtvwtd6qbvcae278883',
      type: 'Renegotiation',
      badge: 'Renegociação',
      amount: 2000000,
      date: '2024-07-24',
      status: 'cancelled',
      showAction: false,
      metadata: {
        title: 'R$ 20.000,00',
        subtitle: 'Cancelado em 24 jul 2024.'
      }
    },
    {
      id: 'jr6ry7g8ssr42st96va8mj9tc',
      type: 'Loan',
      badge: 'Capital de Giro',
      amount: 2500000,
      date: '2024-08-09',
      status: 'quitado',
      showAction: true,
      metadata: {
        title: 'R$ 25.000,00',
        subtitle: 'Quitado em 09 ago 2024.'
      }
    },
    {
      id: 'jr5q2sgexvay5f9tntm2b79hh',
      type: 'Revolving',
      badge: 'Giro Fácil',
      amount: 0,
      date: '2024-06-26',
      status: 'cancelled',
      showAction: false,
      metadata: {
        title: 'R$ 0,00',
        subtitle: 'Cancelado em 26 jun 2024.'
      }
    }
  ],
  traceKey: 'jr72akkvyzts5nkbtyjbdefjh',
  isValid: true
}
