import { Grid, Stack } from '@dlpco/fluid-layout'
import { Card, CardBody } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { If } from '~/domains/platform/lib/utilities-components'

export function RenegotiationFlowLoading({ showTitle = true }: { showTitle?: boolean }) {
  return (
    <Box>
      <Stack space="1rem">
        <If condition={showTitle}>
          <Skeleton width="40%" height="2ch" aria-label="Renegociação" />
          <Skeleton width="50%" height="5ch" />
        </If>

        <Card aria-label="carregando detalhes da renegociação">
          <CardBody>
            <Flex flexDirection="column" gap="1rem">
              <Skeleton width="70%" height="4ch" />
              <Skeleton width="20%" height="2ch" />
              <Grid gutter="2rem" min="20ch" style={{ marginTop: '1rem' }}>
                <div>
                  <Skeleton width="15%" height="2ch" />
                  <Flex flexDirection="column" gap="1rem">
                    {Array.from({ length: 8 }).map((_, index) => (
                      <Skeleton key={`loading-${index + 1}`} width="100%" height="3ch" />
                    ))}
                  </Flex>
                </div>
                <div>
                  <Skeleton width="15%" height="2ch" />
                  <Flex flexDirection="column" gap="1rem">
                    {Array.from({ length: 8 }).map((_, index) => (
                      <Skeleton key={`loading-${index + 1}`} width="100%" height="3ch" />
                    ))}
                  </Flex>
                </div>
              </Grid>

              <Flex justifyContent="flex-end" gap="1rem" mt="1rem">
                <Skeleton width="25%" height="4ch" />
                <Skeleton width="25%" height="4ch" />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Stack>
    </Box>
  )
}
