import { useEffectOnce } from 'react-use'
import { CreditBucket } from '@credit-web/common/browser-utils'

import { renegotiationBasePath } from '~/domains/credit/ports/credit.routes'
import BlackBird from '~/domains/platform/lib/blackbird'
import { removeNullProperties } from '~/domains/platform/lib/object-helpers/remove-null-properties'

import { type RenegotiationProposal } from '../../../loan-concession/entities'
import { proposalBannerEvent } from '../../../loan-details/helpers/events'
import { type CreditOffer } from '../../../shared/entities'
import { isAdminProposal, RENEGOTIATON_VALID_STATUS } from '../../helpers/renegotiation'
import renegotiationAnalitica from '../../helpers/renegotiation-analitica'

import { RenegotiationBannerOffer } from './renegotiation-banner-offer'
import { RenegotiationBannerProposal } from './renegotiation-banner-proposal'

interface BannerProps {
  offer?: CreditOffer
  proposal?: RenegotiationProposal
  loanId?: string
}

export function RenegotiationBannerContainerCTA({ offer, proposal, loanId }: BannerProps) {
  const renegotiationProposalIsValid = RENEGOTIATON_VALID_STATUS.includes(proposal?.status || '')

  const goToRenegotiation = () => {
    renegotiationAnalitica.events.banner.proposalClick()
    proposalBannerEvent('clicked', { offer, proposal })
    CreditBucket.set('credit::shared::initial-path', 'loan-details')

    BlackBird.travelTo({
      pathname: renegotiationBasePath,
      urlParams: { loan: loanId },
      query: removeNullProperties({ offerId: offer?.id, proposalId: proposal?.id || null })
    })
  }

  useEffectOnce(() => proposalBannerEvent('view', { offer, proposal }))

  if (offer?.type === 'DiscountSettlement' && isAdminProposal(proposal))
    return <RenegotiationBannerProposal handleClick={goToRenegotiation} />

  if (offer?.type === 'DiscountSettlement') return <RenegotiationBannerOffer handleClick={goToRenegotiation} />

  if (offer?.type === 'Renegotiation' && renegotiationProposalIsValid && proposal)
    return <RenegotiationBannerProposal handleClick={goToRenegotiation} />

  return null
}
