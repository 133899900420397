import { type ContentWrapped, type CreditPaging } from '~/domains/credit/shared/entities'

import { type LoanInstallment, type LoanSummary } from '../../shared/entities'
import { type LegacySpotOffer, type SpotOffer } from '../entities'

export const spotOfferPixMock: LegacySpotOffer = {
  id: 'jqb8ucespjrrc7zrtc1v2e6wt',
  status: 'Processed',
  type: 'Regular',
  amount: 100.0,
  discountRate: 0.1,
  discountAmount: 10.0,
  paymentMethod: 'Pix',
  dueDate: '2023-06-10',
  origin: {
    providerId: 'Operation',
    externalReference: '80954f690596'
  },
  product: {
    type: 'Loan',
    externalId: 'jq6tknqrqqmccta6a938f2jmq'
  },
  customer: {
    name: 'Anakin Skywalker',
    fantasyName: 'Darth Vader',
    document: '11111111111111',
    email: 'darth.vader@stone.com.br'
  },
  beneficiary: {
    name: 'FIDC Stone',
    document: '11111111111111',
    stoneOpenbankAccountId: '123456-acd-xyz-9876'
  },
  pix: {
    amount: 90.0,
    provider: 'StoneOpenbank',
    type: 'InvoiceWithDueDate',
    externalId: 'jqb8ucex2tbq2y6jw1zmj9wqy',
    dueDate: '2022-05-29',
    status: 'Settled',
    key: '11111111111111',
    qrCode: {
      content:
        '00020101021226890014br.gov.bcb.pix2567pix-h.stone.com.br/pix/v2/cobv/e1e80eed-d0cc-4ba1-a87c-2526f5e61cec5204000053039865406100.005802BR5923Nome do CPF 502021060986014RIO DE JANEIRO622905256df486abbb294668817b903ad6304D69E'
    },
    createdAt: '2021-07-15T20:55:20Z',
    updatedAt: '2021-07-15T20:57:03Z',
    settledAt: '2021-07-15T20:57:03Z',
    expiredAt: '2021-07-15T20:57:03Z'
  },
  entries: [
    {
      type: 'Pix',
      provider: 'StoneOpenbank',
      amount: 90.0,
      entryDate: '2022-05-29',
      status: 'Created',
      externalId: 'jqb8ucezdhm35ne5axnv1pv9p'
    },
    {
      type: 'DebtDiscount',
      provider: 'StoneLoan',
      amount: 10.0,
      entryDate: '2022-05-29T00:00:00Z',
      status: 'Created',
      externalId: 'jqb8ucezdhm35ne5axnv1pv9p'
    }
  ],
  cancellation: {
    status: 'Accepted',
    requestedAt: '2022-03-04T05:09:04Z',
    acceptedAt: '2022-03-04T05:09:06Z'
  },
  createdAt: '2021-07-15T20:55:20Z',
  createdBy: 'oferta@stone.com.br',
  updatedAt: '2021-07-15T20:57:06Z',
  emailSentAt: '2021-07-15T20:55:26Z',
  failedAt: '2021-07-15T20:55:26Z'
}

export const spotOfferCreatedMock: ContentWrapped<Partial<SpotOffer>> = {
  content: {
    id: 'jqrmz5qc1f9ub9nzq62ghpffz'
  },
  traceKey: 'jqvkk4123dt25cggj2fmqj5j5',
  isValid: true
}

export const spotOfferBFFSearchMock: ContentWrapped<SpotOffer> = {
  content: {
    id: 'jqrmz5qc1f9ub9nzq62ghpffz',
    dueDate: '2023-01-13T00:00:00Z',
    amount: 230700,
    payment: {
      content: '19796922900000023070000058347271450720379338',
      image: 'data:image/png;base64',
      publicUri: 'https://sandbox-link.openbank.stone.com.br/api/boleto?id=b7c51a02-91f9-4a05-bbc6-0210dd4199ae'
    },
    createdBy: 'adm_ops@openbank.stone.com.br',
    paymentMethod: 'Pix',
    generalStatus: 'processed'
  },
  traceKey: 'jqvkk4123dt25cggj2fmqj5j5',
  isValid: true
}

export const spotOfferBoletoMock: LegacySpotOffer = {
  id: 'jqb8ucespjrrc7zrtc1v2e6wa',
  status: 'Processed',
  type: 'Regular',
  amount: 100.0,
  discountRate: 0.1,
  discountAmount: 10.0,
  paymentMethod: 'Boleto',
  dueDate: '2023-06-10',
  origin: {
    providerId: 'Operation',
    externalReference: '80954f690596'
  },
  product: {
    type: 'Loan',
    externalId: 'jq6tknqrqqmccta6a938f2jmq'
  },
  customer: {
    name: 'Anakin Skywalker',
    fantasyName: 'Darth Vader',
    document: '11111111111111',
    email: 'darth.vader@stone.com.br'
  },
  beneficiary: {
    name: 'FIDC Stone',
    document: '11111111111111',
    stoneOpenbankAccountId: '123456-acd-xyz-9876'
  },
  boleto: {
    amount: 90.0,
    provider: 'StoneOpenbank',
    type: 'BillOfExchange',
    externalId: 'jqb8ucex2tbq2y6jw1zmj9wqy',
    dueDate: '2022-05-29',
    status: 'Settled',
    publicUri: 'https://sandbox-link.openbank.stone.com.br/api/boleto?id=b7c51a02-91f9-4a05-bbc6-0210dd4199ae',
    barCode: '19792888200000080000000097672187089193449477',
    createdAt: '2021-07-15T20:55:20Z',
    updatedAt: '2021-07-15T20:57:03Z',
    registeredAt: '2021-07-15T20:57:03Z',
    settledAt: '2021-07-15T20:57:03Z',
    expiredAt: '2021-07-15T20:57:03Z'
  },
  entries: [
    {
      type: 'Boleto',
      provider: 'StoneOpenbank',
      amount: 90.0,
      entryDate: '2022-05-29',
      status: 'Created',
      externalId: 'jqb8ucezdhm35ne5axnv1pv9p'
    },
    {
      type: 'DebtDiscount',
      provider: 'StoneLoan',
      amount: 10.0,
      entryDate: '2022-05-29T00:00:00Z',
      status: 'Created',
      externalId: 'jqb8ucezdhm35ne5axnv1pv9p'
    }
  ],
  cancellation: {
    status: 'Accepted',
    requestedAt: '2022-03-04T05:09:04Z',
    acceptedAt: '2022-03-04T05:09:06Z'
  },
  createdAt: '2021-07-15T20:55:20Z',
  createdBy: 'carolina.serrao@stone.com.br',
  updatedAt: '2021-07-15T20:57:06Z',
  emailSentAt: '2021-07-15T20:55:26Z',
  failedAt: '2021-07-15T20:55:26Z'
}
export const spotOfferBoletoRegisteredMock: LegacySpotOffer = {
  id: 'jqb8ucespjrrc7zrtc1v2e6wa',
  status: 'Processed',
  type: 'Regular',
  amount: 100.0,
  discountRate: 0.1,
  discountAmount: 10.0,
  paymentMethod: 'Boleto',
  dueDate: '2023-06-10',
  origin: {
    providerId: 'Operation',
    externalReference: '80954f690596'
  },
  product: {
    type: 'Loan',
    externalId: 'jq6tknqrqqmccta6a938f2jmq'
  },
  customer: {
    name: 'Anakin Skywalker',
    fantasyName: 'Darth Vader',
    document: '11111111111111',
    email: 'darth.vader@stone.com.br'
  },
  beneficiary: {
    name: 'FIDC Stone',
    document: '11111111111111',
    stoneOpenbankAccountId: '123456-acd-xyz-9876'
  },
  boleto: {
    amount: 90.0,
    provider: 'StoneOpenbank',
    type: 'BillOfExchange',
    externalId: 'jqb8ucex2tbq2y6jw1zmj9wqy',
    dueDate: '2022-05-29',
    status: 'Registered',
    publicUri: 'https://sandbox-link.openbank.stone.com.br/api/boleto?id=b7c51a02-91f9-4a05-bbc6-0210dd4199ae',
    barCode: '19792888200000080000000097672187089193449477',
    createdAt: '2021-07-15T20:55:20Z',
    updatedAt: '2021-07-15T20:57:03Z',
    registeredAt: '2021-07-15T20:57:03Z',
    settledAt: '2021-07-15T20:57:03Z',
    expiredAt: '2021-07-15T20:57:03Z'
  },
  entries: [
    {
      type: 'Boleto',
      provider: 'StoneOpenbank',
      amount: 90.0,
      entryDate: '2022-05-29',
      status: 'Created',
      externalId: 'jqb8ucezdhm35ne5axnv1pv9p'
    },
    {
      type: 'DebtDiscount',
      provider: 'StoneLoan',
      amount: 10.0,
      entryDate: '2022-05-29T00:00:00Z',
      status: 'Created',
      externalId: 'jqb8ucezdhm35ne5axnv1pv9p'
    }
  ],
  cancellation: {
    status: 'Accepted',
    requestedAt: '2022-03-04T05:09:04Z',
    acceptedAt: '2022-03-04T05:09:06Z'
  },
  createdAt: '2021-07-15T20:55:20Z',
  createdBy: 'carolina.serrao@stone.com.br',
  updatedAt: '2021-07-15T20:57:06Z',
  emailSentAt: '2021-07-15T20:55:26Z',
  failedAt: '2021-07-15T20:55:26Z'
}

export const spotOfferListMock: Partial<CreditPaging<LegacySpotOffer[]>> = {
  items: [spotOfferPixMock, spotOfferBoletoMock]
}

export const summaryNotOverdued: LoanSummary = {
  label: 'Empréstimo',
  initialAmount: 16007.23,
  initialInstallmentAmount: 1778.58,
  futureBalance: 12216.75,
  presentBalance: 10766.37,
  creditAmount: 13000,
  taxAmount: 230.37,
  ddcUrl: 'mocked-url',
  interestEconomyAmount: -11.89,
  paidAmount: -3778.58,
  numberOfInstallments: 9,
  numberOfSettledInstallments: 2,
  numberOfOverdueInstallments: 0,
  isRetentionEnabled: true,
  retentionRate: 0.1,
  monthlyInterestRate: 0.0399,
  yearlyInterestRate: 0.5992,
  yearlyTotalEffectiveCostRate: 0.6617,
  dueDate: '2023-12-29',
  contractUri:
    'https://credit-sandbox.stone.com.br/api/v3/borrowers/jqqky9fux5xxcz11q4uu9rpza/proposals/jqthdvbzfbs21gc84b1mc76zp/contract',
  currentInstallment: {
    id: 'jqthdw6jndyfejvzpww6hxg36',
    installmentNumber: 3,
    startDate: '2023-04-01',
    dueDate: '2023-05-01',
    daysUntilDueDate: 6,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: true,
    isOverdue: false,
    status: 'Open',
    paidAmount: -223.78,
    initialAmount: 1778.58,
    futureBalance: 1545.27,
    presentBalance: 1531.23,
    interestEconomyAmount: -9.54,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  overdueInstallmentsSummary: {
    overdueInstallments: [],
    totalPresentBalance: 0,
    daysOverdue: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0
  },
  nextInstallment: {
    installmentNumber: 4,
    startDate: '2023-05-01',
    dueDate: '2023-05-31',
    status: 'Future',
    futureBalance: 1778.58,
    presentBalance: 1694.8
  }
}

export const installmentsNotOverdued: LoanInstallment[] = [
  {
    id: 'jqthdw6jndhvfezygc27tazwb',
    installmentNumber: 1,
    startDate: '2023-02-01',
    dueDate: '2023-03-01',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 54,
    settlementDate: '2023-03-30',
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Settled',
    paidAmount: -1778.58,
    initialAmount: 1778.58,
    futureBalance: 0,
    presentBalance: 0,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jndxqb5273fmrzh3ca',
    installmentNumber: 2,
    startDate: '2023-03-01',
    dueDate: '2023-03-31',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 24,
    settlementDate: '2023-03-30',
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Settled',
    paidAmount: -1776.22,
    initialAmount: 1778.58,
    futureBalance: 0,
    presentBalance: 0,
    interestEconomyAmount: -2.36,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jndyfejvzpww6hxg36',
    installmentNumber: 3,
    startDate: '2023-04-01',
    dueDate: '2023-05-01',
    daysUntilDueDate: 6,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: true,
    isOverdue: false,
    status: 'Open',
    paidAmount: -223.78,
    initialAmount: 1778.58,
    futureBalance: 1545.27,
    presentBalance: 1531.23,
    interestEconomyAmount: -9.54,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jndz5amr6tv7mgc3ew',
    installmentNumber: 4,
    startDate: '2023-05-01',
    dueDate: '2023-05-31',
    daysUntilDueDate: 36,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0,
    initialAmount: 1778.58,
    futureBalance: 1778.58,
    presentBalance: 1694.8,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jndzpbkavqqjhxk258',
    installmentNumber: 5,
    startDate: '2023-06-01',
    dueDate: '2023-06-30',
    daysUntilDueDate: 66,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0,
    initialAmount: 1778.58,
    futureBalance: 1778.58,
    presentBalance: 1629.77,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jne1f4919k8hy3yqez',
    installmentNumber: 6,
    startDate: '2023-07-01',
    dueDate: '2023-07-31',
    daysUntilDueDate: 97,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0,
    initialAmount: 1778.58,
    futureBalance: 1778.58,
    presentBalance: 1566.3,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jne1ya98ufaaerz39n',
    installmentNumber: 7,
    startDate: '2023-08-01',
    dueDate: '2023-08-31',
    daysUntilDueDate: 128,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0,
    initialAmount: 1778.58,
    futureBalance: 1778.58,
    presentBalance: 1505.56,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jne2fby43tvc8a4fsh',
    installmentNumber: 8,
    startDate: '2023-09-01',
    dueDate: '2023-10-02',
    daysUntilDueDate: 160,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0,
    initialAmount: 1778.58,
    futureBalance: 1778.58,
    presentBalance: 1446.71,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  },
  {
    id: 'jqthdw6jne2wdxvp3x6s7hrtd',
    installmentNumber: 9,
    startDate: '2023-10-01',
    dueDate: '2023-10-31',
    daysUntilDueDate: 189,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0,
    initialAmount: 1778.58,
    futureBalance: 1778.58,
    presentBalance: 1392.01,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 1778.58
  }
]

export const summaryOverdued: LoanSummary = {
  label: 'Empréstimo',
  initialAmount: 991.36,
  initialInstallmentAmount: 330.45,
  futureBalance: 453.71,
  ddcUrl: 'mocked-url',
  presentBalance: 449.43,
  creditAmount: 910,
  taxAmount: 7.21,
  interestEconomyAmount: -20.49,
  paidAmount: -520.32,
  numberOfInstallments: 3,
  numberOfSettledInstallments: 1,
  numberOfOverdueInstallments: 1,
  isRetentionEnabled: true,
  retentionRate: 0.1,
  monthlyInterestRate: 0.0399,
  yearlyInterestRate: 0.5992,
  yearlyTotalEffectiveCostRate: 0.6617,
  dueDate: '2023-05-14',
  contractUri:
    'https://credit-sandbox.stone.com.br/api/v3/borrowers/jqsfm86xmvrf9qw35az6kzm9n/proposals/jqsfn25yfyb27gje2b2uaajja/contract',
  currentInstallment: {
    id: 'jqsfn3uezky68vxpydhvtjs1h',
    installmentNumber: 2,
    startDate: '2023-03-05',
    dueDate: '2023-04-04',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 20,
    isCollectionEnabled: true,
    isOverdue: true,
    status: 'Overdue',
    paidAmount: -198.12,
    initialAmount: 330.45,
    futureBalance: 123.26,
    presentBalance: 123.26,
    interestEconomyAmount: -12.25,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 3.17,
    totalDebtAmount: 333.63
  },
  overdueInstallmentsSummary: {
    overdueInstallments: [
      {
        installmentNumber: 2,
        dueDate: '2023-04-04',
        presentBalance: 123.26
      }
    ],
    totalPresentBalance: 123.26,
    daysOverdue: 20,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 3.17
  },
  nextInstallment: {
    installmentNumber: 3,
    startDate: '2023-04-05',
    dueDate: '2023-05-04',
    status: 'Open',
    futureBalance: 330.45,
    presentBalance: 326.17
  }
}

export const installmentsOverdued: LoanInstallment[] = [
  {
    id: 'jqsfn3uezks31kba6q8mp3ygv',
    installmentNumber: 1,
    startDate: '2023-02-04',
    dueDate: '2023-03-06',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 49,
    settlementDate: '2023-02-14',
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Settled',
    paidAmount: -322.21,
    initialAmount: 330.45,
    futureBalance: 0,
    presentBalance: 0,
    interestEconomyAmount: -8.24,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 330.45
  },
  {
    id: 'jqsfn3uezky68vxpydhvtjs1h',
    installmentNumber: 2,
    startDate: '2023-03-05',
    dueDate: '2023-04-04',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 20,
    isCollectionEnabled: true,
    isOverdue: true,
    status: 'Overdue',
    paidAmount: -198.12,
    initialAmount: 330.45,
    futureBalance: 123.26,
    presentBalance: 123.26,
    interestEconomyAmount: -12.25,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 3.17,
    totalDebtAmount: 333.63
  },
  {
    id: 'jqsfn3uezkynchk5xadrn89q6',
    installmentNumber: 3,
    startDate: '2023-04-05',
    dueDate: '2023-05-04',
    daysUntilDueDate: 9,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: true,
    isOverdue: false,
    status: 'Open',
    paidAmount: 0,
    initialAmount: 330.45,
    futureBalance: 330.45,
    presentBalance: 326.17,
    interestEconomyAmount: 0,
    penaltyFeeAmount: 0,
    penaltyInterestAmount: 0,
    latePaymentInterestAmount: 0,
    totalDebtAmount: 330.45
  }
]

export const installmentsSettledAndFuture: LoanInstallment[] = [
  {
    id: 'jqtuknzmye4fd8z4q7e8n4te3',
    installmentNumber: 1,
    startDate: '2023-04-12',
    dueDate: '2023-05-19',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 32,
    settlementDate: '2023-05-25',
    isCollectionEnabled: false,
    isOverdue: true,
    status: 'Settled',
    paidAmount: -181.22,
    initialAmount: 180.51,
    futureBalance: 0.0,
    presentBalance: 0.0,
    interestEconomyAmount: 0.0,
    penaltyFeeAmount: 0.0,
    penaltyInterestAmount: 0.0,
    latePaymentInterestAmount: 0.71,
    totalDebtAmount: 181.22
  },
  {
    id: 'jqtuknzn6e1c76g4ztvhdvsdf',
    installmentNumber: 2,
    startDate: '2023-05-20',
    dueDate: '2023-06-19',
    daysUntilDueDate: 0,
    isDueSoon: true,
    daysOverdue: 1,
    settlementDate: '2023-05-25',
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Settled',
    paidAmount: -177.95,
    initialAmount: 180.51,
    futureBalance: 0.0,
    presentBalance: 0.0,
    interestEconomyAmount: -2.56,
    penaltyFeeAmount: 0.0,
    penaltyInterestAmount: 0.0,
    latePaymentInterestAmount: 0.0,
    totalDebtAmount: 180.51
  },
  {
    id: 'jqtuknzndmjpa68gyj9jdawum',
    installmentNumber: 3,
    startDate: '2023-06-20',
    dueDate: '2023-07-19',
    daysUntilDueDate: 28,
    isDueSoon: false,
    daysOverdue: 0,
    settlementDate: '2023-05-25',
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Settled',
    paidAmount: -174.67,
    initialAmount: 180.51,
    futureBalance: 0.0,
    presentBalance: 0.0,
    interestEconomyAmount: -5.83,
    penaltyFeeAmount: 0.0,
    penaltyInterestAmount: 0.0,
    latePaymentInterestAmount: 0.0,
    totalDebtAmount: 180.51
  },
  {
    id: 'jqtuknznsd1nd8euc8g7x4vyd',
    installmentNumber: 4,
    startDate: '2023-07-20',
    dueDate: '2023-08-21',
    daysUntilDueDate: 61,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: -121.21,
    initialAmount: 180.51,
    futureBalance: 52.76,
    presentBalance: 50.84,
    interestEconomyAmount: -6.54,
    penaltyFeeAmount: 0.0,
    penaltyInterestAmount: 0.0,
    latePaymentInterestAmount: 0.0,
    totalDebtAmount: 180.51
  },
  {
    id: 'jqtuknzp12gh1wh6y28tmq7um',
    installmentNumber: 5,
    startDate: '20230820',
    dueDate: '20230919',
    daysUntilDueDate: 90,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0.0,
    initialAmount: 180.51,
    futureBalance: 180.51,
    presentBalance: 170.73,
    interestEconomyAmount: 0.0,
    penaltyFeeAmount: 0.0,
    penaltyInterestAmount: 0.0,
    latePaymentInterestAmount: 0.0,
    totalDebtAmount: 180.51
  },
  {
    id: 'jqtuknzp7vuv54ncdf1jjv3b7',
    installmentNumber: 6,
    startDate: '2023-09-20',
    dueDate: '2023-10-19',
    daysUntilDueDate: 120,
    isDueSoon: false,
    daysOverdue: 0,
    isCollectionEnabled: false,
    isOverdue: false,
    status: 'Future',
    paidAmount: 0.0,
    initialAmount: 180.51,
    futureBalance: 180.51,
    presentBalance: 167.48,
    interestEconomyAmount: 0.0,
    penaltyFeeAmount: 0.0,
    penaltyInterestAmount: 0.0,
    latePaymentInterestAmount: 0.0,
    totalDebtAmount: 180.51
  }
]

export const currentInstallmentSettled: LoanInstallment = {
  id: 'jqtuknzndmjpa68gyj9jdawum',
  installmentNumber: 3,
  startDate: '2023-06-20',
  dueDate: '2023-07-19',
  daysUntilDueDate: 28,
  isDueSoon: false,
  daysOverdue: 0,
  settlementDate: '2023-05-25',
  isCollectionEnabled: false,
  isOverdue: false,
  status: 'Settled',
  paidAmount: -174.67,
  initialAmount: 180.51,
  futureBalance: 0.0,
  presentBalance: 0.0,
  interestEconomyAmount: -5.83,
  penaltyFeeAmount: 0.0,
  penaltyInterestAmount: 0.0,
  latePaymentInterestAmount: 0.0,
  totalDebtAmount: 180.51
}
