import { useEffectOnce, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { type IconId, Button, Card, CardBody, Heading } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

import { type HistoryItem } from '../entities'
import analitica from '../helpers/analitica'

import { LoanHistoryTable } from './loan-home-history-table'

export type LoanHistoryCardProps = {
  historyList?: HistoryItem[]
  isLoading: boolean
  flexDirection?: 'row' | 'column'
}

export function LoanHomeHistoryCard({ historyList, isLoading, flexDirection = 'row' }: LoanHistoryCardProps) {
  const cardStyle =
    flexDirection === 'column'
      ? {
          height: 'fit-content'
        }
      : {}
  const [displayCard, toggleDisplayCard] = useToggle(true)
  const toggleButtonLabel = !displayCard ? 'Mostrar' : 'Ocultar'
  const toggleButtonIcon: IconId = !displayCard ? 'caret-down-solid' : 'caret-up-solid'

  useEffectOnce(() => {
    analitica.events.home.loansHistoryListView(historyList?.length || 0)
  })

  const loanClickEvent = (rowInfo: HistoryItem) => {
    analitica.events.home.loansHistoryListClick({
      'loan id': rowInfo?.id,
      'loan amount': rowInfo?.amount,
      'loan type': rowInfo?.type.toLowerCase()
    })
  }

  return (
    <Card color="neutralOverlay" spacing="xSmall" style={cardStyle}>
      <CardBody>
        <Stack space="1rem">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="xSmall" weight="bold">
              Histórico
            </Heading>
            <Button
              variant="contentOnly"
              color="neutral"
              iconPosition="end"
              icon={toggleButtonIcon}
              size="small"
              onClick={() => toggleDisplayCard()}
              aria-label={`${toggleButtonLabel} histórico`}
            >
              {toggleButtonLabel}
            </Button>
          </Flex>
          <If condition={displayCard}>
            <LoanHistoryTable
              historyList={historyList}
              isLoading={isLoading}
              fullTable={flexDirection === 'row'}
              extraRowClickAction={loanClickEvent}
            />
          </If>
        </Stack>
      </CardBody>
    </Card>
  )
}
