import { useState } from 'react'

import { useToast } from '~/domains/platform/layout/toast'
import { useMutationRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type UseCreditoffersReturn } from '../../shared'
import { useLoanConcessionContext } from '../context/loan-concession-context'
import { type NegotiationsProposal, type UniversalProposalData, LoanConcessionSteps } from '../entities'
import analitica from '../helpers/analitica'
import { formatSimulationData, hasProposalData } from '../helpers/loan-concession-simulation-data'
import { useLoanConcessionSimulation } from '../hooks/use-proposal-simulation'
import { createProposal, loanConcessionSimulate } from '../services/proposal'

import { loanConcessionNavigate } from './loan-concession-flow-guard'
import { LoanConcessionSimulationContent } from './loan-concession-simulation-content'

type LoanConcessionSimulationProps = {
  creditOffer: UseCreditoffersReturn
  proposalAvailable?: NegotiationsProposal
  proposalProcessing?: NegotiationsProposal
}

export const LoanConcessionSimulation = ({
  creditOffer,
  proposalAvailable,
  proposalProcessing
}: LoanConcessionSimulationProps) => {
  const { addToast } = useToast()
  const [currentProposalId, setCurrentProposalId] = useState<string | undefined>(proposalAvailable?.id)
  const [proposalSimulationData, setLoanConcessionSimulationData] = useState<UniversalProposalData>()
  const { data: proposal, isLoading: isloadingSimulate } = useLoanConcessionSimulation({
    creditOffer,
    amount: creditOffer?.maxCreditValue || 0,
    proposalAvailable,
    proposalProcessing
  })
  const { saveLoanConcessionSimulationData } = useLoanConcessionContext()

  const proposalData = proposalSimulationData ? { ...proposalSimulationData } : proposal
  const hasToCreateProposal = currentProposalId !== proposalAvailable?.id || currentProposalId === undefined

  const { mutate, isLoading: isLoadingInputSimulate } = useMutationRequest(
    ['useLoanConcesionSimulate'],
    loanConcessionSimulate,
    {
      onSuccess: ({ data }) => {
        setCurrentProposalId(undefined)
        setLoanConcessionSimulationData(formatSimulationData({ creditOffer, simulationProposal: data }))
      },
      onError: () => {
        addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
      }
    }
  )

  const isLoading = !hasProposalData(proposalData) || isLoadingInputSimulate || isloadingSimulate
  const onSimulate = async (amount: number) => {
    mutate({
      creditOffer: creditOffer?.offer,
      requestedAmount: amount,
      proposalId: proposalData?.proposalId
    })
    setCurrentProposalId(proposalData?.proposalId)
  }

  const onContinue = async () => {
    if (hasToCreateProposal && creditOffer.offer) {
      const createProposalProps = {
        offerId: creditOffer?.offer?.id || '',
        requestedAmount: proposalData?.creditAmount || 0,
        numberOfInstallments: proposalData?.numberOfInstallments || 0
      }
      const { data: savedProposalInfo } = await createProposal(createProposalProps)
      setCurrentProposalId(savedProposalInfo?.id)
      saveLoanConcessionSimulationData(formatSimulationData({ creditOffer, proposal: savedProposalInfo }))
    } else {
      saveLoanConcessionSimulationData(formatSimulationData({ creditOffer, proposal: proposalAvailable }))
    }
    analitica.events.simulation.click('ir para atualização de dados')
    loanConcessionNavigate({ step: LoanConcessionSteps.QR_CODE })
  }

  return (
    <LoanConcessionSimulationContent
      proposalData={proposalData}
      isLoading={isLoading}
      onSimulate={onSimulate}
      onContinue={onContinue}
    />
  )
}
