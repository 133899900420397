/* eslint-disable @nx/enforce-module-boundaries */
import { type ChangeEvent, Fragment, useEffect, useState, useSyncExternalStore } from 'react'
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import Script from 'next/script'
import { Button, InputField } from '@stone-payments/jade'
import { APP_ENV, APP_VERSION, DEPLOY_TYPE } from 'shared/envs'

import Blackbird from '~/domains/platform/lib/blackbird'
import { isomorphicCookies } from '~/lib/helpers'

import { type SwhConfigStore, MicroFrontEndConfigStoreDefault } from './swh-config-context'

const isSsr = typeof window === 'undefined'

const IS_PRD = APP_ENV() === 'prod'

const microFrontEndLocalStorage = 'microFrontEndVersion'

type Fn<T> = (a: T) => unknown

const IS_PR_PREVIEW = DEPLOY_TYPE() === 'preview'

const VERSION = (IS_PR_PREVIEW ? APP_VERSION() : '').trim().normalize('NFKD')

const Url = {
  create: (path: string) => new URL(path, 'https://stone.com.br'),
  normalizeSlashes: (s: string) => s.replace(/\/+$/, '').replace(/^\/+/, '/'),
  trailingPath: (str: string) => str.replace(/\/+$/g, '/'),
  join: (baseURL: string, ...urls: string[]) =>
    Url.trailingPath(urls.reduce((acc, el) => acc.replace(/\/+$/, '') + '/' + el.replace(/^\/+/, ''), baseURL)),
  sanitizeUrl: (s: string) =>
    IS_PR_PREVIEW ? Url.normalizeSlashes(s.normalize('NFKD').replace(`/${VERSION}/`, '/')) : Url.normalizeSlashes(s)
}

export class Store<T extends object> {
  private subscribers = new Set<Fn<T>>()

  constructor(private state: T) {}

  static create = (store: Store<SwhConfigStore>, name: 'STONE_SWH' | 'STONE_MICRO_FRONTEND') => {
    if (!isSsr) {
      window[name] = store
      window.STONE_SWH = store
    }
  }

  public get = () => {
    return this.state
  }

  public set = (callback: (t: T) => T) => {
    this.state = callback(this.state)
    this.subscribers.forEach(subscriber => subscriber(this.state))
  }

  public subscribe = (instance: Fn<T>) => {
    this.subscribers.add(instance)
    return () => this.subscribers.delete(instance)
  }
}

export const MICROFRONTEND_CDN =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:9999'
    : IS_PRD
    ? 'https://swh-cdn-prd.stone.com.br'
    : 'https://swh-cdn-stg.stone.com.br'

declare global {
  interface Window {
    STONE_SWH: Store<SwhConfigStore>
    STONE_MICRO_FRONTEND: Store<SwhConfigStore>
    FRONTEND_CONCIERGE: Record<string, { rerender(): void }>
  }
}

export const broadcastRerender = (name: string) => {
  if (typeof window === 'undefined') return
  setTimeout(() => {
    const concierge = window.FRONTEND_CONCIERGE?.[name]
    if (concierge && typeof concierge.rerender === 'function') concierge.rerender()
  }, 200)
}

export const useMicroFrontEndGlobalStore = () => {
  const state = useSyncExternalStore(store.subscribe, store.get, store.get)
  return [state, store] as const
}

const store = new Store(MicroFrontEndConfigStoreDefault)
Store.create(store, 'STONE_SWH')
Store.create(store, 'STONE_MICRO_FRONTEND')

const VersionSelection = (props: { version: string | undefined; setVersion: (version: string) => void }) => {
  const router = useRouter()
  const [showVersionConfig, setShowVersionConfig] = useState(false)
  const [versionInput, setVersionInput] = useState<string | undefined>(props.version)
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setVersionInput(e.target.value)
  }

  const onSubmit = async () => {
    localStorage.setItem(microFrontEndLocalStorage, versionInput || '')
    await Blackbird.replace({ pathname: Blackbird.getPathname(), query: { ...router.query, __swh: versionInput } })
  }

  const onReset = async () => {
    localStorage.removeItem(microFrontEndLocalStorage)
    const { __swh, ...newquery } = router.query
    console.info('Remove version from url:', __swh)
    await Blackbird.replace({ pathname: Blackbird.getPathname(), query: newquery })
  }

  if (APP_ENV() === 'prod') {
    return null
  }
  return (
    <div className="absolute left-1/2 center top-2 z-max bg-white border-r-8" style={{ zIndex: '1001' }}>
      {!showVersionConfig ? (
        <div>
          <Button variant="danger-ghost" onClick={() => setShowVersionConfig(!showVersionConfig)}>
            Version: {props.version ? props.version : 'latest'}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col py-4 px-2 border-r-2 gap-jade-100">
          <span className="text-jade-150">Version: </span>
          <InputField
            placeholder="0.0.0"
            label=""
            className="text-jade"
            onChange={onChangeInput}
            value={versionInput}
          />
          <div className="flex justify-between">
            <Button variant="primary-ghost" onClick={onSubmit}>
              Apply
            </Button>
            <Button variant="primary-ghost" onClick={onReset}>
              Reset
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export const useUrlChanges = () => {
  const router = useRouter()
  const [lastUrl, setLastUrl] = useState(Url.sanitizeUrl(Url.create(router.asPath).pathname))

  useEffect(() => {
    const checkUrlChange = () => {
      const currentUrl = Url.sanitizeUrl(Url.create(window.location.pathname).pathname)
      const lastUrlUpdated = Url.sanitizeUrl(lastUrl)
      if (currentUrl !== lastUrlUpdated) {
        setLastUrl(currentUrl)
      }
    }
    checkUrlChange()
    const interval = setInterval(checkUrlChange, 500)
    return () => clearInterval(interval)
  }, [])

  return lastUrl
}

type MicrofrontendProps = {
  folder: string
  projectName: string
  urlBasename: string
}

export const Microfrontend = (props: MicrofrontendProps) => {
  const updatedUrl = useUrlChanges()
  const router = useRouter()
  const [origin, setOrigin] = useState(isSsr ? '' : window.location.origin)
  const [basename] = useState(() =>
    IS_PR_PREVIEW ? Url.join('/', VERSION, props.urlBasename) : `/${props.urlBasename}`
  )
  const [version, setVersion] = useState<string | undefined>(
    (router.query?.['__swh'] as string) || (localStorage.getItem(microFrontEndLocalStorage) as string)
  )
  const [localFile] = useState((): string =>
    version ? `/${props.folder}/${version}/entrypoint.js` : `/${props.folder}/root.js`
  )

  useEffect(() => {
    const cookies = isomorphicCookies.getAll()
    if (cookies.token) window.document.cookie = `token=${cookies.token};domain=${MICROFRONTEND_CDN}`
  }, [router.pathname])

  useEffect(() => {
    if (IS_PRD) return
    const queryStringValue = router.query['__swh']
    if (queryStringValue) {
      localStorage.setItem(microFrontEndLocalStorage, queryStringValue as string)
      return
    }
    const localStorageValue = localStorage.getItem(microFrontEndLocalStorage)
    if (!queryStringValue && localStorageValue) {
      Blackbird.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, __swh: localStorageValue }
        },
        undefined,
        { shallow: true }
      )
    }
  }, [])

  useEffect(() => {
    const pathFromNext = Url.sanitizeUrl(Url.create(router.asPath).pathname)
    const browserPath = Url.sanitizeUrl(updatedUrl)
    if (browserPath === pathFromNext) {
      if (history.state?.as === browserPath) return void broadcastRerender(props.folder)
      return
    }
    setOrigin(window.location.origin)
    const pushStateUrl = IS_PR_PREVIEW ? Url.join(VERSION, browserPath) : browserPath
    const redirect = new URL(pushStateUrl, window.location.origin)
    redirect.search = window.location.search
    void broadcastRerender(props.folder)
    history.replaceState({}, '', redirect.href)
  }, [updatedUrl, router.pathname, props.folder])

  useEffect(() => {
    Router.events.on('routeChangeComplete', e => {
      if (typeof e === 'string') {
        const pathFromNext = Url.sanitizeUrl(Url.create(e).pathname)
        const browserPath = Url.sanitizeUrl(updatedUrl)
        if (pathFromNext === browserPath) return broadcastRerender(props.projectName)
      }
    })
  }, [])

  return (
    <Fragment>
      <Head>
        <link rel="stylesheet" href={Url.join(MICROFRONTEND_CDN, props.folder, 'style.css')} />
      </Head>
      {IS_PRD ? null : <VersionSelection version={version} setVersion={setVersion} />}
      <div
        className="w-full"
        data-origin={origin}
        data-basename={basename}
        data-href={router.asPath}
        id={`${props.folder}-container`}
      />
      <Script type="module" src={Url.join(MICROFRONTEND_CDN, localFile)} async crossOrigin="anonymous" />
    </Fragment>
  )
}
