import { multiplyByOneHundred, rateUtils } from '@credit-web/common/number-utils'
import { analiticaInstance } from 'shared/analitica'

import { stringFormat } from '~/lib/helpers'
type OnboardingViewProps = {
  offerId: string
  amount: number
}

type OnboardingClickProps = OnboardingViewProps & {
  action: string
}

type ProposalViewProps = {
  amount: number
  proposalId: string
}

type ProposalClickProps = ProposalViewProps & {
  action: string
}

export default analiticaInstance.eventFactory('Revolving Credit Concession', {
  onboarding: {
    /**
     * @analitica The user saw the Onboarding screen view
     */
    view: ({ offerId, amount }: OnboardingViewProps) => ({
      label: 'credit - onboarding revolving credit view',
      metadata: {
        'offer id': offerId,
        'offer amount': rateUtils.centsToDecimal(amount)
      }
    }),
    /**
     * @analitica Click on action elements of the onboarding screen
     */

    click: (props: OnboardingClickProps) => {
      const { offerId, amount, action } = props
      return {
        label: 'credit - onboarding revolving credit clicked',
        metadata: {
          'offer id': offerId,
          'offer amount': rateUtils.centsToDecimal(amount),
          action
        }
      }
    }
  },
  proposal: {
    /**
     * @analitica The user saw proposal (offer) screen view
     */
    view: ({ proposalId, amount }: ProposalViewProps) => ({
      label: 'credit - active revolving credit view',
      metadata: {
        'id da proposta': proposalId,
        amount: stringFormat.currency(multiplyByOneHundred(amount ?? 0), true),
        type: 'RevolvingCredit'
      }
    }),
    /**
     * @analitica Click on action elements of the proposal screen
     */
    click: (props: ProposalClickProps) => {
      const { proposalId, amount, action } = props
      return {
        label: 'credit - active revolving credit clicked',
        metadata: {
          'id da proposta': proposalId,
          amount: stringFormat.currency(multiplyByOneHundred(amount ?? 0), true),
          action,
          type: 'RevolvingCredit'
        }
      }
    },
    /**
     * @analitica Click on action elements of the proposal doubts screen
     */
    doubts: (action: string) => {
      return {
        label: 'credit - doubts revolving credit clicked',
        metadata: {
          action
        }
      }
    },
    /**
     * @analitica The user saw proposal (doubts) drawer screen view
     */
    doubtsView: ({ proposalId, amount }: ProposalViewProps) => ({
      label: 'credit - doubts revolving credit view',
      metadata: {
        'id da proposta': proposalId,
        amount: stringFormat.currency(multiplyByOneHundred(amount ?? 0), true),
        type: 'RevolvingCredit'
      }
    }),
    /**
     * @analitica The user saw proposal (fees) drawer screen view
     */
    feesView: ({ proposalId, amount }: ProposalViewProps) => ({
      label: 'credit - fees revolving credit view',
      metadata: {
        'id da proposta': proposalId,
        amount: stringFormat.currency(multiplyByOneHundred(amount ?? 0), true),
        type: 'RevolvingCredit'
      }
    }),
    /**
     * @analitica Click on action elements of the proposal fees drawer screen
     */
    fees: (action: string) => {
      return {
        label: 'credit - fees revolving credit clicked',
        metadata: {
          action
        }
      }
    }
  },
  review: {
    /**
     * @analitica The user saw review screen view
     */
    view: ({ proposalId, amount }: ProposalViewProps) => ({
      label: 'credit - resume revolving credit view',
      metadata: {
        'id da proposta': proposalId,
        amount: rateUtils.centsToDecimal(amount),
        type: 'RevolvingCredit'
      }
    }),
    /**
     * @analitica Click on action elements of the review screen
     */
    click: (props: ProposalClickProps) => {
      const { proposalId, amount, action } = props
      return {
        label: 'credit - resume revolving credit clicked',
        metadata: {
          'id da proposta': proposalId,
          amount: rateUtils.centsToDecimal(amount),
          action,
          type: 'RevolvingCredit'
        }
      }
    }
  },
  success: {
    /**
     * @analitica The user saw success screen view
     */
    view: ({ proposalId, amount }: ProposalViewProps) => ({
      label: 'credit - positive feedback revolving credit view',
      metadata: {
        'id da proposta': proposalId,
        amount: rateUtils.centsToDecimal(amount),
        type: 'RevolvingCredit'
      }
    }),
    /**
     * @analitica Click on action elements of the success screen
     */
    click: (action: string) => {
      return {
        label: 'credit - positive feedback revolving credit clicked',
        metadata: { action }
      }
    }
  },
  error: {
    /**
     * @analitica The user saw error screen view
     */
    view: ({ proposalId, amount }: ProposalViewProps) => ({
      label: 'credit - negative feedback revolving credit view',
      metadata: {
        'id da proposta': proposalId,
        amount: rateUtils.centsToDecimal(amount),
        type: 'RevolvingCredit'
      }
    }),
    /**
     * @analitica Click on action elements of the error screen
     */
    click: (action: string) => {
      return {
        label: 'credit - negative feedback revolving credit clicked',
        metadata: { action }
      }
    }
  }
})
