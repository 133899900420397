import { rateUtils } from '@credit-web/common/number-utils'
import { Stack } from '@dlpco/fluid-layout'
import { DateTime, Description, Heading, Money, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Flex } from '~/domains/platform/design-system'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { applyDecimal } from '~/lib/helpers/credit/loans'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { theme } from '~/ui/theme'

import { type UniversalProposalData } from '../entities'

interface LoanConcessionSimulationResultProps {
  proposalData: UniversalProposalData
  isLoading?: boolean
  inputChange?: number
}

const StyledFlex = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid ${theme.colors.lightGray6};

  @media (max-width: ${theme.windowSizes.small}px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    & > p {
      width: 100%;
    }
  }
`

interface ProposalValueProps {
  showText: boolean
  text: any
}

function ProposalValue({ showText, text }: ProposalValueProps) {
  return (
    <Choose>
      <Choose.When condition={showText}>
        <Text weight="bold">{text}</Text>
      </Choose.When>
      <Choose.Otherwise>
        <Text>-</Text>
      </Choose.Otherwise>
    </Choose>
  )
}

export function LoanConcessionSimulationResult({
  proposalData,
  isLoading,
  inputChange
}: LoanConcessionSimulationResultProps) {
  const { currency } = stringFormat

  const showText = Boolean(
    (inputChange === undefined && isLoading === false) ||
      (inputChange !== undefined && inputChange === proposalData.creditAmount)
  )

  return (
    <Stack space="0">
      <Heading size="large" weight="bold" style={{ marginBottom: '1rem' }}>
        Detalhes de pagamento
      </Heading>

      <StyledFlex>
        <Text size="small" color="neutral" weight="bold">
          Total a pagar
        </Text>

        <ProposalValue showText={showText} text={<Money amount={proposalData.totalEstimatedAmountToBePaid} />} />
      </StyledFlex>

      <StyledFlex>
        <Text size="small" color="neutral" weight="bold">
          Você vai pagar com
        </Text>
        <ProposalValue
          showText={showText}
          text={`${rateUtils.formatToLimitedDecimalPlaces(proposalData.retentionRate)}% de retenção automática`}
        />
      </StyledFlex>

      {proposalData.numberOfInstallments && proposalData.installmentValue && (
        <StyledFlex>
          <Text size="small" color="neutral" weight="bold">
            Número de parcelas
          </Text>

          <ProposalValue
            showText={showText}
            text={`${proposalData.numberOfInstallments}x de ${currency(applyDecimal(proposalData.installmentValue))}`}
          />
        </StyledFlex>
      )}

      <StyledFlex>
        <Stack space="0.125rem">
          <Text size="small" color="neutral" weight="bold">
            Juros ao mês
          </Text>
          <Description color="neutral">Calculados todos os dias sobre o quanto ainda falta pagar</Description>
        </Stack>
        <ProposalValue
          showText={showText}
          text={`${rateUtils.formatToLimitedDecimalPlaces(proposalData.interestRate)}%`}
        />
      </StyledFlex>

      <StyledFlex>
        <Stack space="0.125rem">
          <Text size="small" color="neutral" weight="bold">
            Juros ao ano
          </Text>
        </Stack>
        <ProposalValue
          showText={showText}
          text={`${rateUtils.formatToLimitedDecimalPlaces(proposalData.annualInterestRate)}%`}
        />
      </StyledFlex>

      <StyledFlex>
        <Text size="small" color="neutral" weight="bold">
          IOF financiado
        </Text>

        <ProposalValue showText={showText} text={<Money amount={proposalData.taxAmount} />} />
      </StyledFlex>

      <StyledFlex>
        <Stack space="0.125rem">
          <Text size="small" color="neutral" weight="bold">
            Custo Efetivo Total (CET)
          </Text>
          <Description color="neutral">Soma total dos juros e IOF</Description>
        </Stack>

        <ProposalValue
          showText={showText}
          text={`${rateUtils.formatToLimitedDecimalPlaces(proposalData.annualTotalEffectiveCostRate)}% ao ano`}
        />
      </StyledFlex>

      <StyledFlex>
        <Text size="small" color="neutral" weight="bold">
          Contrato vence em
        </Text>

        <ProposalValue
          showText={showText}
          text={<DateTime preset="day" format="DD [de] MMMM, YYYY" date={proposalData.contractDueDate} />}
        />
      </StyledFlex>
    </Stack>
  )
}
