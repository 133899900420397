import { useEffectOnce } from 'react-use'
import { divideByOneHundred } from '@credit-web/common/number-utils'
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Money,
  Overline,
  Text,
  theme as themeGinga,
  Tooltip
} from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import Blackbird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { theme } from '~/ui/theme'

import { type RevolvingCreditSection } from '../entities'
import analitica from '../helpers/analitica'

import { LoanHomeBadgeProductType } from './loan-home-badge-product-type'
import { LoanHomeRevolvingCreditProgressBar } from './loan-home-progress-bar'
export const BorderHeader = styled(CardHeader)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGray4};
  padding: ${({ theme }) => theme.space.medium} ${({ theme }) => theme.space.xLarge} !important;
`

export function LoanHomeRevolvingCredit({ loanInfo }: { loanInfo: RevolvingCreditSection }) {
  const { amountLimit, contractedAmount, withdrawalAmount, id } = loanInfo
  const { currency } = stringFormat
  const hasFees = false // TODO verificar campos de taxas: iof e total a pagar

  useEffectOnce(() => {
    analitica.events.home.loanHomeRevolvingCreditCardView({ amountLimit, contractedAmount, id })
  })

  const onClickInCard = () => {
    analitica.events.home.loanHomeRevolvingCreditCardClick({ amountLimit, contractedAmount, id })
    Blackbird.travelTo({
      pathname: '/credito/giro-facil/${product}',
      urlParams: {
        product: loanInfo?.id
      }
    })
  }
  return (
    <Card
      key={loanInfo?.id}
      onClick={onClickInCard}
      style={{ cursor: 'pointer' }}
      data-cy="credit-home-revolving-credit-follow-up-card"
      aria-label={`Acompanhar Giro fácil, Valor contratado: ${currency(
        contractedAmount,
        false
      )}, Valor disponível: ${currency(amountLimit, false)}`}
      tabIndex={0}
      role="button"
    >
      <BorderHeader
        aria-hidden
        verticalAlignment="center"
        slotEnd={<Icon use="chevron-right-outline" size="large" />}
        style={{ cursor: 'pointer' }}
      >
        <LoanHomeBadgeProductType aria-hidden type="revolving_credit" />

        <Heading size="medium" weight="semi" style={{ marginTop: theme.space.default }}>
          <Money amount={divideByOneHundred(contractedAmount)} />
        </Heading>
      </BorderHeader>
      <CardBody>
        <Flex justifyContent="space-between" alignItems="center" mb={theme.space.medium}>
          <Overline aria-hidden color="neutral">
            Consumo de limite
          </Overline>
        </Flex>

        <Flex aria-hidden justifyContent="space-between" alignItems="center">
          <Text color="neutralHigh">Retirado</Text>
          <Text style={{ color: themeGinga.light.color.infoHigh }}>Disponível</Text>
        </Flex>

        <LoanHomeRevolvingCreditProgressBar
          amountLimit={amountLimit}
          contractedAmount={contractedAmount}
          withdrawalAmount={withdrawalAmount}
        />

        <Flex justifyContent="space-between" alignItems="center">
          <Heading aria-hidden color="neutralHigh">
            <Money amount={divideByOneHundred(withdrawalAmount)} />
          </Heading>
          <Heading aria-hidden style={{ color: themeGinga.light.color.infoHigh }}>
            <Money amount={divideByOneHundred(amountLimit)} />
          </Heading>
        </Flex>

        <If condition={hasFees}>
          <Flex justifyContent="space-between" alignItems="center" textAlign="center" mt={theme.space.medium}>
            <Box style={{ display: 'flex' }}>
              <Text color="neutral">Juros e IOF acumulados</Text>
              <Tooltip content="O desconto inclui o valor de juros economizados, se houver.">
                <Icon use="round-info-outline" color="neutral" style={{ marginLeft: '.5em' }} />
              </Tooltip>
            </Box>
            <Text size="xSmall" weight="semi" color="neutralHigh">
              <Money amount={divideByOneHundred(contractedAmount)} />
            </Text>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center" textAlign="center" mt={theme.space.default}>
            <Box style={{ display: 'flex' }}>
              <Text color="neutral">Total a pagar</Text>
              <Tooltip content="O desconto inclui o valor de juros economizados, se houver.">
                <Icon use="round-info-outline" color="neutral" style={{ marginLeft: '.5em' }} />
              </Tooltip>
            </Box>
            <Text size="xSmall" weight="semi" style={{ color: themeGinga.light.color.negativeHigh }}>
              <Money amount={divideByOneHundred(contractedAmount)} />
            </Text>
          </Flex>
        </If>
      </CardBody>
    </Card>
  )
}
