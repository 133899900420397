import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Description, Money, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { SideModal } from '~/ui/components/modals/side-modal/side-modal'
import { theme } from '~/ui/theme'

import {
  type InstallmentStatementEntry,
  type InstallmentStatementEntryType,
  type RepaymentMethodType
} from '../entities'

interface SidebarProps {
  isOpen: boolean
  toggleSidebar?: (toggle: boolean) => void
  entries: InstallmentStatementEntry[]
  date: string
  entriesSum: number
  hideInterestEconomies?: boolean
}

const stringToLowerCase = (text = '' as string) => text.toLowerCase()

export const LoanInstallmentDetailsSidebar = (props: SidebarProps) => {
  const { isOpen, toggleSidebar, entries = [], date, entriesSum, hideInterestEconomies } = props
  const validEntries = entries.filter(({ amount }) => amount !== 0)
  const MoneyFormatted = ({ amount }: { amount: number }) => {
    const signal = amount <= 0 ? '-' : '+'

    return (
      <>
        <Money amount={Math.abs(amount)} /> ({signal})
      </>
    )
  }

  const entryTypeTranslationMap: Record<
    InstallmentStatementEntryType,
    {
      title: string | Record<RepaymentMethodType, string>
      subtitle: string
    }
  > = {
    Repayment: {
      title: {
        Stonebankingretention: 'Retenção automática',
        Boleto: 'Pagamento pontual por boleto',
        Pix: 'Pagamento pontual por pix',
        Manual: 'Pagamento pontual'
      },
      subtitle: 'Juros economizados'
    },
    RepaymentReversal: {
      title: {
        Stonebankingretention: 'Cancelamento de retenção automática',
        Boleto: 'Cancelamento de pagamento pontual',
        Pix: 'Cancelamento de pagamento pontual',
        Manual: 'Cancelamento de pagamento pontual'
      },
      subtitle: 'Lançamento desfeito'
    },
    Refund: {
      title: 'Reembolso',
      subtitle: 'Devolução de valor extra'
    },
    RefundReversal: {
      title: 'Cancelamento de reembolso',
      subtitle: 'Lançamento desfeito'
    },
    Discount: {
      title: 'Desconto',
      subtitle: 'Acordado após renegociação'
    },
    DiscountReversal: {
      title: 'Cancelamento de desconto',
      subtitle: 'Lançamento desfeito'
    },
    LatePaymentInterest: {
      title: 'Cobranças por atraso',
      subtitle: 'Juros remuneratórios'
    },
    PenaltyFee: {
      title: 'Cobranças por atraso',
      subtitle: 'Multa'
    },
    PenaltyInterest: {
      title: 'Cobranças por atraso',
      subtitle: 'Juros de mora'
    },
    LatePaymentInterestReversal: {
      title: 'Cancelamento de juros economizados',
      subtitle: 'Lançamento desfeito'
    },
    PenaltyInterestReversal: {
      title: 'Cancelamento de cobrança por atraso',
      subtitle: 'Lançamento desfeito'
    },
    LatePaymentInterestAdjustment: {
      title: 'Reembolso',
      subtitle: 'Devolução de juros'
    },
    PenaltyInterestAdjustment: {
      title: 'Reembolso',
      subtitle: 'Devolução de juros de mora'
    }
  }

  const translateByType = (
    type: InstallmentStatementEntryType,
    isTitle: boolean,
    repaymentMethod: RepaymentMethodType | undefined = undefined
  ): string => {
    const currentType = entryTypeTranslationMap[type]
    if (!currentType) {
      // this guard prevent a bug with <Choose.When>, that compute its contents anyway, even when condition is false somehow, but show the correct render afterward.
      return `${type}` // The return do nothing on the view, it will fallback to <Choose.Otherwise> later...
    }

    if (isTitle === false) return currentType.subtitle as string

    const translationMap: any = currentType.title
    return repaymentMethod ? translationMap[repaymentMethod] : (translationMap as string)
  }

  const isPenaltyType = (type: InstallmentStatementEntryType): boolean => {
    return ['latepaymentinterest', 'penaltyfee', 'penaltyinterest', 'penaltyinterestadjustment'].includes(
      stringToLowerCase(type)
    )
  }

  const isExistingType = (type: InstallmentStatementEntryType) => {
    return [
      'latepaymentinterest',
      'penaltyfee',
      'penaltyinterest',
      'repayment',
      'discount',
      'refund',
      'repaymentreversal',
      'refundreversal',
      'discountreversal',
      'latepaymentinterestreversal',
      'latepaymentinterestadjustment',
      'penaltyinterestreversal',
      'penaltyinterestadjustment'
    ].includes(stringToLowerCase(type))
  }

  return (
    <Dimmer isVisible={isOpen} style={{ position: 'fixed' }}>
      <SideModal
        title={`Detalhes de ${formatDate(date, 'extensiveBrazilianPattern')}`}
        isOpen={isOpen}
        toggle={toggleSidebar}
        ignoreClickAway={true}
      >
        <For
          of={validEntries}
          render={({ type, repaymentMethod, amount, interestEconomy }, index) => {
            const entryTotalStyle = isPenaltyType(type)
              ? { color: `${theme.colors.text.error}`, fontWeight: `${theme.fontWeights.medium}` }
              : {}
            const isZeroDiscount = type === 'Discount' && amount === 0
            return (
              <If condition={!isZeroDiscount} key={index}>
                <Stack style={{ marginBottom: theme.space.medium }}>
                  <Choose>
                    <Choose.When condition={isExistingType(type)}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Flex flexDirection="column">
                          <Text size="small" color="neutral">
                            {translateByType(type, true, repaymentMethod)}
                          </Text>
                          <If condition={(type === 'Repayment' && !hideInterestEconomies) || type !== 'Repayment'}>
                            <Description color="neutral">{translateByType(type, false)}</Description>
                          </If>
                        </Flex>
                        <Flex flexDirection="column" alignItems="end">
                          <Text size="small" color="neutralHigh" style={entryTotalStyle}>
                            <MoneyFormatted amount={amount} />
                          </Text>
                          <If condition={(type === 'Repayment' && !hideInterestEconomies) || type !== 'Repayment'}>
                            <Text size="xSmall" style={{ color: `${theme.colors.blue}` }}>
                              <MoneyFormatted amount={interestEconomy ?? 0} />
                            </Text>
                          </If>
                        </Flex>
                      </Flex>
                    </Choose.When>
                    <Choose.Otherwise>
                      <Flex justifyContent="space-between">
                        <Text size="small" color="neutral">
                          Pagamento
                        </Text>
                        <Text size="small" color="neutralHigh">
                          <MoneyFormatted amount={amount} />
                        </Text>
                      </Flex>
                    </Choose.Otherwise>
                  </Choose>
                </Stack>
              </If>
            )
          }}
        />

        <Divider style={{ marginTop: theme.space.medium }} />
        <Flex justifyContent="space-between" mt={theme.space.medium}>
          <Text size="medium" color="neutralHigh">
            Total do dia
          </Text>
          <Text size="medium" color="neutralHigh" weight="semi">
            <MoneyFormatted amount={entriesSum} />
          </Text>
        </Flex>
      </SideModal>
    </Dimmer>
  )
}
