import { type CreditPaging } from '~/domains/credit/shared/entities'

import { type LoanInstallment } from '../../shared/entities'
import { type InstallmentDetails, type InstallmentDetailsStatement, type InstallmentStatementItem } from '../entities'

export const installmentMock: { items: LoanInstallment[] } = {
  items: [
    {
      id: 'jqsj8m26srg4gs56ynr192rac',
      installmentNumber: 1,
      startDate: '2022-12-23',
      dueDate: '2023-01-23',
      daysUntilDueDate: 0,
      isDueSoon: true,
      daysOverdue: 42,
      isCollectionEnabled: true,
      isOverdue: false,
      status: 'Open',
      paidAmount: 0,
      initialAmount: 1778.43,
      futureBalance: 1778.43,
      presentBalance: 1778.43,
      interestEconomyAmount: 0,
      penaltyFeeAmount: 0,
      penaltyInterestAmount: 0,
      latePaymentInterestAmount: 0,
      totalDebtAmount: 1778.43
    }
  ]
}

export const installmentStatementsMock: CreditPaging<InstallmentStatementItem[]> = {
  items: [
    {
      date: '2023-02-14',
      entriesSum: -0.13,
      entries: [
        {
          installmentNumber: 2,
          type: 'Repayment',
          amount: -0.12,
          repaymentMethod: 'Boleto',
          interestEconomy: -0.01
        },
        {
          installmentNumber: 2,
          type: 'RepaymentReversal',
          amount: 1000,
          repaymentMethod: 'Boleto'
        },
        {
          installmentNumber: 2,
          type: 'RepaymentReversal',
          amount: 1000,
          repaymentMethod: 'Stonebankingretention'
        },
        {
          installmentNumber: 2,
          type: 'Refund',
          amount: 1000
        },
        {
          installmentNumber: 2,
          type: 'RefundReversal',
          amount: -1000
        },
        {
          installmentNumber: 2,
          type: 'Discount',
          amount: -1000
        },
        {
          installmentNumber: 2,
          type: 'DiscountReversal',
          amount: -1000
        },
        {
          installmentNumber: 2,
          type: 'LatePaymentInterest',
          amount: 0.4
        },
        {
          installmentNumber: 2,
          type: 'PenaltyFee',
          amount: 0.1
        },
        {
          installmentNumber: 2,
          type: 'PenaltyInterest',
          amount: 0.1
        },
        {
          installmentNumber: 2,
          type: 'LatePaymentInterestReversal',
          amount: -100
        },
        {
          installmentNumber: 2,
          type: 'PenaltyInterestReversal',
          amount: -100
        },
        {
          installmentNumber: 2,
          type: 'LatePaymentInterestAdjustment',
          amount: -100
        },
        {
          installmentNumber: 2,
          type: 'PenaltyInterestAdjustment',
          amount: -100
        }
      ]
    },
    {
      date: '2023-02-17',
      entriesSum: -210.24,
      entries: [
        {
          installmentNumber: 2,
          type: 'Repayment',
          amount: -99,
          repaymentMethod: 'Boleto',
          interestEconomy: -6.12
        },
        {
          installmentNumber: 2,
          type: 'Repayment',
          amount: -99,
          repaymentMethod: 'Boleto',
          interestEconomy: -6.12
        }
      ]
    },
    {
      date: '2023-02-17',
      entriesSum: -0.13,
      entries: [
        {
          installmentNumber: 2,
          type: 'Repayment',
          amount: -0.12,
          repaymentMethod: 'Boleto',
          interestEconomy: -0.01
        },
        {
          installmentNumber: 2,
          type: 'Discount',
          amount: 0,
          interestEconomy: 0
        }
      ]
    }
  ],
  paging: { currentPage: 1, pageCount: 1, pageSize: 10, totalItems: 2 }
}

export const withInvalidEntry: CreditPaging<InstallmentStatementItem[]> = {
  items: [
    {
      date: '2023-05-25',
      entriesSum: -180.51,
      entries: [
        {
          installmentNumber: 2,
          type: 'Repayment',
          amount: 0.0,
          repaymentMethod: 'Pix',
          interestEconomy: 0.0
        },
        {
          installmentNumber: 2,
          type: 'Repayment',
          amount: -177.95,
          repaymentMethod: 'Pix',
          interestEconomy: -2.56
        }
      ]
    }
  ],
  paging: {
    totalItems: 1,
    currentPage: 1,
    pageSize: 10,
    pageCount: 1
  }
}

export const installmentDetailsMock: InstallmentDetails = {
  installmentNumber: 1,
  loanId: 'jr7km5kmmq2bf6st48kjya4j6',
  detailsInstallment: {
    remainingAmount: {
      description: 'R$ 1.324,87',
      value: 132487,
      type: 'money'
    },
    status: {
      description: 'Em atraso',
      value: 'overdue',
      type: 'enum'
    },
    variant: 'negative',
    actions: [
      {
        type: 'payment'
      }
    ],
    information: [
      {
        icon: 'calendar-money',
        label: 'Em atraso há *89 dias*'
      },
      {
        icon: 'money-hand',
        label: 'Multa e juros *R$ 182,71*'
      }
    ]
  },
  aboutInstallment: [
    [
      {
        title: 'Valor inicial',
        value: {
          description: 'R$ 1.142,16',
          value: 114216,
          type: 'money'
        },
        variant: 'neutral'
      }
    ],
    [
      {
        title: 'Juros por atraso (+)',
        value: {
          description: 'R$ 33,88',
          value: 3388,
          type: 'money'
        },
        variant: 'negative'
      },
      {
        title: 'Multa por atraso (+)',
        value: {
          description: 'R$ 22,84',
          value: 2284,
          type: 'money'
        },
        variant: 'negative'
      }
    ],
    [
      {
        title: 'Você pagou (-)',
        value: {
          description: 'R$ 0,05',
          value: 0,
          type: 'money'
        },
        variant: 'positive'
      },
      {
        title: 'Economia de juros (-)',
        value: {
          description: 'R$ 0,01',
          value: 0,
          type: 'money'
        },
        variant: 'positive'
      }
    ]
  ],
  notification: 'help_overdue',
  alert: 'overdue'
}

export const statementsMock: CreditPaging<InstallmentDetailsStatement[]> = {
  items: [
    {
      date: {
        description: '08 out 2024',
        value: '2024-10-08',
        type: 'date'
      },
      remainingAmount: {
        description: 'R$ 30,31',
        value: 3031,
        type: 'money'
      },
      items: [
        {
          type: {
            description: 'Pagamento1',
            value: 'Payment',
            type: 'type'
          },
          amount: {
            description: 'R$ 30,32',
            value: 3032,
            type: 'money'
          },
          description: 'Pagamento adicional1',
          name: 'Pagamento',
          icon: 'device-pos',
          variant: 'neutral',
          related: [
            {
              type: {
                description: 'Pagamento2',
                value: 'Payment',
                type: 'type'
              },
              amount: {
                description: 'R$ 30,33',
                value: 3033,
                type: 'money'
              },
              description: 'Pagamento adicional2',
              name: 'Pagamento',
              icon: 'device-pos',
              variant: 'positive'
            }
          ]
        },
        {
          type: {
            description: 'Juros de mora',
            value: 'Juros',
            type: 'type'
          },
          amount: {
            description: 'R$ 30,34',
            value: 3034,
            type: 'money'
          },
          description: 'Cobrança por atraso',
          name: 'Juros de mora',
          icon: 'calendar',
          variant: 'negative'
        }
      ]
    }
  ],
  paging: {
    totalItems: 166,
    currentPage: 1,
    pageSize: 30,
    pageCount: 6
  }
}
