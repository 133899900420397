import styled from 'styled-components'
import { type FlexboxProps, flexbox } from 'styled-system'

import { toRem } from '~/lib/helpers'

type InlineProps = FlexboxProps & { gap: number | string }

export const Inline = styled.div<InlineProps>`
  ${flexbox}
  display: flex;

  > * {
    margin-left: 0;
    margin-right: 0;
  }

  > * + * {
    margin-left: ${props => (typeof props.gap === 'number' ? toRem(props.gap) : props.gap)};
  }
`
