import { Stack } from '@dlpco/fluid-layout'
import { Card, CardBody, Heading } from '@dlpco/ginga-stone'
import { Text } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'
import { appEnv } from '~/lib/helpers'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import {
  LoanConcessionQrCodeStepSVGProd,
  LoanConcessionQrCodeStepSVGSdb
} from '../helpers/loan-concession-qrcode-step-svg'

import { ResponsiveFlexContainer } from '../helpers/loan-concession.styles'

export function LoanConcessionQrCodeStep() {
  const { isViewSmall } = useViewSize()

  return (
    <Stack space="2rem">
      <Heading size="large" weight="bold">
        Simular empréstimo
      </Heading>
      <Card spacing={isViewSmall ? 'small' : 'large'}>
        <CardBody>
          <ResponsiveFlexContainer
            padding="2rem"
            gap="2rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <Box>
              <Box margin="1.8rem">
                {appEnv.isSandbox() ? (
                  <LoanConcessionQrCodeStepSVGSdb data-testid="sandbox-qr-code" />
                ) : (
                  <LoanConcessionQrCodeStepSVGProd data-testid="production-qr-code" />
                )}
              </Box>
              <Box>
                <Box width="23.4375rem" height="3.625rem" margin="1.8rem">
                  <Heading weight="bold" size="large">
                    Falta pouco! Finalize seu pedido de empréstimo pelo aplicativo
                  </Heading>
                </Box>
                <Box width="23.4375rem" height="2.75rem" margin="1.8rem">
                  <Text color="high" variant="text-medium" weight="regular">
                    Aponte a câmera do seu celular ou tablet para o QR Code e finalize seu pedido de forma rápida e
                    segura. O aplicativo Stone deve estar instalado no seu dispositivo.
                  </Text>
                </Box>
              </Box>
            </Box>
          </ResponsiveFlexContainer>
        </CardBody>
      </Card>
    </Stack>
  )
}
