import { useRouter } from 'next/router'

import { useRenegotiationsData } from '../../hooks/use-renegotiation-data'

import { type RenegotiationSummaryStatus } from '../../../shared/entities'

import { RenegotiationBannerContainerCTA } from './renegotiation-banner-container-cta'
import { RenegotiationBannerContainerFollowUp } from './renegotiation-banner-container-follow-up'

export function RenegotiationBannerContainer({
  renegotiationStatus,
  renegotiationCreationDate,
  document
}: {
  renegotiationStatus?: RenegotiationSummaryStatus
  renegotiationCreationDate?: string
  document: string
}) {
  const { loan: loanId }: { loan?: string } = useRouter().query
  const { data } = useRenegotiationsData({ loanId: loanId ?? '', document })
  const { offer, proposal } = data ?? {}

  if (offer) return <RenegotiationBannerContainerCTA loanId={loanId || ''} offer={offer} proposal={proposal} />

  if (renegotiationStatus)
    return (
      <RenegotiationBannerContainerFollowUp
        renegotiationCreationDate={renegotiationCreationDate}
        renegStatus={renegotiationStatus}
      />
    )

  return null
}
