import { analiticaInstance } from 'shared/analitica'

const analiticaDDC = analiticaInstance.eventFactory('DDC', {
  revolvingCredit: {
    clickInRequestDDC: () => ({
      label: `credit - DDC revolving credit clicked`
    }),
    downloadDDC: () => ({
      label: `credit - DDC revolving credit download`
    }),
    negativeFeedbackView: () => ({
      label: `credit - DDC revolving credit negative feedback view`
    }),
    negativeFeedbackClick: () => ({
      label: `credit - DDC revolving credit negative feedback click`
    })
  },
  loans: {
    clickInRequestDDC: () => ({
      label: `credit - DDC credit clicked`
    }),
    downloadDDC: () => ({
      label: `credit - DDC credit download`
    }),
    negativeFeedbackView: () => ({
      label: `credit - DDC credit negative feedback view`
    }),
    negativeFeedbackClick: () => ({
      label: `credit - DDC credit negative feedback click`
    })
  }
})

export default analiticaDDC
