import { Stack } from '@dlpco/fluid-layout'
import { Heading, Radio, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Box, Flex } from '~/domains/platform/design-system'
import { type AssessmentCheckOption } from '~/domains/stone-account/ports/entities/KYC'
import { List } from '~/ui/components/utils/list/list'

type SharedKycFlowFormOptionListProps = {
  title: string
  setCurrentValue: (value: any) => void
  currentValue: any
  options: AssessmentCheckOption[]
}

export const BorderItem = styled(Flex)`
  border-bottom: 1px solid ${theme('colors.mediumGray4')};
  &:last-child {
    border-bottom: none;
  }
`
export const ScrollBorderBox = styled(Flex)`
  border: 1px solid ${theme('colors.mediumGray4')};
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  max-height: 350px;
  overflow: auto;
  overflow-x: hidden;
`

export function KycFlowFormOptionList({
  title,
  setCurrentValue,
  currentValue,
  options
}: SharedKycFlowFormOptionListProps) {
  return (
    <Stack space="1rem">
      <Box>
        <Heading weight="bold" size="large">
          {title}
        </Heading>
      </Box>
      <ScrollBorderBox flexDirection="column" overflow="scroll" p="1.5rem">
        <List
          items={options || []}
          render={item => (
            <BorderItem
              key={item.referenceValue}
              justifyContent="space-between"
              align-items="center"
              width="100%"
              pt="1rem"
              pb="1rem"
            >
              <Text as="label" weight="bold" htmlFor={item.referenceName} style={{ cursor: 'pointer', width: '100%' }}>
                {item.referenceName}
              </Text>{' '}
              <Radio
                id={item.referenceName}
                name="kyc-item"
                checked={currentValue === item}
                onChange={() => setCurrentValue(item)}
              />
            </BorderItem>
          )}
        />
      </ScrollBorderBox>
    </Stack>
  )
}
