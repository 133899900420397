import BlackBird from '~/domains/platform/lib/blackbird'

import { type DiscountSettlementSteps, type RenegotiationSteps } from '../entities'
import { basePath } from '../helpers/renegotiation'

interface renegotiationNavigateProps {
  step: RenegotiationSteps | DiscountSettlementSteps
  query?: any
}

export function renegotiationNavigate({ step, query }: renegotiationNavigateProps) {
  const { proposalId, loan: loanId, offerId } = BlackBird.getQuery()
  const params = { ...query, offerId, proposalId }

  for (const key in params) {
    if (params[key] === undefined || params[key] === null) delete params[key]
  }

  return BlackBird.travelTo({
    pathname: basePath,
    urlParams: { loan: loanId, step },
    query: params
  })
}
