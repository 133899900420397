import { type PropsWithChildren, type ReactNode } from 'react'
import { Stack } from '@dlpco/fluid-layout'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import { Divider } from '~/domains/platform/design-system/divider/divider'
import { If } from '~/domains/platform/lib/utilities-components'

import { StyledCard } from './styles'

export interface ContentCardProps {
  title?: string | ReactNode
  rightTitle?: string | ReactNode
  withShadow?: boolean
  withBorder?: boolean
  p?: string | number
  subHeader?: string | ReactNode
}

export function ContentCard(props: PropsWithChildren<ContentCardProps>) {
  const { title, rightTitle, children, withShadow = false, withBorder = false, p = '0rem 1rem 1rem', subHeader } = props

  return (
    <StyledCard p={0} withShadow={withShadow} withBorder={withBorder}>
      <Stack space="1rem">
        <If condition={Boolean(title)}>
          <Flex justifyContent="space-between">
            <Text fontWeight="bold">{title}</Text>
            <If condition={Boolean(rightTitle)}>
              <Text fontWeight="bold" color="primary">
                {rightTitle}
              </Text>
            </If>
          </Flex>
          <If condition={Boolean(subHeader)}>
            <Flex justifyContent="center">{subHeader}</Flex>
          </If>
          <Divider />
        </If>
        <Box padding={p}>{children}</Box>
      </Stack>
    </StyledCard>
  )
}
