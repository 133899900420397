import { type ReactNode } from 'react'
import { useEffectOnce } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Card, CardBody, theme } from '@dlpco/ginga-stone'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import { type Assessment, type AssessmentCheck } from '~/domains/stone-account/ports/entities/KYC'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { useCreditUserAssessmentPolling } from '../../loan-concession/hooks/use-credit-user-assessment-polling'
import { KycProvider, useKycContext } from '../context/kyc-context'
import { type KycStepperData, type KycSteps, buildKycFlowSteps } from '../helpers/kyc-steps'

export type KycFlowProps = {
  review: ReactNode
  onNext?: (step: KycSteps) => void
  onPrev?: (step: KycSteps) => void
}

export function KycFlow({ review, onNext, onPrev }: KycFlowProps) {
  const { isViewSmall } = useViewSize()
  return (
    <KycProvider>
      <Stack space={theme.light.space.large}>
        <Card spacing={isViewSmall ? 'small' : 'large'}>
          <CardBody>
            <KycStepper review={review} onNext={onNext} onPrev={onPrev} />
          </CardBody>
        </Card>
      </Stack>
    </KycProvider>
  )
}

interface KycStepperProps {
  review: ReactNode
  onNext?: (step: KycSteps) => void
  onPrev?: (step: KycSteps) => void
}

export function KycStepper({ review, onNext, onPrev }: KycStepperProps) {
  const { currentStep, saveSteps, reason, saveAssessment, stepChange, resetStepChange } = useKycContext()
  if (onNext && stepChange?.direction === 'NEXT') {
    onNext(stepChange.step)
    resetStepChange()
  }
  if (onPrev && stepChange?.direction === 'PREV') {
    onPrev(stepChange.step)
    resetStepChange()
  }
  const setUpSteps = (assessment?: Assessment) => {
    const assessmentChecks: AssessmentCheck[] = assessment?.checks ?? []
    const steps: KycStepperData[] = [
      {
        key: 'revisao',
        render: () => <>{review}</>
      },
      ...buildKycFlowSteps(assessmentChecks)
    ]
    saveSteps(steps)
  }
  const assessmentPollingSuccess = (data: Assessment) => {
    saveAssessment(data)
    setUpSteps(data)
  }
  useCreditUserAssessmentPolling(reason, assessmentPollingSuccess, !!reason)

  useEffectOnce(() => {
    setUpSteps()
  })

  if (!currentStep?.render) {
    return <Loader />
  }
  return currentStep.render()
}
