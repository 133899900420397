import { Icon, Text } from '@dlpco/ginga-stone'

import { RenegotiationSteps } from '../../entities'
import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { Flex } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'

export interface RenegotiationFlowBreadcrumbProps {
  step: RenegotiationSteps
}

export function RenegotiationFlowBreadcrumb({ step }: RenegotiationFlowBreadcrumbProps) {
  const { loan } = BlackBird.getQuery()
  const goToRootPath = () => {
    // TODO mapear métodos para remover ifs
    if (step === RenegotiationSteps.REVIEW) renegotiationAnalitica.events.review.click('voltar')
    if (step === RenegotiationSteps.PROPOSAL) renegotiationAnalitica.events.proposal.click('voltar')
    if (step === RenegotiationSteps.KYC) renegotiationAnalitica.events.proposal.preKycClick('voltar')
    BlackBird.travelTo({ pathname: '/credito/emprestimo/${loan}', urlParams: { loan } })
  }

  const defaultBreadcrumbs = [
    {
      steps: [RenegotiationSteps.ROOT],
      title: 'Empréstimo',
      isRoot: true
    },
    {
      steps: [RenegotiationSteps.PROPOSAL],
      title: 'Renegociação'
    },
    {
      steps: [RenegotiationSteps.KYC],
      title: 'Dados pessoais'
    },
    {
      steps: [RenegotiationSteps.REVIEW],
      title: 'Condições da renegociação'
    }
  ]

  const breadcrumbs = defaultBreadcrumbs.slice(0, defaultBreadcrumbs.findIndex(e => e.steps.includes(step)) + 1)

  return (
    <Flex alignContent="center" gap=".5rem">
      <Flex onClick={goToRootPath} alignItems="center" style={{ cursor: 'pointer' }} aria-label="Voltar">
        <Icon use="direction-arrow-left-outline" />
      </Flex>
      <For
        of={breadcrumbs}
        render={(breadcrumb, index) => {
          const { title, steps, isRoot } = breadcrumb
          const color = steps.includes(step) ? 'neutralHigh' : 'neutral'
          return (
            <Flex key={breadcrumb.title} alignItems="center" gap=".5rem">
              <If condition={index > 0}>
                <Text color="neutral">/</Text>
              </If>
              <If condition={Boolean(isRoot)}>
                <Text onClick={goToRootPath} color={color} style={{ cursor: 'pointer' }}>
                  {title}
                </Text>
              </If>
              <If condition={Boolean(!isRoot)}>
                <Text color={color}>{title}</Text>
              </If>
            </Flex>
          )
        }}
      />
    </Flex>
  )
}
