import styled from 'styled-components'

import { Flex } from '~/domains/platform/design-system'
import { theme } from '~/ui/theme'

export const ResponsiveFlexContainer = styled(Flex)`
  justify-content: space-between;

  @media (max-width: ${theme.windowSizes.small}px) {
    flex-direction: column;
  }
`
