import {
  Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow
} from '@dlpco/ginga-stone'

import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { For } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { BoxTable } from './loan-installment-details-list.styles'

const mockList = Array(8).fill(1)

export const LoanInstallmentDetailsListLoading = () => {
  return (
    <BoxTable>
      <Heading size="xSmall" weight="semi">
        <Skeleton aria-label="Carregando lista de parcelas" width="20%" height="4ch" />
      </Heading>

      <Table clickableRows style={{ marginTop: `${theme.space.medium}` }}>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>Data</TableHeaderCell>
            <TableHeaderCell>Valor Retido</TableHeaderCell>
            <TableHeaderCell>Juros Economizados</TableHeaderCell>
            <TableHeaderCell>Cobranças por atraso</TableHeaderCell>
            <TableHeaderCell>Total quitado no dia</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>

        <TableBody>
          <For
            of={mockList}
            render={(item, index) => {
              return (
                <TableRow key={item + index} uniqueId={item + index}>
                  <TableCell>
                    <Skeleton width="100%" height="4ch" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="100%" height="4ch" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="100%" height="4ch" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="100%" height="4ch" />
                  </TableCell>
                  <TableCell>
                    <Skeleton width="100%" height="4ch" />
                  </TableCell>
                </TableRow>
              )
            }}
          />
        </TableBody>
      </Table>
    </BoxTable>
  )
}
