import { getSagaKey } from '@credit-web/common/browser-utils'
import { Box } from '@dlpco/fluid-layout'
import { Button, IconShape, PageContainer, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'
import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import BlackBird from '~/domains/platform/lib/blackbird'
import { appEnv } from '~/lib/helpers'

type LoanGenericFeedbackProps = {
  title?: string
  description?: string
  action?: () => void
  actionButtonText?: string
  backLink?: string
  navigationBarTitle: string
  hasSagaKey?: boolean
  isPositiveFeedback?: boolean
  onlyActionButton?: boolean
}

export const BorderBox = styled(Box)`
  border: 1px solid ${jadeTheme.theme.color.border.low};
  border-radius: 0.5rem;
  text-align: left;
  padding: ${jadeTheme.global.space[200]};
  gap: ${jadeTheme.global.space[50]};
`

export function SharedLoanGenericFeedback({
  title,
  description,
  action,
  actionButtonText,
  backLink,
  navigationBarTitle,
  hasSagaKey = false,
  isPositiveFeedback = false,
  onlyActionButton = false
}: LoanGenericFeedbackProps) {
  const sagaKey = getSagaKey()
  /*
   * TODO: Jest-axe error
   * 1. "landmark-no-duplicate-main": Document should not have more than one main landmark (landmark-no-duplicate-main)
   */
  return (
    <Box
      style={{ height: '100%', backgroundColor: jadeTheme.theme.color.background.surface, justifyContent: 'center' }}
    >
      <SharedWithCustomNavbar
        navigationBarTitle={navigationBarTitle}
        navigationBarNavigateButtonTarget={backLink || '/'}
        navigationBarNavigateButtonType="close"
        backgroundColor="surface"
      >
        <PageContainer size="narrow">
          <SharedCustomCard>
            <Flex
              flexDirection="column"
              gap={jadeTheme.global.space[300]}
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 70px - 80px - 40px - 40px)"
              backgroundColor={jadeTheme.theme.color.background.surface}
            >
              <Box>
                {isPositiveFeedback === true ? (
                  <IconShape variant="positive" size="large" use="circle-check" />
                ) : (
                  <IconShape variant="negative" size="large" use="circle-error" />
                )}
              </Box>
              <Flex gap={jadeTheme.global.space[150]} textAlign="center" flexDirection="column">
                <Text variant="display-small">{title || 'Algo não deu certo por aqui'}</Text>
                <Text color="medium">
                  {description ||
                    'Por enquanto, não vai ser possível continuar. Se precisar de ajuda, converse com a gente.'}
                </Text>
                {Boolean(sagaKey) && hasSagaKey && (
                  <BorderBox>
                    <Text variant="text-medium" color="high" weight="medium">
                      Código do erro: {sagaKey}
                    </Text>
                    <Text variant="text-small" color="medium">
                      Informe o código para o time de atendimento ao conversar com a Stone.
                    </Text>
                  </BorderBox>
                )}
              </Flex>
              <Flex gap={jadeTheme.global.space[100]} width="100%" justifyContent="center">
                {onlyActionButton ? (
                  <Button
                    size="medium"
                    style={{ width: '100%' }}
                    onClick={() => {
                      if (action !== undefined) action()
                      else BlackBird.travelTo('/')
                    }}
                  >
                    {actionButtonText || 'Voltar para o início'}
                  </Button>
                ) : (
                  <>
                    <Button
                      size="medium"
                      onClick={() => {
                        appEnv.isProduction() && chat.open()
                      }}
                    >
                      Conversar com a Stone
                    </Button>
                    <Button
                      size="medium"
                      variant="primary-solid"
                      onClick={() => {
                        if (action !== undefined) action()
                        else BlackBird.travelTo('/')
                      }}
                    >
                      {actionButtonText || 'Voltar para o início'}
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          </SharedCustomCard>
        </PageContainer>
      </SharedWithCustomNavbar>
    </Box>
  )
}
