import { type ReactNode } from 'react'
import { useToggle } from 'react-use'
import { Text, theme } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box } from '~/domains/platform/design-system'
import { Accordion } from '~/domains/platform/design-system/accordion/accordion'

interface BoxCustomProps {
  readonly $largeMargin?: boolean
}

// eslint-disable-next-line prettier/prettier
const BoxCustom = styled(Box) <BoxCustomProps>`
  margin-bottom: ${({ $largeMargin }) => ($largeMargin ? theme.light.space.large : theme.light.space.small)};
`

const TextCustom = styled(Text)`
  padding: ${theme.light.space.medium};
`

interface AccordionProps {
  title: string
  content: ReactNode
  withIndicator?: boolean
  initialOpen?: boolean
  disableTrigger?: boolean
  disableClick?: boolean
  variant?: 'primary' | 'secondary'
  onOpen?: () => void
}

export function RenegotiationAccordion({
  title,
  content,
  onOpen,
  withIndicator = true,
  initialOpen = false,
  disableTrigger = false,
  disableClick = true,
  variant = 'primary'
}: AccordionProps) {
  const [open, toggleOpen] = useToggle(initialOpen)
  const onHandleOpen = () => {
    if (onOpen) onOpen()
    toggleOpen()
  }

  return (
    <BoxCustom $largeMargin={variant === 'secondary'}>
      <Accordion
        isOpen={open}
        onOpen={onHandleOpen}
        disableTrigger={disableTrigger}
        disableClick={disableClick}
        dividerBackgroundColor={(variant === 'secondary' && theme.light.color.borderLow) || undefined}
        dividerOpacity={(variant === 'secondary' && 1) || undefined}
        trigger={
          <TextCustom color="neutralHigh" weight="semi">
            {title}
          </TextCustom>
        }
        withDivider={open}
        withBorder
        variant="secondary"
        openIndicator={false}
        withIndicator={withIndicator}
        inicialOpen={initialOpen}
      >
        <Box
          p={theme.light.space.medium}
          style={(variant === 'secondary' && { backgroundColor: theme.light.color.surfaceHigh }) || undefined}
        >
          {content}
        </Box>
      </Accordion>
    </BoxCustom>
  )
}
