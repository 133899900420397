import { type CreditPaging } from '~/domains/credit/shared/entities'
import { httpClientCredit } from '~/domains/credit/shared/infra/http-client-credit'
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { type InstallmentStatementItem } from '../entities'

interface UseInstallmentStatementsProps {
  installmentPlanId?: string
  installmentNumber?: string
  pageSize?: number
  pageNumber?: number
}

export const fetchInstallmentStatements = ({
  installmentPlanId,
  installmentNumber,
  pageNumber = 1,
  pageSize = 10
}: UseInstallmentStatementsProps) => {
  return httpClientCredit<CreditPaging<InstallmentStatementItem[]>>({
    url: `experience-bff-service/api/legacy/web/v1/installment-plans/${installmentPlanId}/statements`,
    params: { InstallmentNumber: installmentNumber, PageNumber: pageNumber, PageSize: pageSize }
  })
}

export function useInstallmentStatements(
  { installmentPlanId, installmentNumber, pageNumber = 1, pageSize = 10 }: UseInstallmentStatementsProps,
  queryOptions?: UseQueryRequestOptions<CreditPaging<InstallmentStatementItem[]>>
) {
  const { data: response, ...restQuery } = useQueryRequest<CreditPaging<InstallmentStatementItem[]>>(
    ['useInstallmentStatements', installmentPlanId, installmentNumber, pageNumber, pageSize],
    () => fetchInstallmentStatements({ installmentPlanId, installmentNumber, pageNumber, pageSize }),
    {
      ...queryOptions,
      enabled: Boolean(installmentPlanId) && Boolean(installmentNumber)
    }
  )

  return { data: response?.data, ...restQuery }
}
