import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardDivider,
  CopyButton,
  Heading,
  Overline,
  Text,
  theme
} from '@dlpco/ginga-stone'
import Image from 'next/image'
import analitica from '~/domains/credit/shared/helpers/spot-offer-analitica'
import { Flex } from '~/domains/platform/design-system'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import { type SpotOffer } from '../entities'

interface SpotOfferDetailsProps {
  spotOffer: SpotOffer
}
export function SpotOfferDetails({ spotOffer }: SpotOfferDetailsProps) {
  const { addToast } = useToast()
  const baseCardStyle = { minWidth: '512px', border: `1px solid ${theme.light.color.borderLow}` }
  const cardDividerStyle = { border: `1px solid ${theme.light.color.borderLow}`, margin: '0' }

  const { paymentMethod, createdBy, payment } = spotOffer
  const isPix = paymentMethod === 'Pix'
  const isBoleto = paymentMethod === 'Boleto'
  const overlineText = isPix ? 'Dados do QR Code' : 'Sobre o boleto'
  const dueDate = formatDate(spotOffer.dueDate || '')
  const amount = stringFormat.currency(spotOffer.amount ?? 0)
  const onClickCopyValue = () => {
    analitica.events.button.successClicked({ action: 'copiar código', paymentType: spotOffer.paymentMethod })
    addToast({ message: 'Código copiado com sucesso', type: 'success' })
  }
  return (
    <Stack space="1rem">
      <If condition={isPix && !!payment?.image}>
        <Flex>
          <Image
            width={182}
            height={182}
            src={payment?.image || ''}
            alt="Imagem QR code para pagamento via PIX"
            style={{ display: 'block', margin: '1.5rem' }}
          />
        </Flex>
      </If>
      <Stack space="0.75rem">
        <Overline color="neutral">{overlineText}</Overline>
        <Card style={baseCardStyle}>
          <CardBody>
            <Stack space="1rem">
              <Stack>
                <Heading size="small" color="neutral">
                  Total a pagar
                </Heading>
                <Heading size="large" weight="bold">
                  {amount}
                </Heading>
              </Stack>
              <CardDivider style={cardDividerStyle} />
              <Choose>
                <Choose.When
                  condition={isPix}
                  render={() => {
                    return (
                      <Flex alignItems="center">
                        <Stack>
                          <Text>Código do QR Code</Text>
                          <Text color="neutral" style={{ wordBreak: 'break-all' }}>
                            {payment.content.slice(0, 108)}...
                          </Text>
                        </Stack>
                        <CopyButton copiedValue={payment.content} onClick={onClickCopyValue} variant="contentOnly">
                          Copiar código
                        </CopyButton>
                      </Flex>
                    )
                  }}
                />
                <Choose.When
                  condition={isBoleto}
                  render={() => {
                    return (
                      <Stack space=".75rem">
                        <Text>Código do Boleto</Text>
                        <Flex alignItems="center" justifyContent="space-between" gap="1rem">
                          <Text color="neutral">{payment.content}</Text>
                          <CopyButton copiedValue={payment.content} onClick={onClickCopyValue} variant="contentOnly">
                            Copiar código
                          </CopyButton>
                        </Flex>
                      </Stack>
                    )
                  }}
                />
              </Choose>
              <CardDivider style={cardDividerStyle} />
              <Stack>
                <Text>Vence em</Text>
                <Text color="neutral">{dueDate}</Text>
              </Stack>
            </Stack>
          </CardBody>
        </Card>
        <Alert color="info" style={{ margin: '1rem' }}>
          Enviamos essas informações para <strong>{createdBy}</strong>
        </Alert>
      </Stack>
      <If condition={isBoleto}>
        <Flex justifyContent="space-between" gap="1rem">
          {/**
            @disclaimer - Button ok as anchor cause it's an external link
           */}
          <Button
            as="a"
            href={payment?.publicUri}
            rel="noreferrer"
            target="_blank"
            color="neutral"
            shape="pill"
            style={{
              width: '100%'
            }}
            onClick={() => {
              analitica.events.button.successClicked({
                action: 'baixar boleto',
                paymentType: spotOffer.paymentMethod
              })
            }}
          >
            Baixar boleto
          </Button>
          <Button
            shape="pill"
            style={{
              width: '100%'
            }}
            onClick={() => {
              analitica.events.button.successClicked({
                action: 'copiar qr code',
                paymentType: spotOffer.paymentMethod
              })
              navigator.clipboard.writeText(payment.content ?? '')
              onClickCopyValue()
            }}
          >
            Copiar código
          </Button>
        </Flex>
      </If>
    </Stack>
  )
}
