import { useState } from 'react'
import { useRouter } from 'next/router'
import {
  Alert,
  Button,
  DisplayItem,
  Divider,
  GroupHeader,
  IconButton,
  IconShape,
  Link,
  ListItem,
  ListSubheader,
  Text
} from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'
import styled from 'styled-components'

import { SharedCustomCard } from '~/domains/credit/shared/components/credit-custom-card'
import { SharedWithCustomNavbar } from '~/domains/credit/shared/components/with-custom-navbar'
import { Box, Flex } from '~/domains/platform/design-system'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'

import { OverdueConsequencesDrawer } from '../../loan-details/components/loan-details-jade-drawers'
import { StatusTag } from '../../shared/jade-status-color-map'
import { type InstallmentDetails, type InstallmentDetailsStatement } from '../entities'

import { installmentDetailsMock, statementsMock } from './loan-installment-details.mock'

const themeSpacings = jadeTheme.global.space
const themeColors = jadeTheme.theme.color

const ConnectionLine = styled.div`
  width: 1px;
  height: 25px;
  border: 1px solid ${themeColors.border.low};
  margin-top: -12px;
  margin-left: 43px;
  margin-bottom: -12px;
`

function Aside({ detailsInstallment, aboutInstallment, alert, notification }: InstallmentDetails) {
  const [isOverdueDrawerOpen, setIsOverdueDrawerOpen] = useState(false)
  const warningsMap = {
    dueSoon: {
      title: 'Evite negativação e cobranças',
      text: 'Quite o valor em atraso para evitar a aplicação das garantias.',
      link: undefined
    },
    overdue: {
      title: 'Evite o acúmulo de juros e a negativação',
      text: 'O valor restante está aumentando por causa do atraso. Regularize a situação o quanto antes para evitar essa e outras consequências.',
      link: { text: 'Saber mais', onClick: () => setIsOverdueDrawerOpen(true) }
    }
  }

  const notificationsMap = {
    help_dueSoon: {
      title: 'Evite a cobrança de juros',
      text: 'Sua parcela vence em breve. Faça um pagamento e continue em dia.',
      link: { text: 'Precisa de ajuda?', onClick: () => window.open('https://ajuda.stone.com.br/', '_blank') }
    },
    help_overdue: {
      title: 'Dúvidas sobre como quitar o empréstimo?',
      text: 'Converse com a gente agora mesmo.',
      link: { text: 'Conversar com a Stone', onClick: () => chat.open('LOANS') }
    }
  }

  return (
    <Flex gap="1.5rem" flexDirection="column" width="100%" maxWidth="344px">
      <SharedCustomCard style={{ width: '100%', maxWidth: '344px', height: 'fit-content', paddingBottom: '0.75rem' }}>
        <Flex padding="0.75rem 0.75rem 0" width="100%" justifyContent="flex-end">
          <StatusTag description={detailsInstallment.status.description} status={detailsInstallment.status.value} />
        </Flex>
        <Box margin="0.75rem 0 1.25rem 1.25rem ">
          <DisplayItem value={detailsInstallment.remainingAmount.description} label="Valor restante" />
        </Box>
        <For
          of={aboutInstallment}
          render={(item, index) => (
            <Box key={'aboutInstallmentItem' + index}>
              <Divider />
              <For
                of={item}
                render={(subItem, subItemIndex) => (
                  <ListItem
                    key={subItem.title + subItemIndex}
                    content={<Text color="medium">{subItem.title}</Text>}
                    trailing={subItem.value.description}
                  />
                )}
              />
            </Box>
          )}
        />
      </SharedCustomCard>

      <If condition={Boolean(alert)}>
        <Alert
          showIcon
          variant="warning"
          title={alert ? warningsMap[alert].title : ''}
          description={alert ? warningsMap[alert].text : ''}
          link={alert ? warningsMap[alert].link : undefined}
        />
      </If>

      <If condition={Boolean(notification)}>
        <SharedCustomCard style={{ width: '100%', maxWidth: '344px', height: 'fit-content', paddingBottom: '0.75rem' }}>
          <GroupHeader title={notification ? notificationsMap[notification].title : ''} />
          <Flex padding="0 1.25rem 1.25rem" gap="1rem" flexDirection="column">
            <Text color="medium">{notification ? notificationsMap[notification].text : ''}</Text>
            <Link icon="chat" onClick={notification ? notificationsMap[notification].link.onClick : () => {}}>
              {notification ? notificationsMap[notification].link.text : ''}
            </Link>
          </Flex>
        </SharedCustomCard>
      </If>

      <OverdueConsequencesDrawer isOpen={isOverdueDrawerOpen} setIsOpen={setIsOverdueDrawerOpen} />
    </Flex>
  )
}

function StatementDay({ item }: { item: InstallmentDetailsStatement }) {
  return (
    <>
      <ListSubheader label={item.date.description} value={item.remainingAmount?.description || ''} />

      <For
        of={item.items}
        render={(dayItem, index) => (
          <Flex flexDirection="column" key={dayItem.amount.value + index}>
            <Divider />
            <ListItem
              leading={<IconShape size="medium" use={dayItem.icon} variant={dayItem.variant} />}
              content={<ListItem.Content label={dayItem.type.description} description={dayItem.description} />}
              trailing={
                <Text variant="text-medium" weight="semibold">
                  {dayItem.amount.description}
                </Text>
              }
            />

            <For
              of={dayItem.related || []}
              render={(relatedItem, indexRelatedItem) => (
                <Box key={relatedItem.amount.description + index + indexRelatedItem.toString()}>
                  <ConnectionLine />

                  <ListItem
                    leading={<IconShape size="medium" use={relatedItem.icon} variant={relatedItem.variant} />}
                    content={
                      <ListItem.Content label={relatedItem.type.description} description={relatedItem.description} />
                    }
                    trailing={
                      <Text variant="text-medium" weight="semibold" color="brand">
                        {relatedItem.amount.description}
                      </Text>
                    }
                  />
                </Box>
              )}
            />
          </Flex>
        )}
      />
    </>
  )
}

export function LoanInstallmentDetailsJade() {
  const { loan: loanId }: { loan?: string } = useRouter().query
  const isOverdueOrDueSoon = ['overdue', 'dueSoon'].includes(installmentDetailsMock.detailsInstallment.status.value)
  const { currentPage, pageCount } = statementsMock.paging

  return (
    <SharedWithCustomNavbar
      navigationBarTitle={`Resumo da parcela ${installmentDetailsMock.installmentNumber}`}
      navigationBarNavigateButtonTarget={'/credito'}
      navigationBarNavigateButtonType="back"
      trailingItem={
        <If condition={isOverdueOrDueSoon}>
          <Button
            variant="primary-solid"
            icon="money-hand"
            onClick={() =>
              BlackBird.travelTo({
                pathname: '/credito/emprestimo/${loan}/antecipar-pagamento/inicio',
                urlParams: { loan: loanId }
              })
            }
          >
            Fazer um pagamento
          </Button>
        </If>
      }
    >
      <Flex gap="1.5rem" padding={[0, themeSpacings[500]]} width="100%" maxWidth="var(--comp-page-container-max-width)">
        <Flex flexDirection="column" gap={themeSpacings[300]} width="100%" maxWidth="528px" height="100%">
          <SharedCustomCard style={{ width: '100%', height: '100%' }}>
            <Choose>
              <Choose.When condition={statementsMock.items.length > 0}>
                <Flex flexDirection="column" justifyContent="space-between" height="100%">
                  <Box>
                    <For
                      of={statementsMock.items}
                      render={(item, index) => <StatementDay key={item.date.value + index} item={item} />}
                    />
                  </Box>
                  <Flex justifyContent="center" p="0.5rem 0 1rem" gap="1rem">
                    <IconButton
                      icon="chevron-left"
                      variant="neutral-subtle"
                      size="medium"
                      disabled={currentPage === 1}
                      onClick={() => {}}
                    />
                    <IconButton
                      icon="chevron-right"
                      variant="neutral-subtle"
                      size="medium"
                      disabled={currentPage === pageCount}
                      onClick={() => {}}
                    />
                  </Flex>
                </Flex>
              </Choose.When>
              <Choose.Otherwise />
            </Choose>
          </SharedCustomCard>
        </Flex>
        <Aside {...installmentDetailsMock} />
      </Flex>
    </SharedWithCustomNavbar>
  )
}
