import { useEffectOnce, useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Card, CardBody, Checkbox, Heading, Text, theme } from '@dlpco/ginga-stone'

import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { getRenegotiationContract } from '../../services/renegotiation'
import { SharedCreditGrid } from '~/domains/credit/shared/components/credit-grid'
import { Box, Flex } from '~/domains/platform/design-system'
import { Wrapper } from '~/domains/platform/design-system/accordion/styles'
import { useToast } from '~/domains/platform/layout/toast'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import { type RenegotiationProposalId } from '../../entities'

import { RenegotiationReviewAccordionContainer } from './renegotiation-review-accordion-container'
import { RenegotiationReviewConfirm } from './renegotiation-review-confirm'

export function RenegotiationReview({
  proposal,
  showInstallmentsPlan
}: {
  proposal: RenegotiationProposalId
  showInstallmentsPlan: () => void
}) {
  const { addToast } = useToast()
  const [confirmation, toggleConfirmation] = useToggle(false)
  const [isAccepted, toggleIsAccepted] = useToggle(false)
  const isButtonDisabled = !confirmation || isAccepted

  const { challenge } = BlackBird.getQuery()
  const handleAccept = async () => {
    renegotiationAnalitica.events.review.contractRequested()
    toggleIsAccepted()
  }

  useEffectOnce(() => renegotiationAnalitica.events.review.view())

  const openRenegotiationContract = async () => {
    addToast({ message: 'Gerando contrato.', type: 'info' })
    await getRenegotiationContract({
      templateContractUrl: proposal.templateContractUrl,
      onError: () => addToast({ message: 'Erro ao baixar contrato.', type: 'error' })
    })
  }

  const handleToggleConfirmation = () => {
    toggleConfirmation()
    renegotiationAnalitica.events.review.click('checkbox termos e condições')
  }

  return (
    <Stack space={theme.light.space.large}>
      <Card spacing="large">
        <CardBody>
          <SharedCreditGrid
            left={
              <>
                <Heading size="large" weight="bold">
                  As principais informações da renegociação estão aqui
                </Heading>
                <Text size="medium" weight="regular" color="neutral" style={{ marginTop: theme.light.space.xSmall }}>
                  Aceite os termos no fim da página para seguir.
                </Text>
                <Wrapper
                  withBorder={true}
                  rounder={true}
                  disableTrigger={true}
                  variant="secondary"
                  marginTop={theme.light.space.large}
                  padding={theme.light.space.xSmall}
                >
                  <Box padding={`${theme.light.space.small} ${theme.light.space.small} ${theme.light.space.small} 0`}>
                    <Flex flexDirection="row" pl={theme.light.space.medium}>
                      <Flex flexDirection="column" flexGrow={1}>
                        <Text size="medium" weight="regular" color="neutral">
                          Total a pagar
                        </Text>
                        <Heading
                          style={{ marginTop: theme.light.space.xSmall2 }}
                          weight="bold"
                          size="large"
                          color="neutralHigh"
                        >
                          {proposal.proposal.conditions.totalSum.description}
                        </Heading>
                      </Flex>
                      <Button variant="contentOnly" color="primary" size="small" onClick={() => showInstallmentsPlan()}>
                        Conferir detalhes
                      </Button>
                    </Flex>
                  </Box>
                </Wrapper>
              </>
            }
            right={
              <>
                <RenegotiationReviewAccordionContainer proposal={proposal} handleContract={openRenegotiationContract} />
                <Flex gap={theme.light.space.small} marginTop={theme.light.space.xLarge}>
                  <Checkbox
                    data-test-id="renegotiation-flow-review-step-checkbox"
                    id="confirm-term"
                    onChange={handleToggleConfirmation}
                  />
                  <Text>
                    Li e aceito os{' '}
                    <Box
                      as="span"
                      onClick={openRenegotiationContract}
                      style={{
                        textDecoration: 'none',
                        color: 'primary',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }}
                      aria-label="Termos e Condições"
                    >
                      Termos e Condições
                    </Box>
                    , tendo a sua autenticidade e validade garantidas na forma da lei.
                  </Text>
                </Flex>
                <Button
                  onClick={handleAccept}
                  color={isButtonDisabled ? 'neutral' : 'primary'}
                  shape="pill"
                  disabled={isButtonDisabled}
                  style={{
                    width: '100%',
                    marginTop: theme.light.space.medium
                  }}
                  data-test-id="renegotiation-review-step-button"
                >
                  Confirmar renegociação
                </Button>
              </>
            }
          />
        </CardBody>
      </Card>
      <If condition={isAccepted || Boolean(challenge)}>
        <RenegotiationReviewConfirm isOpen={isAccepted || Boolean(challenge)} />
      </If>
    </Stack>
  )
}
