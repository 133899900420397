import { AccordionItem, Divider, ListItem } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'
import { For } from '~/domains/platform/lib/utilities-components'

import { type LoanDetails } from '../entities'

export function LoanDetailsJadeAsideGuarantorInformation({ warranties }: { warranties: LoanDetails['warranties'] }) {
  return (
    <Box pt="0.5rem">
      <AccordionItem title="Garantidores" hasSafeArea={false}>
        <For
          of={warranties}
          render={(item, index) => (
            <Box key={item.document + index}>
              <ListItem
                content={<ListItem.Content label="Nome" type="display" value={item.name} />}
                variant="neutral"
              />
              <Divider />
              <ListItem
                content={<ListItem.Content label="CPF" type="display" value={item.document} />}
                variant="neutral"
              />
              <Divider />
              <ListItem
                content={<ListItem.Content label="Estado civil" type="display" value={item.maritalStatus} />}
                variant="neutral"
              />

              <Divider />
              <ListItem
                content={<ListItem.Content label="Endereço" type="display" value={item.address} />}
                variant="neutral"
              />
              <Divider />
            </Box>
          )}
        />
      </AccordionItem>
    </Box>
  )
}
