import { useToggle } from 'react-use'

import { Modal } from '~/ui/components'

import { SharedConfirmDownload } from './shared-confirm-download'
import { SharedDownloadingDocument } from './shared-downloading-document'
import { SharedErrorGeneratingDocument } from './shared-error-generating-document'

export type SharedModalDownloadDocumentProps = {
  title: string
  onConfirm: () => Promise<void>
  onErrorConfirm?: () => void
  onErrorView?: () => void
  onClose: () => void
  isOpen: boolean
}
const commonModalStyles = { padding: '40px', height: '392px', width: '480px' }
const SharedModalDownloadDocument = ({
  title,
  onConfirm,
  onErrorConfirm,
  onErrorView,
  onClose,
  isOpen
}: SharedModalDownloadDocumentProps) => {
  const [isLoading, setIsLoading] = useToggle(false)
  const [error, setIsError] = useToggle(false)

  const handleDownloadDocument = async () => {
    setIsError(false)
    setIsLoading(true)
    try {
      await onConfirm()
      onClose()
    } catch (e) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading && !error) {
    return (
      <Modal isOpen={isOpen && isLoading} onClose={onClose} modalStyles={commonModalStyles}>
        <SharedDownloadingDocument />
      </Modal>
    )
  }
  if (error) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} modalStyles={commonModalStyles}>
        <SharedErrorGeneratingDocument onErrorConfirm={onErrorConfirm} onErrorView={onErrorView} />
      </Modal>
    )
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} modalStyles={commonModalStyles}>
      <SharedConfirmDownload title={title} onConfirm={handleDownloadDocument} />
    </Modal>
  )
}

export { SharedModalDownloadDocument }
