import { type Loan } from '~/domains/credit/shared/entities'

import { useLoanSummary } from '../../renegotiation/hooks/use-loan-summary'
import { type LoanInstallment, type LoanSummary } from '../../shared/entities'
import { useInstallmentPlans } from '../../shared/hooks/use-installments-plans'
import { type LegacySpotOffer } from '../entities'

import { useLoanDetails } from './use-loan-details'
import { useSpotOfferList } from './use-spot-offer'

export function useSpotOfferFlow(document: string, loanId: string) {
  const {
    data: summaryResponse,
    isLoading: isSummaryLoading,
    isError: isSummaryError,
    isFetching: isSummaryFetching,
    refetch: refetchLoansSummary
  } = useLoanSummary(loanId)
  const summary = summaryResponse?.currentSummary

  const {
    data: installments,
    isLoading: isLoanInstallmentsLoading,
    isError: isLoanInstallmentsError,
    isFetching: isLoanInstallmentsFetching,
    refetch: refetchLoanInstallments
  } = useInstallmentPlans({ installmentPlanId: summary?.installmentPlanId })

  const {
    data: loan,
    isLoading: isLoansLoading,
    isError: isLoansError,
    isFetching: isLoansFetching,
    refetch: refetchLoans
  } = useLoanDetails(document, loanId)

  const {
    data: spotOffers,
    isLoading: isSpotOffersLoading,
    isError: isSpotOffersError,
    isFetching: isSpotOffersFetching,
    refetch: refetchSpotOffers
  } = useSpotOfferList({
    productExternalId: loanId,
    status: 'Processed',
    pixStatus: 'Created',
    boletoStatus: 'Registered',
    document
  })

  const refetchAll = async () => {
    await refetchLoansSummary()
    await refetchLoanInstallments()
    await refetchLoans()
    await refetchSpotOffers()
  }

  return {
    data: {
      loan: loan as Loan,
      summary: summary as LoanSummary,
      installments: installments?.items as LoanInstallment[],
      spotOffers: spotOffers?.items as LegacySpotOffer[]
    },
    isError: isSummaryError || isLoanInstallmentsError || isLoansError,
    isLoading: [
      isLoansLoading,
      isLoansFetching,
      isSummaryLoading,
      isSummaryFetching,
      isLoanInstallmentsLoading,
      isLoanInstallmentsFetching
    ].some(val => Boolean(val)),
    isSpotOffersLoading: [isSpotOffersLoading, isSpotOffersFetching].some(val => Boolean(val)),
    isSpotOffersError,
    refetchSpotOffers,
    refetch: refetchAll
  }
}
