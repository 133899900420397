import { useState } from 'react'
import { formatDate } from '@credit-web/common/date-utils'
import {
  Badge,
  Checkbox,
  Money,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
  Text
} from '@dlpco/ginga-stone'
import isEqual from 'lodash.isequal'

import { Box } from '~/domains/platform/design-system'
import { For, If } from '~/domains/platform/lib/utilities-components'

import { type InstallmentStatus, type LoanInstallment } from '../entities'

interface SharedInstallmentSelectionProps {
  installments: LoanInstallment[]
  selectedInstallments: LoanInstallment[]
  setSelectedInstallments: (value: LoanInstallment[]) => void
  onSelectAllEvent: () => void
  usePresentBalance?: boolean
  showLabel?: boolean
}

interface getFormattedDateProps {
  dueDate: string
  startDate: string
  status: InstallmentStatus
}

function getFormattedDate({ dueDate, startDate, status }: getFormattedDateProps): string {
  const statusTexts = {
    Overdue: `Venceu em ${formatDate(dueDate)}`,
    Open: `Aberta desde ${formatDate(startDate)}`,
    Future: `Abre em ${formatDate(startDate)}`,
    Settled: '',
    DueSoon: `Vence em ${formatDate(dueDate)}`,
    Suspended: ''
  }

  return statusTexts[status] ?? ''
}

export function SharedInstallmentSelectionList({
  installments,
  selectedInstallments,
  setSelectedInstallments,
  onSelectAllEvent,
  usePresentBalance = true,
  showLabel = true
}: SharedInstallmentSelectionProps) {
  const [selectableInstallments, setSelectableInstallments] = useState<number[]>([
    ...installments.slice(0, 1).map(installment => installment.installmentNumber)
  ])

  if (
    selectableInstallments.length <= selectedInstallments.length &&
    selectedInstallments.length != installments.length
  ) {
    setSelectableInstallments(
      installments.slice(0, selectedInstallments.length + 1).map(installment => installment.installmentNumber)
    )
  }

  if (
    selectableInstallments.length < selectedInstallments.length &&
    selectedInstallments.length === installments.length
  ) {
    setSelectableInstallments(installments.map(installment => installment.installmentNumber))
  }

  const handleInstallmentSelection = (installmentNumber: number, isSelected: boolean) => {
    const isSelectable = selectableInstallments.includes(installmentNumber)

    if (isSelectable) {
      const newSelectableInstallments = installments.filter(
        installment =>
          (isSelected && installment.installmentNumber <= installmentNumber) ||
          (!isSelected && installment.installmentNumber <= installmentNumber + 1)
      )

      const newSelectedInstallments = installments.filter(
        installment =>
          (isSelected && installment.installmentNumber < installmentNumber) ||
          (!isSelected && installment.installmentNumber <= installmentNumber)
      )

      setSelectableInstallments(newSelectableInstallments.map(installment => installment.installmentNumber))
      setSelectedInstallments(newSelectedInstallments)
    }
  }

  const allInstallmentNumbers = installments.map(installment => installment.installmentNumber)
  const areAllSelected = isEqual(installments, selectedInstallments)
  const handeAllSelection = () => {
    onSelectAllEvent()
    setSelectableInstallments(areAllSelected ? [selectableInstallments[0]] : allInstallmentNumbers)
    setSelectedInstallments(areAllSelected ? [] : installments)
  }

  return (
    <Table>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>
            <Checkbox onChange={handeAllSelection} checked={areAllSelected} />
          </TableHeaderCell>
          <TableHeaderCell>Parcela</TableHeaderCell>
          <TableHeaderCell>Valor</TableHeaderCell>
          <TableHeaderCell>Data de vencimento</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        <For
          of={installments}
          render={({ id, futureBalance, presentBalance, dueDate, installmentNumber, status, startDate }) => {
            const isSelected = selectedInstallments.map(item => item.installmentNumber).includes(installmentNumber)
            const balance = usePresentBalance ? presentBalance : futureBalance
            const isSelectable = !selectableInstallments.includes(installmentNumber)
            const dateTextColor = status === 'Overdue' ? 'danger' : isSelectable ? 'mediumGray2' : 'darkGray'
            return (
              <TableRow uniqueId={id} key={id}>
                <TableCell>
                  <Checkbox
                    disabled={!selectableInstallments.includes(installmentNumber)}
                    onChange={() => handleInstallmentSelection(installmentNumber, isSelected)}
                    checked={isSelected}
                  />
                </TableCell>
                <TableCell>
                  <Text weight="semi" color={isSelectable ? 'neutral' : 'neutralHigh'}>
                    {installmentNumber}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text weight="semi" color={isSelectable ? 'neutral' : 'neutralHigh'}>
                    <Money amount={balance} />
                    <If condition={showLabel}>
                      <Badge color="positive">Desconto disponível</Badge>
                    </If>
                  </Text>
                </TableCell>
                <TableCell>
                  <Box color={dateTextColor}>
                    <Text size="small" inheritColor>
                      {getFormattedDate({ dueDate, startDate, status })}
                    </Text>
                  </Box>
                </TableCell>
              </TableRow>
            )
          }}
        />
      </TableBody>
    </Table>
  )
}
