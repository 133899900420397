import styled from 'styled-components'

import { theme } from '~/ui/theme'

export const CustomBox = styled.div`
  border: 1.5px solid ${theme.colors.border.disabled};
  padding: 1rem;
  border-radius: 12px;
`

export const CustomDivider = styled.div`
  border-top: 1.5px solid ${theme.colors.border.disabled};
`
