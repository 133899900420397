import { Heimdall } from '~/domains/platform/core/heimdall'
import { Box } from '~/domains/platform/design-system'

import { LoanInstallmentDetails } from './loan-installment-details'
import { LoanInstallmentDetailsJade } from './loan-installment-details-jade'

export function LoanInstallmentDetailsPageSelector() {
  const hasJadeFlag = Heimdall.pass(['credit_loan_management_web'])

  if (hasJadeFlag) return <LoanInstallmentDetailsJade />
  return (
    <Box maxWidth="1024px" padding="40px">
      <LoanInstallmentDetails />
    </Box>
  )
}
