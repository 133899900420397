import { Loader } from '~/domains/platform/design-system/loader/loader'
import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'

type WongStep = {
  render: () => JSX.Element
  key: string
}

interface WongProps {
  basePath?: Routes
  steps: WongStep[]
}

export default function Wong({ steps, basePath }: WongProps) {
  const query = BlackBird.getQuery()

  const currentStep = steps.find(steps => steps.key === query.step)

  if (!currentStep && query.step) {
    BlackBird.travelTo(basePath ?? '/404')
  }

  if (!currentStep) {
    return <Loader />
  }

  return currentStep.render()
}
