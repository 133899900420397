import { Suspense, useEffect, useState } from 'react'

import { useCheckConfigurations } from '~/domains/credit/ports/hooks/use-check-configurations'
import { checkCreditAccess } from '~/domains/credit/ports/services/check-credit-access'
import { Heimdall } from '~/domains/platform/core/heimdall'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'

type WithCreditGuardOptions = {
  productKey: string
  document?: string
  organizationId?: string
  onlyConfigurationsRequest?: boolean
  redirectWhenIsInMaintenance?: boolean
  fallbackComponent?: React.ReactNode
  redirectWhenConfigurationError?: boolean
}

const CustomLoader = () => (
  <Dimmer isVisible>
    <Loader />
  </Dimmer>
)

const withCreditGuard = (WrappedComponent: React.ComponentType<any>, options: WithCreditGuardOptions) => {
  return (props: WithPageConfigProps<any> | any) => {
    const productKey = options.productKey

    const document = options.document || props.entity?.document
    const organizationId = options.organizationId || props.entity?.id

    const shouldFetchCheckCreditAccess =
      Heimdall.pass(['credit_home_loan_web', 'credit_beta_web']) && !options?.onlyConfigurationsRequest

    const {
      data: configurations,
      isLoading: isConfigurationsLoading,
      error: configurationsError
    } = useCheckConfigurations({ document })

    const [state, setState] = useState<{
      hasCreditAccess?: boolean | null
      isLoading?: boolean
      error?: any
    }>({
      hasCreditAccess: null,
      isLoading: true,
      error: null
    })

    useEffect(() => {
      const fetchData = async () => {
        setState(prevState => ({
          ...prevState,
          isLoading: true,
          error: null
        }))
        try {
          const hasCreditAccess = shouldFetchCheckCreditAccess
            ? await checkCreditAccess({ organizationId, document })
            : null

          setState(prevState => ({
            ...prevState,
            hasCreditAccess,
            error: null
          }))
        } catch (error) {
          setState(prevState => ({
            ...prevState,
            hasCreditAccess: null,
            error
          }))
          BlackBird.travelTo('/404')
        } finally {
          setState(prevState => ({
            ...prevState,
            isLoading: false
          }))
        }
      }

      fetchData()
    }, [organizationId, document, shouldFetchCheckCreditAccess])

    const isLoading = isConfigurationsLoading || state.isLoading
    const isInMaintenance =
      configurations?.content.productsUnderMaintenance?.includes(productKey) ||
      !!configurations?.content.allProductsIsUnderMaintenance ||
      false

    useEffect(() => {
      if (options?.redirectWhenIsInMaintenance && isInMaintenance) {
        const redirectPath = `maintenance-${productKey}`
        BlackBird.travelTo(`/credito/manutencao/${redirectPath}` as any)
      }
    }, [isInMaintenance, productKey])

    useEffect(() => {
      if (configurationsError && options.redirectWhenConfigurationError) {
        BlackBird.travelTo('/credito/manutencao/404' as any)
      }
    }, [configurationsError])

    if (isLoading) {
      return <CustomLoader />
    }

    if (isInMaintenance && options.fallbackComponent) {
      const Fallback = options.fallbackComponent
      return <>{Fallback}</>
    }

    if (state.hasCreditAccess === false && !options?.onlyConfigurationsRequest) {
      BlackBird.travelTo('/404')
      return null
    }

    return (
      <Suspense fallback={<CustomLoader />}>
        <WrappedComponent {...props} />
      </Suspense>
    )
  }
}

export default withCreditGuard
