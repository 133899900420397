import { useEffectOnce } from 'react-use'
import { useRouter } from 'next/router'
import { Stack } from '@dlpco/fluid-layout'
import { Alert } from '@dlpco/ginga-stone'

import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { Box } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'

import { useLoanSummary } from '../../renegotiation/hooks/use-loan-summary'
import { isRenegotiationSummary, isZeroTaxRenegotiation } from '../../shared/helpers/loan'
import { useInstallmentPlans } from '../../shared/hooks/use-installments-plans'
import installmentDetailsAnalitica from '../helpers/installment-details-analitica'

import { LoanInstallmentDetailsHeader } from './loan-installment-details-header'
import { LoanInstallmentDetailsList } from './loan-installment-details-list'
import { LoanInstallmentDetailsSummary } from './loan-installment-details-summary'

export const LoanInstallmentDetails = () => {
  const { query, pathname } = useRouter()
  const { loan: loanId, installment: installmentNumber }: { loan?: string; installment?: string } = query
  const usePreviousSummary = pathname.includes('original-loan')
  /**
   * @disclaimer we're only using the useLoanSummary here to get the total number of installments
   */
  const { data: summaryResponse, isLoading: isSummaryLoading, isError: isSummaryError } = useLoanSummary(loanId)
  const summary = usePreviousSummary ? summaryResponse?.previousSummary : summaryResponse?.currentSummary
  const hasRenegotiation = isRenegotiationSummary(summaryResponse)
  const {
    data: installmentList,
    isLoading: isInstallmentLoading,
    isError: isInstallmentError
  } = useInstallmentPlans({ installmentPlanId: summary?.installmentPlanId, params: { installmentNumber } })

  const isLoading = isSummaryLoading || isInstallmentLoading

  useEffectOnce(() => {
    if (!usePreviousSummary) installmentDetailsAnalitica.events.installmentDetails.view(loanId ?? '')
    else installmentDetailsAnalitica.events.originalContract.view(loanId ?? '')
  })

  if (isInstallmentError || isSummaryError) return <SharedGenericCardError />

  const installment = installmentList?.items[0]

  if ((!installment && !isLoading) || (installment && installment.status === 'Future' && installment.paidAmount === 0))
    BlackBird.travelTo({ pathname: '/credito/emprestimo/${loan}', urlParams: { loan: loanId } })

  const isZeroTax = Boolean(hasRenegotiation && !usePreviousSummary && summary && isZeroTaxRenegotiation(summary))

  return (
    <Box>
      <Stack space="1.5rem">
        <LoanInstallmentDetailsHeader installmentNumber={installmentNumber} />
        <If condition={hasRenegotiation}>
          <Alert color="info" dismissible={false}>
            No empréstimo renegociado, as parcelas do seu contrato original ficam inativadas.
          </Alert>
        </If>
        <LoanInstallmentDetailsSummary
          installment={installment}
          numberOfInstallments={summary?.numberOfInstallments}
          isLoading={isLoading}
          isZeroTaxRenegotiation={isZeroTax}
        />
      </Stack>
      <LoanInstallmentDetailsList
        installmentPlanId={summary?.installmentPlanId}
        installmentNumber={installmentNumber}
        renegotiationLoan={usePreviousSummary}
        hideInterestEconomies={isZeroTax}
      />
    </Box>
  )
}
