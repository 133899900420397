import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { APP_VERSION, DEPLOY_TYPE } from 'shared/envs'

import {
  SWH_ROUTES_CATALOG_SOURCE as SWH_URL_CATALOG,
  SWH_ROUTES_CLIENTS,
  SWH_ROUTES_INVOICE,
  SWH_ROUTES_INVOICE_SETTINGS,
  SWH_ROUTES_METRICS,
  SWH_ROUTES_SALES
} from './swh.routes'

export enum SwhFlag {
  ALL = 'conta-stone-swh',
  CATALOG = 'conta-stone-swh-catalog'
}

type FlagPermission = {
  routes: string[]
}

const flagPermissions: Record<SwhFlag, FlagPermission> = {
  [SwhFlag.ALL]: {
    routes: [
      ...SWH_URL_CATALOG,
      ...SWH_ROUTES_INVOICE,
      ...SWH_ROUTES_INVOICE_SETTINGS,
      ...SWH_ROUTES_SALES,
      ...SWH_ROUTES_CLIENTS,
      ...SWH_ROUTES_METRICS
    ]
  },
  [SwhFlag.CATALOG]: {
    routes: SWH_URL_CATALOG as unknown as string[]
  }
}

export class SwhExperimentFlags {
  private swhFlags: Set<SwhFlag> = new Set()
  private readonly activeVariantValue = 'on'

  constructor(experimentData: ExperimentValues | undefined) {
    Object.values(SwhFlag).forEach(flag => {
      if (experimentData?.[flag]?.value === this.activeVariantValue) {
        this.swhFlags.add(flag)
      }
    })
  }

  checkActiveFlagByPathname(pathname: string): boolean {
    const cleanPath = DEPLOY_TYPE() === 'preview' ? pathname.replace(`/${APP_VERSION()}`, '') : pathname
    const pathNameCurrent = cleanPath.replace(/^\/_/, '')
    const dynamicParams = /\${[^}]+}/
    for (const key in flagPermissions) {
      if (!this.swhFlags.has(key as SwhFlag)) continue
      for (const route of flagPermissions[key as SwhFlag].routes) {
        if (dynamicParams.test(route)) {
          const dynamicRoute = route.replace(dynamicParams, '[^/]+')
          const regex = new RegExp(`^${dynamicRoute}$`)
          if (regex.test(pathNameCurrent)) {
            return true
          }
        } else if (route === pathNameCurrent) {
          return true
        }
      }
    }
    return false
  }
}
