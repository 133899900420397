import { useRouter } from 'next/router'

import { DiscountSettlementProvider } from '../../context/discount-settlement-context'

import { type RenegotiationData, DiscountSettlementSteps } from '../../entities'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'

import { RenegotiationDiscountAmountFlowBase } from './discount-settlement-amount-flow-base'

export interface DiscountSettlementFlowProps {
  renegotiationData: RenegotiationData
  userEmail: string
}

export function RenegotiationDiscountAmountFlow(props: DiscountSettlementFlowProps) {
  const { step }: { step?: string; loan?: string } = useRouter().query

  if (!step) renegotiationNavigate({ step: DiscountSettlementSteps.PROPOSAL })

  return (
    <DiscountSettlementProvider>
      <RenegotiationDiscountAmountFlowBase {...props} />
    </DiscountSettlementProvider>
  )
}
