import { useEffectOnce } from 'react-use'
import Image from 'next/image'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Text } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'

type SharedErrorGeneratingDocumentProps = {
  onErrorConfirm?: () => void
  onErrorView?: () => void
}
const SharedErrorGeneratingDocument = ({ onErrorConfirm, onErrorView }: SharedErrorGeneratingDocumentProps) => {
  useEffectOnce(() => {
    if (onErrorView) onErrorView()
  })

  return (
    <>
      <Box textAlign="center" width="100%" height="300px" alignContent="center">
        <Stack space="1.5rem">
          <Image src="/document-error.svg" alt={''} width={150} height={150} style={{ margin: '0 auto' }} />
          <Stack space=".5rem">
            <Text variant="heading-medium">Não conseguimos gerar seu documento</Text>
            <Text variant="text-medium" color="high">
              Tente de novo ou converse com a gente.
            </Text>
          </Stack>
        </Stack>
      </Box>

      <Button variant="neutral-subtle" onClick={onErrorConfirm} size="large">
        Conversar com a Stone
      </Button>
    </>
  )
}
export { SharedErrorGeneratingDocument }
