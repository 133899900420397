import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Heading } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { type AnswerChallenge, httpWithChallenge } from '~/domains/platform/infra/http/http-with-challenge'
import { useToast } from '~/domains/platform/layout/toast'
import { ChallengeForm } from '~/ui/business-components/shared'

import { httpClientCreditNegotiations } from '../../../loan-concession/infra/http-client-credit-negotiations'
import { type SpotOffer } from '../../../spot-offer/entities'
import { useDiscountSettlementContext } from '../../context/discount-settlement-context'

import { RenegotiationPaymentDialog } from './renegotiation-payment-dialog'

export function RenegotiationChallenge({ saveSpotOffer }: { saveSpotOffer: (spotOffer?: SpotOffer) => void }) {
  const { addToast } = useToast()
  const { paymentMethod, contractId } = useDiscountSettlementContext()

  const [isDialogOpen, setDialogOpen] = useToggle(false)

  function handleRequest(payload?: AnswerChallenge) {
    return httpWithChallenge({
      config: {
        url: `/contracts-service/api/v1/contracts/${contractId}/sign`,
        method: 'POST'
      },
      client: httpClientCreditNegotiations
    })(payload)
  }

  return (
    <>
      <Stack space="2rem">
        <Heading size="xLarge" weight="semi">
          Renegociar empréstimo
        </Heading>

        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <ChallengeForm
            onRequest={handleRequest}
            onError={() => {
              addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
            }}
            onSuccess={() => setDialogOpen()}
          />
        </Flex>
      </Stack>
      <RenegotiationPaymentDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen()}
        paymentMethod={paymentMethod ?? 'Pix'}
        saveSpotOffer={saveSpotOffer}
      />
    </>
  )
}
