import {
  type PropsWithChildren,
  type ReactElement,
  type ReactNode,
  isValidElement,
  useEffect,
  useRef,
  useState
} from 'react'
import { useToggle } from 'react-use'
import { Center } from '@dlpco/fluid-layout'
import { Icon } from '@dlpco/ginga-stone'

import { Divider } from '~/domains/platform/design-system/divider/divider'

import { Choose, If } from '../../lib/utilities-components'
import { Box, Text } from '../index'

import {
  ContentWrapper,
  OpennessIndicator,
  TriggerButtonWrapper,
  TriggerContentWrapper,
  TriggerWrapper,
  Wrapper
} from './styles'
import { TurnIcon } from './turn-icon'

interface AccordionProps {
  trigger: ReactElement<ReactNode> | ((opened: boolean) => ReactElement<ReactNode>)
  onTrigger?: (closed: boolean) => Promise<void>
  loading?: boolean
  space?: string
  withBorder?: boolean
  variant?: string
  rounder?: boolean
  withIndicator?: boolean
  withDivider?: boolean
  inicialOpen?: boolean
  dividerBackgroundColor?: string
  dividerOpacity?: number
  openIndicator?: boolean
  disableTrigger?: boolean
  disableClick?: boolean
  isOpen?: boolean
  onOpen?: (status: boolean) => void
}

export function Accordion({
  trigger,
  onTrigger,
  space = '2rem',
  withBorder,
  children,
  variant,
  rounder = false,
  withIndicator = true,
  withDivider = true,
  inicialOpen = false,
  dividerBackgroundColor,
  dividerOpacity,
  openIndicator = true,
  disableTrigger = false,
  disableClick = true,
  isOpen,
  onOpen
}: PropsWithChildren<AccordionProps>) {
  const [open, toggleOpen] = useToggle(inicialOpen)
  const [loading, toggleLoading] = useToggle(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const [maxHeight, setMaxHeight] = useState<string>()

  const onClick = async () => {
    toggleOpen(!open)

    if (onOpen) {
      onOpen(!open)
    }

    if (onTrigger) {
      toggleLoading()
      await onTrigger(!open)
      toggleLoading()
    }
  }

  useEffect(() => {
    if (isOpen != undefined) toggleOpen(isOpen)
  }, [isOpen, toggleOpen])

  useEffect(() => {
    setMaxHeight(open ? `${contentRef?.current?.scrollHeight}px` : undefined)
  }, [contentRef, open, loading])

  return (
    <Wrapper withBorder={withBorder} variant={variant} rounder={rounder} disableTrigger={disableTrigger}>
      <TriggerWrapper
        noWrap
        style={{ position: openIndicator ? 'relative' : 'initial' }}
        space={space}
        justify="space-between"
        align="center"
        onClick={disableClick ? onClick : undefined}
        triggered={open}
        rounder={rounder}
      >
        <Box>
          <If condition={variant === 'secondary' && openIndicator}>
            <OpennessIndicator isOpen={open} />
          </If>
          <TriggerContentWrapper variant={variant}>
            {isValidElement(trigger) ? trigger : trigger(open)}
          </TriggerContentWrapper>
          <If condition={withIndicator}>
            <TriggerButtonWrapper variant={variant} data-testid="accordion-button">
              <TurnIcon turn={open} disableTrigger={disableTrigger}>
                <Icon use="chevron-down-outline" />
              </TurnIcon>
            </TriggerButtonWrapper>
          </If>
        </Box>
      </TriggerWrapper>
      <If condition={withDivider}>
        <Divider backgroundColor={dividerBackgroundColor} opacity={dividerOpacity} />
      </If>
      <ContentWrapper rounder={rounder} ref={contentRef} maxHeight={maxHeight}>
        <Choose>
          <Choose.When
            condition={Boolean(loading)}
            render={() => (
              <Center>
                <Text color="mediumGray" py="2rem" fontSize="medium">
                  Carregando...
                </Text>
              </Center>
            )}
          />
          <Choose.Otherwise render={() => <Box> {children} </Box>} />
        </Choose>
      </ContentWrapper>
    </Wrapper>
  )
}
