import { useState } from 'react'
import { useEffectOnce, useToggle } from 'react-use'
import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Badge, Button, Card, CardBody, Heading, theme } from '@dlpco/ginga-stone'

import { type RenegotiationProposalId, RenegotiationSteps } from '../../entities'
import renegotiationAnalitica from '../../helpers/renegotiation-analitica'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'
import { type ItemContent, SharedCreditListBox } from '~/domains/credit/shared/components/credit-list-box'
import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { chat } from '~/domains/platform/infra/chat/ports/chat'
import { useToast } from '~/domains/platform/layout/toast'

import { useCheckProposalUpdateTime } from '../../../loan-concession/hooks/use-check-proposal-update-time'
import { updateProposalCustomer } from '../../../loan-concession/services/proposal'

export function RenegotiationProposal({
  renegotiation,
  proposalRefetch,
  showInstallmentsPlan
}: {
  renegotiation: RenegotiationProposalId
  proposalRefetch: () => void
  showInstallmentsPlan: () => void
}) {
  const {
    proposal,
    current: { productSummary, conditions: currentConditions }
  } = renegotiation
  const { conditions, plans } = proposal

  const [isLoading, toggleLoading] = useToggle(false)

  const { addToast } = useToast()
  const [proposalPollingIsActive, setProposalPollingIsActive] = useState(false)

  useCheckProposalUpdateTime(
    {
      proposalId: renegotiation?.id as string,
      lastUpdateDate: renegotiation.customer.lastUpdateDate,
      onSuccess: () => {
        toggleLoading(false)
        proposalRefetch()
        renegotiationNavigate({ step: RenegotiationSteps.KYC })
      },
      onError: () => {
        toggleLoading(false)
        addToast({ message: 'Ocorreu um erro durante o processo de confirmação dos dados', type: 'error' })
      }
    },
    {
      enabled: proposalPollingIsActive
    }
  )

  const openChat = () => {
    renegotiationAnalitica.events.proposal.click('conversar com a stone')
    chat.open()
  }

  const onContinue = async () => {
    renegotiationAnalitica.events.proposal.click('revisar dados pessoais')

    toggleLoading(true)
    await updateProposalCustomer(renegotiation?.id)
    setProposalPollingIsActive(true)
  }

  const regularInstallments = plans.filter(plan => plan.type.value === 'regular')
  const graceInstallments = plans.filter(plan => plan.type.value === 'grace_with_interest')
  const totalRowSpan = Math.max(0, regularInstallments.length + graceInstallments.length - 1)

  const initialValues = [
    {
      title: 'Valor renegociado',
      value: productSummary.currentDebtAmount.description,
      description: 'Valor total do contrato, incluindo o saldo em aberto e cobranças por atraso.'
    },
    {
      title: 'Parcelado em',
      value: currentConditions.installments.description,
      showBorder: false
    },
    {
      title: 'Valor da parcela',
      value: currentConditions.lowestInstallment.description,
      showBorder: totalRowSpan === 0
    },
    // Rows span
    ...Array.from({ length: totalRowSpan }).map((_, index) => ({
      title: ' ',
      value: ' ',
      condition: Boolean(graceInstallments),
      showBorder: index === totalRowSpan - 1
    })),
    {
      title: 'Juros ao mês',
      value: currentConditions.interestRate.description
    },
    {
      title: 'Juros ao ano',
      value: currentConditions.annualInterestRate.description
    },
    {
      title: 'Forma de pagamento',
      value: `Retenção diária`
    },
    {
      title: 'Percentual de retenção',
      value: `${currentConditions.retentionRate.description} dos recebimentos`
    },
    {
      title: 'Contrato vence em',
      value: productSummary.dueDate.description
    },
    {
      title: 'IOF financiado',
      value: currentConditions.taxAmount.description,
      description: 'O IOF é um imposto federal aplicado sobre operações financeiras.'
    },
    {
      title: 'CET',
      value: `${currentConditions.annualTotalEffectiveCostRate.description} ao ano`,
      description: 'O CET é a soma de todos os custos da operação de empréstimo, ou seja, juros e IOF.'
    }
  ].filter(Boolean) as ItemContent[]

  const changedValues = [
    {
      title: 'Total a pagar',
      value: conditions.totalSum.description,
      color: 'primary'
    },
    {
      title: 'Parcelado em',
      value: conditions.installments.description,
      color: 'primary',
      showBorder: regularInstallments.length === 0
    },
    ...graceInstallments.map(installment => ({
      title: `${installment.installments.value} parcelas de`,
      value: installment.amountByInstallment.description,
      description: 'Parcelas referentes aos juros acumulados sobre o tempo de carência.',
      color: 'primary',
      showBorder: graceInstallments.length === 0
    })),
    ...regularInstallments.map((installment, i) => ({
      title: `${installment.installments.value} parcelas de`,
      value: installment.amountByInstallment.description,
      color: 'primary',
      showBorder: i === graceInstallments.length - 1
    })),
    {
      title: 'Juros ao mês',
      value: conditions.interestRate.value > 0 ? conditions.interestRate.description : 'Sem juros',
      color: 'primary'
    },
    {
      title: 'Juros ao ano',
      value: conditions.annualInterestRate.value > 0 ? conditions.annualInterestRate.description : 'Sem juros',
      color: 'primary'
    },
    {
      title: 'Forma de pagamento',
      value: `Retenção diária`,
      color: 'primary'
    },
    {
      title: 'Percentual de retenção',
      value: `${conditions.retentionRate.description} dos recebimentos`,
      color: 'primary'
    },
    {
      title: 'Contrato vence em',
      value: conditions.dueDate.description,
      color: 'primary'
    },
    {
      title: 'IOF financiado',
      value: conditions.taxAmount.value > 0 ? conditions.taxAmount.description : 'Sem IOF',
      description: 'O IOF é um imposto federal aplicado sobre operações financeiras.',
      color: 'primary'
    },
    {
      title: 'CET',
      value:
        conditions.annualTotalEffectiveCostRate.value > 0
          ? `${conditions.annualTotalEffectiveCostRate.description} ao ano`
          : 'Sem taxas',
      description: 'O CET é a soma de todos os custos da operação de empréstimo, ou seja, juros e IOF.',
      color: 'primary'
    }
  ] as ItemContent[]

  useEffectOnce(() => renegotiationAnalitica.events.proposal.view())

  return (
    <>
      <Stack space={theme.light.space.large}>
        <Card spacing="large">
          <CardBody>
            <Heading size="large" weight="semi">
              Você recebeu uma proposta especial de renegociação
            </Heading>
            <Badge icon="round-clock-outline" color="warning" style={{ marginTop: theme.light.space.xSmall }}>
              Expira em {formatDate(renegotiation.expirationDate, 'dateAndMonth')}
            </Badge>

            <Flex justifyContent="space-between" mt={theme.light.space.xLarge}>
              <Box width="48%" data-testid="after">
                <SharedCreditListBox
                  title="Como ficou"
                  items={changedValues}
                  button={
                    <Button onClick={() => showInstallmentsPlan()} size="small" color="neutral">
                      Conferir mês a mês
                    </Button>
                  }
                />
              </Box>
              <Box width="48%" data-testid="before">
                <SharedCreditListBox title="Como era" items={initialValues} />
              </Box>
            </Flex>

            <Flex justifyContent="flex-end" gap="1rem" mt={theme.light.space.xLarge}>
              <Button color="neutral" onClick={openChat} shape="pill">
                Conversar com a Stone
              </Button>
              <Button
                icon="direction-arrow-right-outline"
                iconPosition="end"
                onClick={onContinue}
                shape="pill"
                data-test-id="renegotiation-flow-proposal-step-continue-button"
              >
                Revisar dados pessoais
              </Button>
            </Flex>
          </CardBody>
        </Card>
      </Stack>
      {isLoading && (
        <Dimmer isVisible>
          <Loader />
        </Dimmer>
      )}
    </>
  )
}
