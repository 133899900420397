import { useRouter } from 'next/router'

import { type RenegotiationProposalId, RenegotiationSteps } from '../../entities'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'

import { RenegotiationFlowLoading } from './renegotiation-flow-loading'
interface RenegotiationFlowGuardProps {
  proposal?: RenegotiationProposalId
  children: React.ReactNode
}

export function RenegotiationFlowGuard({ children, proposal }: RenegotiationFlowGuardProps) {
  const { step }: { step?: string } = useRouter().query

  if (!step) {
    if (proposal?.status.value === 'processing') renegotiationNavigate({ step: RenegotiationSteps.REVIEW })
    if (proposal?.status.value === 'available') renegotiationNavigate({ step: RenegotiationSteps.PROPOSAL })
    return <RenegotiationFlowLoading showTitle={false} />
  }

  return <>{children}</>
}
