import { useEffectOnce, useToggle } from 'react-use'
import { formatDate } from '@credit-web/common/date-utils'
import { Stack } from '@dlpco/fluid-layout'
import { Alert, Button, Checkbox, Heading, Icon, Money, Overline, Text, Tooltip } from '@dlpco/ginga-stone'

import { useDiscountSettlementContext } from '../../context/discount-settlement-context'
import { DiscountSettlementSteps } from '../../entities'
import discountSettlementAnalitica from '../../helpers/discount-settlement-analitica'
import { renegotiationNavigate } from '../../helpers/renegotiation-navigate'
import { useRenegotiationSign } from '../../hooks/use-renegotiation-sign'
import { CustomBox, CustomDivider } from '../../../shared/components/shared-custom-styles'
import { SharedPaymentOptionSelector } from '../../../shared/components/shared-payment-option-selector'
import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useToast } from '~/domains/platform/layout/toast'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'

import { getMaxConditionfromDiscountAmount } from '../../helpers/discount-settlement'
import {
  acceptRenegotiation,
  createRenegotiationsProposal,
  simulateRenegotiationProposal
} from '../../services/renegotiation'

type StepMode = 'paymentMethodSelection' | 'paymentLoanConcessionReview'

type RenegotiationDiscountAmountSimulationProps = {
  mode: StepMode
  userEmail: string
}

export function RenegotiationDiscountAmountSimulation({ mode, userEmail }: RenegotiationDiscountAmountSimulationProps) {
  const [enableSign, toggleEnableSign] = useToggle(false)
  const [acceptedTerms, toggleAcceptedTerms] = useToggle(false)
  const { addToast } = useToast()
  const addErrorToast = () => addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })

  const {
    offer,
    proposal,
    installments,
    simulation,
    saveSimulation,
    savePaymentMethod,
    paymentMethod,
    saveProposal,
    saveContractId,
    contractId: contextContractId
  } = useDiscountSettlementContext()

  const [isContentLoading, toggleContentLoading] = useToggle(false)

  const isPaymentReview = mode === 'paymentLoanConcessionReview'
  const isPaymentMethodSelection = mode === 'paymentMethodSelection'

  const { data: contractData } = useRenegotiationSign({ proposalId: proposal?.id ?? '', enabled: enableSign }) || {}

  const { contractId, state: contractPollingState, resetCount: resetContractPollingCount } = contractData || {}

  if (contractPollingState == 'ERROR' && isContentLoading) {
    toggleContentLoading(false)
    addErrorToast()
  }

  if (contractId && !contextContractId) {
    saveContractId(contractId)
    renegotiationNavigate({ step: DiscountSettlementSteps.PIN })
  }

  const fetchSimulationData = async () => {
    toggleContentLoading(true)
    const baseProps = {
      offerId: offer?.id || '',
      installments: offer?.discountSettlement?.securities[0].installmentNumbers || [],
      amount: offer ? getMaxConditionfromDiscountAmount(offer) : 0
    }
    const props = paymentMethod ? { ...baseProps, paymentMethod } : baseProps
    await simulateRenegotiationProposal(props)
      .then(response => {
        const { data } = response
        saveSimulation(data)
        toggleContentLoading(false)
      })
      .catch(() => {
        toggleContentLoading(false)
        addErrorToast()
      })
  }

  if (isPaymentMethodSelection && offer && !simulation && !isContentLoading) {
    fetchSimulationData()
  }

  useEffectOnce(() => {
    if (isPaymentMethodSelection) discountSettlementAnalitica.events.simulation.view('DiscountAmount')
    if (isPaymentReview) discountSettlementAnalitica.events.proposal.view()
  })

  const debtAmount =
    (isPaymentReview ? proposal?.discountSettlement?.negotiationDebtAmount : simulation?.negotiationDebtAmount) ?? 0
  const amount = (isPaymentReview ? proposal?.discountSettlement?.paymentMethod?.amount : simulation?.amount) ?? 0
  const isButtonDisabled = isPaymentMethodSelection ? !paymentMethod || !acceptedTerms : !acceptedTerms
  const disabledButtonText = proposal
    ? 'Escolha a forma de pagamento e aceite a condição para continuar'
    : 'Aceite a condição para continuar'
  const dueDate = (
    isPaymentReview ? proposal?.discountSettlement?.paymentMethod?.dueDate : simulation?.dueDate
  ) as string

  async function handlePaymentMethodSelectionClick() {
    if (isPaymentReview) {
      discountSettlementAnalitica.events.proposal.clicked('continuar')
    }
    toggleContentLoading(true)
    let proposalToAccept = proposal
    if (simulation && paymentMethod) {
      await createRenegotiationsProposal({
        offerId: offer?.id || '',
        installmentNumbers: simulation.installmentNumbers,
        paymentMethod: paymentMethod,
        amount: offer ? getMaxConditionfromDiscountAmount(offer) : 0,
        email: userEmail || ''
      })
        .then(response => {
          const { data } = response
          saveProposal(data)
          proposalToAccept = data
        })
        .catch(() => toggleContentLoading(false))
    }
    await acceptRenegotiation({
      proposalId: proposalToAccept?.id || '',
      onSuccess: () => {
        resetContractPollingCount()
        toggleEnableSign(true)
      },
      onError: () => {
        addToast({ message: 'Tivemos um problema, tente novamente', type: 'error' })
        toggleContentLoading(false)
      }
    })
  }
  return (
    <>
      <Flex flexDirection="column" gap="1.5rem">
        <CustomBox>
          <Stack space="1rem">
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="neutral">Total a pagar</Text>
              <Flex flexDirection="column" alignItems="end">
                <Text color="neutral" size="xSmall" style={{ textDecoration: 'line-through' }}>
                  <Money amount={debtAmount} />
                </Text>
                <Heading size="xSmall" color="primary" weight="semi">
                  <Money amount={amount} />
                </Heading>
              </Flex>
            </Flex>

            <CustomDivider />

            <Flex alignItems="center" justifyContent="space-between">
              <Text color="neutral">Você está quitando</Text>
              <Text weight="semi">{installments?.length} parcelas</Text>
            </Flex>

            <Box pl="1rem">
              <Stack space="1rem">
                <For
                  of={installments ?? []}
                  render={installment => (
                    <Flex key={installment.id} alignItems="center" justifyContent="space-between">
                      <Text color="neutral">Parcela {installment.installmentNumber}</Text>
                      <Text weight="semi" color="neutral">
                        <Money amount={Number(installment.presentBalance)} />
                      </Text>
                    </Flex>
                  )}
                />
              </Stack>
            </Box>

            <CustomDivider />

            <Choose>
              <Choose.When condition={isPaymentMethodSelection}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex alignItems="center" gap=".3rem">
                    <Text color="neutral">Desconto</Text>
                    <Tooltip content="O desconto inclui o valor de juros economizados, se houver.">
                      <Icon use="round-info-outline" color="neutral" size="small" />
                    </Tooltip>
                  </Flex>
                  <Text weight="semi" color="primary">
                    <Money amount={Number(simulation?.discountAmount)} />
                  </Text>
                </Flex>
              </Choose.When>
              <Choose.When condition={!isPaymentMethodSelection}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="neutral">Forma de pagamento</Text>
                  <Text weight="semi">{proposal?.discountSettlement?.paymentMethod?.type}</Text>
                </Flex>
              </Choose.When>
            </Choose>
            <If condition={Boolean(dueDate)}>
              <CustomDivider />
              <Flex alignItems="center" justifyContent="space-between">
                <Text color="neutral">Vence em</Text>
                <Text weight="semi">{formatDate(dueDate)}</Text>
              </Flex>
              <Box pt="1.5rem">
                <Alert color="warning">
                  <Text size="small">
                    O desconto vale para pagamento até a data de vencimento. Se passar da data, você perde o desconto.
                  </Text>
                </Alert>
              </Box>
            </If>
          </Stack>
        </CustomBox>
        <Choose>
          <Choose.When condition={isPaymentMethodSelection}>
            <Stack space=".5rem">
              <Overline color="neutral">Como você quer pagar?</Overline>
              <SharedPaymentOptionSelector
                paymentOption={paymentMethod || undefined}
                setPaymentOption={selectedPaymentMethod => {
                  discountSettlementAnalitica.events.payment.clicked(selectedPaymentMethod)
                  savePaymentMethod(selectedPaymentMethod)
                  if (offer && selectedPaymentMethod != paymentMethod) fetchSimulationData()
                }}
              />
            </Stack>
          </Choose.When>
        </Choose>

        <Flex gap="1rem">
          <Checkbox onChange={toggleAcceptedTerms} />
          <Text>
            <b>Aceito</b> as condições anteriores e <b>estou ciente</b> de que o desconto só se aplica se eu pagar o
            valor total até a data de vencimento.
          </Text>
        </Flex>

        <Button
          data-testid="next-step-button"
          disabled={isButtonDisabled}
          shape="pill"
          iconPosition="end"
          {...(!isButtonDisabled && { icon: 'direction-arrow-right-outline' })}
          onClick={handlePaymentMethodSelectionClick}
        >
          {isButtonDisabled ? disabledButtonText : 'Ir para o pagamento'}
        </Button>
      </Flex>

      <Dimmer isVisible={isContentLoading}>
        <Loader />
      </Dimmer>
    </>
  )
}
