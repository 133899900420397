import { AccordionItem, Divider, GroupHeader, ListItem, Text } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Box } from '~/domains/platform/design-system'
import { Choose, If } from '~/domains/platform/lib/utilities-components'

import { type LoanDetails } from '../entities'

import { ColorIndicator } from './loan-details-jade-color-indicator'
import { DynamicProgressBar } from './loan-details-jade-dynamic-progressbar'

const themeColors = jadeTheme.theme.color

export const getDynamicProgressBarValues = (generalInformation: LoanDetails['generalInformation']) => {
  const currentInfo = []

  if (generalInformation.totalPaid && generalInformation.totalPaid.value) {
    currentInfo.push({
      currentValue: generalInformation.totalPaid.value,
      maxValue: generalInformation.remainingAmount.value + generalInformation.totalPaid.value,
      surfaceColor: themeColors.background.brand
    })
  }

  if (generalInformation.interestEconomyAmount && generalInformation.interestEconomyAmount.value) {
    currentInfo.push({
      currentValue: generalInformation.interestEconomyAmount.value,
      maxValue: generalInformation.remainingAmount.value + generalInformation.totalPaid.value,
      surfaceColor: themeColors.background.info
    })
  }

  if (generalInformation.taxAndInterestAmount && generalInformation.taxAndInterestAmount.value) {
    currentInfo.push({
      currentValue: generalInformation.taxAndInterestAmount.value,
      maxValue: generalInformation.remainingAmount.value + generalInformation.totalPaid.value,
      surfaceColor: themeColors.background.negative
    })
  }

  currentInfo.push({
    currentValue: 'fill' as number | 'fill',
    maxValue: 100,
    surfaceColor: themeColors.background.body
  })

  return currentInfo
}

export function LoanDetailsJadeAsideGeneralLoanInformations({
  generalInformation
}: {
  generalInformation: LoanDetails['generalInformation']
}) {
  const shouldHideOverdue = Boolean(
    generalInformation.status !== 'overdue' &&
      generalInformation.status !== 'paused' &&
      generalInformation?.taxAndInterestAmount?.value &&
      generalInformation?.taxAndInterestAmount?.value > 0
  )

  return (
    <Box pt="0rem" pb="0.75rem">
      <GroupHeader title="Informações gerais" />

      <DynamicProgressBar
        progressBarList={getDynamicProgressBarValues(generalInformation)}
        style={{
          margin: '1.25rem 1.25rem 1rem',
          height: '6px',
          gap: '4px',
          borderRadius: jadeTheme.global['corner-radius'].pill
        }}
      />
      <Box paddingRight={shouldHideOverdue ? `2rem` : 0}>
        <ListItem
          content={<Text color="medium">Valor inicial</Text>}
          trailing={<Text color="medium">{generalInformation.creditAmount?.description || 0}</Text>}
          variant="neutral"
        />

        <Divider />
        <ListItem
          leading={<ColorIndicator color={themeColors.background.positive} />}
          content={<Text color="medium">Quitado</Text>}
          trailing={<Text color="medium">{generalInformation.totalPaid.description}</Text>}
          variant="neutral"
        />
        <If condition={Boolean(generalInformation.interestEconomyAmount)}>
          <ListItem
            leading={<ColorIndicator color={themeColors.background.info} />}
            content={
              <Box maxWidth="4rem">
                <Text color="medium">Economia de juros</Text>
              </Box>
            }
            trailing={<Text color="medium">{generalInformation.interestEconomyAmount?.description}</Text>}
            variant="neutral"
          />
        </If>
      </Box>
      <Choose>
        <Choose.When condition={shouldHideOverdue}>
          <Divider />
          <AccordionItem
            title="Restante"
            leading={<ColorIndicator color={themeColors.background.body} />}
            trailing={
              <Text color="high" weight="semibold">
                {generalInformation.remainingAmount.description}
              </Text>
            }
            hasSafeArea={false}
          >
            <ListItem
              leading={<ColorIndicator color={themeColors.background.negative} />}
              content={<Text color="medium">Multa e juros</Text>}
              trailing={<Text color="medium">{generalInformation.taxAndInterestAmount?.description}</Text>}
              variant="neutral"
            />
          </AccordionItem>
        </Choose.When>

        <Choose.Otherwise>
          <If
            condition={Boolean(
              generalInformation.taxAndInterestAmount && generalInformation.taxAndInterestAmount.value
            )}
          >
            <ListItem
              leading={<ColorIndicator color={themeColors.background.negative} />}
              content={<Text color="medium">Multa e juros</Text>}
              trailing={<Text color="medium">{generalInformation.taxAndInterestAmount?.description}</Text>}
              variant="neutral"
            />
          </If>
          <Divider />
          <ListItem
            leading={<ColorIndicator color={themeColors.background.body} />}
            content={<Text color="medium">Restante</Text>}
            trailing={
              <Text color="high" weight="semibold">
                {generalInformation.remainingAmount.description}
              </Text>
            }
            variant="neutral"
          />
        </Choose.Otherwise>
      </Choose>
    </Box>
  )
}
