import { useToggle } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { type IconId, Button, Card, CardBody, CardDivider, CardHeader, Heading, Text, theme } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

type LoanHomeInfoCardsProps = {
  flexDirection: 'row' | 'column'
}

export function LoanHomeInfoCards({ flexDirection = 'row' }: LoanHomeInfoCardsProps) {
  const cardStyle =
    flexDirection === 'column'
      ? {
          height: 'fit-content'
        }
      : {}
  const cardWidth = flexDirection === 'row' ? '33%' : '100%'
  const [showInfo, toggleShowInfo] = useToggle(true)
  const toggleButtonLabel = !showInfo ? 'Mostrar' : 'Ocultar'
  const toggleButtonIcon: IconId = !showInfo ? 'caret-down-solid' : 'caret-up-solid'
  return (
    <Card color="neutralOverlay" spacing="xSmall" style={cardStyle}>
      <CardBody>
        <Stack space="1rem">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading aria-label={`Saiba mais sobre o capital de giro`} size="xSmall" weight="bold">
              Saiba mais
            </Heading>
            <Button
              variant="contentOnly"
              color="neutral"
              iconPosition="end"
              icon={toggleButtonIcon}
              size="small"
              onClick={() => toggleShowInfo()}
              aria-label={`${toggleButtonLabel} informações sobre o capital de giro`}
            >
              {toggleButtonLabel}
            </Button>
          </Flex>
          <If condition={showInfo}>
            <Flex justifyContent="space-between" flexDirection={flexDirection} gap="1.25rem">
              <Card style={{ width: cardWidth }} spacing="xSmall" elevation={2}>
                <CardHeader>Pague um pouco todos os dias</CardHeader>
                <CardDivider style={{ border: `1px dashed ${theme.light.color.borderLow}`, margin: '0' }} />
                <CardBody>
                  <Text color="neutral">
                    Quite o empréstimo sem sentir: a gente envia automaticamente parte dos seus recebimentos diários
                    para o pagamento das parcelas.
                  </Text>
                </CardBody>
              </Card>
              <Card style={{ width: cardWidth }} spacing="xSmall" elevation={2}>
                <CardHeader>Parcelas iguais até quitar</CardHeader>
                <CardDivider style={{ border: `1px dashed ${theme.light.color.borderLow}`, margin: '0' }} />
                <CardBody>
                  <Text color="neutral">
                    O mesmo valor todo mês pra você ter controle total de quanto está pagando e economizando.
                  </Text>
                </CardBody>
              </Card>
              <Card style={{ width: cardWidth }} spacing="xSmall" elevation={2}>
                <CardHeader>Economize juros</CardHeader>
                <CardDivider style={{ border: `1px dashed ${theme.light.color.borderLow}`, margin: '0' }} />
                <CardBody>
                  <Text color="neutral">
                    Toda retenção automática feita antes de vencer a parcela economiza juros e acelera o pagamento do
                    seu empréstimo.
                  </Text>
                </CardBody>
              </Card>
            </Flex>
          </If>
        </Stack>
      </CardBody>
    </Card>
  )
}
