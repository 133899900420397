import { Box, Stack } from '@dlpco/fluid-layout'
import { type IconId, CardDivider, Icon, Radio, Text } from '@dlpco/ginga-stone'

import { Flex } from '~/domains/platform/design-system'
import { For, If } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { type RenegotiationPaymentMethod } from '../../renegotiation/entities'

import { CustomBox } from './shared-custom-styles'

interface PaymentOptionsMap {
  icon: IconId
  title: string
  description: string
  paymentMethod: RenegotiationPaymentMethod
  buttonLabel: string
}
const options: PaymentOptionsMap[] = [
  {
    icon: 'pix-outline',
    title: 'Com Pix',
    description: 'O prazo para pagar é de 1 dia útil e a confirmação do pagamento é na hora.',
    paymentMethod: 'Pix',
    buttonLabel: 'Criar Pix QR Code'
  },
  {
    icon: 'code-bar-outline',
    title: 'Com boleto',
    description: 'Prazo de 2 dias úteis para pagar, com confirmação do pagamento em até 3 dias úteis.',
    paymentMethod: 'Boleto',
    buttonLabel: 'Criar boleto'
  }
]

export function SharedPaymentOptionSelector({
  paymentOption,
  setPaymentOption
}: {
  paymentOption?: RenegotiationPaymentMethod
  setPaymentOption: (value: RenegotiationPaymentMethod) => void
}) {
  return (
    <CustomBox>
      <Stack space="1rem">
        <For
          of={options}
          render={({ icon, title, description, paymentMethod }, index) => (
            <Stack space="1rem" key={index}>
              <Box as="label" htmlFor={paymentMethod} style={{ cursor: 'pointer' }}>
                <Flex gap="1rem" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Icon use={icon} color="neutral" size="large" />
                  </Box>
                  <Box style={{ flexGrow: '1' }}>
                    <Stack>
                      <Text size="large" weight="semi">
                        {title}
                      </Text>
                      <Text color="neutral">{description}</Text>
                    </Stack>
                  </Box>
                  <Radio
                    id={paymentMethod}
                    role="radio"
                    aria-label={title}
                    name="paymentMethod"
                    checked={paymentMethod === paymentOption}
                    onChange={() => setPaymentOption(paymentMethod)}
                  />
                </Flex>
              </Box>
              <If condition={index < options.length - 1}>
                <CardDivider style={{ border: `1px solid ${theme.colors.border.disabled}`, margin: '0' }} />
              </If>
            </Stack>
          )}
        />
      </Stack>
    </CustomBox>
  )
}
