import { type ReactNode } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { type TextColor } from '@dlpco/ginga-components/cjs/components/text/props/types'
import { Icon, Overline, Text, theme, Tooltip } from '@dlpco/ginga-stone'
import styled, { css } from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { List } from '~/ui/components/utils/list/list'

export const ListBox = styled.div`
  border: 1px solid ${theme.light.color.borderLow};
  border-radius: ${theme.light.space.small};
  padding: ${theme.light.space.medium};
`

interface ListItemProps {
  showBorder?: boolean
}

export const ListItem = styled.div<ListItemProps>`
  ${({ showBorder = true }) =>
    showBorder === true
      ? css`
          border-bottom: 1px solid ${theme.light.color.borderLow};
          padding: ${theme.light.space.medium} 0;
        `
      : css`
          padding-top: ${theme.light.space.medium};
        `}

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  white-space: pre-wrap;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: 1px solid transparent;
    padding-bottom: 0;
  }
`

export const CustomTooltip = styled(Tooltip)`
  max-width: 30ch;
  z-index: 1001;
`
export interface ItemContent extends ListItemProps {
  title: string
  value: string
  description?: string
  color?: TextColor
  condition?: boolean
}

export function SharedCreditListBox({
  title,
  button,
  items
}: {
  title?: string
  button?: ReactNode
  items: ItemContent[]
}) {
  return (
    <Box>
      <Stack space="1rem">
        <If condition={Boolean(title)}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{ minHeight: `${theme.light.space.xLarge3}` }}
          >
            <Overline color="neutral">{title}</Overline>
            {button}
          </Flex>
        </If>
        <ListBox role="listbox">
          <List
            items={items}
            render={({ title, value, description, color, showBorder }, index) => {
              return (
                <ListItem key={index + title} showBorder={showBorder} role="listitem">
                  <Flex alignItems="center" gap="5px">
                    <Text size="medium" color="neutral" role="term">
                      {title}
                    </Text>
                    <If condition={Boolean(description)}>
                      <CustomTooltip content={description} placement="top">
                        <Icon use="round-info-outline" color="neutralLow" />
                      </CustomTooltip>
                    </If>
                  </Flex>
                  <Text size="medium" color={color || 'neutralHigh'} weight="semi" role="definition">
                    {value}
                  </Text>
                </ListItem>
              )
            }}
          />
        </ListBox>
      </Stack>
    </Box>
  )
}
