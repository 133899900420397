import { Stack } from '@dlpco/fluid-layout'

import { loanConcessionBasePath } from '~/domains/credit/ports/credit.routes'
import { SharedGenericCardError } from '~/domains/credit/shared/components/generic-card-error'
import { Box } from '~/domains/platform/design-system'
import { type WithPageConfigProps } from '~/domains/platform/infra/page-enhancers/with-page-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import Wong from '~/domains/platform/lib/wong'

import { ProposalProvider } from '../context/loan-concession-context'
import { LoanConcessionSteps } from '../entities'
import analitica from '../helpers/analitica'
import { useLoanConcessionFlow } from '../hooks/use-negotiations-proposal'

import { LoanConcessionBreadcrumb } from './loan-concession-flow-breadcrumb'
import { LoanConcessionFlowGuard, loanConcessionNavigate } from './loan-concession-flow-guard'
import { LoanConcessionFlowLoading } from './loan-concession-flow-loading'
import { LoanConcessionQrCodeStep } from './loan-concession-qrcode-step'
import { LoanConcessionSimulation } from './loan-concession-simulation'

export function LoanConcessionFlow({ entity }: WithPageConfigProps<'stone_account' | 'banking' | 'stone_account_kyc'>) {
  const { proposalId, offerId } = BlackBird.getQuery()
  const { data, isLoading, isError, refetch } = useLoanConcessionFlow({
    proposalId,
    offerId,
    document: entity?.document || ''
  })
  const { proposalProcessing, proposalAvailable, creditOffer } = data

  if (isLoading) return <LoanConcessionFlowLoading />
  if (isError) return <SharedGenericCardError />

  const steps: { key: LoanConcessionSteps; render: () => JSX.Element }[] = [
    {
      key: LoanConcessionSteps.SIMULATION,
      render: () => (
        <LoanConcessionSimulation
          creditOffer={creditOffer}
          proposalAvailable={proposalAvailable}
          proposalProcessing={proposalProcessing}
        />
      )
    },
    {
      key: LoanConcessionSteps.QR_CODE,
      render: () => <LoanConcessionQrCodeStep />
    }
  ]

  const arrowLeftIconClick = () => {
    analitica.events.breadcrumb.changeStep(BlackBird.getQuery().step)

    if (BlackBird.getQuery().step === LoanConcessionSteps.QR_CODE) {
      refetch()
      loanConcessionNavigate({ step: LoanConcessionSteps.SIMULATION })
    } else BlackBird.travelTo('/home')
  }

  return (
    <Box>
      <Stack space="1rem">
        <LoanConcessionBreadcrumb arrowLeftIconClickFn={arrowLeftIconClick} step={BlackBird.getQuery().step} />

        <ProposalProvider>
          <LoanConcessionFlowGuard proposalProcessing={proposalProcessing} creditOffer={creditOffer}>
            <Wong steps={steps} basePath={loanConcessionBasePath} />
          </LoanConcessionFlowGuard>
        </ProposalProvider>
      </Stack>
    </Box>
  )
}
