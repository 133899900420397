import { useState } from 'react'
import { AccordionItem, Button, Divider, Icon, ListItem, Text } from '@stone-payments/jade'

import { Box, Flex } from '~/domains/platform/design-system'
import { For, If } from '~/domains/platform/lib/utilities-components'

import { type LoanDetails, type TooltipType } from '../entities'

import {
  LoanDetailsJadeCETDrawer,
  LoanDetailsJadeDocumentsDrawer,
  LoanDetailsJadeIOFDrawer
} from './loan-details-jade-drawers'

export function LoanDetailsJadeAsideContractInformations({ aboutLoan }: { aboutLoan: LoanDetails['aboutLoan'] }) {
  const [isCETDrawerOpen, setIsCETDrawerOpen] = useState<boolean>(false)
  const [isIOFDrawerOpen, setIsIOFDrawerOpen] = useState<boolean>(false)
  const [isDocumentsDrawerOpen, setIsDocumentsDrawerOpen] = useState<boolean>(false)
  const tooltipMap: Record<TooltipType, () => void> = {
    iof: () => setIsIOFDrawerOpen(true),
    cet: () => setIsCETDrawerOpen(true),
    graceperiod: () => {}
  }

  return (
    <Box pt="0.5rem" pb="0.5rem">
      <AccordionItem title="Informações do contrato" hasSafeArea={false}>
        <For
          of={aboutLoan.informations}
          render={(item, index) => (
            <Box key={'loanInfo' + index}>
              <For
                of={item}
                render={(subItem, subItemIndex) => (
                  <Flex key={subItem.title + subItemIndex} width="fit-content">
                    <ListItem
                      content={
                        <ListItem.Content label={subItem.title} type="display" value={subItem.value.description} />
                      }
                      variant="neutral"
                    />
                    <If condition={subItem.hasTooltip && Boolean(subItem.tooltipType)}>
                      <Box paddingTop="9px" ml="-10px">
                        <Icon
                          data-testid={subItem.tooltipType + '-drawer-button'}
                          use="circle-info"
                          size="small"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (subItem.tooltipType) tooltipMap[subItem.tooltipType]()
                          }}
                        />
                      </Box>
                    </If>
                  </Flex>
                )}
              />
              <Divider />
            </Box>
          )}
        />

        <Box p="1rem 1.25rem 1.5rem">
          <Text variant="text-small" color="medium">
            Até quitar o empréstimo, pedidos de novos empréstimos em instituições que também usem seus recebimentos como
            garantia podem ser recusados.
          </Text>
        </Box>
        <Box padding="0 1.25rem 0.75rem">
          <Button style={{ width: '100%' }} onClick={() => setIsDocumentsDrawerOpen(true)}>
            Baixar documentos
          </Button>
        </Box>
      </AccordionItem>
      <LoanDetailsJadeCETDrawer isOpen={isCETDrawerOpen} setIsOpen={setIsCETDrawerOpen} />
      <LoanDetailsJadeIOFDrawer isOpen={isIOFDrawerOpen} setIsOpen={setIsIOFDrawerOpen} />
      <LoanDetailsJadeDocumentsDrawer
        isOpen={isDocumentsDrawerOpen}
        setIsOpen={setIsDocumentsDrawerOpen}
        documents={aboutLoan.documents}
      />
    </Box>
  )
}
