import { Text } from '@dlpco/ginga-stone'

import { If } from '~/domains/platform/lib/utilities-components'

import { CustomGrid, Description, NavigationText, StepListItemWrapper } from './styles'
import { type Variants } from './types'
import { variants } from './variants'

export interface StepListItemProps {
  title: string
  description?: string
  isCurrent?: boolean
  isClickable?: boolean
  variant?: Variants
  navigationText?: string
  showNavigationText?: boolean
  thirdLine?: string
  onNavigate?: () => void
}

export function StepListItem(props: StepListItemProps) {
  const {
    title,
    description,
    isCurrent,
    isClickable = false,
    variant = 'in-progress',
    navigationText,
    showNavigationText = false,
    thirdLine,
    onNavigate
  } = props

  const { icon, textColor } = variants[variant]

  return (
    <StepListItemWrapper
      aria-label={isCurrent ? 'Passo atual' : title}
      onClick={isClickable ? onNavigate : undefined}
      isClickable={isClickable}
    >
      <CustomGrid color={textColor}>
        {icon}
        <Text inheritColor weight={isCurrent ? 'semi' : 'regular'}>
          {title}
        </Text>

        <If condition={showNavigationText}>
          <NavigationText type="button" onClick={onNavigate}>
            {navigationText}
          </NavigationText>
        </If>

        <Description>{description}</Description>

        <If condition={!!thirdLine}>
          <Description>{thirdLine}</Description>
        </If>
      </CustomGrid>
    </StepListItemWrapper>
  )
}
