import { type ReactElement } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { type BadgeColor } from '@dlpco/ginga-components/cjs/components/badge/props/types'
import { type ButtonColor } from '@dlpco/ginga-components/cjs/components/button/props/types'
import { type IconId, Button, Heading, IconBadge, Text } from '@dlpco/ginga-stone'
import styled, { css } from 'styled-components'

import { Box } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

const BannerBox = styled.div<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor};
  `}
  text-decoration: none;
  border-radius: ${theme.radii.medium};
  box-shadow: ${theme.shadows.card};
  padding: 1rem 1.25rem;
`

export type IconBackgroundColor = 'lightPurple' | 'lighterGreen' | 'lightGray' | 'lightRed' | 'white' | 'info'

export interface CreditBannerProps {
  title: string
  description: string | ReactElement
  iconColor: BadgeColor
  iconName?: IconId
  showButton: boolean
  buttonText?: string
  buttonTextArialLabel?: string
  buttonColor?: ButtonColor
  buttonAction?: () => void
  onColorTheme?: boolean
}

export function SharedCreditBanner(props: CreditBannerProps) {
  const {
    title,
    description,
    iconColor,
    iconName = 'money-hand-outline',
    buttonColor,
    buttonText,
    buttonTextArialLabel,
    showButton,
    buttonAction,
    onColorTheme
  } = props

  return (
    <BannerBox backgroundColor={onColorTheme ? '#008E5A' : 'white'}>
      <IconBadge
        color={iconColor}
        icon={iconName}
        style={{ padding: '0.75rem', ...(onColorTheme && { background: '#ffffff' }) }}
      />
      <Box flexGrow="1">
        <Stack space="0.25rem">
          <Heading size="xSmall" weight="semi" color={onColorTheme ? 'onColorHigh' : 'neutralHigh'}>
            {title}
          </Heading>
          <Text size="small" color={onColorTheme ? 'onColorHigh' : 'neutral'}>
            {description}
          </Text>
        </Stack>
      </Box>
      <If condition={showButton}>
        <Button
          {...(buttonTextArialLabel && { 'aria-label': buttonTextArialLabel })}
          size="small"
          color={buttonColor}
          onClick={buttonAction}
          data-cy="button-banner-home"
        >
          {buttonText}
        </Button>
      </If>
    </BannerBox>
  )
}
