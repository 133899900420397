import {
  type CreditOffer,
  type CreditOfferRoles,
  type CreditOfferRolesRenegotiation,
  type LoanInstallment
} from '../../shared/entities'

export function getInstallmentsFromOffer(offer?: CreditOffer, installments?: LoanInstallment[]) {
  const installmentNumbers: number[] = offer?.discountSettlement?.securities[0].installmentNumbers || []
  return installments
    ? installments.filter(installment => installmentNumbers.includes(installment.installmentNumber))
    : []
}

function getConditions(roles: CreditOfferRoles[]): CreditOfferRolesRenegotiation {
  for (const role of roles) {
    if (role.conditions && role.conditions.length > 0) {
      return (role.conditions[0] as CreditOfferRolesRenegotiation) || ({} as CreditOfferRolesRenegotiation)
    }
  }
  return {} as CreditOfferRolesRenegotiation
}

export function findOfferConditionsType(offer?: CreditOffer): 'DiscountAmount' | 'DiscountRate' | null {
  if (offer?.roles) {
    const { type } = getConditions(offer.roles)
    return type
  }
  return null
}

export function getMaxConditionfromDiscountAmount(offer?: CreditOffer): number {
  if (offer?.roles) {
    const { max } = getConditions(offer.roles)
    return max
  }
  return 0
}
