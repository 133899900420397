import { Grid, Stack } from '@dlpco/fluid-layout'
import { Card, CardBody } from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'

function DefaultGrid() {
  return (
    <Grid gutter="2rem" min="20ch" style={{ marginTop: '2rem' }}>
      <Skeleton width="100%" height="8ch" />
      <div>
        <Skeleton width="100%" height="3ch" />
        <Skeleton width="100%" height="3ch" />
        <Skeleton width="100%" height="3ch" />
      </div>
    </Grid>
  )
}

export function LoanConcessionFlowLoading() {
  return (
    <Box>
      <Stack space="1rem">
        <Skeleton width="30%" height="3ch" aria-label="Empréstimo" />

        <Card aria-label="carregando simulação de empréstimo">
          <CardBody>
            <DefaultGrid />
            <DefaultGrid />
            <DefaultGrid />
          </CardBody>
        </Card>
      </Stack>
    </Box>
  )
}
